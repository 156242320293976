import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { omit } from 'lodash';
import { Loader } from '../../shared/ui/components/Loader';
import ReactTooltip from 'react-tooltip';
import WorkplaceFloorPlan from './WorkplaceFloorPlan';
import locationData from './location-data';
import WorkplaceReservationContainer from './WorkplaceReservation.container';
import useModalState from '../../utils/useModalState';
import * as actions from '../../store/actions';
import { ArrowBack } from '../../shared/ui/icons/core/ArrowBack';
import { screenRessolutions } from '../../shared/ui/constants/styleConstants';
import { useResize } from '../../shared/utils/hooks/useResize';
import styled from 'styled-components';
import { CustomSelectField } from '../../shared/ui/components/Select';
import { TabsComponent } from '../../shared/ui/components/Tabs/Tabs';
import { LegendAttribute } from '../../shared/ui/icons/core/LegendAttribute';
import { BookingInfoDialog } from '../../widgets/BookingWidgets/BookingInfo/BookingInfoDialog';
import CustomSwitch from '../../shared/ui/components/Switch';
import { GeneralInfoDialog } from '../../widgets/GeneralInfoDialog';
import { BookingConfirmationDialog } from '../../widgets/BookingWidgets/BookingConfirmationDialog';
import { BookingSuccessDialog } from '../../widgets/BookingWidgets/BookingSuccessDialog';
import { BookingErrorDialog } from '../../widgets/BookingWidgets/BookingErrorDialog';
import { StepLabel } from '../../entities/Profile/PrefillProfile/model/PrefillProfileComponent';

const WorkplaceReservationWrapper = styled.div`
  margin: 16px 0;
  @media (min-width: ${screenRessolutions.smallMobile}px) {
    width: 100%;
  }
  @media (min-width: ${screenRessolutions.laptop}px) {
    min-width: 1010px;
    max-width: 1518px;
  }
  @media (min-width: ${screenRessolutions.desktop}px) {
    min-width: 1536px;
    max-width: 1692px;
  }
`;
const WorkplaceReservationSection = styled.section`
  width: 100%;
`;

const LocationPickerWrapper = styled.div`
  margin-bottom: 35px;

  @media (max-width: 747px) {
    margin-bottom: 24px;
  }
`;

const ControlsWrapper = styled.div`
  @media (min-width: ${screenRessolutions.smallMobile}px) {
    display: grid;
    margin: 16px;
    grid-template-areas:
      'label label'
      'select select'
      'tabs tabs'
      'filters legend';
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: ${screenRessolutions.tablet}px) {
    margin: 16px 32px;
    grid-template-areas:
      'label filters'
      'select legend'
      'tabs legend';
    grid-template-columns: 1.5fr 0.75fr;
  }
  @media (min-width: ${screenRessolutions.laptop}px) {
    margin: 30px 0px;
    grid-template-areas:
      'label label label label'
      'select tabs legend filters';
    grid-template-columns: 240px ${props => props.tabsWrapper} 280px 280px;
  }
`;

export const SelectWrapper = styled.div`
  grid-area: select;
  @media (min-width: ${screenRessolutions.smallMobile}px) {
    margin: 16px 0 8px 0;
    width: 100%;
  }
  @media (min-width: ${screenRessolutions.tablet}px) {
    min-width: 340px;
    max-width: 639px;
  }
  @media (min-width: ${screenRessolutions.laptop}px) {
    min-width: 241px;
    max-width: 241px;
  }
`;
const TabsBlock = styled.div`
  grid-area: tabs;
  @media (min-width: ${screenRessolutions.smallMobile}px) {
    width: 100%;
    flex-direction: column;
    display: flex;
    background-color: white;
    border-radius: 8px;
    margin-bottom: 8px;
  }
  @media (min-width: ${screenRessolutions.tablet}px) {
    min-width: 340px;
    max-width: 639px;
  }
  @media (min-width: ${screenRessolutions.laptop}px) {
    background-color: transparent;
    margin: -12px 24px 0 24px;
    justify-content: center;
    width: fit-content;
    min-width: ${props => props.size || '100%'};
    max-width: ${props => props.size || '100%'};
  }
`;
export const LabelWrapper = styled.div`
  grid-area: label;
  @media (min-width: ${screenRessolutions.smallMobile}px) {
    width: 100%;
  }
  @media (min-width: ${screenRessolutions.tablet}px) {
    width: auto;
  }
`;
const LegendWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
  @media (min-width: ${screenRessolutions.laptop}px) {
    :first-child {
      margin-left: 20px;
    }
  }
`;
const Legend = styled.div`
  grid-area: legend;
  @media (min-width: ${screenRessolutions.mobile}px) {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  @media (min-width: ${screenRessolutions.tablet}px) {
    min-width: 137px;
    min-height: 58px;
    background-color: #ffffff;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
    flex-direction: column;
    padding: 8px 16px;
    justify-self: flex-end;
    align-self: flex-start;
    border-radius: 4px;
    :first-child {
      margin-bottom: 10px;
    }
  }
  @media (min-width: ${screenRessolutions.laptop}px) {
    flex-direction: row;
    margin-left: 24px;
    padding: 24px 8px;
    :first-child {
      margin-bottom: 0;
      margin-left: 0;
    }
  }
`;
const LegendValue = styled.div`
  display: flex;
  margin: 0 0 0 16px;
  justify-content: center;
  align-items: flex-start;
  & > p {
    margin: 0;
    font-weight: 300;
    flex-direction: row;
  }
`;
const FiltersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  grid-area: filters;
  & > p {
    font-weight: 300;
  }
`;
const Filters = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  & > p {
    margin: 0;
    font-weight: 300;
  }
  @media (min-width: ${screenRessolutions.tablet}px) {
    width: auto;
    justify-content: center;
  }
`;
const FloorPlan = styled.div`
  &.workplace-reservation__floor-plan {
    &--with-preloader > &-content {
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        display: block;
        top: 0;
        left: 0;
        z-index: 2;
        background-color: #fff;
      }
    }
    &--with-preloader > &-loader {
      display: block;
    }
  }
`;

const FloorPlanContent = styled.div`
  position: relative;
`;

const FloorPlanLoader = styled.div`
  display: none;
  position: absolute;
  z-index: 3;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const BookingModal = styled.div`
  @media (max-width: 1366px) {
    max-width: 450px;
    left: 50% !important;
    transform: translate(-50%) !important;
  }

  @media (max-width: 747px) {
    max-width: 100%;
    left: auto;
    transform: none;
  }
`;
const FloorLabel = styled.p`
  margin: 0;
  font-weight: 300;
  @media (min-width: ${screenRessolutions.smallMobile}px) {
    font-size: 14px;
  }
  @media (min-width: ${screenRessolutions.laptop}px) {
    font-size: 16px;
  }
`;
const BlockLabel = styled.p`
  display: ${props => (props.isShown ? 'flex' : 'none')};
  font-weight: 400;
  font-size: 14px;
  margin: 0 0 8px 0;
`;
// HELPERS
const subLocations = locationData.map(l => omit(l, ['rooms']));
const getSubLocationsInSelectedLocation = currentLocation => {
  const activeFloorsIds = currentLocation.officeFloors.filter(floor => floor.active).map(floor => floor.floor_id);

  return subLocations
    .filter(sub => sub.location_id === currentLocation.id)
    .filter(sub => activeFloorsIds.includes(sub.id));
};

// COMPONENT
const WorkplaceReservation = ({
  dispatch,
  getRoomsFiles,
  getActiveServiceRequests,
  locations,
  rooms,
  getServiceLocationIsAvailable,
  isFetching,
  locationsWithLoadedDataIds,
  history,
  customer,
  serviceRequestCreateLoadingStatus
}) => {
  const [locationOptions, setLocationOptions] = useState([]);
  const [floorOptions, setFloorOptions] = useState([]);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [selectedSubLocation, setSelectedSubLocation] = useState(null);
  const [formData, setFormData] = useState({
    location: '',
    floor: ''
  });
  const [tabValue, setTabValue] = useState(floorOptions.length > 0 ? floorOptions[0].value : '');
  const handleSelectChange = event => {
    dispatch(actions.requestServiceRequestCreate.reset());
    setFormData(prevState => ({ ...prevState, location: event.target.value }));
    const selectedLocation = locations.find(location => location.id === event.target.value);
    setCurrentLocation(selectedLocation);
  };
  const handleFocus = fieldName => {
    setIsFocused(prevFocused => ({
      ...prevFocused,
      [fieldName]: true
    }));
  };

  const handleBlur = (fieldName, type) => {
    setIsFocused(prevFocused => ({
      ...prevFocused,
      [fieldName]: false
    }));
  };
  const [isFocused, setIsFocused] = useState({
    location: false
  });
  const [isModalWindowOpen, openModalWindow, closeModal] = useModalState(false);
  const [unfixedRooms, setUnfixedRooms] = useState([]);
  const [filteredRooms, setFilteredRooms] = useState([]);
  const [zoom, setZoom] = useState('sm');
  const [isTouchDevice, setIsTouchDevice] = useState(false);
  const [checked, setChecked] = useState(false);

  const handleSwitchChange = event => {
    setChecked(event.target.checked);
    if (event.target.checked) {
      setOpenDialog(prevState => ({ ...prevState, message: true }));
    }
  };
  useEffect(() => {
    if (window.innerWidth < 1366) {
      setIsTouchDevice(true);
    } else setIsTouchDevice(false);
  }, []);
  const handleResize = () => {
    if (window.innerWidth < screenRessolutions.laptop) {
      setIsTouchDevice(true);
    } else {
      setIsTouchDevice(false);
    }
  };
  useResize(handleResize, 0);
  const handleTabChange = (event, newValue) => {
    const currentFloor = floorOptions.find(floor => floor.value === newValue);
    setTabValue(currentFloor.value);
    const newActiveFloor = getSubLocationsInSelectedLocation(currentLocation).find(
      location => location.id === newValue
    );
    setSelectedSubLocation(newActiveFloor);
  };
  useEffect(() => {
    if (locations) {
      const options = locations.reduce((acc, item) => {
        const floorsByLocation = item.officeFloors.filter(floor => floor.active).map(floor => floor.floor_id);
        const emptyLocation = floorsByLocation.length === 1 && floorsByLocation[0] === null ? item : '';
        acc.push({ value: item.id, label: item.t_name });
        return acc.filter(location => location.value !== emptyLocation.id);
      }, []);
      setLocationOptions(options);
      if (options && options.length > 0 && formData.location === '') {
        setFormData(prevState => ({ ...prevState, location: options[0].value }));
      }
    }
  }, [locations]);

  useEffect(() => {
    if (currentLocation) {
      //Получение данных по доступным рабочим местам
      getServiceLocationIsAvailable(currentLocation.id);
      const currentSubLocations = getSubLocationsInSelectedLocation(currentLocation).find(
        sub => sub.location_id === currentLocation.id
      );
      const floorsData = getSubLocationsInSelectedLocation(currentLocation);
      const floorsByLocation = floorsData.reduce((acc, floorData) => {
        acc.push({ value: floorData.id, label: floorData.t_name });
        return acc;
      }, []);
      setTabValue(floorsByLocation.length > 0 ? floorsByLocation[0].value : '');
      setSelectedSubLocation(currentSubLocations || []);
      setFloorOptions(floorsByLocation);
    }
  }, [currentLocation]);

  const plusZoom = () => {
    if (zoom === 'sm') {
      setZoom('md');
    } else {
      setZoom('lg');
    }
  };

  const minusZoom = () => {
    if (zoom === 'lg') {
      setZoom('md');
    } else {
      setZoom('sm');
    }
  };

  const closeModalWindow = useCallback(() => {
    dispatch(actions.requestServiceRequestCreate.reset());
    closeModal();
  }, [closeModal]); // eslint-disable-line

  useEffect(() => {
    if (serviceRequestCreateLoadingStatus.isSuccess) {
      setOpenDialog(prevState => ({ ...prevState, success: true }));
    } else if (serviceRequestCreateLoadingStatus.isFailure) {
      setOpenDialog(prevState => ({ ...prevState, error: true }));
    }
  }, [serviceRequestCreateLoadingStatus]); // eslint-disable-line

  // INITIAL LOADINGS
  useEffect(() => {
    dispatch(actions.requestServiceRequestCreate.reset());
    getActiveServiceRequests();
    getRoomsFiles();
    return () => closeModalWindow();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const wereDataReceived = useMemo(() => locations.length && rooms.length, [locations, rooms]);
  useEffect(() => {
    if (wereDataReceived) {
      setCurrentLocation(locations[0]);
      setFormData(prevState => ({
        ...prevState,
        location: locationOptions.length > 0 ? locationOptions[0].value : ''
      }));
      setUnfixedRooms(
        rooms.reduce((acc, room) => {
          if (room.is_unfixed == 1 && room.status_id === 1) {
            acc.push(room);
          }
          return acc;
        }, [])
      );
    }
  }, [wereDataReceived]);
  useEffect(() => {
    if (!!(selectedSubLocation && unfixedRooms)) {
      setFilteredRooms(unfixedRooms.filter(room => room.office_id === selectedSubLocation.location_id));
    }
  }, [selectedSubLocation, unfixedRooms]);

  // PLACE STATE
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [openDialog, setOpenDialog] = useState({
    info: false,
    booking: false,
    message: false,
    success: false,
    error: false,
    meetingRoomRedirect: false
  });
  useEffect(() => {
    if (selectedPlace && selectedPlace.tariff && selectedPlace.tariff.type_id === 1) {
      setOpenDialog(prevState => ({ ...prevState, meetingRoomRedirect: true }));
    } else if (!!selectedPlace) {
      setOpenDialog(prevState => ({ ...prevState, info: true }));
    }
  }, [selectedPlace]); // eslint-disable-line react-hooks/exhaustive-deps

  const currentLocationId = useMemo(() => (!selectedPlace ? null : selectedPlace.office_id), [selectedPlace]);

  if (isFetching() || !selectedSubLocation) {
    return <Loader wrapperType='transparent' />;
  }

  if (rooms.length === 0 || currentLocation === null) {
    return null;
  }
  const calculaTetabsWidth = () => {
    if (floorOptions.length > 1 && floorOptions.length < 3 && window.innerWidth > screenRessolutions.laptop) {
      return '110px';
    } else if (floorOptions.length > 2 && window.innerWidth > screenRessolutions.laptop) {
      return '400px';
    } else if (window.innerWidth > screenRessolutions.laptop && floorOptions.length === 1) {
      return '92px';
    } else return 0;
  };

  return (
    <WorkplaceReservationWrapper>
      <WorkplaceReservationSection>
        <ControlsWrapper tabsWrapper={calculaTetabsWidth}>
          <LabelWrapper>
            <StepLabel
              reservation={'reservation'}
              position={window.innerWidth < screenRessolutions.tablet ? 'center' : 'flexStart'}
            >
              {isTouchDevice && (
                <ArrowBack defaultColor={'#24272A'} spacing={'7px'} hoverColor={'#383c40'} rotation={0} />
              )}
              <p>Рабочие места и кабинеты</p>
            </StepLabel>
          </LabelWrapper>
          <SelectWrapper>
            <BlockLabel isShown={window.innerWidth > screenRessolutions.laptop}>Локация</BlockLabel>
            <CustomSelectField
              isFocused={isFocused}
              name='location'
              id='custom-select'
              label={'Локация'}
              variant='filled'
              value={formData.location}
              onFocus={() => handleFocus('location')}
              onBlur={() => handleBlur('location')}
              onChange={handleSelectChange}
              options={locationOptions}
            />
          </SelectWrapper>
          {floorOptions && floorOptions.length > 0 && (
            <TabsBlock
              size={
                window.innerWidth > screenRessolutions.laptop && floorOptions.length > 2
                  ? '400px'
                  : window.innerWidth < screenRessolutions.laptop && floorOptions.length > 2
                  ? '300px'
                  : '110px'
              }
            >
              <BlockLabel isShown={window.innerWidth > screenRessolutions.laptop}>Этаж</BlockLabel>
              {floorOptions.length > 1 && window.innerWidth < screenRessolutions.laptop ? (
                <TabsComponent
                  tabsContainerStyle={{ width: '100%', height: floorOptions.length > 2 ? '48px' : '32px' }}
                  tabStyle={{
                    height: floorOptions.length > 2 ? '40px' : '24px',
                    width: floorOptions.length > 2 ? '90px' : '158px'
                  }}
                  tabValue={tabValue}
                  handleTabChange={handleTabChange}
                  tabs={floorOptions}
                />
              ) : floorOptions.length > 1 && floorOptions.length ? (
                <TabsComponent
                  tabsContainerStyle={
                    window.innerWidth > screenRessolutions.laptop &&
                    floorOptions.some(option => option.label.includes('блок'))
                      ? { width: '100%', height: '48px' }
                      : { width: '100%', height: '48px' }
                  }
                  tabStyle={
                    window.innerWidth > screenRessolutions.laptop &&
                    floorOptions.some(option => option.label.includes('блок'))
                      ? { height: '40px', width: '124px' }
                      : { height: '40px', width: '49px' }
                  }
                  tabValue={tabValue}
                  handleTabChange={handleTabChange}
                  tabs={
                    window.innerWidth > screenRessolutions.laptop
                      ? floorOptions.reduce((acc, option) => {
                          acc.push({ value: option.value, label: option.label.replace('этаж', '') });
                          return acc;
                        }, [])
                      : floorOptions
                  }
                />
              ) : (
                <FloorLabel>{floorOptions[0].label}</FloorLabel>
              )}
            </TabsBlock>
          )}
          <FiltersWrapper>
            <Filters>
              <CustomSwitch size={'small'} spacing={'6px'} checked={checked} onChange={handleSwitchChange} />
              <p>Нефиксированное</p>
            </Filters>
            {/*<p>Для выбора нефиксированного рабочего места кликните на любую зеленую область на карте</p>*/}
          </FiltersWrapper>
          {floorOptions.length > 0 && (
            <LegendWrapper>
              <BlockLabel isShown={window.innerWidth > screenRessolutions.laptop}>Место на карте</BlockLabel>
              <Legend>
                <LegendValue>
                  <LegendAttribute type={'available'} />
                  <p>Доступно</p>
                </LegendValue>
                <LegendValue>
                  <LegendAttribute type={'busy'} />
                  <p>Недоступно</p>
                </LegendValue>
              </Legend>
            </LegendWrapper>
          )}
        </ControlsWrapper>
        <div className='workplace-picker'>
          <FloorPlan>
            <FloorPlanContent>
              <WorkplaceFloorPlan
                filtersActive={checked}
                currentSubLocation={selectedSubLocation}
                rooms={rooms}
                setSelectBookingPlace={setSelectedPlace}
                zoom={zoom}
                plusZoom={plusZoom}
                minusZoom={minusZoom}
              />
            </FloorPlanContent>
            <FloorPlanLoader>
              <Loader type='ThreeDots' color='#f15a4f' height='25' width='40' />
            </FloorPlanLoader>
          </FloorPlan>
        </div>
        {openDialog.message && (
          <GeneralInfoDialog
            dialogType={'generalInfo'}
            alert={'Для выбора нефиксированного рабочего места кликните на любую зеленую область на карте'}
            dialogData={
              'При бронировании нефиксированного рабочего места вы сможете пользоваться только местами общего пользования: кухнями, Skype-переговорными, террасами и мягкими зонами\n'
            }
            title={'Нефиксированное рабочее место'}
            buttonLabel={'Понятно, спасибо'}
            handleSubmit={() => setOpenDialog(prevState => ({ ...prevState, message: false }))}
          />
        )}
        {openDialog.meetingRoomRedirect && (
          <GeneralInfoDialog
            dialogType={'generalInfo'}
            alert={'Для бронирования переговорных перейдите в раздел "Переговорные", либо нажмите на кнопку ниже'}
            dialogData={
              'Переговорные комнаты можно бронировать на определенное время по часам либо целым днем. Если ваш тариф подразумевает бесплатные часы - они будут вычтены из общей стоимости либо полностью покроют цену на время брони'
            }
            title={'Переговорная комната'}
            buttonLabel={'Бронирование переговорных'}
            handleSubmit={() => history.push('/room-reservation')}
            handleClose={() => {
              setOpenDialog(prevState => ({ ...prevState, meetingRoomRedirect: false }));
              setSelectedPlace(null);
            }}
          />
        )}
        {openDialog.info && (
          <BookingInfoDialog
            unfixedWorkplaces={unfixedRooms}
            dialogType='bookingInfo'
            customer={customer}
            workplaceInfo={selectedPlace}
            handleSubmit={() => {
              setOpenDialog(prevState => ({ ...prevState, info: false, success: false, error: false, booking: true }));
              dispatch(actions.requestServiceRequestCreate.reset());
            }}
            handleClose={() => {
              setOpenDialog(prevState => ({ ...prevState, info: false }));
              setSelectedPlace(null);
            }}
          />
        )}
        {openDialog.booking && (
          <BookingConfirmationDialog
            dialogType='bookingConfirmation'
            workplaceInfo={selectedPlace}
            currentLocationValue={currentLocation && currentLocation.t_name}
            currentSubLocationValue={selectedSubLocation && selectedSubLocation.t_name}
            unfixedWorkplaces={unfixedRooms}
            customer={customer}
            handleSubmit={() => {
              setOpenDialog(prevState => ({ ...prevState, booking: false }));
            }}
            handlePrevStep={() => {
              setOpenDialog(prevState => ({ ...prevState, booking: false, info: true }));
            }}
            handleClose={() => {
              setOpenDialog(prevState => ({ ...prevState, booking: false }));
              setSelectedPlace(null);
            }}
          />
        )}
        {openDialog.success && (
          <BookingSuccessDialog
            dialogType='bookingSuccess'
            buttonLabel={'Готово'}
            customer={customer}
            workplaceInfo={selectedPlace}
            currentLocationValue={currentLocation && currentLocation.t_name}
            currentSubLocationValue={selectedSubLocation && selectedSubLocation.t_name}
            unfixedWorkplaces={unfixedRooms}
            handleSubmit={() => {
              setOpenDialog(prevState => ({ ...prevState, success: false }));
              setSelectedPlace(null);
            }}
          />
        )}
        {openDialog.error && (
          <BookingErrorDialog
            dialogType='bookingError'
            buttonLabel={'Понятно'}
            customer={customer}
            dialogData={'Средства зачислены на ваш внутренний баланс. Выберите другое место и оплатите с баланса.'}
            title={'Место занял кто-то другой'}
            handleSubmit={() => {
              setOpenDialog(prevState => ({ ...prevState, error: false }));
              setSelectedPlace(null);
            }}
          />
        )}

        <ReactTooltip
          id='amount-informer'
          type='dark'
          place='right'
          effect='solid'
          multiline
          getContent={dataTip =>
            dataTip && isModalWindowOpen ? (
              <span className='text-light-weight'>
                Стоимость аренды выбранного рабочего
                <br /> места рассчитана за период до конца месяца.
                <br /> Стоимость за полный календарный месяц
                <br /> составляет {dataTip} &#8381;
              </span>
            ) : null
          }
        />
        <ReactTooltip id='setup-fee' type='dark' place='bottom' effect='solid'>
          <span className='text-light-weight'>SETUP FEE - плата за подготовку рабочего пространства</span>
        </ReactTooltip>
      </WorkplaceReservationSection>
    </WorkplaceReservationWrapper>
  );
};

export default WorkplaceReservationContainer(WorkplaceReservation);
