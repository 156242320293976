import { WorkplaceType, AreaType } from '../types';

export const NEO_GEO_FLOOR_1_A_AREAS = [
  // Буквы в названии и айди переговорок на латинице
  {
    uuid: 'area-m01',
    type: AreaType.MEETING_ROOM,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'M01',
    svgPath: 'M1120 610H1030V465H1060V467H1120V610Z',
    workplaces: []
  },
  {
    uuid: 'area-m02',
    type: AreaType.MEETING_ROOM,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'M02',
    svgPath: 'M460 600H277V510H275V480H460V600Z',
    workplaces: []
  },
  {
    uuid: 'area-m03',
    type: AreaType.MEETING_ROOM,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'M03',
    svgPath: 'M20 610V470H110V480H115V510H113V590H110V610H20Z',
    workplaces: []
  },
  {
    uuid: 'area-m04',
    type: AreaType.MEETING_ROOM,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'M04',
    svgPath: 'M630 430V327H753V390H755V420H753V430H630Z',
    workplaces: []
  },
  {
    uuid: 'area-a8',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'A8',
    svgPath: 'M1200 842H1290V930H1280V950H1200V930H1197V905H1195V875H1197V850H1200V842Z',
    workplaces: [
      { uuid: 'work-a8-1', name: 'A8', position: { x: 1250, y: 845, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a8-2', name: 'A8', position: { x: 1210, y: 845, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a8-3', name: 'A8', position: { x: 1240, y: 910, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a8-3', name: 'A8', position: { x: 1200, y: 910, angle: 0 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-a9',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'A9',
    svgPath: 'M1280 750H1290V838H1200V830H1197V800H1195V770H1197V740H1280V750Z',
    workplaces: [
      { uuid: 'work-a9-1', name: 'A9', position: { x: 1240, y: 740, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a9-2', name: 'A9', position: { x: 1200, y: 740, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a9-3', name: 'A9', position: { x: 1250, y: 795, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a9-4', name: 'A9', position: { x: 1210, y: 795, angle: 0 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-a10',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'A10',
    svgPath: 'M587 757H653V790H655V820H653V850H600V830H587V757Z',
    workplaces: [
      { uuid: 'work-a10-1', name: 'A10', position: { x: 600, y: 760, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a10-2', name: 'A10', position: { x: 600, y: 810, angle: 0 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-a11',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'A11',
    svgPath: 'M587 697H653V720H655V750H653V753H587V697Z',
    workplaces: [
      { uuid: 'work-a11-1', name: 'A11', position: { x: 590, y: 710, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-a12',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'A12',
    svgPath: 'M550 697H583V830H580V850H387V697H420V695H450V697H520V695H550V697Z',
    workplaces: [
      { uuid: 'work-a12-1', name: 'A12', position: { x: 540, y: 710, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a12-2', name: 'A12', position: { x: 540, y: 750, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a12-3', name: 'A12', position: { x: 540, y: 790, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a12-4', name: 'A12', position: { x: 485, y: 750, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a12-5', name: 'A12', position: { x: 485, y: 790, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a12-6', name: 'A12', position: { x: 445, y: 750, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a12-7', name: 'A12', position: { x: 445, y: 790, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a12-8', name: 'A12', position: { x: 390, y: 710, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a12-9', name: 'A12', position: { x: 390, y: 750, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a12-10', name: 'A12', position: { x: 390, y: 790, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-a14',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'A14',
    svgPath: 'M340 697H383V830H380V850H290V830H287V697H310V695H340V697Z',
    workplaces: [
      { uuid: 'work-a14-1', name: 'A14', position: { x: 340, y: 710, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a14-2', name: 'A14', position: { x: 340, y: 750, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a14-3', name: 'A14', position: { x: 340, y: 790, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a14-4', name: 'A14', position: { x: 290, y: 730, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a14-5', name: 'A14', position: { x: 290, y: 770, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-a15',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'A15',
    svgPath: 'M280 697H283V830H280V850H220V697H250V695H280V697Z',
    workplaces: [
      { uuid: 'work-a15-1', name: 'A15', position: { x: 220, y: 710, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a15-2', name: 'A15', position: { x: 220, y: 750, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a15-3', name: 'A15', position: { x: 220, y: 790, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-a16',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'A16',
    svgPath: 'M150 920H200V1035H170V1090H120V915H150V920Z',
    workplaces: [
      { uuid: 'work-a16-1', name: 'A16', position: { x: 160, y: 930, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a16-2', name: 'A16', position: { x: 160, y: 970, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a16-3', name: 'A16', position: { x: 120, y: 1050, angle: 0 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-a7',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'A7',
    svgPath: 'M20 30H180V290H150V295H120V290H70V160H20V30Z',
    workplaces: [
      { uuid: 'work-a7-1', name: 'A7', position: { x: 140, y: 40, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a7-2', name: 'A7', position: { x: 140, y: 80, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a7-3', name: 'A7', position: { x: 140, y: 120, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a7-4', name: 'A7', position: { x: 140, y: 160, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a7-5', name: 'A7', position: { x: 140, y: 200, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a7-6', name: 'A7', position: { x: 140, y: 240, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a7-7', name: 'A7', position: { x: 20, y: 40, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a7-12', name: 'A7', position: { x: 20, y: 80, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a7-8', name: 'A7', position: { x: 20, y: 120, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a7-9', name: 'A7', position: { x: 70, y: 160, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a7-10', name: 'A7', position: { x: 70, y: 200, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a7-11', name: 'A7', position: { x: 70, y: 240, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a7-12', name: 'A7', position: { x: 80, y: 30, angle: 180 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-a6',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'A6',
    svgPath: 'M280 250H283V383H280V385H250V383H220V380H200V235H280V250Z',
    workplaces: [
      { uuid: 'work-a6-1', name: 'A6', position: { x: 200, y: 250, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a6-2', name: 'A6', position: { x: 200, y: 290, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a6-3', name: 'A6', position: { x: 200, y: 330, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-a5',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'A5',
    svgPath: 'M380 250H383V383H340V385H310V383H287V250H290V235H380V250Z',
    workplaces: [
      { uuid: 'work-a5-1', name: 'A5', position: { x: 290, y: 270, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a5-2', name: 'A5', position: { x: 290, y: 310, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a5-3', name: 'A5', position: { x: 340, y: 250, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a5-4', name: 'A5', position: { x: 340, y: 290, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a5-5', name: 'A5', position: { x: 340, y: 330, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-a4',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'A4',
    svgPath: 'M400 235H483V383H450V385H420V383H387V250H400V235Z',
    workplaces: [
      { uuid: 'work-a4-1', name: 'A4', position: { x: 390, y: 250, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a4-2', name: 'A4', position: { x: 390, y: 290, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a4-3', name: 'A4', position: { x: 390, y: 330, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a4-4', name: 'A4', position: { x: 440, y: 250, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a4-5', name: 'A4', position: { x: 440, y: 290, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a4-6', name: 'A4', position: { x: 440, y: 330, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-a3',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'A3',
    svgPath: 'M487 383V235H580V250H583V383H550V385H520V383H487Z',
    workplaces: [
      { uuid: 'work-a3-1', name: 'A3', position: { x: 490, y: 250, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a3-2', name: 'A3', position: { x: 490, y: 290, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a3-3', name: 'A3', position: { x: 490, y: 330, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a3-4', name: 'A3', position: { x: 540, y: 250, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a3-5', name: 'A3', position: { x: 540, y: 290, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a3-6', name: 'A3', position: { x: 540, y: 330, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-a2',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'A2',
    svgPath: 'M587 323V250H600V230H650V235H653V260H655V290H653V323H587Z',
    workplaces: [
      { uuid: 'work-a2-1', name: 'A2', position: { x: 600, y: 230, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a2-2', name: 'A2', position: { x: 600, y: 280, angle: 0 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-a1',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'A1',
    svgPath: 'M587 383V327H653V330H655V360H653V383H587Z',
    workplaces: [
      { uuid: 'work-a1-1', name: 'A1', position: { x: 590, y: 340, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'NG-1A-OP-1',
    uuid: 'area-ng-1a-op-sp-1',
    type: AreaType.OPEN_SPACE,
    position: { x: 720, y: 20 },
    svgPath: 'M730 850V700H1150V950H1000V930H969V865H975V860H1000V830H980V840H950V850H800V830H780V850H730Z',
    workplaces: [
      {
        name: 'K30',
        textRotate: true,
        uuid: 'ng1a-op-1-30',
        type: WorkplaceType.DEFAULT,
        position: { x: 1090, y: 710, angle: -90 }
      },
      {
        name: 'K29',
        textRotate: true,
        uuid: 'ng1a-op-1-29',
        type: WorkplaceType.DEFAULT,
        position: { x: 1090, y: 750, angle: -90 }
      },
      {
        name: 'K28',
        textRotate: true,
        uuid: 'ng1a-op-1-28',
        type: WorkplaceType.DEFAULT,
        position: { x: 1090, y: 790, angle: -90 }
      },
      {
        name: 'K27',
        textRotate: true,
        uuid: 'ng1a-op-1-27',
        type: WorkplaceType.DEFAULT,
        position: { x: 1090, y: 830, angle: -90 }
      },
      {
        name: 'K26',
        textRotate: true,
        uuid: 'ng1a-op-1-26',
        type: WorkplaceType.DEFAULT,
        position: { x: 1090, y: 870, angle: -90 }
      },
      {
        name: 'K35',
        uuid: 'ng1a-op-1-35',
        type: WorkplaceType.DEFAULT,
        position: { x: 1040, y: 710, angle: 90 }
      },
      {
        name: 'K34',
        uuid: 'ng1a-op-1-34',
        type: WorkplaceType.DEFAULT,
        position: { x: 1040, y: 750, angle: 90 }
      },
      {
        name: 'K33',
        uuid: 'ng1a-op-1-33',
        type: WorkplaceType.DEFAULT,
        position: { x: 1040, y: 790, angle: 90 }
      },
      {
        name: 'K32',
        uuid: 'ng1a-op-1-32',
        type: WorkplaceType.DEFAULT,
        position: { x: 1040, y: 830, angle: 90 }
      },
      {
        name: 'K31',
        uuid: 'ng1a-op-1-31',
        type: WorkplaceType.DEFAULT,
        position: { x: 1040, y: 870, angle: 90 }
      },
      {
        name: 'K40',
        textRotate: true,
        uuid: 'ng1a-op-1-40',
        type: WorkplaceType.DEFAULT,
        position: { x: 1000, y: 710, angle: -90 }
      },
      {
        name: 'K39',
        textRotate: true,
        uuid: 'ng1a-op-1-39',
        type: WorkplaceType.DEFAULT,
        position: { x: 1000, y: 750, angle: -90 }
      },
      {
        name: 'K38',
        textRotate: true,
        uuid: 'ng1a-op-1-38',
        type: WorkplaceType.DEFAULT,
        position: { x: 1000, y: 790, angle: -90 }
      },
      {
        name: 'K37',
        textRotate: true,
        uuid: 'ng1a-op-1-37',
        type: WorkplaceType.DEFAULT,
        position: { x: 1000, y: 830, angle: -90 }
      },
      {
        name: 'K36',
        textRotate: true,
        uuid: 'ng1a-op-1-36',
        type: WorkplaceType.DEFAULT,
        position: { x: 1000, y: 870, angle: -90 }
      },
      {
        name: 'K43',
        uuid: 'ng1a-op-1-43',
        type: WorkplaceType.DEFAULT,
        position: { x: 930, y: 710, angle: 90 }
      },
      {
        name: 'K42',
        uuid: 'ng1a-op-1-42',
        type: WorkplaceType.DEFAULT,
        position: { x: 930, y: 750, angle: 90 }
      },
      {
        name: 'K41',
        uuid: 'ng1a-op-1-41',
        type: WorkplaceType.DEFAULT,
        position: { x: 930, y: 790, angle: 90 }
      },
      {
        name: 'K46',
        textRotate: true,
        uuid: 'ng1a-op-1-46',
        type: WorkplaceType.DEFAULT,
        position: { x: 890, y: 710, angle: -90 }
      },
      {
        name: 'K45',
        textRotate: true,
        uuid: 'ng1a-op-1-45',
        type: WorkplaceType.DEFAULT,
        position: { x: 890, y: 750, angle: -90 }
      },
      {
        name: 'K44',
        textRotate: true,
        uuid: 'ng1a-op-1-44',
        type: WorkplaceType.DEFAULT,
        position: { x: 890, y: 790, angle: -90 }
      },
      {
        name: 'K49',
        uuid: 'ng1a-op-1-49',
        type: WorkplaceType.DEFAULT,
        position: { x: 840, y: 710, angle: 90 }
      },
      {
        name: 'K48',
        uuid: 'ng1a-op-1-48',
        type: WorkplaceType.DEFAULT,
        position: { x: 840, y: 750, angle: 90 }
      },
      {
        name: 'K47',
        uuid: 'ng1a-op-1-47',
        type: WorkplaceType.DEFAULT,
        position: { x: 840, y: 790, angle: 90 }
      },
      {
        name: 'K52',
        textRotate: true,
        uuid: 'ng1a-op-1-52',
        type: WorkplaceType.DEFAULT,
        position: { x: 800, y: 710, angle: -90 }
      },
      {
        name: 'K51',
        textRotate: true,
        uuid: 'ng1a-op-1-51',
        type: WorkplaceType.DEFAULT,
        position: { x: 800, y: 750, angle: -90 }
      },
      {
        name: 'K50',
        textRotate: true,
        uuid: 'ng1a-op-1-50',
        type: WorkplaceType.DEFAULT,
        position: { x: 800, y: 790, angle: -90 }
      },
      {
        name: 'K55',
        uuid: 'ng1a-op-1-55',
        type: WorkplaceType.DEFAULT,
        position: { x: 750, y: 710, angle: 90 }
      },
      {
        name: 'K54',
        uuid: 'ng1a-op-1-54',
        type: WorkplaceType.DEFAULT,
        position: { x: 750, y: 750, angle: 90 }
      },
      {
        name: 'K53',
        uuid: 'ng1a-op-1-53',
        type: WorkplaceType.DEFAULT,
        position: { x: 750, y: 790, angle: 90 }
      }
    ]
  },
  {
    name: 'NG-1A-OP-2',
    uuid: 'area-ng-1a-op-sp-2',
    type: AreaType.OPEN_SPACE,
    position: { x: 720, y: 20 },
    svgPath: 'M730 380V230H780V250H800V230H980V250H1000V230H1170V250H1190V380H730Z',
    workplaces: [
      {
        name: 'K2',
        uuid: 'ng1a-op-2-2',
        type: WorkplaceType.DEFAULT,
        position: { x: 1130, y: 250, angle: 90 }
      },
      {
        name: 'K1',
        uuid: 'ng1a-op-2-1',
        type: WorkplaceType.DEFAULT,
        position: { x: 1130, y: 290, angle: 90 }
      },
      {
        name: 'K4',
        textRotate: true,
        uuid: 'ng1a-op-2-4',
        type: WorkplaceType.DEFAULT,
        position: { x: 1090, y: 250, angle: -90 }
      },
      {
        name: 'K3',
        textRotate: true,
        uuid: 'ng1a-op-2-3',
        type: WorkplaceType.DEFAULT,
        position: { x: 1090, y: 290, angle: -90 }
      },
      {
        name: 'K7',
        uuid: 'ng1a-op-2-7',
        type: WorkplaceType.DEFAULT,
        position: { x: 1040, y: 250, angle: 90 }
      },
      {
        name: 'K6',
        uuid: 'ng1a-op-2-6',
        type: WorkplaceType.DEFAULT,
        position: { x: 1040, y: 290, angle: 90 }
      },
      {
        name: 'K5',
        uuid: 'ng1a-op-2-5',
        type: WorkplaceType.DEFAULT,
        position: { x: 1040, y: 330, angle: 90 }
      },
      {
        name: 'K10',
        textRotate: true,
        uuid: 'ng1a-op-2-10',
        type: WorkplaceType.DEFAULT,
        position: { x: 1000, y: 250, angle: -90 }
      },
      {
        name: 'K9',
        textRotate: true,
        uuid: 'ng1a-op-2-9',
        type: WorkplaceType.DEFAULT,
        position: { x: 1000, y: 290, angle: -90 }
      },
      {
        name: 'K8',
        textRotate: true,
        uuid: 'ng1a-op-2-8',
        type: WorkplaceType.DEFAULT,
        position: { x: 1000, y: 330, angle: -90 }
      },
      {
        name: 'K15',
        uuid: 'ng1a-op-2-15',
        type: WorkplaceType.DEFAULT,
        position: { x: 930, y: 250, angle: 90 }
      },
      {
        name: 'K13',
        uuid: 'ng1a-op-2-13',
        type: WorkplaceType.DEFAULT,
        position: { x: 930, y: 290, angle: 90 }
      },
      {
        name: 'K11',
        uuid: 'ng1a-op-2-11',
        type: WorkplaceType.DEFAULT,
        position: { x: 930, y: 330, angle: 90 }
      },
      {
        name: 'K16',
        textRotate: true,
        uuid: 'ng1a-op-2-16',
        type: WorkplaceType.DEFAULT,
        position: { x: 890, y: 250, angle: -90 }
      },
      {
        name: 'K14',
        textRotate: true,
        uuid: 'ng1a-op-2-14',
        type: WorkplaceType.DEFAULT,
        position: { x: 890, y: 290, angle: -90 }
      },
      {
        name: 'K12',
        textRotate: true,
        uuid: 'ng1a-op-2-12',
        type: WorkplaceType.DEFAULT,
        position: { x: 890, y: 330, angle: -90 }
      },
      {
        name: 'K21',
        uuid: 'ng1a-op-2-21',
        type: WorkplaceType.DEFAULT,
        position: { x: 840, y: 250, angle: 90 }
      },
      {
        name: 'K19',
        uuid: 'ng1a-op-2-19',
        type: WorkplaceType.DEFAULT,
        position: { x: 840, y: 290, angle: 90 }
      },
      {
        name: 'K17',
        uuid: 'ng1a-op-2-17',
        type: WorkplaceType.DEFAULT,
        position: { x: 840, y: 330, angle: 90 }
      },
      {
        name: 'K22',
        textRotate: true,
        uuid: 'ng1a-op-2-22',
        type: WorkplaceType.DEFAULT,
        position: { x: 800, y: 250, angle: -90 }
      },
      {
        name: 'K20',
        textRotate: true,
        uuid: 'ng1a-op-2-20',
        type: WorkplaceType.DEFAULT,
        position: { x: 800, y: 290, angle: -90 }
      },
      {
        name: 'K18',
        textRotate: true,
        uuid: 'ng1a-op-2-18',
        type: WorkplaceType.DEFAULT,
        position: { x: 800, y: 330, angle: -90 }
      },
      {
        name: 'K25',
        uuid: 'ng1a-op-2-25',
        type: WorkplaceType.DEFAULT,
        position: { x: 750, y: 250, angle: 90 }
      },
      {
        name: 'K24',
        uuid: 'ng1a-op-2-24',
        type: WorkplaceType.DEFAULT,
        position: { x: 750, y: 290, angle: 90 }
      },
      {
        name: 'K23',
        uuid: 'ng1a-op-2-23',
        type: WorkplaceType.DEFAULT,
        position: { x: 750, y: 330, angle: 90 }
      }
    ]
  },
  {
    uuid: 'PS-1',
    type: AreaType.CABINET,
    zones: [
      {
        position: { x: 220, y: 40 },
        svgPath: 'M220 210V40H1170H1540V105H1170V210H220Z'
      },
      {
        position: { x: 1200, y: 130 },
        svgPath: 'M1480 129H1200V350H1380V310H1430V315H1460V150H1480V129Z'
      },
      {
        position: { x: 470, y: 480 },
        svgPath: 'M669 600H470V480H669V600Z'
      },
      {
        position: { x: 880, y: 470 },
        svgPath: 'M1020 470V610H940V620H980V650H880V610V470H1020Z'
      },
      {
        position: { x: 215, y: 873 },
        svgPath: 'M215 873V1040H1285V1240H1390V968H967V873H215Z'
      },
      //Зоны переговорных комнат
      {
        position: { x: 80, y: 300 },
        svgPath: 'M115 300H80V340H115V300Z'
      },
      {
        position: { x: 80, y: 350 },
        svgPath: 'M115 350H80V380H115V350Z'
      },
      {
        position: { x: 80, y: 390 },
        svgPath: 'M115 390H80V420H115V390Z'
      },
      {
        position: { x: 80, y: 430 },
        svgPath: 'M115 430H80V460H115V430Z'
      },
      {
        position: { x: 1130, y: 460 },
        svgPath: 'M1165 460H1130V490H1165V460Z'
      },
      {
        position: { x: 1130, y: 500 },
        svgPath: 'M1165 500H1130V530H1165V500Z'
      },
      {
        position: { x: 1130, y: 540 },
        svgPath: 'M1165 540H1130V570H1165V540Z'
      },
      {
        position: { x: 1130, y: 580 },
        svgPath: 'M1165 580H1130V610H1165V580Z'
      }
    ],
    name: 'Нефиксированное рабочее место'
  }
];
export const NEO_GEO_FLOOR_1_C_AREAS = [
  // Буквы в названии и айди переговорок на латинице
  {
    uuid: 'area-m04',
    type: AreaType.MEETING_ROOM,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'M04',
    svgPath: 'M630 430V327H753V390H755V420H753V430H630Z',
    workplaces: []
  },
  {
    uuid: 'area-m05',
    type: AreaType.MEETING_ROOM,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'M06',
    svgPath: 'M630 550V440H753V490H755V520H753V550H630Z',
    workplaces: []
  },
  {
    uuid: 'area-m06',
    type: AreaType.MEETING_ROOM,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'M07',
    svgPath: 'M753 663H627V570H630V560H753V590H755V620H753V663Z',
    workplaces: []
  },
  {
    uuid: 'area-m07',
    type: AreaType.MEETING_ROOM,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'M05',
    svgPath: 'M1480 320H1260V310H1257V197H1383H1430V195H1460V197H1483V310H1480V320Z',
    workplaces: []
  },
  {
    name: 'NG-1C-OP-1',
    uuid: 'area-ng-1a-op-sp-1',
    type: AreaType.OPEN_SPACE,
    position: { x: 70, y: 60 },
    svgPath: '',
    workplaces: [
      {
        name: 'K87',
        textRotate: true,
        uuid: 'ng1a-op-1-87',
        type: WorkplaceType.DEFAULT,
        position: { x: 70, y: 60, angle: -90 }
      },
      {
        name: 'K86',
        textRotate: true,
        uuid: 'ng1a-op-1-86',
        type: WorkplaceType.DEFAULT,
        position: { x: 70, y: 100, angle: -90 }
      },
      {
        name: 'K85',
        textRotate: true,
        uuid: 'ng1a-op-1-85',
        type: WorkplaceType.DEFAULT,
        position: { x: 70, y: 140, angle: -90 }
      },
      {
        name: 'K84',
        textRotate: true,
        uuid: 'ng1a-op-1-84',
        type: WorkplaceType.DEFAULT,
        position: { x: 70, y: 180, angle: -90 }
      },
      {
        name: 'K83',
        textRotate: false,
        uuid: 'ng1a-op-1-83',
        type: WorkplaceType.DEFAULT,
        position: { x: 110, y: 60, angle: 90 }
      },
      {
        name: 'K82',
        textRotate: false,
        uuid: 'ng1a-op-1-82',
        type: WorkplaceType.DEFAULT,
        position: { x: 110, y: 100, angle: 90 }
      },
      {
        name: 'K81',
        textRotate: false,
        uuid: 'ng1a-op-1-81',
        type: WorkplaceType.DEFAULT,
        position: { x: 110, y: 140, angle: 90 }
      },
      {
        name: 'K80',
        textRotate: false,
        uuid: 'ng1a-op-1-80',
        type: WorkplaceType.DEFAULT,
        position: { x: 110, y: 180, angle: 90 }
      },
      ///
      {
        name: 'K79',
        textRotate: true,
        uuid: 'ng1a-op-1-79',
        type: WorkplaceType.DEFAULT,
        position: { x: 190, y: 60, angle: -90 }
      },
      {
        name: 'K78',
        textRotate: true,
        uuid: 'ng1a-op-1-78',
        type: WorkplaceType.DEFAULT,
        position: { x: 190, y: 100, angle: -90 }
      },
      {
        name: 'K77',
        textRotate: true,
        uuid: 'ng1a-op-1-77',
        type: WorkplaceType.DEFAULT,
        position: { x: 190, y: 140, angle: -90 }
      },
      {
        name: 'K76',
        textRotate: true,
        uuid: 'ng1a-op-1-76',
        type: WorkplaceType.DEFAULT,
        position: { x: 190, y: 180, angle: -90 }
      },
      {
        name: 'K75',
        textRotate: false,
        uuid: 'ng1a-op-1-75',
        type: WorkplaceType.DEFAULT,
        position: { x: 230, y: 60, angle: 90 }
      },
      {
        name: 'K74',
        textRotate: false,
        uuid: 'ng1a-op-1-74',
        type: WorkplaceType.DEFAULT,
        position: { x: 230, y: 100, angle: 90 }
      },
      {
        name: 'K73',
        textRotate: false,
        uuid: 'ng1a-op-1-73',
        type: WorkplaceType.DEFAULT,
        position: { x: 230, y: 140, angle: 90 }
      },
      {
        name: 'K72',
        textRotate: false,
        uuid: 'ng1a-op-1-72',
        type: WorkplaceType.DEFAULT,
        position: { x: 230, y: 180, angle: 90 }
      },
      ///
      {
        name: 'K71',
        textRotate: true,
        uuid: 'ng1a-op-1-71',
        type: WorkplaceType.DEFAULT,
        position: { x: 320, y: 60, angle: -90 }
      },
      {
        name: 'K70',
        textRotate: true,
        uuid: 'ng1a-op-1-70',
        type: WorkplaceType.DEFAULT,
        position: { x: 320, y: 100, angle: -90 }
      },
      {
        name: 'K69',
        textRotate: true,
        uuid: 'ng1a-op-1-69',
        type: WorkplaceType.DEFAULT,
        position: { x: 320, y: 140, angle: -90 }
      },
      {
        name: 'K68',
        textRotate: true,
        uuid: 'ng1a-op-1-68',
        type: WorkplaceType.DEFAULT,
        position: { x: 320, y: 180, angle: -90 }
      },
      {
        name: 'K67',
        textRotate: false,
        uuid: 'ng1a-op-1-67',
        type: WorkplaceType.DEFAULT,
        position: { x: 360, y: 60, angle: 90 }
      },
      {
        name: 'K66',
        textRotate: false,
        uuid: 'ng1a-op-1-66',
        type: WorkplaceType.DEFAULT,
        position: { x: 360, y: 100, angle: 90 }
      },
      {
        name: 'K65',
        textRotate: false,
        uuid: 'ng1a-op-1-65',
        type: WorkplaceType.DEFAULT,
        position: { x: 360, y: 140, angle: 90 }
      },
      {
        name: 'K64',
        textRotate: false,
        uuid: 'ng1a-op-1-64',
        type: WorkplaceType.DEFAULT,
        position: { x: 360, y: 180, angle: 90 }
      },
      //
      {
        name: 'K63',
        textRotate: true,
        uuid: 'ng1a-op-1-63',
        type: WorkplaceType.DEFAULT,
        position: { x: 480, y: 60, angle: -90 }
      },
      {
        name: 'K62',
        textRotate: true,
        uuid: 'ng1a-op-1-62',
        type: WorkplaceType.DEFAULT,
        position: { x: 480, y: 100, angle: -90 }
      },
      {
        name: 'K61',
        textRotate: true,
        uuid: 'ng1a-op-1-61',
        type: WorkplaceType.DEFAULT,
        position: { x: 480, y: 140, angle: -90 }
      },
      {
        name: 'K60',
        textRotate: true,
        uuid: 'ng1a-op-1-60',
        type: WorkplaceType.DEFAULT,
        position: { x: 480, y: 180, angle: -90 }
      },
      {
        name: 'K59',
        textRotate: false,
        uuid: 'ng1a-op-1-59',
        type: WorkplaceType.DEFAULT,
        position: { x: 520, y: 60, angle: 90 }
      },
      {
        name: 'K58',
        textRotate: false,
        uuid: 'ng1a-op-1-58',
        type: WorkplaceType.DEFAULT,
        position: { x: 520, y: 100, angle: 90 }
      },
      {
        name: 'K57',
        textRotate: false,
        uuid: 'ng1a-op-1-57',
        type: WorkplaceType.DEFAULT,
        position: { x: 520, y: 140, angle: 90 }
      },
      {
        name: 'K56',
        textRotate: false,
        uuid: 'ng1a-op-1-56',
        type: WorkplaceType.DEFAULT,
        position: { x: 520, y: 180, angle: 90 }
      }
    ]
  },
  {
    uuid: 'area-c34',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'C34',
    svgPath: 'M1887 610V567H1940V643H1887V640H1885V610H1887Z',
    workplaces: [
      { uuid: 'work-c34-1', name: 'C34', position: { x: 1890, y: 570, angle: 180 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-c35-c36',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'C35-36',
    svgPath: 'M1887 650V647H1940V800H1890V803H1887V760H1885V730H1887V680H1885V650H1887Z',
    workplaces: [
      { uuid: 'work-c35-c36-1', name: 'C35', position: { x: 1890, y: 680, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c35-c36-2', name: 'C36', position: { x: 1890, y: 760, angle: 0 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-c33',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'C33',
    svgPath: 'M1732 826V757H1838V760H1840V790H1838V805H1835V807H1839V813H1835V826H1732Z',
    workplaces: [
      { uuid: 'work-c33-1', name: 'C33', position: { x: 1780, y: 780, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c33-2', name: 'C33', position: { x: 1740, y: 780, angle: 0 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-c32',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'C32',
    svgPath: 'M1732 753V692H1838V720H1840V750H1838V753H1732Z',
    workplaces: [
      { uuid: 'work-c32-1', name: 'C32', position: { x: 1780, y: 695, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c32-2', name: 'C32', position: { x: 1740, y: 695, angle: 180 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-c31',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'C31',
    svgPath: 'M1732 688V622H1800V620H1830V622H1838V625H1835V627H1839V633H1835V635H1838V688H1732Z',
    workplaces: [
      { uuid: 'work-c31-1', name: 'C31', position: { x: 1780, y: 645, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c31-2', name: 'C31', position: { x: 1740, y: 645, angle: 0 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-c30',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'C30',
    svgPath: 'M1657 826V622H1695V620H1725V622H1728V826H1657Z',
    workplaces: [
      { uuid: 'work-c30-1', name: 'C30', position: { x: 1660, y: 640, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c30-2', name: 'C30', position: { x: 1660, y: 680, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c30-3', name: 'C30', position: { x: 1660, y: 720, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c30-4', name: 'C30', position: { x: 1660, y: 760, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-c29',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'C29',
    svgPath: 'M1552 826V622H1590V620H1620V622H1653V826H1552Z',
    workplaces: [
      { uuid: 'work-c29-1', name: 'C29', position: { x: 1610, y: 680, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c29-2', name: 'C29', position: { x: 1610, y: 720, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c29-3', name: 'C29', position: { x: 1610, y: 760, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c29-4', name: 'C29', position: { x: 1555, y: 640, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c29-5', name: 'C29', position: { x: 1555, y: 680, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c29-6', name: 'C29', position: { x: 1555, y: 720, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c29-7', name: 'C29', position: { x: 1555, y: 760, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-c28',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'C28',
    svgPath: 'M1442 622H1480V620H1510V622H1548V826H1480V815H1442V622Z',
    workplaces: [
      { uuid: 'work-c28-1', name: 'C28', position: { x: 1505, y: 640, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c28-2', name: 'C28', position: { x: 1505, y: 680, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c28-3', name: 'C28', position: { x: 1505, y: 720, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c28-4', name: 'C28', position: { x: 1505, y: 760, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c28-5', name: 'C28', position: { x: 1445, y: 640, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c28-6', name: 'C28', position: { x: 1445, y: 680, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c28-7', name: 'C28', position: { x: 1445, y: 720, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c28-8', name: 'C28', position: { x: 1445, y: 760, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-c26',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'C26',
    svgPath: 'M1400 622H1438V815H1400V826H1367V622H1370V620H1400V622Z',
    workplaces: [
      { uuid: 'work-c26-1', name: 'C26', position: { x: 1395, y: 640, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c26-2', name: 'C26', position: { x: 1395, y: 680, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c26-3', name: 'C26', position: { x: 1395, y: 720, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c26-4', name: 'C26', position: { x: 1395, y: 760, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-c24',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'C24',
    svgPath: 'M1320 622H1363V826H1280V815H1242V625H1265V622H1290V620H1320V622Z',
    workplaces: [
      { uuid: 'work-c24-1', name: 'C24', position: { x: 1320, y: 640, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c24-2', name: 'C24', position: { x: 1320, y: 680, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c24-3', name: 'C24', position: { x: 1320, y: 720, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c24-4', name: 'C24', position: { x: 1320, y: 760, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c24-1', name: 'C24', position: { x: 1245, y: 640, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c24-2', name: 'C24', position: { x: 1245, y: 680, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c24-3', name: 'C24', position: { x: 1245, y: 720, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c24-4', name: 'C24', position: { x: 1245, y: 760, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-c22',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'C22',
    svgPath: 'M1160 622V620H1190V622H1238V815H1200V826H1157V622H1160Z',
    workplaces: [
      { uuid: 'work-c22-1', name: 'C22', position: { x: 1195, y: 640, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c22-2', name: 'C22', position: { x: 1195, y: 680, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c22-3', name: 'C22', position: { x: 1195, y: 720, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c22-4', name: 'C22', position: { x: 1195, y: 760, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-c20',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'C20',
    svgPath: 'M1042 805V622H1080V620H1110V622H1153V826H1050V805H1042Z',
    workplaces: [
      { uuid: 'work-c20-1', name: 'C20', position: { x: 1110, y: 640, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c20-2', name: 'C20', position: { x: 1110, y: 680, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c20-3', name: 'C20', position: { x: 1110, y: 720, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c20-4', name: 'C20', position: { x: 1110, y: 760, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c20-1', name: 'C20', position: { x: 1045, y: 640, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c20-2', name: 'C20', position: { x: 1045, y: 680, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c20-3', name: 'C20', position: { x: 1045, y: 720, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c20-4', name: 'C20', position: { x: 1045, y: 760, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-c18',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'C18',
    svgPath: 'M970 622V620H1000V622H1038V805H1030V826H937V622H970Z',
    workplaces: [
      { uuid: 'work-c18-1', name: 'C18', position: { x: 995, y: 640, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c18-2', name: 'C18', position: { x: 995, y: 680, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c18-3', name: 'C18', position: { x: 995, y: 720, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c18-4', name: 'C18', position: { x: 995, y: 760, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c18-1', name: 'C18', position: { x: 940, y: 640, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c18-2', name: 'C18', position: { x: 940, y: 680, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c18-3', name: 'C18', position: { x: 940, y: 720, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c18-4', name: 'C18', position: { x: 940, y: 760, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-c16',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'C16',
    svgPath: 'M860 622V620H890V622H933V826H880V815H830V622H860Z',
    workplaces: [
      { uuid: 'work-c16-1', name: 'C16', position: { x: 890, y: 640, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c16-2', name: 'C16', position: { x: 890, y: 680, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c16-3', name: 'C16', position: { x: 890, y: 720, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c16-4', name: 'C16', position: { x: 890, y: 760, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c16-2', name: 'C16', position: { x: 830, y: 680, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c16-3', name: 'C16', position: { x: 830, y: 720, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-c14',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'C14',
    svgPath: 'M765 717V715H795V717H800V826H707V717H765Z',
    workplaces: [
      { uuid: 'work-c14-1', name: 'C14', position: { x: 760, y: 740, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c14-2', name: 'C14', position: { x: 760, y: 780, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c14-3', name: 'C14', position: { x: 710, y: 740, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c14-4', name: 'C14', position: { x: 710, y: 780, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-c13',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'C13',
    svgPath: 'M670 717V715H700V717H703V826H680V815H640V805H642V717H670Z',
    workplaces: [
      { uuid: 'work-c13-1', name: 'C13', position: { x: 645, y: 730, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c13-2', name: 'C13', position: { x: 645, y: 770, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-c12',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'C12',
    svgPath: 'M575 715H605V717H638V805H640V815H600V826H537V717H575V715Z',
    workplaces: [
      { uuid: 'work-c12-1', name: 'C12', position: { x: 595, y: 730, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c12-2', name: 'C12', position: { x: 595, y: 770, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c12-3', name: 'C12', position: { x: 540, y: 770, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-c11',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'C11',
    svgPath: 'M460 717H470V715H500V717H533V826H460V717Z',
    workplaces: [
      { uuid: 'work-c11-1', name: 'C11', position: { x: 490, y: 730, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c11-2', name: 'C11', position: { x: 490, y: 770, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-c10',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'C10',
    svgPath: 'M440 620V640H445V805H440V815H445V826H377V617H405V615H435V617H450V620H440Z',
    workplaces: [
      { uuid: 'work-c10-1', name: 'C10', position: { x: 380, y: 640, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c10-2', name: 'C10', position: { x: 380, y: 680, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c10-3', name: 'C10', position: { x: 380, y: 720, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c10-4', name: 'C10', position: { x: 380, y: 760, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-c7',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'C7',
    svgPath: 'M290 617V615H320V617H373V826H287V617H290Z',
    workplaces: [
      { uuid: 'work-c7-1', name: 'C7', position: { x: 330, y: 640, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c7-2', name: 'C7', position: { x: 330, y: 680, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c7-3', name: 'C7', position: { x: 330, y: 720, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c7-4', name: 'C7', position: { x: 330, y: 760, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-c4',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'C4',
    svgPath: 'M240 615V617H283V826H210V615H240Z',
    workplaces: [
      { uuid: 'work-c4-1', name: 'C4', position: { x: 240, y: 640, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c4-2', name: 'C4', position: { x: 240, y: 680, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c4-3', name: 'C4', position: { x: 240, y: 720, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c4-4', name: 'C4', position: { x: 240, y: 760, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-c2',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'C2',
    svgPath: 'M170 615H200V826H24V620H160V617H170V615Z',
    workplaces: [
      { uuid: 'work-c2-1', name: 'C2', position: { x: 160, y: 680, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c2-2', name: 'C2', position: { x: 160, y: 720, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c2-3', name: 'C2', position: { x: 160, y: 760, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c2-4', name: 'C2', position: { x: 100, y: 680, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c2-5', name: 'C2', position: { x: 100, y: 720, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c2-6', name: 'C2', position: { x: 100, y: 760, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c2-7', name: 'C2', position: { x: 60, y: 680, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c2-8', name: 'C2', position: { x: 60, y: 720, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c2-9', name: 'C2', position: { x: 60, y: 760, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-c1',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'C1',
    svgPath: 'M50 440V420H153V570H155V600H153V610H24V480H35V440H50Z',
    workplaces: [
      { uuid: 'work-c1-1', name: 'C1', position: { x: 90, y: 450, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c1-2', name: 'C1', position: { x: 90, y: 490, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c1-3', name: 'C1', position: { x: 90, y: 530, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c1-4', name: 'C1', position: { x: 50, y: 450, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c1-5', name: 'C1', position: { x: 50, y: 490, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c1-6', name: 'C1', position: { x: 50, y: 530, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-c3',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'C3',
    svgPath: 'M157 563V420H220V565H190V563H157Z',
    workplaces: [
      { uuid: 'work-c3-1', name: 'C3', position: { x: 160, y: 430, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c3-2', name: 'C3', position: { x: 160, y: 470, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c3-3', name: 'C3', position: { x: 160, y: 510, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-c5',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'C5',
    svgPath: 'M230 488V420H313V425H315V455H313V488H230Z',
    workplaces: [
      { uuid: 'work-c5-1', name: 'C5', position: { x: 230, y: 445, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c5-2', name: 'C5', position: { x: 270, y: 445, angle: 0 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-c6',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'C6',
    svgPath: 'M230 563V492H313V525H315V555H313V563H230Z',
    workplaces: [
      { uuid: 'work-c6-1', name: 'C6', position: { x: 230, y: 495, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c6-2', name: 'C6', position: { x: 270, y: 495, angle: 180 }, type: WorkplaceType.DEFAULT }
    ]
  },
  // {
  //   uuid: 'area-c8',
  //   type: AreaType.CABINET,
  //   position: { x: 20, y: 20 },
  //   size: { width: 280, height: 185 },
  //   name: 'C8',
  //   svgPath: 'M410 420H367V425H365V455H367V488H410V420Z',
  //   workplaces: []
  // },
  {
    uuid: 'area-c9',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'C8-9',
    svgPath: 'M410 420H367V425H365V455H367V525H365V555H367V563H410V420Z',
    workplaces: [
      { uuid: 'work-c9-1', name: 'C9', position: { x: 370, y: 490, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c8-1', name: 'C8', position: { x: 370, y: 450, angle: 0 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-c55',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'C55',
    svgPath: 'M815 327H810V410H883V327H845V325H815V327Z',
    workplaces: [
      { uuid: 'work-c55-1', name: 'C55', position: { x: 840, y: 330, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c55-2', name: 'C55', position: { x: 840, y: 370, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-c15',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'C15',
    svgPath: 'M810 563V420H883V563H845V565H815V563H810Z',
    workplaces: [
      { uuid: 'work-c15-1', name: 'C15', position: { x: 840, y: 430, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c15-2', name: 'C15', position: { x: 840, y: 470, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c15-3', name: 'C15', position: { x: 840, y: 510, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-c54',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'C54',
    svgPath: 'M955 327H960V410H887V327H925V325H955V327Z',
    workplaces: [
      { uuid: 'work-c54-1', name: 'C54', position: { x: 890, y: 330, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c54-2', name: 'C54', position: { x: 890, y: 370, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-c17',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'C17',
    svgPath: 'M887 563V420H963V563H960V565H930V563H887Z',
    workplaces: [
      { uuid: 'work-c17-1', name: 'C17', position: { x: 890, y: 430, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c17-2', name: 'C17', position: { x: 890, y: 470, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c17-3', name: 'C17', position: { x: 890, y: 510, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-c19',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'C19',
    svgPath: 'M970 370H1048V563H1040V565H1010V563H967V420H970V370Z',
    workplaces: [
      { uuid: 'work-c19-1', name: 'C19', position: { x: 970, y: 390, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c19-2', name: 'C19', position: { x: 970, y: 430, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c19-3', name: 'C19', position: { x: 970, y: 470, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c19-4', name: 'C19', position: { x: 970, y: 510, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-c21',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'C21',
    svgPath: 'M1052 563V370H1120V420H1123V563H1090V565H1060V563H1052Z',
    workplaces: [
      { uuid: 'work-c21-1', name: 'C21', position: { x: 1080, y: 390, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c21-2', name: 'C21', position: { x: 1080, y: 430, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c21-3', name: 'C21', position: { x: 1080, y: 470, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c21-4', name: 'C21', position: { x: 1080, y: 510, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-c53',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'C53',
    svgPath: 'M1135 327H1130V410H1190V327H1165V325H1135V327Z',
    workplaces: [
      { uuid: 'work-c53-1', name: 'C53', position: { x: 1150, y: 330, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c53-2', name: 'C53', position: { x: 1150, y: 370, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-c23',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'C23',
    svgPath: 'M1127 563V420H1190V563H1160V565H1130V563H1127Z',
    workplaces: [
      { uuid: 'work-c23-1', name: 'C23', position: { x: 1150, y: 430, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c23-2', name: 'C23', position: { x: 1150, y: 470, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c23-3', name: 'C23', position: { x: 1150, y: 510, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-c25',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'C25',
    svgPath: 'M1368 553V380H1260V553H1300V555H1330V553H1368Z',
    workplaces: [
      { uuid: 'work-c25-1', name: 'C25', position: { x: 1260, y: 380, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c25-2', name: 'C25', position: { x: 1260, y: 420, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c25-3', name: 'C25', position: { x: 1260, y: 460, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c25-4', name: 'C25', position: { x: 1260, y: 500, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c25-5', name: 'C25', position: { x: 1325, y: 380, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c25-6', name: 'C25', position: { x: 1325, y: 420, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c25-7', name: 'C25', position: { x: 1325, y: 460, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c25-8', name: 'C25', position: { x: 1325, y: 500, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-c27',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'C27',
    svgPath: 'M1372 553V380H1480V553H1440V555H1410V553H1372Z',
    workplaces: [
      { uuid: 'work-c27-1', name: 'C27', position: { x: 1375, y: 380, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c27-2', name: 'C27', position: { x: 1375, y: 420, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c27-3', name: 'C27', position: { x: 1375, y: 460, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c27-4', name: 'C27', position: { x: 1375, y: 500, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c27-5', name: 'C27', position: { x: 1440, y: 390, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c27-5', name: 'C27', position: { x: 1440, y: 430, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c27-6', name: 'C27', position: { x: 1440, y: 470, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c27-7', name: 'C27', position: { x: 1440, y: 510, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-c37',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'C37',
    svgPath: 'M1730 560V460H1833V563H1790V565H1760V563H1750V560H1730Z',
    workplaces: [
      { uuid: 'work-c37-1', name: 'C37', position: { x: 1730, y: 460, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c37-2', name: 'C37', position: { x: 1790, y: 460, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c37-3', name: 'C37', position: { x: 1790, y: 500, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-c38',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'C38',
    svgPath: 'M1840 460V410H1940V563H1880V565H1850V563H1837V460H1840Z',
    workplaces: [
      { uuid: 'work-c38-1', name: 'C38', position: { x: 1840, y: 440, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c38-2', name: 'C38', position: { x: 1840, y: 480, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c38-3', name: 'C38', position: { x: 1840, y: 520, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c38-4', name: 'C38', position: { x: 1900, y: 420, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c38-5', name: 'C38', position: { x: 1900, y: 460, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c38-6', name: 'C38', position: { x: 1900, y: 500, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-c52',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'C52',
    svgPath: 'M1120 24H1163V170H1165V200H1110V180H1100V40H1120V24Z',
    workplaces: [
      { uuid: 'work-c52-1', name: 'C52', position: { x: 1100, y: 50, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c52-2', name: 'C52', position: { x: 1100, y: 90, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c52-3', name: 'C52', position: { x: 1100, y: 130, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-c51',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'C51',
    svgPath: 'M1167 24H1228V143H1200V145H1170V143H1167V24Z',
    workplaces: [
      { uuid: 'work-c51-1', name: 'C51', position: { x: 1185, y: 50, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c51-2', name: 'C51', position: { x: 1185, y: 90, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-c50',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'C50',
    svgPath: 'M1232 24H1333V143H1295V145H1265V143H1232V24Z',
    workplaces: [
      { uuid: 'work-c50-1', name: 'C50', position: { x: 1235, y: 50, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c50-2', name: 'C50', position: { x: 1235, y: 90, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c50-2', name: 'C50', position: { x: 1290, y: 50, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c50-2', name: 'C50', position: { x: 1290, y: 90, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-c49',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'C49',
    svgPath: 'M1400 24H1337V143H1375V145H1405V143H1448V35H1425V40H1400V24Z',
    workplaces: [
      { uuid: 'work-c49-1', name: 'C49', position: { x: 1340, y: 50, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c49-2', name: 'C49', position: { x: 1340, y: 90, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c49-2', name: 'C49', position: { x: 1405, y: 50, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c49-2', name: 'C49', position: { x: 1405, y: 90, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-c48',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'C48',
    svgPath: 'M1480 24H1518V143H1515V145H1485V143H1452V35H1455V40H1480V24Z',
    workplaces: [
      { uuid: 'work-c48-1', name: 'C48', position: { x: 1455, y: 50, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c48-2', name: 'C48', position: { x: 1455, y: 90, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-c47',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'C47',
    svgPath: 'M1625 35H1628V143H1595V145H1565V143H1522V24H1600V40H1625V35Z',
    workplaces: [
      { uuid: 'work-c47-1', name: 'C47', position: { x: 1525, y: 50, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c47-2', name: 'C47', position: { x: 1525, y: 90, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c47-3', name: 'C47', position: { x: 1585, y: 50, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c47-4', name: 'C47', position: { x: 1585, y: 90, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-c46',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'C46',
    svgPath: 'M1680 24H1733V143H1730V145H1700V143H1632V35H1655V40H1680V24Z',
    workplaces: [
      { uuid: 'work-c46-1', name: 'C46', position: { x: 1636, y: 50, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c46-2', name: 'C46', position: { x: 1636, y: 90, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c46-3', name: 'C46', position: { x: 1690, y: 50, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-c45',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'C45',
    svgPath: 'M1737 24H1793V143H1770V145H1740V143H1737V24Z',
    workplaces: [
      { uuid: 'work-c45-1', name: 'C45', position: { x: 1750, y: 50, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c45-2', name: 'C45', position: { x: 1750, y: 90, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-c44',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'C44',
    svgPath: 'M1797 24H1865V35H1868V143H1830V145H1800V143H1797V24Z',
    workplaces: [
      { uuid: 'work-c44-1', name: 'C44', position: { x: 1820, y: 50, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c44-2', name: 'C44', position: { x: 1820, y: 90, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-c43',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'C43',
    svgPath: 'M1930 35H1872V147H1837V155H1835V185H1837V188H1940V40H1930V35Z',
    workplaces: [
      { uuid: 'work-c43-1', name: 'C43', position: { x: 1900, y: 50, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c43-2', name: 'C43', position: { x: 1900, y: 90, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c43-3', name: 'C43', position: { x: 1900, y: 130, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-c41',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'C41',
    svgPath: 'M1700 197H1783V220H1785V250H1783V253H1690V220H1700V197Z',
    workplaces: [
      { uuid: 'work-c41-1', name: 'C41', position: { x: 1720, y: 196, angle: 180 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-c42',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'C42',
    svgPath: 'M1940 192H1837V230H1835V260H1837V263H1940V192Z',
    workplaces: [
      { uuid: 'work-c42-1', name: 'C42', position: { x: 1850, y: 196, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c42-2', name: 'C42', position: { x: 1890, y: 196, angle: 180 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-c39',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'C39',
    svgPath: 'M1690 257H1783V290H1785V320H1783V350H1690V257Z',
    workplaces: [
      { uuid: 'work-c39-1', name: 'C39', position: { x: 1690, y: 260, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c39-2', name: 'C39', position: { x: 1730, y: 260, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c39-3', name: 'C39', position: { x: 1710, y: 310, angle: 0 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-c40',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'C40',
    svgPath: 'M1940 267H1837V270H1835V300H1837V350H1940V267Z',
    workplaces: [
      { uuid: 'work-c40-1', name: 'C40', position: { x: 1890, y: 300, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-c40-2', name: 'C40', position: { x: 1850, y: 300, angle: 0 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'PS-1C',
    type: AreaType.CABINET,
    zones: [
      {
        position: { x: 193, y: 1487 },
        svgPath: 'M1554.39 570H1490V560H1520V420H1490V310H1487V193H1673V570H1605.22H1554.39Z'
      },
      {
        position: { x: 510, y: 550 },
        svgPath: 'M510 550H620V570H623V667H510V550Z'
      },
      {
        position: { x: 24, y: 240 },
        svgPath:
          'M24 240H25V245H30V250H45V258H580V320H500V325H470V320H460V310H400V315H360V310H350V315H320V310H300V315H260V310H80V315H40V310H24V240Z'
      },
      ///Зоны переговорных комнат
      {
        position: { x: 420, y: 380 },
        svgPath: 'M455 380H420V410H455V380Z'
      },
      {
        position: { x: 420, y: 430 },
        svgPath: 'M455 430H420V460H455V430Z'
      },
      {
        position: { x: 420, y: 470 },
        svgPath: 'M455 470H420V500H455V470Z'
      },
      {
        position: { x: 420, y: 510 },
        svgPath: 'M455 510H420V540H455V510Z'
      }
    ],
    name: 'Нефиксированное рабочее место'
  }
];
export const NEO_GEO_FLOOR_2_A_AREAS = [
  {
    uuid: 'area-2-a24',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: 'A24',
    svgPath: 'M20 160V30H180V360H150V365H120V360H70V160H20Z',
    workplaces: [
      { uuid: 'work-a24-1', name: 'A24', position: { x: 20, y: 90, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a24-2', name: 'A24', position: { x: 20, y: 50, angle: 90 }, type: WorkplaceType.DEFAULT },

      { uuid: 'work-a24-3', name: 'A24', position: { x: 140, y: 50, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a24-4', name: 'A24', position: { x: 140, y: 90, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a24-5', name: 'A24', position: { x: 140, y: 130, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a24-6', name: 'A24', position: { x: 140, y: 170, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a24-7', name: 'A24', position: { x: 140, y: 210, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a24-8', name: 'A24', position: { x: 140, y: 250, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a24-9', name: 'A24', position: { x: 140, y: 290, angle: -90 }, type: WorkplaceType.DEFAULT },

      { uuid: 'work-a24-10', name: 'A24', position: { x: 70, y: 170, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a24-11', name: 'A24', position: { x: 70, y: 210, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a24-12', name: 'A24', position: { x: 70, y: 250, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a24-13', name: 'A24', position: { x: 70, y: 290, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-2-a30',
    type: AreaType.CABINET,
    position: { x: 0, y: 0 },
    size: { width: 280, height: 185 },
    name: 'A30',
    svgPath: 'M120 870H110V1100H170V1040H190V870H150V865H120V870Z',
    workplaces: [
      { uuid: 'work-a24-1', name: 'A30', position: { x: 110, y: 1060, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a24-2', name: 'A30', position: { x: 110, y: 1020, angle: 90 }, type: WorkplaceType.DEFAULT },

      { uuid: 'work-a24-3', name: 'A30', position: { x: 150, y: 980, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a24-4', name: 'A30', position: { x: 150, y: 940, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a24-5', name: 'A30', position: { x: 150, y: 900, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-2-A21-23',
    type: AreaType.CABINET,
    position: { x: 0, y: 0 },
    size: { width: 280, height: 185 },
    name: 'A21-23',
    svgPath: 'M200 400V230H390V214H483V403H360V405H330V403H230V400H200Z',
    workplaces: [
      { uuid: 'work-A21-23-1', name: 'A21-23', position: { x: 200, y: 230, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-A21-23-2', name: 'A21-23', position: { x: 240, y: 230, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-A21-23-3', name: 'A21-23', position: { x: 280, y: 230, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-A21-23-4', name: 'A21-23', position: { x: 320, y: 230, angle: 180 }, type: WorkplaceType.DEFAULT },

      { uuid: 'work-A21-23-5', name: 'A21-23', position: { x: 440, y: 240, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-A21-23-6', name: 'A21-23', position: { x: 440, y: 280, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-A21-23-7', name: 'A21-23', position: { x: 440, y: 320, angle: -90 }, type: WorkplaceType.DEFAULT },

      { uuid: 'work-A21-23-8', name: 'A21-23', position: { x: 200, y: 360, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-A21-23-9', name: 'A21-23', position: { x: 240, y: 360, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-A21-23-10', name: 'A21-23', position: { x: 280, y: 360, angle: 180 }, type: WorkplaceType.DEFAULT },
      {
        uuid: 'work-A21-23-11',
        name: 'A21-23',
        position: { x: 200, y: 320, angle: 0 },
        type: WorkplaceType.DEFAULT
      },
      {
        uuid: 'work-A21-23-12',
        name: 'A21-23',
        position: { x: 240, y: 320, angle: 0 },
        type: WorkplaceType.DEFAULT
      },
      { uuid: 'work-A21-23-13', name: 'A21-23', position: { x: 280, y: 320, angle: 0 }, type: WorkplaceType.DEFAULT },

      { uuid: 'work-A21-23-14', name: 'A21-23', position: { x: 320, y: 340, angle: 90 }, type: WorkplaceType.DEFAULT },

      { uuid: 'work-A21-23-15', name: 'A21-23', position: { x: 400, y: 360, angle: 0 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-2-a17-20',
    type: AreaType.CABINET,
    position: { x: 0, y: 0 },
    size: { width: 280, height: 185 },
    name: 'A17-20',
    svgPath: 'M487 214H750V403H575V404.5H545V403H487V214Z',
    workplaces: [
      { uuid: 'work-a17-20', name: 'A17-20', position: { x: 510, y: 220, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a17-20', name: 'A17-20', position: { x: 490, y: 270, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a17-20', name: 'A17-20', position: { x: 545, y: 270, angle: -90 }, type: WorkplaceType.DEFAULT },

      { uuid: 'work-a17-20', name: 'A17-20', position: { x: 710, y: 820, angle: 180 }, type: WorkplaceType.DEFAULT },

      { uuid: 'work-a17-20', name: 'A17-20', position: { x: 670, y: 220, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a17-20', name: 'A17-20', position: { x: 595, y: 290, angle: 90 }, type: WorkplaceType.DEFAULT },

      { uuid: 'work-a17-20', name: 'A17-20', position: { x: 595, y: 360, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a17-20', name: 'A17-20', position: { x: 635, y: 360, angle: 0 }, type: WorkplaceType.DEFAULT },

      { uuid: 'work-a17-20', name: 'A17-20', position: { x: 710, y: 255, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a17-20', name: 'A17-20', position: { x: 710, y: 295, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },

  {
    uuid: 'area-2-a27',
    type: AreaType.CABINET,
    position: { x: 0, y: 0 },
    size: { width: 280, height: 185 },
    name: 'A27',
    svgPath: 'M590 677H587V830H600V871H750V677H620V675H590V677Z',
    workplaces: [
      { uuid: 'work-a27-1', name: 'A27', position: { x: 710, y: 690, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a27-2', name: 'A27', position: { x: 710, y: 730, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a27-3', name: 'A27', position: { x: 670, y: 710, angle: -90 }, type: WorkplaceType.DEFAULT },

      { uuid: 'work-a27-4', name: 'A27', position: { x: 710, y: 780, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a27-5', name: 'A27', position: { x: 710, y: 820, angle: 180 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a27-6', name: 'A27', position: { x: 670, y: 800, angle: -90 }, type: WorkplaceType.DEFAULT },

      { uuid: 'work-a27-7', name: 'A27', position: { x: 590, y: 750, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a27-8', name: 'A27', position: { x: 590, y: 790, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-2-a28',
    type: AreaType.CABINET,
    position: { x: 0, y: 0 },
    size: { width: 280, height: 185 },
    name: 'A28',
    svgPath: 'M520 677H487V871H580V830H583V677H550V675H520V677Z',
    workplaces: [
      { uuid: 'work-a28-1', name: 'A28', position: { x: 490, y: 710, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a28-2', name: 'A28', position: { x: 490, y: 750, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a28-3', name: 'A28', position: { x: 490, y: 790, angle: 90 }, type: WorkplaceType.DEFAULT },

      { uuid: 'work-a28-4', name: 'A28', position: { x: 540, y: 710, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a28-5', name: 'A28', position: { x: 540, y: 750, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a28-6', name: 'A28', position: { x: 540, y: 790, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-2-a29',
    type: AreaType.CABINET,
    position: { x: 0, y: 0 },
    size: { width: 280, height: 185 },
    name: 'A29',
    svgPath: 'M420 677H390V830H400V850H390V871H483V677H450V675H420V677Z',
    workplaces: [
      { uuid: 'work-a29-1', name: 'A29', position: { x: 390, y: 710, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a29-2', name: 'A29', position: { x: 390, y: 750, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a29-3', name: 'A29', position: { x: 390, y: 790, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a29-4', name: 'A29', position: { x: 440, y: 750, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a29-5', name: 'A29', position: { x: 440, y: 710, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-a29-6', name: 'A29', position: { x: 440, y: 790, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'PS-2',
    type: AreaType.CABINET,
    zones: [
      {
        position: { x: 230, y: 670 },
        svgPath: 'M380 860V670H230V860H380Z'
      },
      {
        position: { x: 150, y: 450 },
        svgPath: 'M299.5 620V460.5H199.5V450H150V620V630H199.5V620H299.5Z'
      },
      {
        position: { x: 480, y: 399 },
        svgPath: 'M399 480H670V595H659.5V601H399V480Z'
      },
      ///Зоны переговорок
      {
        position: { x: 70, y: 370 },
        svgPath: 'M115 370H70V400H115V370Z'
      },
      {
        position: { x: 70, y: 410 },
        svgPath: 'M115 410H70V440H115V410Z'
      },
      {
        position: { x: 70, y: 450 },
        svgPath: 'M115 450H70V480H115V450Z'
      }
    ],
    name: 'Нефиксированное рабочее место'
  }
];
