import React, { useCallback, useEffect } from 'react';
import { WorkplaceType } from '../../types';
import { FloorAreaTemplate } from './FloorAreaTemplate';
import { FloorWorkplaceDefault } from './FloorWorkplaceDefault';
import { FloorWorkplaceMeeting } from './FloorWorkplaceMeeting';
import { FloorWorkplaceRound } from './FloorWorkplaceRound';
import { IPlaceStatus } from '../../types';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { serviceRequestWithWorkplacesSelector } from '../../../../../store/selectors/ServicesSelector';
import dayjs from 'dayjs';
import { isDateBusy } from '../../../../../shared/utils/helperFunctions/heplerFunctions';

export const FloorCabinet = ({
  openPriceCard,
  filtersActive,
  floorArea: { zones, svgPath, workplaces, name: areaName, serverData },
  onTouchStart
}) => {
  const onChangeActiveArea = useCallback(() => {
    serverData && openPriceCard(serverData);
  }, [serverData, openPriceCard]);
  const bookingInfo = useSelector(state =>
    serviceRequestWithWorkplacesSelector(state, {
      dateCurrentSubscriptionView: dayjs().subtract(365, 'days'),
      selectorType: 'standard'
    })
  );
  const isDisabled = !serverData || isDateBusy(Date.now(), bookingInfo, serverData.id) || !serverData.isActive;

  return (
    <g
      data-name={areaName}
      id={`workplace-${areaName}`}
      onClick={onChangeActiveArea}
      onTouchStart={onChangeActiveArea}
      className={cn('workplace-floor-cabinet-item', isDisabled, { 'filters-active': filtersActive })}
    >
      {areaName === 'Нефиксированное рабочее место' ? (
        zones &&
        filtersActive &&
        zones.map((zone, index) => (
          <FloorAreaTemplate
            isPublicSpace={true}
            filtersActive={filtersActive}
            key={index}
            drawn={zone.svgPath}
            tabIndex={0}
          ></FloorAreaTemplate>
        ))
      ) : (
        <FloorAreaTemplate
          isPublicSpace={false}
          filtersActive={filtersActive}
          drawn={svgPath}
          tabIndex={0}
        ></FloorAreaTemplate>
      )}
      {workplaces &&
        workplaces.map(workplace => {
          switch (workplace.type) {
            case WorkplaceType.DEFAULT:
              return (
                <FloorWorkplaceDefault
                  key={workplace.uuid}
                  name={workplace.name}
                  position={workplace.position}
                  showName={workplace.showName}
                />
              );
            case WorkplaceType.ROUND:
              return <FloorWorkplaceRound key={workplace.uuid} name={workplace.name} position={workplace.position} />;
            case WorkplaceType.MEETING:
              return (
                <FloorWorkplaceMeeting
                  key={workplace.uuid}
                  position={workplace.position}
                  tableType={workplace.tableType}
                />
              );
            default:
              return null;
          }
        })}
    </g>
  );
};
