export var AreaType;
(function(AreaType) {
  AreaType['CABINET'] = 'cabinet';
  AreaType['CATERING'] = 'catering';
  AreaType['OPEN_SPACE'] = 'open_space';
  AreaType['MEETING_ROOM'] = 'meeting_room';
  AreaType['OFFICE_BLOCK'] = 'office_block';
  AreaType['FLOOR'] = 'floor';
})(AreaType || (AreaType = {}));
export var WorkplaceType;
(function(WorkplaceType) {
  WorkplaceType['DEFAULT'] = 'default';
  WorkplaceType['MEETING'] = 'meeting';
})(WorkplaceType || (WorkplaceType = {}));
export var Placeslist;
(function(Placeslist) {
  Placeslist['KOMSOMOLSKAYA'] = 'komsomolskaya';
  Placeslist['KOMSOMOLSKAYA_FOK_0'] = 'komsomolskaya_fok_0';
  Placeslist['KOMSOMOLSKAYA_FOK_1'] = 'komsomolskaya_fok_1';
  Placeslist['KOMSOMOLSKAYA_FOK_2'] = 'komsomolskaya_fok_2';
  Placeslist['PARK_KULTURY_5'] = 'park_kultury_5';
  Placeslist['PARK_KULTURY_4'] = 'park_kultury_4';
  Placeslist['PARK_KULTURY_6'] = 'park_kultury_6';
  Placeslist['NEO_GEO_1_A'] = 'neo_geo_1_a';
  Placeslist['NEO_GEO_2_A'] = 'neo_geo_2_a';
  Placeslist['NEO_GEO_1_C'] = 'neo_geo_1_c';
  Placeslist['TULSKAYA_6'] = 'tulskaya_6';
  Placeslist['TULSKAYA_5'] = 'tulskaya_5';
  Placeslist['CITY'] = 'city';
})(Placeslist || (Placeslist = {}));
export var IPlaceStatus;
(function(IPlaceStatus) {
  IPlaceStatus['AVAILABLE'] = 'available';
  IPlaceStatus['SELECTED'] = 'selected';
  IPlaceStatus['BUSY'] = 'busy';
  IPlaceStatus['UNSUITABLE'] = 'unsuitable';
})(IPlaceStatus || (IPlaceStatus = {}));
