import React from 'react';

import { Placeslist } from '../types';
import { Komsomolskaya_Floor as KomsomolskayaFloor } from './FloorFloorTemplates/Komsomolskaya_Floor';
import { Komsomolskaya_FOK_Floor as KomsomolskayaFokFloor } from './FloorFloorTemplates/Komsomolskaya_FOK_Floor';
import { Park_Kultury_Floor as ParkKulturyFloor } from './FloorFloorTemplates/Park_Kultury_Floor';
import { Neo_Geo_A_Floor as NeoGeo1AFloor } from './FloorFloorTemplates/Neo_Geo_A_Floor';
import { Neo_Geo_2_A_Floor as NeoGeo2AFloor } from './FloorFloorTemplates/Neo_Geo_2_A_Floor';
import { Neo_Geo_C_Floor as NeoGeo1CFloor } from './FloorFloorTemplates/Neo_Geo_C_Floor';
import { Tulskaya_6_Floor as Tulskaya6Floor } from './FloorFloorTemplates/Tulskaya_6_Floor';
import { Tulskaya_5_Floor as Tulskaya5Floor } from './FloorFloorTemplates/Tulskaya_5_Floor';
import { CityFloor } from './FloorFloorTemplates/CityFloor';

export const LazyFloorFloor = ({ place }) => {
  switch (place) {
    case Placeslist.KOMSOMOLSKAYA:
      return <KomsomolskayaFloor />;
    case Placeslist.KOMSOMOLSKAYA_FOK_1:
      return <KomsomolskayaFokFloor />;
    case Placeslist.KOMSOMOLSKAYA_FOK_2:
      return <KomsomolskayaFokFloor />;
    case Placeslist.PARK_KULTURY_4:
      return <ParkKulturyFloor />;
    case Placeslist.PARK_KULTURY_5:
      return <ParkKulturyFloor />;
    case Placeslist.PARK_KULTURY_6:
      return <ParkKulturyFloor />;
    case Placeslist.NEO_GEO_1_A:
      return <NeoGeo1AFloor />;
    case Placeslist.NEO_GEO_2_A:
      return <NeoGeo2AFloor />;
    case Placeslist.NEO_GEO_1_C:
      return <NeoGeo1CFloor />;
    case Placeslist.TULSKAYA_6:
      return <Tulskaya6Floor />;
    case Placeslist.TULSKAYA_5:
      return <Tulskaya5Floor />;
    case Placeslist.CITY:
      return <CityFloor />;
    default:
      return null;
  }
};
