/* eslint-disable no-shadow */
import { attr, Model, fk } from 'redux-orm';
import * as actions from '../actions';

class Transaction extends Model {
  static reducer(action, transaction) {
    switch (action.type) {
      case actions.resetTransactions.toString():
        transaction.all().delete();
        break;
      case actions.requestTransactions.success.toString():
        let processedServiceRequests = [];
        action.payload.forEach(elem => {
          if (!processedServiceRequests.includes(elem.service_request_id) || !!elem.logBalanceComment) {
            processedServiceRequests.push(elem.service_request_id);
            return transaction.upsert(elem);
          }
        });
        break;
      default:
    }
    // Return value is ignored.
    return undefined;
  }

  toString() {
    return `Transaction: ${this.name}`;
  }
  // Declare any static or instance methods you need.
}

Transaction.modelName = 'Transaction';

Transaction.options = {
  idAttribute: 'additional_id'
};

// Declare your related fields.
Transaction.fields = {
  additional_id: attr(),
  id: attr(), // non-relational field for any value; optional but highly recommended
  service_request_id: attr(),
  service_id: fk('Service'),
  created_at: attr()
};

export default Transaction;
