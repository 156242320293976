export default [
  //city
  {
    location_id: 1, // city
    id: 1,
    t_name: 'Workki City',
    open_space: { id: 795, name: 'Workki Опенспейс', status: 'free', active: false },
    rooms: [
      { id: 122, name: 'A1', status: 'free', active: false },
      { id: 123, name: 'A2', status: 'free', active: false },
      { id: 124, name: 'A3', status: 'free', active: false },
      { id: 125, name: 'A4', status: 'free', active: false },
      { id: 126, name: 'A5', status: 'free', active: false },
      { id: 127, name: 'A6', status: 'free', active: false },
      { id: 128, name: 'A7', status: 'free', active: false },
      { id: 129, name: 'A8', status: 'free', active: false },
      { id: 130, name: 'A9', status: 'free', active: false },
      { id: 131, name: 'A10', status: 'free', active: false },
      { id: 132, name: 'A11', status: 'free', active: false },
      { id: 133, name: 'A12', status: 'free', active: false },
      { id: 134, name: 'A13', status: 'free', active: false },
      { id: 135, name: 'A14', status: 'free', active: false },
      { id: 136, name: 'A15', status: 'free', active: false },
      { id: 137, name: 'A16', status: 'free', active: false },

      { id: 207, name: 'K1', status: 'free', active: false },
      { id: 208, name: 'K2', status: 'free', active: false },
      { id: 209, name: 'K3', status: 'free', active: false },
      { id: 210, name: 'K4', status: 'free', active: false },
      { id: 211, name: 'K5', status: 'free', active: false },
      { id: 212, name: 'K6', status: 'free', active: false },
      { id: 213, name: 'K7', status: 'free', active: false },
      { id: 214, name: 'K8', status: 'free', active: false },
      { id: 215, name: 'K9', status: 'free', active: false },
      { id: 216, name: 'K10', status: 'free', active: false },
      { id: 217, name: 'K11', status: 'free', active: false },
      { id: 218, name: 'K12', status: 'free', active: false },
      { id: 219, name: 'K13', status: 'free', active: false },
      { id: 220, name: 'K14', status: 'free', active: false },
      { id: 221, name: 'K15', status: 'free', active: false },
      { id: 222, name: 'K16', status: 'free', active: false },
      { id: 223, name: 'K17', status: 'free', active: false },
      { id: 224, name: 'K18', status: 'free', active: false },
      { id: 225, name: 'K19', status: 'free', active: false },
      { id: 226, name: 'K20', status: 'free', active: false },
      { id: 227, name: 'K21', status: 'free', active: false },
      { id: 228, name: 'K22', status: 'free', active: false },
      { id: 229, name: 'K23', status: 'free', active: false },
      { id: 230, name: 'K24', status: 'free', active: false },
      { id: 231, name: 'K25', status: 'free', active: false },
      { id: 232, name: 'K26', status: 'free', active: false },
      { id: 233, name: 'K27', status: 'free', active: false },
      { id: 234, name: 'K28', status: 'free', active: false },
      { id: 235, name: 'K29', status: 'free', active: false },
      { id: 236, name: 'K30', status: 'free', active: false },
      { id: 237, name: 'K31', status: 'free', active: false },
      { id: 238, name: 'K32', status: 'free', active: false },
      { id: 239, name: 'K33', status: 'free', active: false },
      { id: 240, name: 'K34', status: 'free', active: false },
      { id: 241, name: 'K35', status: 'free', active: false },
      { id: 242, name: 'K36', status: 'free', active: false },
      { id: 243, name: 'K37', status: 'free', active: false },
      { id: 244, name: 'K38', status: 'free', active: false },
      { id: 245, name: 'K39', status: 'free', active: false },
      { id: 246, name: 'K40', status: 'free', active: false },
      { id: 247, name: 'K41', status: 'free', active: false },
      { id: 248, name: 'K42', status: 'free', active: false },
      { id: 249, name: 'K43', status: 'free', active: false },
      { id: 250, name: 'K44', status: 'free', active: false },
      { id: 251, name: 'K45', status: 'free', active: false },
      { id: 252, name: 'K46', status: 'free', active: false },
      { id: 253, name: 'K47', status: 'free', active: false },
      { id: 254, name: 'K48', status: 'free', active: false },
      { id: 255, name: 'K49', status: 'free', active: false },
      { id: 256, name: 'K50', status: 'free', active: false },
      { id: 257, name: 'K51', status: 'free', active: false },
      { id: 258, name: 'K52', status: 'free', active: false },
      { id: 259, name: 'K53', status: 'free', active: false },
      { id: 260, name: 'K54', status: 'free', active: false },
      { id: 261, name: 'K55', status: 'free', active: false }
    ]
  },

  {
    location_id: 2, // neo
    id: 1,
    t_name: '1 этаж, блок A',
    open_space: { id: 795, name: 'Neo Geo Опенспейс', status: 'free', active: false },
    // { id: 795, name: 'Neo Geo Опенспейс', status: 'free', active: false }
    rooms: [
      { id: 122, name: 'A1', status: 'free', active: false },
      { id: 123, name: 'A2', status: 'free', active: false },
      { id: 124, name: 'A3', status: 'free', active: false },
      { id: 125, name: 'A4', status: 'free', active: false },
      { id: 126, name: 'A5', status: 'free', active: false },
      { id: 127, name: 'A6', status: 'free', active: false },
      { id: 128, name: 'A7', status: 'free', active: false },
      { id: 129, name: 'A8', status: 'free', active: false },
      { id: 130, name: 'A9', status: 'free', active: false },
      { id: 131, name: 'A10', status: 'free', active: false },
      { id: 132, name: 'A11', status: 'free', active: false },
      { id: 133, name: 'A12', status: 'free', active: false },
      { id: 134, name: 'A13', status: 'free', active: false },
      { id: 135, name: 'A14', status: 'free', active: false },
      { id: 136, name: 'A15', status: 'free', active: false },
      { id: 137, name: 'A16', status: 'free', active: false },

      { id: 207, name: 'K1', status: 'free', active: false },
      { id: 208, name: 'K2', status: 'free', active: false },
      { id: 209, name: 'K3', status: 'free', active: false },
      { id: 210, name: 'K4', status: 'free', active: false },
      { id: 211, name: 'K5', status: 'free', active: false },
      { id: 212, name: 'K6', status: 'free', active: false },
      { id: 213, name: 'K7', status: 'free', active: false },
      { id: 214, name: 'K8', status: 'free', active: false },
      { id: 215, name: 'K9', status: 'free', active: false },
      { id: 216, name: 'K10', status: 'free', active: false },
      { id: 217, name: 'K11', status: 'free', active: false },
      { id: 218, name: 'K12', status: 'free', active: false },
      { id: 219, name: 'K13', status: 'free', active: false },
      { id: 220, name: 'K14', status: 'free', active: false },
      { id: 221, name: 'K15', status: 'free', active: false },
      { id: 222, name: 'K16', status: 'free', active: false },
      { id: 223, name: 'K17', status: 'free', active: false },
      { id: 224, name: 'K18', status: 'free', active: false },
      { id: 225, name: 'K19', status: 'free', active: false },
      { id: 226, name: 'K20', status: 'free', active: false },
      { id: 227, name: 'K21', status: 'free', active: false },
      { id: 228, name: 'K22', status: 'free', active: false },
      { id: 229, name: 'K23', status: 'free', active: false },
      { id: 230, name: 'K24', status: 'free', active: false },
      { id: 231, name: 'K25', status: 'free', active: false },
      { id: 232, name: 'K26', status: 'free', active: false },
      { id: 233, name: 'K27', status: 'free', active: false },
      { id: 234, name: 'K28', status: 'free', active: false },
      { id: 235, name: 'K29', status: 'free', active: false },
      { id: 236, name: 'K30', status: 'free', active: false },
      { id: 237, name: 'K31', status: 'free', active: false },
      { id: 238, name: 'K32', status: 'free', active: false },
      { id: 239, name: 'K33', status: 'free', active: false },
      { id: 240, name: 'K34', status: 'free', active: false },
      { id: 241, name: 'K35', status: 'free', active: false },
      { id: 242, name: 'K36', status: 'free', active: false },
      { id: 243, name: 'K37', status: 'free', active: false },
      { id: 244, name: 'K38', status: 'free', active: false },
      { id: 245, name: 'K39', status: 'free', active: false },
      { id: 246, name: 'K40', status: 'free', active: false },
      { id: 247, name: 'K41', status: 'free', active: false },
      { id: 248, name: 'K42', status: 'free', active: false },
      { id: 249, name: 'K43', status: 'free', active: false },
      { id: 250, name: 'K44', status: 'free', active: false },
      { id: 251, name: 'K45', status: 'free', active: false },
      { id: 252, name: 'K46', status: 'free', active: false },
      { id: 253, name: 'K47', status: 'free', active: false },
      { id: 254, name: 'K48', status: 'free', active: false },
      { id: 255, name: 'K49', status: 'free', active: false },
      { id: 256, name: 'K50', status: 'free', active: false },
      { id: 257, name: 'K51', status: 'free', active: false },
      { id: 258, name: 'K52', status: 'free', active: false },
      { id: 259, name: 'K53', status: 'free', active: false },
      { id: 260, name: 'K54', status: 'free', active: false },
      { id: 261, name: 'K55', status: 'free', active: false }
    ]
  },
  {
    location_id: 2,
    id: 2,
    t_name: '1 этаж, блок C',
    open_space: { id: 270, name: 'Neo Geo Опенспейс', status: 'free', active: false },
    rooms: [
      { id: 152, name: 'C1', status: 'free', active: false },
      { id: 153, name: 'C2', status: 'free', active: false },
      { id: 154, name: 'C3', status: 'free', active: false },
      { id: 155, name: 'C4', status: 'free', active: false },
      { id: 156, name: 'C5', status: 'free', active: false },
      { id: 157, name: 'C6', status: 'free', active: false },
      { id: 158, name: 'C7', status: 'free', active: false },
      { id: 159, name: 'C8', status: 'free', active: false },
      { id: 160, name: 'C9', status: 'free', active: false },
      { id: 161, name: 'C10', status: 'free', active: false },
      { id: 162, name: 'C11', status: 'free', active: false },
      { id: 163, name: 'C12', status: 'free', active: false },
      { id: 164, name: 'C13', status: 'free', active: false },
      { id: 165, name: 'C14', status: 'free', active: false },
      { id: 166, name: 'C15', status: 'free', active: false },
      { id: 167, name: 'C16', status: 'free', active: false },
      { id: 168, name: 'C17', status: 'free', active: false },
      { id: 169, name: 'C18', status: 'free', active: false },
      { id: 170, name: 'C19', status: 'free', active: false },
      { id: 171, name: 'C20', status: 'free', active: false },
      { id: 172, name: 'C21', status: 'free', active: false },
      { id: 173, name: 'C22', status: 'free', active: false },
      { id: 174, name: 'C23', status: 'free', active: false },
      { id: 175, name: 'C24', status: 'free', active: false },
      { id: 176, name: 'C25', status: 'free', active: false },
      { id: 177, name: 'C26', status: 'free', active: false },
      { id: 179, name: 'C28', status: 'free', active: false },
      { id: 180, name: 'C29', status: 'free', active: false },
      { id: 181, name: 'C30', status: 'free', active: false },
      { id: 182, name: 'C31', status: 'free', active: false },
      { id: 183, name: 'C32', status: 'free', active: false },
      { id: 184, name: 'C33', status: 'free', active: false },
      { id: 185, name: 'C34', status: 'free', active: false },
      { id: 186, name: 'C35', status: 'free', active: false },
      { id: 187, name: 'C36', status: 'free', active: false },
      { id: 188, name: 'C37', status: 'free', active: false },
      { id: 189, name: 'C38', status: 'free', active: false },
      { id: 190, name: 'C39', status: 'free', active: false },
      { id: 191, name: 'C40', status: 'free', active: false },
      { id: 192, name: 'C41', status: 'free', active: false },
      { id: 193, name: 'C42', status: 'free', active: false },
      { id: 194, name: 'C43', status: 'free', active: false },
      { id: 195, name: 'C44', status: 'free', active: false },
      { id: 196, name: 'C45', status: 'free', active: false },
      { id: 197, name: 'C46', status: 'free', active: false },
      { id: 198, name: 'C47', status: 'free', active: false },
      { id: 199, name: 'C48', status: 'free', active: false },
      { id: 200, name: 'C49', status: 'free', active: false },
      { id: 201, name: 'C50', status: 'free', active: false },
      { id: 202, name: 'C51', status: 'free', active: false },
      { id: 203, name: 'C52', status: 'free', active: false },
      { id: 204, name: 'C53', status: 'free', active: false },
      { id: 205, name: 'C54', status: 'free', active: false },
      { id: 206, name: 'C55', status: 'free', active: false },

      { id: 262, name: 'C27-1', status: 'free', active: false },
      { id: 263, name: 'C27-2', status: 'free', active: false },
      { id: 264, name: 'C27-3', status: 'free', active: false },
      { id: 265, name: 'C27-4', status: 'free', active: false },
      { id: 266, name: 'C27-5', status: 'free', active: false },
      { id: 267, name: 'C27-6', status: 'free', active: false },
      { id: 268, name: 'C27-7', status: 'free', active: false },
      { id: 269, name: 'C27-8', status: 'free', active: false }
    ] // 202, C-27 полный кабинет
  },
  {
    location_id: 2,
    id: 3,
    t_name: '2 этаж',
    open_space: { id: 270, name: 'Neo Geo Опенспейс', status: 'free', active: false },
    rooms: [
      { id: 138, name: 'A17', status: 'free', active: false },
      { id: 139, name: 'A18', status: 'free', active: false },
      { id: 140, name: 'A19', status: 'free', active: false },
      { id: 141, name: 'A20', status: 'free', active: false },
      { id: 142, name: 'A21', status: 'free', active: false },
      { id: 143, name: 'A22', status: 'free', active: false },
      { id: 144, name: 'A23', status: 'free', active: false },
      { id: 145, name: 'A24', status: 'free', active: false },
      { id: 146, name: 'A25', status: 'free', active: false },
      { id: 147, name: 'A26', status: 'free', active: false },
      { id: 148, name: 'A27', status: 'free', active: false },
      { id: 149, name: 'A28', status: 'free', active: false },
      { id: 150, name: 'A29', status: 'free', active: false },
      { id: 151, name: 'A30', status: 'free', active: false }
    ]
  },
  {
    location_id: 2, // neo
    id: 555,
    t_name: '2 этаж, блок A',
    open_space: { id: 270, name: 'Neo Geo Опенспейс', status: 'free', active: false },
    rooms: [
      { id: 144, name: 'A23', status: 'free', active: false },
      { id: 145, name: 'A24', status: 'free', active: false },
      { id: 146, name: 'A25', status: 'free', active: false },
      { id: 147, name: 'A26', status: 'free', active: false },
      { id: 148, name: 'A27', status: 'free', active: false },
      { id: 149, name: 'A28', status: 'free', active: false },
      { id: 150, name: 'A29', status: 'free', active: false },
      { id: 151, name: 'A30', status: 'free', active: false }
    ]
  },

  {
    location_id: 1, // сити
    id: 4,
    t_name: 'City',
    open_space: { id: 121, name: 'MoscowCity Опенспейс', status: 'free', active: false },
    rooms: [
      { id: 7, name: 'C1', status: 'free', active: false },
      { id: 8, name: 'C2', status: 'free', active: false },
      { id: 9, name: 'C3', status: 'free', active: false },
      { id: 10, name: 'C4', status: 'free', active: false },
      { id: 11, name: 'C5', status: 'free', active: false },
      { id: 12, name: 'C6', status: 'free', active: false },
      { id: 13, name: 'C7', status: 'free', active: false },
      { id: 14, name: 'C8', status: 'free', active: false },
      { id: 15, name: 'C9', status: 'free', active: false },
      { id: 16, name: 'C10', status: 'free', active: false },
      { id: 17, name: 'C11', status: 'free', active: false },
      { id: 18, name: 'C12', status: 'free', active: false },
      { id: 19, name: 'C13', status: 'free', active: false },
      { id: 20, name: 'C14', status: 'free', active: false },
      { id: 21, name: 'C15', status: 'free', active: false },
      { id: 22, name: 'C16', status: 'free', active: false },
      { id: 23, name: 'C17', status: 'free', active: false },
      { id: 24, name: 'C18', status: 'free', active: false },
      { id: 25, name: 'C19', status: 'free', active: false },
      { id: 26, name: 'C20', status: 'free', active: false },
      { id: 27, name: 'C21', status: 'free', active: false },
      { id: 28, name: 'C22', status: 'free', active: false },
      { id: 29, name: 'C23', status: 'free', active: false },
      { id: 30, name: 'C24', status: 'free', active: false },
      { id: 31, name: 'C25', status: 'free', active: false },
      { id: 32, name: 'C26', status: 'free', active: false },
      { id: 33, name: 'C27', status: 'free', active: false },
      { id: 34, name: 'C28', status: 'free', active: false },
      { id: 35, name: 'C29', status: 'free', active: false },
      { id: 36, name: 'C30', status: 'free', active: false },
      { id: 37, name: 'C31', status: 'free', active: false },
      { id: 38, name: 'C32', status: 'free', active: false },
      { id: 39, name: 'C33', status: 'free', active: false },
      { id: 40, name: 'C34', status: 'free', active: false },
      { id: 41, name: 'C35', status: 'free', active: false },
      { id: 42, name: 'C36', status: 'free', active: false },
      { id: 43, name: 'C37', status: 'free', active: false },
      { id: 44, name: 'C38', status: 'free', active: false },
      { id: 45, name: 'C39', status: 'free', active: false },
      { id: 46, name: 'C40', status: 'free', active: false },
      { id: 47, name: 'C41', status: 'free', active: false },
      { id: 48, name: 'C42', status: 'free', active: false },
      { id: 49, name: 'C43', status: 'free', active: false },
      { id: 50, name: 'C44', status: 'free', active: false },
      { id: 51, name: 'C45', status: 'free', active: false },
      { id: 52, name: 'C46', status: 'busy', active: false },
      { id: 53, name: 'C47', status: 'free', active: false },
      { id: 54, name: 'C48', status: 'free', active: false },
      { id: 55, name: 'C49', status: 'free', active: false },
      { id: 56, name: 'C50', status: 'free', active: false },
      { id: 57, name: 'C51', status: 'free', active: false },
      { id: 58, name: 'C52', status: 'free', active: false },
      { id: 59, name: 'C53', status: 'free', active: false },
      { id: 60, name: 'C54', status: 'free', active: false },
      { id: 61, name: 'C55', status: 'free', active: false },
      { id: 62, name: 'C56', status: 'free', active: false },
      { id: 63, name: 'C57', status: 'free', active: false },
      { id: 64, name: 'C58', status: 'free', active: false },
      { id: 65, name: 'C59', status: 'free', active: false },
      { id: 66, name: 'C60', status: 'free', active: false },
      { id: 67, name: 'C61', status: 'free', active: false },
      { id: 68, name: 'C62', status: 'free', active: false },
      { id: 69, name: 'C63', status: 'free', active: false },
      { id: 70, name: 'C64', status: 'free', active: false },
      { id: 71, name: 'C65', status: 'free', active: false },
      { id: 72, name: 'C66', status: 'free', active: false },
      { id: 73, name: 'C67', status: 'free', active: false },
      { id: 74, name: 'C68', status: 'free', active: false },
      { id: 75, name: 'C69', status: 'free', active: false },
      { id: 76, name: 'C70', status: 'free', active: false },

      { id: 77, name: 'K1', status: 'free', active: false },
      { id: 78, name: 'K2', status: 'free', active: false },
      { id: 79, name: 'K3', status: 'free', active: false },
      { id: 80, name: 'K4', status: 'free', active: false },
      { id: 81, name: 'K5', status: 'free', active: false },
      { id: 82, name: 'K6', status: 'free', active: false },
      { id: 83, name: 'K7', status: 'free', active: false },
      { id: 84, name: 'K8', status: 'free', active: false },
      { id: 85, name: 'K9', status: 'free', active: false },
      { id: 86, name: 'K10', status: 'free', active: false },
      { id: 87, name: 'K11', status: 'free', active: false },
      { id: 88, name: 'K12', status: 'free', active: false },
      { id: 89, name: 'K13', status: 'free', active: false },
      { id: 90, name: 'K14', status: 'free', active: false },
      { id: 91, name: 'K15', status: 'free', active: false },
      { id: 92, name: 'K16', status: 'free', active: false },
      { id: 93, name: 'K17', status: 'free', active: false },
      { id: 94, name: 'K18', status: 'free', active: false },
      { id: 95, name: 'K19', status: 'free', active: false },
      { id: 96, name: 'K20', status: 'free', active: false },
      { id: 97, name: 'K21', status: 'free', active: false },
      { id: 98, name: 'K22', status: 'free', active: false },
      { id: 99, name: 'K23', status: 'free', active: false },
      { id: 100, name: 'K24', status: 'free', active: false },
      { id: 101, name: 'K25', status: 'free', active: false },
      { id: 102, name: 'K26', status: 'free', active: false },
      { id: 103, name: 'K27', status: 'free', active: false },
      { id: 104, name: 'K28', status: 'free', active: false },
      { id: 105, name: 'K29', status: 'free', active: false },
      { id: 106, name: 'K30', status: 'free', active: false },
      { id: 107, name: 'K31', status: 'free', active: false },
      { id: 108, name: 'K32', status: 'free', active: false },
      { id: 109, name: 'K33', status: 'free', active: false },
      { id: 110, name: 'K34', status: 'free', active: false },
      { id: 111, name: 'K35', status: 'free', active: false },
      { id: 112, name: 'K36', status: 'free', active: false },
      { id: 113, name: 'K37', status: 'free', active: false },
      { id: 114, name: 'K38', status: 'free', active: false },
      { id: 115, name: 'K39', status: 'free', active: false },
      { id: 116, name: 'K40', status: 'free', active: false },
      { id: 117, name: 'K41', status: 'free', active: false },
      { id: 118, name: 'K42', status: 'free', active: false },
      { id: 119, name: 'K43', status: 'free', active: false },
      { id: 120, name: 'K44', status: 'free', active: false }
    ]
  },

  {
    location_id: 3,
    id: 5,
    t_name: '1 этаж',
    open_space: { id: 326, name: 'Нефиксированное рабочее место', status: 'free', active: false },
    rooms: [
      { id: 327, name: 'C101', status: 'free', active: false },
      { id: 329, name: 'C102', status: 'free', active: false },
      {
        id: 330,
        name: 'С103',
        status: 'free',
        active: false
      },
      {
        id: 331,
        name: 'С104 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 332,
        name: 'С105',
        status: 'free',
        active: false
      },
      {
        id: 333,
        name: 'С106 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 334,
        name: 'С107',
        status: 'free',
        active: false
      },
      {
        id: 335,
        name: 'С108 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 336,
        name: 'С109',
        status: 'free',
        active: false
      },
      {
        id: 337,
        name: 'С111 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 338,
        name: 'С112 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 339,
        name: 'С113',
        status: 'free',
        active: false
      },
      {
        id: 340,
        name: 'С114',
        status: 'free',
        active: false
      },
      {
        id: 341,
        name: 'С115',
        status: 'free',
        active: false
      },
      {
        id: 342,
        name: 'С110',
        status: 'free',
        active: false
      },
      {
        id: 343,
        name: 'С116 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 344,
        name: 'С117 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 345,
        name: 'С118 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 346,
        name: 'С119 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 347,
        name: 'С120 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 348,
        name: 'С121 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 349,
        name: 'С122 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 350,
        name: 'С123 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 351,
        name: 'С124 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 352,
        name: 'С125 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 353,
        name: 'С126 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 354,
        name: 'С127',
        status: 'free',
        active: false
      },
      {
        id: 355,
        name: 'С128 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 356,
        name: 'С129 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 357,
        name: 'С130 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 364,
        name: 'K101',
        status: 'free',
        active: false
      },
      {
        id: 365,
        name: 'K102',
        status: 'free',
        active: false
      },
      {
        id: 366,
        name: 'K103',
        status: 'free',
        active: false
      },
      {
        id: 367,
        name: 'K104',
        status: 'free',
        active: false
      },
      {
        id: 368,
        name: 'K105',
        status: 'free',
        active: false
      },
      {
        id: 369,
        name: 'K106',
        status: 'free',
        active: false
      },
      {
        id: 370,
        name: 'K107',
        status: 'free',
        active: false
      },
      {
        id: 371,
        name: 'K108',
        status: 'free',
        active: false
      },
      {
        id: 372,
        name: 'K109',
        status: 'free',
        active: false
      },
      {
        id: 373,
        name: 'K110',
        status: 'free',
        active: false
      },
      {
        id: 374,
        name: 'K111',
        status: 'free',
        active: false
      },
      {
        id: 375,
        name: 'K112',
        status: 'free',
        active: false
      },
      {
        id: 376,
        name: 'K113',
        status: 'free',
        active: false
      },
      {
        id: 377,
        name: 'K114',
        status: 'free',
        active: false
      },
      {
        id: 378,
        name: 'K115',
        status: 'free',
        active: false
      },
      {
        id: 379,
        name: 'K116',
        status: 'free',
        active: false
      },
      {
        id: 380,
        name: 'K117',
        status: 'free',
        active: false
      },
      {
        id: 381,
        name: 'K118',
        status: 'free',
        active: false
      },
      {
        id: 382,
        name: 'K119',
        status: 'free',
        active: false
      },
      {
        id: 383,
        name: 'K120',
        status: 'free',
        active: false
      },
      {
        id: 384,
        name: 'K121',
        status: 'free',
        active: false
      },
      {
        id: 385,
        name: 'K122',
        status: 'free',
        active: false
      },
      {
        id: 386,
        name: 'K123',
        status: 'free',
        active: false
      },
      {
        id: 387,
        name: 'K124',
        status: 'free',
        active: false
      },
      {
        id: 388,
        name: 'K125',
        status: 'free',
        active: false
      },
      {
        id: 389,
        name: 'K126',
        status: 'free',
        active: false
      },
      {
        id: 390,
        name: 'K127',
        status: 'free',
        active: false
      },
      {
        id: 391,
        name: 'K128',
        status: 'free',
        active: false
      },
      {
        id: 392,
        name: 'K129',
        status: 'free',
        active: false
      },
      {
        id: 393,
        name: 'K130',
        status: 'free',
        active: false
      },
      {
        id: 394,
        name: 'K131',
        status: 'free',
        active: false
      },
      {
        id: 395,
        name: 'K132',
        status: 'free',
        active: false
      },
      {
        id: 396,
        name: 'K133',
        status: 'free',
        active: false
      }
    ]
  },
  {
    location_id: 3,
    id: 6,
    t_name: '2 этаж',
    open_space: { id: 326, name: 'Нефиксированное рабочее место', status: 'free', active: false },
    rooms: [
      {
        id: 358,
        name: 'С201 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 359,
        name: 'С202 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 360,
        name: 'С203 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 361,
        name: 'С204 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 362,
        name: 'С205',
        status: 'free',
        active: false
      },
      {
        id: 363,
        name: 'С206 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 464,
        name: 'С207',
        status: 'free',
        active: false
      },
      {
        id: 465,
        name: 'С208 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 466,
        name: 'С209',
        status: 'free',
        active: false
      },
      {
        id: 467,
        name: 'С210 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 468,
        name: 'С211',
        status: 'free',
        active: false
      },
      {
        id: 469,
        name: 'С212 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 470,
        name: 'С213',
        status: 'free',
        active: false
      },
      {
        id: 471,
        name: 'С214 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 472,
        name: 'С215 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 473,
        name: 'С216',
        status: 'free',
        active: false
      },
      {
        id: 474,
        name: 'С217',
        status: 'free',
        active: false
      },
      {
        id: 475,
        name: 'С218',
        status: 'free',
        active: false
      },
      {
        id: 476,
        name: 'С219 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 477,
        name: 'С220 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 478,
        name: 'С221',
        status: 'free',
        active: false
      },
      {
        id: 479,
        name: 'С222 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 480,
        name: 'С223',
        status: 'free',
        active: false
      },
      {
        id: 481,
        name: 'С224 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 482,
        name: 'С225',
        status: 'free',
        active: false
      },
      {
        id: 483,
        name: 'С226 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 484,
        name: 'С227',
        status: 'free',
        active: false
      },
      {
        id: 485,
        name: 'С228 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 486,
        name: 'С229 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 487,
        name: 'С230 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 488,
        name: 'С231 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 489,
        name: 'С232 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 490,
        name: 'С233 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 491,
        name: 'С234 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 492,
        name: 'С235',
        status: 'free',
        active: false
      },
      {
        id: 493,
        name: 'С236 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 494,
        name: 'С237 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 495,
        name: 'С238 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 496,
        name: 'С239 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 497,
        name: 'С240',
        status: 'free',
        active: false
      },
      {
        id: 498,
        name: 'С241 (с окном)',
        status: 'free',
        active: false
      },

      {
        id: 397,
        name: 'K201',
        status: 'free',
        active: false
      },
      {
        id: 398,
        name: 'K202',
        status: 'free',
        active: false
      },
      {
        id: 399,
        name: 'K203',
        status: 'free',
        active: false
      },
      {
        id: 400,
        name: 'K204',
        status: 'free',
        active: false
      },
      {
        id: 401,
        name: 'K205',
        status: 'free',
        active: false
      },
      {
        id: 402,
        name: 'K206',
        status: 'free',
        active: false
      },
      {
        id: 403,
        name: 'K207',
        status: 'free',
        active: false
      },
      {
        id: 404,
        name: 'K208',
        status: 'free',
        active: false
      },
      {
        id: 405,
        name: 'K209',
        status: 'free',
        active: false
      },
      {
        id: 406,
        name: 'K210',
        status: 'free',
        active: false
      },
      {
        id: 407,
        name: 'K211',
        status: 'free',
        active: false
      },
      {
        id: 408,
        name: 'K212',
        status: 'free',
        active: false
      },
      {
        id: 409,
        name: 'K213',
        status: 'free',
        active: false
      },
      {
        id: 410,
        name: 'K214',
        status: 'free',
        active: false
      },
      {
        id: 411,
        name: 'K215',
        status: 'free',
        active: false
      },
      {
        id: 412,
        name: 'K216',
        status: 'free',
        active: false
      },
      {
        id: 413,
        name: 'K217',
        status: 'free',
        active: false
      },
      {
        id: 414,
        name: 'K218',
        status: 'free',
        active: false
      },
      {
        id: 415,
        name: 'K219',
        status: 'free',
        active: false
      },
      {
        id: 416,
        name: 'K220',
        status: 'free',
        active: false
      },
      {
        id: 417,
        name: 'K221',
        status: 'free',
        active: false
      },
      {
        id: 418,
        name: 'K222',
        status: 'free',
        active: false
      },
      {
        id: 419,
        name: 'K223',
        status: 'free',
        active: false
      },
      {
        id: 420,
        name: 'K224',
        status: 'free',
        active: false
      },
      {
        id: 421,
        name: 'K225',
        status: 'free',
        active: false
      },
      {
        id: 422,
        name: 'K226',
        status: 'free',
        active: false
      },
      {
        id: 423,
        name: 'K227',
        status: 'free',
        active: false
      },
      {
        id: 424,
        name: 'K228',
        status: 'free',
        active: false
      },
      {
        id: 425,
        name: 'K229',
        status: 'free',
        active: false
      },
      {
        id: 426,
        name: 'K230',
        status: 'free',
        active: false
      },
      {
        id: 427,
        name: 'K231',
        status: 'free',
        active: false
      }
    ]
  },
  {
    location_id: 3,
    id: 7,
    t_name: '3 этаж',
    open_space: { id: 326, name: 'Нефиксированное рабочее место', status: 'free', active: false },
    rooms: [
      {
        id: 499,
        name: 'С301 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 500,
        name: 'С302 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 501,
        name: 'С304 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 502,
        name: 'С303 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 503,
        name: 'С305',
        status: 'free',
        active: false
      },
      {
        id: 504,
        name: 'С306 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 505,
        name: 'С307',
        status: 'free',
        active: false
      },
      {
        id: 506,
        name: 'С308 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 507,
        name: 'С309',
        status: 'free',
        active: false
      },
      {
        id: 508,
        name: 'С310 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 509,
        name: 'С311',
        status: 'free',
        active: false
      },
      {
        id: 510,
        name: 'С312 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 511,
        name: 'С313',
        status: 'free',
        active: false
      },
      {
        id: 512,
        name: 'С314 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 513,
        name: 'С315 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 514,
        name: 'С316 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 515,
        name: 'С317',
        status: 'free',
        active: false
      },
      {
        id: 516,
        name: 'С318',
        status: 'free',
        active: false
      },
      {
        id: 517,
        name: 'С319',
        status: 'free',
        active: false
      },
      {
        id: 518,
        name: 'С320 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 519,
        name: 'С321 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 520,
        name: 'С322 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 521,
        name: 'С323 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 522,
        name: 'С324 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 523,
        name: 'С325 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 524,
        name: 'С326 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 525,
        name: 'С327 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 526,
        name: 'С328 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 527,
        name: 'С329 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 528,
        name: 'С330 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 529,
        name: 'С331 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 530,
        name: 'С332 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 531,
        name: 'С333 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 532,
        name: 'С334 (с окном)',
        status: 'free',
        active: false
      },

      {
        id: 428,
        name: 'K301',
        status: 'free',
        active: false
      },
      {
        id: 429,
        name: 'K302',
        status: 'free',
        active: false
      },
      {
        id: 430,
        name: 'K303',
        status: 'free',
        active: false
      },
      {
        id: 431,
        name: 'K304',
        status: 'free',
        active: false
      },
      {
        id: 432,
        name: 'K305',
        status: 'free',
        active: false
      },
      {
        id: 433,
        name: 'K306',
        status: 'free',
        active: false
      },
      {
        id: 434,
        name: 'K307',
        status: 'free',
        active: false
      },
      {
        id: 435,
        name: 'K308',
        status: 'free',
        active: false
      },
      {
        id: 436,
        name: 'K309',
        status: 'free',
        active: false
      },
      {
        id: 437,
        name: 'K310',
        status: 'free',
        active: false
      },
      {
        id: 438,
        name: 'K311',
        status: 'free',
        active: false
      },
      {
        id: 439,
        name: 'K312',
        status: 'free',
        active: false
      },
      {
        id: 440,
        name: 'K313',
        status: 'free',
        active: false
      },
      {
        id: 441,
        name: 'K314',
        status: 'free',
        active: false
      },
      {
        id: 442,
        name: 'K315',
        status: 'free',
        active: false
      },
      {
        id: 443,
        name: 'K316',
        status: 'free',
        active: false
      },
      {
        id: 444,
        name: 'K317',
        status: 'free',
        active: false
      },
      {
        id: 445,
        name: 'K318',
        status: 'free',
        active: false
      },
      {
        id: 446,
        name: 'K319',
        status: 'free',
        active: false
      },
      {
        id: 447,
        name: 'K320',
        status: 'free',
        active: false
      },
      {
        id: 448,
        name: 'K321',
        status: 'free',
        active: false
      },
      {
        id: 449,
        name: 'K322',
        status: 'free',
        active: false
      },
      {
        id: 450,
        name: 'K323',
        status: 'free',
        active: false
      },
      {
        id: 451,
        name: 'K324',
        status: 'free',
        active: false
      },
      {
        id: 452,
        name: 'K325',
        status: 'free',
        active: false
      },
      {
        id: 453,
        name: 'K326',
        status: 'free',
        active: false
      },
      {
        id: 454,
        name: 'K327',
        status: 'free',
        active: false
      },
      {
        id: 455,
        name: 'K328',
        status: 'free',
        active: false
      },
      {
        id: 456,
        name: 'K329',
        status: 'free',
        active: false
      },
      {
        id: 457,
        name: 'K330',
        status: 'free',
        active: false
      },
      {
        id: 458,
        name: 'K331',
        status: 'free',
        active: false
      },
      {
        id: 459,
        name: 'K332',
        status: 'free',
        active: false
      },
      {
        id: 460,
        name: 'K333',
        status: 'free',
        active: false
      },
      {
        id: 461,
        name: 'K334',
        status: 'free',
        active: false
      },
      {
        id: 462,
        name: 'K335',
        status: 'free',
        active: false
      },
      {
        id: 463,
        name: 'K336',
        status: 'free',
        active: false
      }
    ]
  },
  {
    location_id: 3,
    id: 8,
    t_name: '4 этаж',
    open_space: { id: 326, name: 'Нефиксированное рабочее место', status: 'free', active: false },
    rooms: [
      {
        id: 533,
        name: 'С401 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 534,
        name: 'С402 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 535,
        name: 'С403 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 536,
        name: 'С404 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 537,
        name: 'С405 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 538,
        name: 'С406 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 539,
        name: 'С407 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 540,
        name: 'С408 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 541,
        name: 'С409 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 542,
        name: 'С410 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 543,
        name: 'С411 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 544,
        name: 'С412 (с окном)',
        status: 'free',
        active: false
      },
      {
        id: 545,
        name: 'С413',
        status: 'free',
        active: false
      },
      {
        id: 546,
        name: 'С414 (с окном)',
        status: 'free',
        active: false
      }
    ]
  },
  {
    location_id: 4,
    id: 9,
    t_name: '4 этаж',
    open_space: { id: 578, name: 'Нефиксированное рабочее место', status: 'free', active: false },
    rooms: [
      {
        id: 593,
        name: '401',
        status: 'free',
        active: false
      },
      {
        id: 594,
        name: '402',
        status: 'free',
        active: false
      },
      {
        id: 595,
        name: '403',
        status: 'free',
        active: false
      },
      {
        id: 596,
        name: '404',
        status: 'free',
        active: false
      },
      {
        id: 597,
        name: '405',
        status: 'free',
        active: false
      },
      {
        id: 598,
        name: '406',
        status: 'free',
        active: false
      },
      {
        id: 599,
        name: '407',
        status: 'free',
        active: false
      },
      {
        id: 600,
        name: '408',
        status: 'free',
        active: false
      },
      {
        id: 601,
        name: '409',
        status: 'free',
        active: false
      },
      {
        id: 602,
        name: '410',
        status: 'free',
        active: false
      },
      {
        id: 603,
        name: '411',
        status: 'free',
        active: false
      },
      {
        id: 604,
        name: '412',
        status: 'free',
        active: false
      },
      {
        id: 605,
        name: '413',
        status: 'free',
        active: false
      },
      {
        id: 606,
        name: '414',
        status: 'free',
        active: false
      },
      {
        id: 607,
        name: '415',
        status: 'free',
        active: false
      },
      {
        id: 608,
        name: '416',
        status: 'free',
        active: false
      },
      {
        id: 609,
        name: '417',
        status: 'free',
        active: false
      },
      {
        id: 610,
        name: '418',
        status: 'free',
        active: false
      },
      {
        id: 611,
        name: '419, 421',
        status: 'free',
        active: false
      },
      {
        id: 612,
        name: '420',
        status: 'free',
        active: false
      },
      {
        id: 613,
        name: '422',
        status: 'free',
        active: false
      },
      {
        id: 614,
        name: '423',
        status: 'free',
        active: false
      },
      {
        id: 615,
        name: '424',
        status: 'free',
        active: false
      },
      {
        id: 616,
        name: '425',
        status: 'free',
        active: false
      },
      {
        id: 617,
        name: '426, 428',
        status: 'free',
        active: false
      },
      {
        id: 618,
        name: '427',
        status: 'free',
        active: false
      },
      {
        id: 619,
        name: '429',
        status: 'free',
        active: false
      },
      {
        id: 620,
        name: '430',
        status: 'free',
        active: false
      },
      {
        id: 621,
        name: '431',
        status: 'free',
        active: false
      },
      {
        id: 622,
        name: '433',
        status: 'free',
        active: false
      },
      {
        id: 623,
        name: '432, 434',
        status: 'free',
        active: false
      },
      {
        id: 624,
        name: '435',
        status: 'free',
        active: false
      },
      {
        id: 625,
        name: '436',
        status: 'free',
        active: false
      },
      {
        id: 626,
        name: '437',
        status: 'free',
        active: false
      },
      {
        id: 627,
        name: '438',
        status: 'free',
        active: false
      },
      {
        id: 628,
        name: '439',
        status: 'free',
        active: false
      },
      {
        id: 629,
        name: '440',
        status: 'free',
        active: false
      }
    ]
  },
  {
    location_id: 4,
    id: 10,
    t_name: '5 этаж',
    open_space: { id: 578, name: 'Нефиксированное рабочее место', status: 'free', active: false },
    rooms: [
      { id: 630, name: '501', status: 'free', active: true },
      { id: 631, name: '502', status: 'free', active: false },
      { id: 632, name: '503', status: 'free', active: false },
      { id: 633, name: '504', status: 'free', active: false },
      { id: 634, name: '505', status: 'free', active: false },
      { id: 635, name: '506', status: 'free', active: false },
      { id: 636, name: '507', status: 'free', active: false },
      { id: 637, name: '508', status: 'free', active: false },
      { id: 638, name: '509', status: 'free', active: false },
      { id: 639, name: '510', status: 'free', active: false },
      { id: 640, name: '511', status: 'free', active: false },
      { id: 641, name: '512', status: 'free', active: false },
      { id: 642, name: '513', status: 'free', active: false },
      { id: 643, name: '514', status: 'free', active: false },
      { id: 644, name: '515', status: 'free', active: false },
      { id: 645, name: '516', status: 'free', active: false },
      { id: 646, name: '517', status: 'free', active: false },
      { id: 647, name: '518', status: 'free', active: false },
      { id: 648, name: '519', status: 'free', active: false },
      { id: 649, name: '520, 521', status: 'free', active: false },
      { id: 650, name: '522', status: 'free', active: false },
      { id: 651, name: '523', status: 'free', active: false },
      { id: 652, name: '524', status: 'free', active: false },
      { id: 653, name: '525', status: 'free', active: false },
      { id: 654, name: '526', status: 'free', active: false },
      { id: 655, name: '527', status: 'free', active: false },
      { id: 656, name: '528', status: 'free', active: false },
      { id: 657, name: '529', status: 'free', active: false },
      { id: 658, name: '530', status: 'free', active: false },
      { id: 659, name: '531', status: 'free', active: false },
      { id: 660, name: '532', status: 'free', active: false },
      { id: 661, name: '533', status: 'free', active: false },
      { id: 662, name: '534', status: 'free', active: false },
      { id: 663, name: '535', status: 'free', active: false },
      { id: 664, name: '536', status: 'free', active: false },
      { id: 665, name: '537', status: 'free', active: false },
      { id: 666, name: '538', status: 'free', active: false },
      { id: 667, name: '539', status: 'free', active: false },

      { id: 668, name: 'K501', status: 'free', active: false },
      { id: 669, name: 'K502', status: 'free', active: false },
      { id: 670, name: 'K503', status: 'free', active: false },
      { id: 671, name: 'K504', status: 'free', active: false },
      { id: 672, name: 'K505', status: 'free', active: false },
      { id: 674, name: 'K506', status: 'free', active: false },
      { id: 675, name: 'K507', status: 'free', active: false },
      { id: 676, name: 'K508', status: 'free', active: false },
      { id: 677, name: 'K509', status: 'free', active: false },
      { id: 678, name: 'K510', status: 'free', active: false },
      { id: 679, name: 'K511', status: 'free', active: false },
      { id: 680, name: 'K512', status: 'free', active: false },
      { id: 681, name: 'K513', status: 'free', active: false },
      { id: 682, name: 'K514', status: 'free', active: false },
      { id: 683, name: 'K515', status: 'free', active: false },
      { id: 684, name: 'K516', status: 'free', active: false },
      { id: 685, name: 'K517', status: 'free', active: false },
      { id: 686, name: 'K518', status: 'free', active: false }
    ]
  },
  {
    location_id: 4,
    id: 11,
    t_name: '6 этаж',
    open_space: { id: 578, name: 'Нефиксированное рабочее место', status: 'free', active: false },
    rooms: [
      { id: 630, name: '601', status: 'free', active: false },
      { id: 631, name: '602', status: 'free', active: false },
      { id: 632, name: '603', status: 'free', active: false },
      { id: 633, name: '604', status: 'free', active: false },
      { id: 634, name: '605', status: 'free', active: false },
      { id: 635, name: '606', status: 'free', active: false },
      { id: 636, name: '607', status: 'free', active: false },
      { id: 637, name: '608', status: 'free', active: false },
      { id: 638, name: '609', status: 'free', active: false },
      { id: 639, name: '510', status: 'free', active: false },
      { id: 640, name: '511', status: 'free', active: false },
      { id: 641, name: '512', status: 'free', active: false },
      { id: 642, name: '513', status: 'free', active: false },
      { id: 643, name: '514', status: 'free', active: false },
      { id: 644, name: '515', status: 'free', active: false },
      { id: 645, name: '516', status: 'free', active: false },
      { id: 646, name: '517', status: 'free', active: false },
      { id: 647, name: '518', status: 'free', active: false },
      { id: 648, name: '519', status: 'free', active: false },
      { id: 650, name: '522', status: 'free', active: false },
      { id: 651, name: '523', status: 'free', active: false },
      { id: 652, name: '524', status: 'free', active: false },
      { id: 653, name: '525', status: 'free', active: false },
      { id: 654, name: '526', status: 'free', active: false },
      { id: 655, name: '527', status: 'free', active: false },
      { id: 656, name: '528', status: 'free', active: false },
      { id: 657, name: '529', status: 'free', active: false },
      { id: 658, name: '530', status: 'free', active: false },
      { id: 659, name: '531', status: 'free', active: false },
      { id: 660, name: '532', status: 'free', active: false },
      { id: 661, name: '533', status: 'free', active: false },
      { id: 662, name: '534', status: 'free', active: false },
      { id: 663, name: '535', status: 'free', active: false },
      { id: 664, name: '536', status: 'free', active: false },
      { id: 665, name: '537', status: 'free', active: false },
      { id: 666, name: '538', status: 'free', active: false },
      { id: 667, name: '539', status: 'free', active: false },

      { id: 668, name: 'K501', status: 'free', active: false },
      { id: 669, name: 'K502', status: 'free', active: false },
      { id: 670, name: 'K503', status: 'free', active: false },
      { id: 671, name: 'K504', status: 'free', active: false },
      { id: 672, name: 'K505', status: 'free', active: false },
      { id: 674, name: 'K506', status: 'free', active: false },
      { id: 675, name: 'K507', status: 'free', active: false },
      { id: 676, name: 'K508', status: 'free', active: false },
      { id: 677, name: 'K509', status: 'free', active: false },
      { id: 678, name: 'K510', status: 'free', active: false },
      { id: 679, name: 'K511', status: 'free', active: false },
      { id: 680, name: 'K512', status: 'free', active: false },
      { id: 681, name: 'K513', status: 'free', active: false },
      { id: 682, name: 'K514', status: 'free', active: false },
      { id: 683, name: 'K515', status: 'free', active: false },
      { id: 684, name: 'K516', status: 'free', active: false },
      { id: 685, name: 'K517', status: 'free', active: false },
      { id: 686, name: 'K518', status: 'free', active: false }
    ]
  },
  {
    location_id: 5,
    id: 13,
    t_name: '-1 этаж',
    open_space: {},
    rooms: []
  },
  {
    location_id: 5,
    id: 14,
    t_name: '1 этаж',
    open_space: { id: 687, name: 'Нефиксированное рабочее место', status: 'free', active: false }
  },
  {
    location_id: 5,
    id: 15,
    t_name: '2 этаж',
    open_space: { id: 688, name: 'Нефиксированное рабочее место', status: 'free', active: false },
    rooms: []
  },
  {
    location_id: 7,
    id: 20,
    t_name: '5 этаж',
    open_space: {},
    rooms: []
  },
  {
    location_id: 7,
    id: 21,
    t_name: '6 этаж',
    open_space: { id: 689, name: 'Нефиксированное рабочее место', status: 'free', active: false },
    rooms: []
  }
];
