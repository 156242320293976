import React from 'react';

export const CityPatch2 = () => {
  return (
    <>
      <path
        d='M1314.07 1444.9C1313.65 1445.26 1313.02 1445.2 1312.66 1444.78L1305.59 1436.35C1305.24 1435.93 1305.29 1435.3 1305.71 1434.94C1306.14 1434.59 1306.77 1434.64 1307.12 1435.07L1314.19 1443.49C1314.55 1443.92 1314.49 1444.55 1314.07 1444.9Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M1336.14 1383.31C1336.78 1382.77 1337.72 1382.86 1338.25 1383.49L1344.68 1391.15C1345.21 1391.79 1345.13 1392.73 1344.5 1393.26L1340.67 1396.48C1339.19 1397.72 1336.98 1397.53 1335.74 1396.05L1331.88 1391.45C1330.64 1389.97 1330.83 1387.76 1332.31 1386.52L1336.14 1383.31Z'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <path
        d='M1340.73 1379.45C1341.37 1378.92 1342.32 1379 1342.85 1379.63L1349.28 1387.3C1349.81 1387.93 1349.73 1388.88 1349.09 1389.41L1346.79 1391.34C1346.16 1391.87 1345.21 1391.79 1344.68 1391.15L1338.25 1383.49C1337.72 1382.86 1337.8 1381.91 1338.44 1381.38L1340.73 1379.45Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M1341.12 1379.13C1341.54 1378.77 1342.17 1378.83 1342.53 1379.25L1349.6 1387.68C1349.95 1388.1 1349.9 1388.73 1349.47 1389.09C1349.05 1389.44 1348.42 1389.39 1348.07 1388.96L1340.99 1380.54C1340.64 1380.11 1340.69 1379.48 1341.12 1379.13Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M1306.19 1425.41C1305.55 1425.94 1304.61 1425.86 1304.07 1425.22L1297.65 1417.56C1297.11 1416.93 1297.2 1415.98 1297.83 1415.45L1301.66 1412.23C1303.14 1410.99 1305.35 1411.18 1306.59 1412.66L1310.45 1417.26C1311.69 1418.74 1311.5 1420.95 1310.02 1422.19L1306.19 1425.41Z'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <path
        d='M1301.59 1429.26C1300.96 1429.79 1300.01 1429.71 1299.48 1429.08L1293.05 1421.42C1292.52 1420.78 1292.6 1419.84 1293.24 1419.3L1295.54 1417.37C1296.17 1416.84 1297.12 1416.92 1297.65 1417.56L1304.08 1425.22C1304.61 1425.85 1304.53 1426.8 1303.89 1427.33L1301.59 1429.26Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M1301.21 1429.58C1300.79 1429.94 1300.16 1429.88 1299.8 1429.46L1292.73 1421.03C1292.38 1420.61 1292.43 1419.98 1292.85 1419.62C1293.28 1419.27 1293.91 1419.32 1294.26 1419.75L1301.33 1428.17C1301.69 1428.6 1301.63 1429.23 1301.21 1429.58Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M1348.99 1398.63C1349.63 1398.09 1350.57 1398.18 1351.11 1398.81L1357.53 1406.47C1358.07 1407.11 1357.98 1408.05 1357.35 1408.58L1353.52 1411.8C1352.04 1413.04 1349.83 1412.85 1348.59 1411.37L1344.73 1406.77C1343.49 1405.29 1343.68 1403.08 1345.16 1401.84L1348.99 1398.63Z'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <path
        d='M1353.59 1394.77C1354.22 1394.24 1355.17 1394.32 1355.7 1394.96L1362.13 1402.62C1362.66 1403.25 1362.58 1404.2 1361.94 1404.73L1359.64 1406.66C1359.01 1407.19 1358.06 1407.11 1357.53 1406.47L1351.1 1398.81C1350.57 1398.18 1350.65 1397.23 1351.29 1396.7L1353.59 1394.77Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M1353.97 1394.45C1354.39 1394.09 1355.02 1394.15 1355.38 1394.57L1362.45 1403C1362.8 1403.42 1362.75 1404.05 1362.33 1404.41C1361.9 1404.76 1361.27 1404.71 1360.92 1404.28L1353.85 1395.86C1353.49 1395.43 1353.55 1394.8 1353.97 1394.45Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M1293.34 1410.09C1292.7 1410.62 1291.75 1410.54 1291.22 1409.9L1284.79 1402.24C1284.26 1401.61 1284.34 1400.66 1284.98 1400.13L1288.81 1396.91C1290.29 1395.67 1292.5 1395.86 1293.74 1397.34L1297.6 1401.94C1298.84 1403.42 1298.65 1405.63 1297.17 1406.87L1293.34 1410.09Z'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <path
        d='M1288.74 1413.94C1288.11 1414.47 1287.16 1414.39 1286.63 1413.76L1280.2 1406.1C1279.67 1405.46 1279.75 1404.51 1280.39 1403.98L1282.68 1402.05C1283.32 1401.52 1284.26 1401.6 1284.8 1402.24L1291.22 1409.9C1291.76 1410.53 1291.67 1411.48 1291.04 1412.01L1288.74 1413.94Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M1288.36 1414.26C1287.94 1414.62 1287.31 1414.56 1286.95 1414.14L1279.88 1405.71C1279.52 1405.29 1279.58 1404.66 1280 1404.3C1280.43 1403.95 1281.06 1404 1281.41 1404.43L1288.48 1412.85C1288.84 1413.28 1288.78 1413.91 1288.36 1414.26Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M1280.48 1394.76C1279.84 1395.3 1278.9 1395.21 1278.36 1394.58L1271.94 1386.92C1271.4 1386.29 1271.49 1385.34 1272.12 1384.81L1275.95 1381.59C1277.43 1380.35 1279.64 1380.54 1280.88 1382.02L1284.74 1386.62C1285.98 1388.1 1285.79 1390.31 1284.31 1391.55L1280.48 1394.76Z'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <path
        d='M1275.88 1398.62C1275.25 1399.15 1274.3 1399.07 1273.77 1398.44L1267.34 1390.78C1266.81 1390.14 1266.89 1389.19 1267.53 1388.66L1269.82 1386.73C1270.46 1386.2 1271.41 1386.28 1271.94 1386.92L1278.37 1394.58C1278.9 1395.21 1278.82 1396.16 1278.18 1396.69L1275.88 1398.62Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M1275.5 1398.94C1275.08 1399.3 1274.45 1399.24 1274.09 1398.82L1267.02 1390.39C1266.67 1389.97 1266.72 1389.34 1267.14 1388.98C1267.57 1388.63 1268.2 1388.68 1268.55 1389.11L1275.62 1397.53C1275.98 1397.96 1275.92 1398.59 1275.5 1398.94Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M1267.62 1379.44C1266.99 1379.97 1266.04 1379.89 1265.51 1379.26L1259.08 1371.6C1258.55 1370.96 1258.63 1370.01 1259.27 1369.48L1263.1 1366.27C1264.58 1365.03 1266.79 1365.22 1268.03 1366.7L1271.89 1371.3C1273.13 1372.78 1272.94 1374.98 1271.45 1376.23L1267.62 1379.44Z'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <path
        d='M1263.03 1383.3C1262.4 1383.83 1261.45 1383.75 1260.92 1383.11L1254.49 1375.45C1253.96 1374.82 1254.04 1373.87 1254.67 1373.34L1256.97 1371.41C1257.61 1370.88 1258.55 1370.96 1259.09 1371.59L1265.51 1379.25C1266.05 1379.89 1265.96 1380.84 1265.33 1381.37L1263.03 1383.3Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M1262.65 1383.62C1262.22 1383.97 1261.59 1383.92 1261.24 1383.49L1254.17 1375.07C1253.81 1374.64 1253.87 1374.01 1254.29 1373.66C1254.71 1373.3 1255.35 1373.36 1255.7 1373.78L1262.77 1382.21C1263.13 1382.63 1263.07 1383.26 1262.65 1383.62Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M1287.48 1343.2L1350.93 1418.83L1330.25 1436.18L1266.79 1360.56L1287.48 1343.2Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M1933.25 909.012L1884.7 862.127L1903.46 842.705L1952.01 889.59L1933.25 909.012Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M260.057 1824.24C259.862 1823.43 260.358 1822.62 261.163 1822.43L270.884 1820.08C271.689 1819.89 272.5 1820.38 272.694 1821.19L273.867 1826.05C274.32 1827.93 273.164 1829.82 271.285 1830.27L265.452 1831.68C263.573 1832.13 261.683 1830.98 261.229 1829.1L260.057 1824.24Z'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <path
        d='M258.65 1818.41C258.456 1817.6 258.951 1816.79 259.757 1816.6L269.478 1814.25C270.283 1814.06 271.094 1814.55 271.288 1815.36L271.991 1818.27C272.186 1819.08 271.69 1819.89 270.885 1820.08L261.164 1822.43C260.359 1822.62 259.548 1822.13 259.354 1821.32L258.65 1818.41Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M258.533 1817.92C258.404 1817.38 258.734 1816.84 259.271 1816.71L269.964 1814.13C270.501 1814 271.041 1814.33 271.171 1814.87C271.3 1815.41 270.97 1815.95 270.433 1816.08L259.74 1818.66C259.203 1818.79 258.663 1818.46 258.533 1817.92Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M182.291 1843C182.097 1842.19 182.592 1841.38 183.397 1841.19L193.119 1838.84C193.924 1838.65 194.734 1839.14 194.928 1839.95L196.101 1844.81C196.554 1846.69 195.399 1848.58 193.519 1849.03L187.687 1850.44C185.808 1850.89 183.917 1849.74 183.464 1847.86L182.291 1843Z'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <path
        d='M180.885 1837.17C180.69 1836.36 181.186 1835.55 181.991 1835.36L191.712 1833.01C192.518 1832.82 193.328 1833.31 193.522 1834.12L194.226 1837.03C194.42 1837.84 193.925 1838.65 193.119 1838.84L183.398 1841.19C182.593 1841.38 181.783 1840.89 181.588 1840.08L180.885 1837.17Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M180.768 1836.68C180.638 1836.14 180.968 1835.6 181.505 1835.47L192.198 1832.89C192.735 1832.76 193.275 1833.1 193.405 1833.63C193.534 1834.17 193.204 1834.71 192.667 1834.84L181.974 1837.42C181.437 1837.55 180.897 1837.22 180.768 1836.68Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M162.846 1847.69C162.651 1846.88 163.147 1846.07 163.952 1845.88L173.673 1843.54C174.479 1843.34 175.289 1843.84 175.483 1844.64L176.656 1849.5C177.109 1851.38 175.953 1853.27 174.074 1853.73L168.241 1855.13C166.362 1855.59 164.472 1854.43 164.018 1852.55L162.846 1847.69Z'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <path
        d='M161.439 1841.86C161.245 1841.05 161.741 1840.24 162.546 1840.05L172.267 1837.7C173.072 1837.51 173.883 1838 174.077 1838.81L174.78 1841.73C174.975 1842.53 174.479 1843.34 173.674 1843.54L163.953 1845.88C163.148 1846.08 162.337 1845.58 162.143 1844.77L161.439 1841.86Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M161.322 1841.37C161.193 1840.84 161.523 1840.3 162.06 1840.17L172.753 1837.59C173.29 1837.46 173.83 1837.79 173.96 1838.32C174.089 1838.86 173.759 1839.4 173.222 1839.53L162.529 1842.11C161.992 1842.24 161.452 1841.91 161.322 1841.37Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M279.502 1819.55C279.308 1818.74 279.803 1817.93 280.608 1817.74L290.329 1815.39C291.135 1815.2 291.945 1815.69 292.139 1816.5L293.312 1821.36C293.765 1823.24 292.609 1825.13 290.73 1825.58L284.898 1826.99C283.019 1827.44 281.128 1826.29 280.675 1824.41L279.502 1819.55Z'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <path
        d='M278.096 1813.71C277.901 1812.91 278.397 1812.1 279.202 1811.9L288.923 1809.56C289.729 1809.36 290.539 1809.86 290.733 1810.67L291.437 1813.58C291.631 1814.39 291.136 1815.2 290.33 1815.39L280.609 1817.74C279.804 1817.93 278.994 1817.44 278.799 1816.63L278.096 1813.71Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M277.978 1813.23C277.849 1812.69 278.179 1812.15 278.716 1812.02L289.409 1809.44C289.946 1809.31 290.486 1809.64 290.616 1810.18C290.745 1810.72 290.415 1811.26 289.878 1811.39L279.185 1813.97C278.648 1814.09 278.108 1813.76 277.978 1813.23Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M201.729 1838.31C201.534 1837.5 202.03 1836.69 202.835 1836.5L212.556 1834.15C213.361 1833.96 214.172 1834.45 214.366 1835.26L215.539 1840.12C215.992 1842 214.836 1843.89 212.957 1844.34L207.124 1845.75C205.245 1846.2 203.354 1845.05 202.901 1843.17L201.729 1838.31Z'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <path
        d='M200.322 1832.48C200.128 1831.67 200.623 1830.86 201.429 1830.67L211.15 1828.32C211.955 1828.13 212.765 1828.62 212.96 1829.43L213.663 1832.34C213.858 1833.15 213.362 1833.96 212.557 1834.15L202.836 1836.5C202.03 1836.69 201.22 1836.2 201.026 1835.39L200.322 1832.48Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M200.205 1831.99C200.075 1831.45 200.406 1830.91 200.943 1830.78L211.636 1828.2C212.173 1828.07 212.713 1828.4 212.842 1828.94C212.972 1829.48 212.642 1830.02 212.105 1830.15L201.412 1832.73C200.875 1832.86 200.335 1832.53 200.205 1831.99Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M342.748 1855.72C342.942 1856.53 342.447 1857.34 341.642 1857.53L331.921 1859.88C331.115 1860.07 330.305 1859.58 330.111 1858.77L328.938 1853.91C328.485 1852.03 329.641 1850.14 331.52 1849.69L337.352 1848.28C339.231 1847.83 341.122 1848.98 341.575 1850.86L342.748 1855.72Z'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <path
        d='M344.154 1861.56C344.349 1862.36 343.853 1863.17 343.048 1863.37L333.327 1865.71C332.521 1865.91 331.711 1865.41 331.517 1864.6L330.813 1861.69C330.619 1860.88 331.114 1860.07 331.92 1859.88L341.641 1857.53C342.446 1857.34 343.256 1857.83 343.451 1858.64L344.154 1861.56Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M344.272 1862.04C344.401 1862.58 344.071 1863.12 343.534 1863.25L332.841 1865.83C332.304 1865.96 331.764 1865.63 331.634 1865.09C331.505 1864.55 331.835 1864.01 332.372 1863.88L343.065 1861.3C343.602 1861.17 344.142 1861.51 344.272 1862.04Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M264.982 1874.49C265.177 1875.29 264.681 1876.1 263.876 1876.3L254.155 1878.64C253.35 1878.83 252.539 1878.34 252.345 1877.53L251.172 1872.67C250.719 1870.79 251.875 1868.9 253.754 1868.45L259.587 1867.04C261.466 1866.59 263.357 1867.75 263.81 1869.62L264.982 1874.49Z'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <path
        d='M266.389 1880.32C266.583 1881.12 266.088 1881.93 265.282 1882.13L255.561 1884.47C254.756 1884.67 253.945 1884.17 253.751 1883.37L253.048 1880.45C252.853 1879.64 253.349 1878.83 254.154 1878.64L263.875 1876.29C264.681 1876.1 265.491 1876.6 265.685 1877.4L266.389 1880.32Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M266.506 1880.8C266.635 1881.34 266.305 1881.88 265.768 1882.01L255.075 1884.59C254.538 1884.72 253.998 1884.39 253.868 1883.85C253.739 1883.32 254.069 1882.78 254.606 1882.65L265.299 1880.07C265.836 1879.94 266.376 1880.27 266.506 1880.8Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M298.939 1814.86C298.745 1814.05 299.241 1813.24 300.046 1813.05L309.767 1810.7C310.572 1810.51 311.383 1811 311.577 1811.81L312.749 1816.67C313.203 1818.55 312.047 1820.44 310.168 1820.89L304.335 1822.3C302.456 1822.75 300.565 1821.6 300.112 1819.72L298.939 1814.86Z'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <path
        d='M297.533 1809.03C297.339 1808.22 297.834 1807.41 298.64 1807.22L308.361 1804.87C309.166 1804.68 309.976 1805.17 310.171 1805.98L310.874 1808.89C311.068 1809.7 310.573 1810.51 309.768 1810.7L300.047 1813.05C299.241 1813.24 298.431 1812.75 298.237 1811.94L297.533 1809.03Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M297.416 1808.54C297.286 1808 297.617 1807.46 298.154 1807.33L308.847 1804.75C309.384 1804.62 309.924 1804.95 310.053 1805.49C310.183 1806.03 309.853 1806.57 309.316 1806.7L298.623 1809.28C298.086 1809.41 297.545 1809.08 297.416 1808.54Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M221.174 1833.62C220.98 1832.81 221.475 1832 222.28 1831.81L232.001 1829.46C232.807 1829.27 233.617 1829.77 233.811 1830.57L234.984 1835.43C235.437 1837.31 234.281 1839.2 232.402 1839.66L226.57 1841.06C224.691 1841.52 222.8 1840.36 222.346 1838.48L221.174 1833.62Z'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <path
        d='M219.768 1827.79C219.573 1826.98 220.069 1826.17 220.874 1825.98L230.595 1823.63C231.4 1823.44 232.211 1823.93 232.405 1824.74L233.109 1827.66C233.303 1828.46 232.808 1829.27 232.002 1829.47L222.281 1831.81C221.476 1832 220.665 1831.51 220.471 1830.7L219.768 1827.79Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M219.65 1827.3C219.521 1826.76 219.851 1826.22 220.388 1826.1L231.081 1823.52C231.618 1823.39 232.158 1823.72 232.288 1824.25C232.417 1824.79 232.087 1825.33 231.55 1825.46L220.857 1828.04C220.32 1828.17 219.78 1827.84 219.65 1827.3Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M323.303 1860.42C323.497 1861.22 323.002 1862.03 322.196 1862.23L312.475 1864.57C311.67 1864.76 310.86 1864.27 310.665 1863.46L309.493 1858.6C309.039 1856.72 310.195 1854.83 312.074 1854.38L317.907 1852.97C319.786 1852.52 321.677 1853.68 322.13 1855.55L323.303 1860.42Z'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <path
        d='M324.709 1866.25C324.903 1867.05 324.408 1867.86 323.603 1868.06L313.881 1870.4C313.076 1870.6 312.266 1870.1 312.072 1869.3L311.368 1866.38C311.174 1865.57 311.669 1864.76 312.474 1864.57L322.195 1862.22C323.001 1862.03 323.811 1862.53 324.005 1863.33L324.709 1866.25Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M324.826 1866.73C324.956 1867.27 324.626 1867.81 324.089 1867.94L313.395 1870.52C312.859 1870.65 312.318 1870.32 312.189 1869.78C312.059 1869.25 312.389 1868.7 312.926 1868.58L323.62 1866C324.156 1865.87 324.697 1866.2 324.826 1866.73Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M245.537 1879.18C245.731 1879.98 245.236 1880.79 244.431 1880.99L234.71 1883.33C233.904 1883.53 233.094 1883.03 232.9 1882.23L231.727 1877.37C231.274 1875.49 232.43 1873.6 234.309 1873.14L240.141 1871.73C242.02 1871.28 243.911 1872.44 244.365 1874.32L245.537 1879.18Z'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <path
        d='M246.943 1885.01C247.138 1885.81 246.642 1886.62 245.837 1886.82L236.116 1889.16C235.311 1889.36 234.5 1888.86 234.306 1888.06L233.602 1885.14C233.408 1884.34 233.903 1883.53 234.709 1883.33L244.43 1880.99C245.235 1880.79 246.046 1881.29 246.24 1882.09L246.943 1885.01Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M247.061 1885.5C247.19 1886.03 246.86 1886.57 246.323 1886.7L235.63 1889.28C235.093 1889.41 234.553 1889.08 234.423 1888.54C234.294 1888.01 234.624 1887.47 235.161 1887.34L245.854 1884.76C246.391 1884.63 246.931 1884.96 247.061 1885.5Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M318.385 1810.17C318.19 1809.36 318.686 1808.55 319.491 1808.36L329.212 1806.01C330.018 1805.82 330.828 1806.31 331.022 1807.12L332.195 1811.98C332.648 1813.86 331.492 1815.75 329.613 1816.2L323.781 1817.61C321.901 1818.06 320.011 1816.91 319.557 1815.03L318.385 1810.17Z'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <path
        d='M316.979 1804.33C316.784 1803.53 317.28 1802.72 318.085 1802.52L327.806 1800.18C328.611 1799.99 329.422 1800.48 329.616 1801.29L330.32 1804.2C330.514 1805.01 330.018 1805.82 329.213 1806.01L319.492 1808.36C318.687 1808.55 317.876 1808.06 317.682 1807.25L316.979 1804.33Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M316.861 1803.85C316.732 1803.31 317.062 1802.77 317.599 1802.64L328.292 1800.06C328.829 1799.93 329.369 1800.26 329.499 1800.8C329.628 1801.34 329.298 1801.88 328.761 1802.01L318.068 1804.59C317.531 1804.72 316.991 1804.39 316.861 1803.85Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M240.611 1828.93C240.417 1828.12 240.912 1827.31 241.718 1827.12L251.439 1824.77C252.244 1824.58 253.055 1825.07 253.249 1825.88L254.421 1830.74C254.875 1832.62 253.719 1834.51 251.84 1834.96L246.007 1836.37C244.128 1836.82 242.237 1835.67 241.784 1833.79L240.611 1828.93Z'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <path
        d='M239.205 1823.1C239.011 1822.29 239.506 1821.48 240.311 1821.29L250.033 1818.94C250.838 1818.75 251.648 1819.24 251.843 1820.05L252.546 1822.96C252.74 1823.77 252.245 1824.58 251.44 1824.77L241.719 1827.12C240.913 1827.31 240.103 1826.82 239.909 1826.01L239.205 1823.1Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M239.088 1822.61C238.958 1822.07 239.289 1821.53 239.825 1821.4L250.519 1818.82C251.056 1818.69 251.596 1819.02 251.725 1819.56C251.855 1820.1 251.525 1820.64 250.988 1820.77L240.294 1823.35C239.758 1823.48 239.217 1823.15 239.088 1822.61Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M303.865 1865.11C304.06 1865.91 303.564 1866.72 302.759 1866.92L293.038 1869.26C292.232 1869.46 291.422 1868.96 291.228 1868.16L290.055 1863.29C289.602 1861.42 290.758 1859.52 292.637 1859.07L298.469 1857.66C300.349 1857.21 302.239 1858.37 302.693 1860.25L303.865 1865.11Z'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <path
        d='M305.271 1870.94C305.466 1871.74 304.97 1872.55 304.165 1872.75L294.444 1875.09C293.639 1875.29 292.828 1874.79 292.634 1873.99L291.93 1871.07C291.736 1870.27 292.232 1869.46 293.037 1869.26L302.758 1866.92C303.563 1866.72 304.374 1867.22 304.568 1868.02L305.271 1870.94Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M305.389 1871.42C305.518 1871.96 305.188 1872.5 304.651 1872.63L293.958 1875.21C293.421 1875.34 292.881 1875.01 292.751 1874.47C292.622 1873.94 292.952 1873.4 293.489 1873.27L304.182 1870.69C304.719 1870.56 305.259 1870.89 305.389 1871.42Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M226.092 1883.87C226.286 1884.67 225.791 1885.48 224.985 1885.68L215.264 1888.02C214.459 1888.22 213.649 1887.72 213.454 1886.92L212.282 1882.06C211.828 1880.18 212.984 1878.29 214.863 1877.83L220.696 1876.43C222.575 1875.97 224.466 1877.13 224.919 1879.01L226.092 1883.87Z'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <path
        d='M227.498 1889.7C227.692 1890.51 227.197 1891.32 226.392 1891.51L216.671 1893.86C215.865 1894.05 215.055 1893.55 214.861 1892.75L214.157 1889.83C213.963 1889.03 214.458 1888.22 215.263 1888.02L224.985 1885.68C225.79 1885.48 226.6 1885.98 226.795 1886.78L227.498 1889.7Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M227.615 1890.19C227.745 1890.72 227.415 1891.26 226.878 1891.39L216.184 1893.97C215.648 1894.1 215.107 1893.77 214.978 1893.24C214.848 1892.7 215.179 1892.16 215.715 1892.03L226.409 1889.45C226.946 1889.32 227.486 1889.65 227.615 1890.19Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M284.42 1869.79C284.614 1870.6 284.119 1871.41 283.314 1871.6L273.592 1873.95C272.787 1874.14 271.977 1873.65 271.782 1872.84L270.61 1867.98C270.157 1866.1 271.312 1864.21 273.191 1863.76L279.024 1862.35C280.903 1861.9 282.794 1863.05 283.247 1864.93L284.42 1869.79Z'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <path
        d='M285.826 1875.63C286.02 1876.43 285.525 1877.24 284.72 1877.44L274.999 1879.78C274.193 1879.98 273.383 1879.48 273.189 1878.67L272.485 1875.76C272.291 1874.95 272.786 1874.14 273.592 1873.95L283.313 1871.6C284.118 1871.41 284.928 1871.9 285.123 1872.71L285.826 1875.63Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M285.943 1876.11C286.073 1876.65 285.743 1877.19 285.206 1877.32L274.513 1879.9C273.976 1880.03 273.435 1879.7 273.306 1879.16C273.176 1878.62 273.507 1878.08 274.044 1877.95L284.737 1875.37C285.274 1875.25 285.814 1875.58 285.943 1876.11Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M206.654 1888.56C206.849 1889.36 206.353 1890.17 205.548 1890.37L195.827 1892.71C195.021 1892.91 194.211 1892.41 194.017 1891.6L192.844 1886.74C192.391 1884.86 193.547 1882.97 195.426 1882.52L201.259 1881.11C203.138 1880.66 205.028 1881.82 205.482 1883.7L206.654 1888.56Z'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <path
        d='M208.061 1894.39C208.255 1895.19 207.759 1896 206.954 1896.2L197.233 1898.54C196.428 1898.74 195.617 1898.24 195.423 1897.44L194.72 1894.52C194.525 1893.71 195.021 1892.9 195.826 1892.71L205.547 1890.37C206.352 1890.17 207.163 1890.67 207.357 1891.47L208.061 1894.39Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M208.178 1894.87C208.307 1895.41 207.977 1895.95 207.44 1896.08L196.747 1898.66C196.21 1898.79 195.67 1898.46 195.54 1897.92C195.411 1897.39 195.741 1896.85 196.278 1896.72L206.971 1894.14C207.508 1894.01 208.048 1894.34 208.178 1894.87Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M187.209 1893.25C187.403 1894.05 186.908 1894.86 186.103 1895.06L176.381 1897.4C175.576 1897.6 174.766 1897.1 174.572 1896.3L173.399 1891.44C172.946 1889.56 174.101 1887.67 175.981 1887.21L181.813 1885.81C183.692 1885.35 185.583 1886.51 186.036 1888.39L187.209 1893.25Z'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <path
        d='M188.615 1899.08C188.81 1899.88 188.314 1900.69 187.509 1900.89L177.788 1903.23C176.982 1903.43 176.172 1902.93 175.978 1902.13L175.274 1899.21C175.08 1898.41 175.575 1897.6 176.381 1897.4L186.102 1895.06C186.907 1894.86 187.717 1895.36 187.912 1896.16L188.615 1899.08Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M188.732 1899.57C188.862 1900.1 188.532 1900.64 187.995 1900.77L177.302 1903.35C176.765 1903.48 176.225 1903.15 176.095 1902.61C175.966 1902.08 176.296 1901.54 176.833 1901.41L187.526 1898.83C188.063 1898.7 188.603 1899.03 188.732 1899.57Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M163.596 1859.34L335.661 1817.83L341.993 1844.08L169.927 1885.58L163.596 1859.34Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M1191.58 589.229C1190.93 588.705 1190.84 587.76 1191.36 587.119L1197.69 579.373C1198.21 578.731 1199.16 578.636 1199.8 579.159L1203.67 582.322C1205.17 583.544 1205.39 585.749 1204.17 587.246L1200.37 591.894C1199.15 593.391 1196.95 593.614 1195.45 592.391L1191.58 589.229Z'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <path
        d='M1186.93 585.436C1186.29 584.912 1186.19 583.967 1186.71 583.326L1193.04 575.58C1193.56 574.938 1194.51 574.843 1195.15 575.367L1197.47 577.264C1198.12 577.788 1198.21 578.733 1197.69 579.374L1191.36 587.12C1190.84 587.762 1189.89 587.858 1189.25 587.334L1186.93 585.436Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M1186.54 585.12C1186.11 584.771 1186.05 584.141 1186.4 583.713L1193.36 575.192C1193.7 574.765 1194.33 574.701 1194.76 575.05C1195.19 575.4 1195.25 576.03 1194.9 576.457L1187.95 584.978C1187.6 585.406 1186.97 585.469 1186.54 585.12Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M1140.97 651.194C1140.33 650.67 1140.24 649.725 1140.76 649.083L1147.09 641.338C1147.61 640.696 1148.56 640.6 1149.2 641.124L1153.07 644.287C1154.57 645.509 1154.79 647.714 1153.57 649.211L1149.77 653.859C1148.55 655.356 1146.35 655.579 1144.85 654.356L1140.97 651.194Z'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <path
        d='M1136.33 647.401C1135.68 646.877 1135.59 645.932 1136.11 645.29L1142.44 637.545C1142.96 636.903 1143.91 636.807 1144.55 637.331L1146.87 639.229C1147.51 639.753 1147.61 640.698 1147.09 641.339L1140.76 649.085C1140.24 649.727 1139.29 649.822 1138.65 649.298L1136.33 647.401Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M1135.94 647.085C1135.51 646.736 1135.45 646.106 1135.8 645.678L1142.75 637.157C1143.1 636.729 1143.73 636.666 1144.16 637.015C1144.59 637.364 1144.65 637.994 1144.3 638.422L1137.35 646.943C1137 647.37 1136.37 647.434 1135.94 647.085Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M1128.33 666.686C1127.68 666.162 1127.59 665.217 1128.11 664.576L1134.44 656.83C1134.96 656.188 1135.91 656.093 1136.55 656.617L1140.42 659.779C1141.92 661.001 1142.14 663.206 1140.92 664.704L1137.12 669.351C1135.9 670.848 1133.7 671.071 1132.2 669.849L1128.33 666.686Z'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <path
        d='M1123.68 662.893C1123.04 662.369 1122.94 661.424 1123.46 660.783L1129.79 653.037C1130.31 652.395 1131.26 652.3 1131.9 652.824L1134.22 654.721C1134.87 655.245 1134.96 656.19 1134.44 656.832L1128.11 664.577C1127.59 665.219 1126.64 665.315 1126 664.791L1123.68 662.893Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M1123.29 662.577C1122.86 662.228 1122.8 661.598 1123.15 661.17L1130.11 652.649C1130.45 652.222 1131.08 652.158 1131.51 652.507C1131.94 652.857 1132 653.487 1131.65 653.914L1124.7 662.435C1124.35 662.863 1123.72 662.926 1123.29 662.577Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M1153.62 635.702C1152.98 635.178 1152.89 634.233 1153.41 633.591L1159.73 625.845C1160.26 625.204 1161.2 625.108 1161.85 625.632L1165.72 628.794C1167.22 630.017 1167.44 632.222 1166.22 633.719L1162.42 638.367C1161.2 639.864 1158.99 640.087 1157.5 638.864L1153.62 635.702Z'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <path
        d='M1148.97 631.909C1148.33 631.385 1148.24 630.44 1148.76 629.798L1155.09 622.052C1155.61 621.411 1156.56 621.315 1157.2 621.839L1159.52 623.737C1160.16 624.261 1160.26 625.205 1159.73 625.847L1153.41 633.593C1152.89 634.235 1151.94 634.33 1151.3 633.806L1148.97 631.909Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M1148.59 631.593C1148.16 631.243 1148.1 630.613 1148.45 630.186L1155.4 621.665C1155.75 621.237 1156.38 621.174 1156.81 621.523C1157.24 621.872 1157.3 622.502 1156.95 622.93L1149.99 631.451C1149.65 631.878 1149.02 631.942 1148.59 631.593Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M1225.88 626.279C1226.52 626.803 1226.61 627.748 1226.09 628.389L1219.77 636.135C1219.24 636.777 1218.3 636.872 1217.65 636.348L1213.78 633.186C1212.28 631.963 1212.06 629.759 1213.28 628.261L1217.08 623.614C1218.3 622.116 1220.51 621.894 1222 623.116L1225.88 626.279Z'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <path
        d='M1230.53 630.072C1231.17 630.596 1231.26 631.54 1230.74 632.182L1224.41 639.928C1223.89 640.57 1222.94 640.665 1222.3 640.141L1219.98 638.244C1219.34 637.72 1219.24 636.775 1219.77 636.133L1226.09 628.387C1226.61 627.746 1227.56 627.65 1228.2 628.174L1230.53 630.072Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M1230.91 630.388C1231.34 630.737 1231.4 631.367 1231.05 631.795L1224.1 640.315C1223.75 640.743 1223.12 640.807 1222.69 640.458C1222.26 640.108 1222.2 639.478 1222.55 639.05L1229.51 630.53C1229.85 630.102 1230.48 630.039 1230.91 630.388Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M1166.27 620.214C1165.63 619.69 1165.53 618.745 1166.06 618.103L1172.38 610.357C1172.91 609.715 1173.85 609.62 1174.49 610.144L1178.37 613.306C1179.86 614.529 1180.09 616.734 1178.86 618.231L1175.07 622.878C1173.85 624.376 1171.64 624.598 1170.14 623.376L1166.27 620.214Z'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <path
        d='M1161.62 616.421C1160.98 615.897 1160.89 614.952 1161.41 614.31L1167.73 606.564C1168.26 605.922 1169.2 605.827 1169.85 606.351L1172.17 608.248C1172.81 608.772 1172.91 609.717 1172.38 610.359L1166.06 618.105C1165.53 618.746 1164.59 618.842 1163.95 618.318L1161.62 616.421Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M1161.24 616.104C1160.81 615.755 1160.74 615.125 1161.09 614.697L1168.05 606.177C1168.4 605.749 1169.03 605.685 1169.46 606.035C1169.89 606.384 1169.95 607.014 1169.6 607.442L1162.64 615.962C1162.29 616.39 1161.66 616.454 1161.24 616.104Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M1213.23 641.767C1213.87 642.291 1213.97 643.236 1213.44 643.877L1207.12 651.623C1206.59 652.265 1205.65 652.361 1205.01 651.837L1201.13 648.674C1199.64 647.452 1199.41 645.247 1200.64 643.75L1204.43 639.102C1205.65 637.605 1207.86 637.382 1209.36 638.605L1213.23 641.767Z'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <path
        d='M1217.88 645.56C1218.52 646.084 1218.61 647.029 1218.09 647.67L1211.77 655.416C1211.24 656.058 1210.3 656.154 1209.65 655.63L1207.33 653.732C1206.69 653.208 1206.59 652.263 1207.12 651.622L1213.44 643.876C1213.97 643.234 1214.91 643.139 1215.55 643.662L1217.88 645.56Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M1218.26 645.876C1218.69 646.225 1218.76 646.855 1218.41 647.283L1211.45 655.804C1211.1 656.231 1210.47 656.295 1210.04 655.946C1209.61 655.596 1209.55 654.967 1209.9 654.539L1216.86 646.018C1217.21 645.59 1217.84 645.527 1218.26 645.876Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M1178.93 604.721C1178.29 604.197 1178.19 603.252 1178.71 602.611L1185.04 594.865C1185.56 594.223 1186.51 594.128 1187.15 594.652L1191.02 597.814C1192.52 599.037 1192.74 601.241 1191.52 602.739L1187.73 607.386C1186.5 608.884 1184.3 609.106 1182.8 607.884L1178.93 604.721Z'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <path
        d='M1174.28 600.928C1173.64 600.404 1173.54 599.46 1174.07 598.818L1180.39 591.072C1180.91 590.43 1181.86 590.335 1182.5 590.859L1184.83 592.756C1185.47 593.28 1185.56 594.225 1185.04 594.867L1178.71 602.613C1178.19 603.254 1177.24 603.35 1176.6 602.826L1174.28 600.928Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M1173.89 600.612C1173.46 600.263 1173.4 599.633 1173.75 599.205L1180.71 590.685C1181.06 590.257 1181.69 590.193 1182.11 590.542C1182.54 590.892 1182.61 591.522 1182.26 591.95L1175.3 600.47C1174.95 600.898 1174.32 600.961 1173.89 600.612Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M1200.58 657.259C1201.22 657.783 1201.32 658.728 1200.79 659.37L1194.47 667.116C1193.94 667.757 1193 667.853 1192.36 667.329L1188.48 664.166C1186.99 662.944 1186.76 660.739 1187.99 659.242L1191.78 654.594C1193 653.097 1195.21 652.874 1196.71 654.097L1200.58 657.259Z'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <path
        d='M1205.23 661.052C1205.87 661.576 1205.97 662.521 1205.44 663.163L1199.12 670.909C1198.59 671.55 1197.65 671.646 1197.01 671.122L1194.68 669.224C1194.04 668.7 1193.95 667.755 1194.47 667.114L1200.79 659.368C1201.32 658.726 1202.26 658.631 1202.9 659.155L1205.23 661.052Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M1205.62 661.368C1206.04 661.718 1206.11 662.348 1205.76 662.775L1198.8 671.296C1198.45 671.724 1197.82 671.787 1197.39 671.438C1196.97 671.089 1196.9 670.459 1197.25 670.031L1204.21 661.51C1204.56 661.083 1205.19 661.019 1205.62 661.368Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M1238.53 610.786C1239.17 611.31 1239.26 612.255 1238.74 612.897L1232.41 620.643C1231.89 621.285 1230.94 621.38 1230.3 620.856L1226.43 617.694C1224.93 616.471 1224.71 614.266 1225.93 612.769L1229.73 608.122C1230.95 606.624 1233.15 606.402 1234.65 607.624L1238.53 610.786Z'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <path
        d='M1243.17 614.579C1243.82 615.103 1243.91 616.048 1243.39 616.69L1237.06 624.436C1236.54 625.078 1235.59 625.173 1234.95 624.649L1232.63 622.752C1231.99 622.228 1231.89 621.283 1232.41 620.641L1238.74 612.895C1239.26 612.254 1240.21 612.158 1240.85 612.682L1243.17 614.579Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M1243.56 614.896C1243.99 615.245 1244.05 615.875 1243.7 616.303L1236.75 624.823C1236.4 625.251 1235.77 625.315 1235.34 624.965C1234.91 624.616 1234.85 623.986 1235.2 623.558L1242.15 615.038C1242.5 614.61 1243.13 614.546 1243.56 614.896Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M1187.92 672.751C1188.57 673.275 1188.66 674.22 1188.14 674.862L1181.81 682.608C1181.29 683.249 1180.34 683.345 1179.7 682.821L1175.83 679.659C1174.33 678.436 1174.11 676.231 1175.33 674.734L1179.13 670.086C1180.35 668.589 1182.55 668.366 1184.05 669.589L1187.92 672.751Z'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <path
        d='M1192.57 676.544C1193.21 677.068 1193.31 678.013 1192.79 678.655L1186.46 686.401C1185.94 687.042 1184.99 687.138 1184.35 686.614L1182.03 684.717C1181.38 684.193 1181.29 683.248 1181.81 682.606L1188.14 674.86C1188.66 674.218 1189.61 674.123 1190.25 674.647L1192.57 676.544Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M1192.96 676.86C1193.39 677.21 1193.45 677.84 1193.1 678.268L1186.14 686.788C1185.8 687.216 1185.17 687.279 1184.74 686.93C1184.31 686.581 1184.25 685.951 1184.6 685.523L1191.55 677.003C1191.9 676.575 1192.53 676.511 1192.96 676.86Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M1175.28 688.243C1175.92 688.767 1176.01 689.712 1175.49 690.354L1169.16 698.1C1168.64 698.742 1167.69 698.837 1167.05 698.313L1163.18 695.151C1161.68 693.928 1161.46 691.723 1162.68 690.226L1166.48 685.579C1167.7 684.081 1169.9 683.859 1171.4 685.081L1175.28 688.243Z'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <path
        d='M1179.92 692.036C1180.57 692.56 1180.66 693.505 1180.14 694.147L1173.81 701.893C1173.29 702.535 1172.34 702.63 1171.7 702.106L1169.38 700.209C1168.74 699.685 1168.64 698.74 1169.16 698.098L1175.49 690.352C1176.01 689.711 1176.96 689.615 1177.6 690.139L1179.92 692.036Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M1180.31 692.353C1180.74 692.702 1180.8 693.332 1180.45 693.76L1173.5 702.28C1173.15 702.708 1172.52 702.772 1172.09 702.422C1171.66 702.073 1171.6 701.443 1171.95 701.015L1178.9 692.495C1179.25 692.067 1179.88 692.003 1180.31 692.353Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M1135.97 675.511L1209.75 585.146L1230.67 602.223L1156.88 692.587L1135.97 675.511Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M1305.58 695.229C1304.93 694.705 1304.84 693.76 1305.36 693.119L1311.69 685.373C1312.21 684.731 1313.16 684.636 1313.8 685.159L1317.67 688.322C1319.17 689.544 1319.39 691.749 1318.17 693.246L1314.37 697.894C1313.15 699.391 1310.95 699.614 1309.45 698.391L1305.58 695.229Z'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <path
        d='M1300.93 691.436C1300.29 690.912 1300.19 689.967 1300.71 689.326L1307.04 681.58C1307.56 680.938 1308.51 680.843 1309.15 681.367L1311.47 683.264C1312.12 683.788 1312.21 684.733 1311.69 685.374L1305.36 693.12C1304.84 693.762 1303.89 693.858 1303.25 693.334L1300.93 691.436Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M1300.54 691.12C1300.11 690.771 1300.05 690.141 1300.4 689.713L1307.36 681.192C1307.7 680.765 1308.33 680.701 1308.76 681.05C1309.19 681.4 1309.25 682.03 1308.9 682.457L1301.95 690.978C1301.6 691.406 1300.97 691.469 1300.54 691.12Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M1254.97 757.194C1254.33 756.67 1254.24 755.725 1254.76 755.083L1261.09 747.338C1261.61 746.696 1262.56 746.6 1263.2 747.124L1267.07 750.287C1268.57 751.509 1268.79 753.714 1267.57 755.211L1263.77 759.859C1262.55 761.356 1260.35 761.579 1258.85 760.356L1254.97 757.194Z'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <path
        d='M1250.33 753.401C1249.68 752.877 1249.59 751.932 1250.11 751.29L1256.44 743.545C1256.96 742.903 1257.91 742.807 1258.55 743.331L1260.87 745.229C1261.51 745.753 1261.61 746.698 1261.09 747.339L1254.76 755.085C1254.24 755.727 1253.29 755.822 1252.65 755.298L1250.33 753.401Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M1249.94 753.085C1249.51 752.736 1249.45 752.106 1249.8 751.678L1256.75 743.157C1257.1 742.729 1257.73 742.666 1258.16 743.015C1258.59 743.364 1258.65 743.994 1258.3 744.422L1251.35 752.943C1251 753.37 1250.37 753.434 1249.94 753.085Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M1242.33 772.686C1241.68 772.162 1241.59 771.217 1242.11 770.576L1248.44 762.83C1248.96 762.188 1249.91 762.093 1250.55 762.617L1254.42 765.779C1255.92 767.001 1256.14 769.206 1254.92 770.704L1251.12 775.351C1249.9 776.848 1247.7 777.071 1246.2 775.849L1242.33 772.686Z'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <path
        d='M1237.68 768.893C1237.04 768.369 1236.94 767.424 1237.46 766.783L1243.79 759.037C1244.31 758.395 1245.26 758.3 1245.9 758.824L1248.22 760.721C1248.87 761.245 1248.96 762.19 1248.44 762.832L1242.11 770.577C1241.59 771.219 1240.64 771.315 1240 770.791L1237.68 768.893Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M1237.29 768.577C1236.86 768.228 1236.8 767.598 1237.15 767.17L1244.11 758.649C1244.45 758.222 1245.08 758.158 1245.51 758.507C1245.94 758.857 1246 759.487 1245.65 759.914L1238.7 768.435C1238.35 768.863 1237.72 768.926 1237.29 768.577Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M1267.62 741.702C1266.98 741.178 1266.89 740.233 1267.41 739.591L1273.73 731.845C1274.26 731.204 1275.2 731.108 1275.85 731.632L1279.72 734.794C1281.22 736.017 1281.44 738.222 1280.22 739.719L1276.42 744.367C1275.2 745.864 1272.99 746.087 1271.5 744.864L1267.62 741.702Z'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <path
        d='M1262.97 737.909C1262.33 737.385 1262.24 736.44 1262.76 735.798L1269.09 728.052C1269.61 727.411 1270.56 727.315 1271.2 727.839L1273.52 729.737C1274.16 730.261 1274.26 731.205 1273.73 731.847L1267.41 739.593C1266.89 740.235 1265.94 740.33 1265.3 739.806L1262.97 737.909Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M1262.59 737.593C1262.16 737.243 1262.1 736.613 1262.45 736.186L1269.4 727.665C1269.75 727.237 1270.38 727.174 1270.81 727.523C1271.24 727.872 1271.3 728.502 1270.95 728.93L1263.99 737.451C1263.65 737.878 1263.02 737.942 1262.59 737.593Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M1339.88 732.279C1340.52 732.803 1340.61 733.748 1340.09 734.389L1333.77 742.135C1333.24 742.777 1332.3 742.872 1331.65 742.348L1327.78 739.186C1326.28 737.963 1326.06 735.759 1327.28 734.261L1331.08 729.614C1332.3 728.116 1334.51 727.894 1336 729.116L1339.88 732.279Z'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <path
        d='M1344.53 736.072C1345.17 736.596 1345.26 737.54 1344.74 738.182L1338.41 745.928C1337.89 746.57 1336.94 746.665 1336.3 746.141L1333.98 744.244C1333.34 743.72 1333.24 742.775 1333.77 742.133L1340.09 734.387C1340.61 733.746 1341.56 733.65 1342.2 734.174L1344.53 736.072Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M1344.91 736.388C1345.34 736.737 1345.4 737.367 1345.05 737.795L1338.1 746.315C1337.75 746.743 1337.12 746.807 1336.69 746.458C1336.26 746.108 1336.2 745.478 1336.55 745.05L1343.51 736.53C1343.85 736.102 1344.48 736.039 1344.91 736.388Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M1280.27 726.214C1279.63 725.69 1279.53 724.745 1280.06 724.103L1286.38 716.357C1286.91 715.715 1287.85 715.62 1288.49 716.144L1292.37 719.306C1293.86 720.529 1294.09 722.734 1292.86 724.231L1289.07 728.878C1287.85 730.376 1285.64 730.598 1284.14 729.376L1280.27 726.214Z'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <path
        d='M1275.62 722.421C1274.98 721.897 1274.89 720.952 1275.41 720.31L1281.73 712.564C1282.26 711.922 1283.2 711.827 1283.85 712.351L1286.17 714.248C1286.81 714.772 1286.91 715.717 1286.38 716.359L1280.06 724.105C1279.53 724.746 1278.59 724.842 1277.95 724.318L1275.62 722.421Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M1275.24 722.104C1274.81 721.755 1274.74 721.125 1275.09 720.697L1282.05 712.177C1282.4 711.749 1283.03 711.685 1283.46 712.035C1283.89 712.384 1283.95 713.014 1283.6 713.442L1276.64 721.962C1276.29 722.39 1275.66 722.454 1275.24 722.104Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M1327.23 747.767C1327.87 748.291 1327.97 749.236 1327.44 749.877L1321.12 757.623C1320.59 758.265 1319.65 758.361 1319.01 757.837L1315.13 754.674C1313.64 753.452 1313.41 751.247 1314.64 749.75L1318.43 745.102C1319.65 743.605 1321.86 743.382 1323.36 744.605L1327.23 747.767Z'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <path
        d='M1331.88 751.56C1332.52 752.084 1332.61 753.029 1332.09 753.67L1325.77 761.416C1325.24 762.058 1324.3 762.154 1323.65 761.63L1321.33 759.732C1320.69 759.208 1320.59 758.263 1321.12 757.622L1327.44 749.876C1327.97 749.234 1328.91 749.139 1329.55 749.662L1331.88 751.56Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M1332.26 751.876C1332.69 752.225 1332.76 752.855 1332.41 753.283L1325.45 761.804C1325.1 762.231 1324.47 762.295 1324.04 761.946C1323.61 761.596 1323.55 760.967 1323.9 760.539L1330.86 752.018C1331.21 751.59 1331.84 751.527 1332.26 751.876Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M1292.93 710.721C1292.29 710.197 1292.19 709.252 1292.71 708.611L1299.04 700.865C1299.56 700.223 1300.51 700.128 1301.15 700.652L1305.02 703.814C1306.52 705.037 1306.74 707.241 1305.52 708.739L1301.73 713.386C1300.5 714.884 1298.3 715.106 1296.8 713.884L1292.93 710.721Z'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <path
        d='M1288.28 706.928C1287.64 706.404 1287.54 705.46 1288.07 704.818L1294.39 697.072C1294.91 696.43 1295.86 696.335 1296.5 696.859L1298.83 698.756C1299.47 699.28 1299.56 700.225 1299.04 700.867L1292.71 708.613C1292.19 709.254 1291.24 709.35 1290.6 708.826L1288.28 706.928Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M1287.89 706.612C1287.46 706.263 1287.4 705.633 1287.75 705.205L1294.71 696.685C1295.06 696.257 1295.69 696.193 1296.11 696.542C1296.54 696.892 1296.61 697.522 1296.26 697.95L1289.3 706.47C1288.95 706.898 1288.32 706.961 1287.89 706.612Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M1314.58 763.259C1315.22 763.783 1315.32 764.728 1314.79 765.37L1308.47 773.116C1307.94 773.757 1307 773.853 1306.36 773.329L1302.48 770.166C1300.99 768.944 1300.76 766.739 1301.99 765.242L1305.78 760.594C1307 759.097 1309.21 758.874 1310.71 760.097L1314.58 763.259Z'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <path
        d='M1319.23 767.052C1319.87 767.576 1319.97 768.521 1319.44 769.163L1313.12 776.909C1312.59 777.55 1311.65 777.646 1311.01 777.122L1308.68 775.224C1308.04 774.7 1307.95 773.755 1308.47 773.114L1314.79 765.368C1315.32 764.726 1316.26 764.631 1316.9 765.155L1319.23 767.052Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M1319.62 767.368C1320.04 767.718 1320.11 768.348 1319.76 768.775L1312.8 777.296C1312.45 777.724 1311.82 777.787 1311.39 777.438C1310.97 777.089 1310.9 776.459 1311.25 776.031L1318.21 767.51C1318.56 767.083 1319.19 767.019 1319.62 767.368Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M1352.53 716.786C1353.17 717.31 1353.26 718.255 1352.74 718.897L1346.41 726.643C1345.89 727.285 1344.94 727.38 1344.3 726.856L1340.43 723.694C1338.93 722.471 1338.71 720.266 1339.93 718.769L1343.73 714.122C1344.95 712.624 1347.15 712.402 1348.65 713.624L1352.53 716.786Z'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <path
        d='M1357.17 720.579C1357.82 721.103 1357.91 722.048 1357.39 722.69L1351.06 730.436C1350.54 731.078 1349.59 731.173 1348.95 730.649L1346.63 728.752C1345.99 728.228 1345.89 727.283 1346.41 726.641L1352.74 718.895C1353.26 718.254 1354.21 718.158 1354.85 718.682L1357.17 720.579Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M1357.56 720.896C1357.99 721.245 1358.05 721.875 1357.7 722.303L1350.75 730.823C1350.4 731.251 1349.77 731.315 1349.34 730.965C1348.91 730.616 1348.85 729.986 1349.2 729.558L1356.15 721.038C1356.5 720.61 1357.13 720.546 1357.56 720.896Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M1301.92 778.751C1302.57 779.275 1302.66 780.22 1302.14 780.862L1295.81 788.608C1295.29 789.249 1294.34 789.345 1293.7 788.821L1289.83 785.659C1288.33 784.436 1288.11 782.231 1289.33 780.734L1293.13 776.086C1294.35 774.589 1296.55 774.366 1298.05 775.589L1301.92 778.751Z'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <path
        d='M1306.57 782.544C1307.21 783.068 1307.31 784.013 1306.79 784.655L1300.46 792.401C1299.94 793.042 1298.99 793.138 1298.35 792.614L1296.03 790.717C1295.38 790.193 1295.29 789.248 1295.81 788.606L1302.14 780.86C1302.66 780.218 1303.61 780.123 1304.25 780.647L1306.57 782.544Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M1306.96 782.86C1307.39 783.21 1307.45 783.84 1307.1 784.268L1300.14 792.788C1299.8 793.216 1299.17 793.279 1298.74 792.93C1298.31 792.581 1298.25 791.951 1298.6 791.523L1305.55 783.003C1305.9 782.575 1306.53 782.511 1306.96 782.86Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M1289.28 794.243C1289.92 794.767 1290.01 795.712 1289.49 796.354L1283.16 804.1C1282.64 804.742 1281.69 804.837 1281.05 804.313L1277.18 801.151C1275.68 799.928 1275.46 797.723 1276.68 796.226L1280.48 791.579C1281.7 790.081 1283.9 789.859 1285.4 791.081L1289.28 794.243Z'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <path
        d='M1293.92 798.036C1294.57 798.56 1294.66 799.505 1294.14 800.147L1287.81 807.893C1287.29 808.535 1286.34 808.63 1285.7 808.106L1283.38 806.209C1282.74 805.685 1282.64 804.74 1283.16 804.098L1289.49 796.352C1290.01 795.711 1290.96 795.615 1291.6 796.139L1293.92 798.036Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M1294.31 798.353C1294.74 798.702 1294.8 799.332 1294.45 799.76L1287.5 808.28C1287.15 808.708 1286.52 808.772 1286.09 808.422C1285.66 808.073 1285.6 807.443 1285.95 807.015L1292.9 798.495C1293.25 798.067 1293.88 798.003 1294.31 798.353Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M1249.97 781.511L1323.75 691.146L1344.67 708.223L1270.88 798.587L1249.97 781.511Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <rect
        x='530.838'
        y='2009.23'
        width='29'
        height='9'
        rx='1.5'
        transform='rotate(73.1623 530.838 2009.23)'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <rect
        x='565.346'
        y='2019.68'
        width='9'
        height='29'
        rx='1.5'
        transform='rotate(73.1623 565.346 2019.68)'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <rect
        x='559.549'
        y='2000.54'
        width='19'
        height='29'
        rx='1.5'
        transform='rotate(73.1623 559.549 2000.54)'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <rect
        x='594.057'
        y='2010.99'
        width='9'
        height='29'
        rx='1.5'
        transform='rotate(73.1623 594.057 2010.99)'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <rect
        x='613.198'
        y='2005.2'
        width='9'
        height='19'
        rx='1.5'
        transform='rotate(73.1623 613.198 2005.2)'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <rect
        x='588.268'
        y='1991.85'
        width='19'
        height='29'
        rx='1.5'
        transform='rotate(73.1623 588.268 1991.85)'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <rect
        x='626.549'
        y='1980.26'
        width='19'
        height='39'
        rx='1.5'
        transform='rotate(73.1623 626.549 1980.26)'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <rect
        x='607.159'
        y='1910.45'
        width='29'
        height='9'
        rx='1.5'
        transform='rotate(-105.699 607.159 1910.45)'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <rect
        x='572.862'
        y='1899.32'
        width='9'
        height='29'
        rx='1.5'
        transform='rotate(-105.699 572.862 1899.32)'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <rect
        x='578.276'
        y='1918.57'
        width='19'
        height='29'
        rx='1.5'
        transform='rotate(-105.699 578.276 1918.57)'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <rect
        x='543.979'
        y='1907.43'
        width='9'
        height='29'
        rx='1.5'
        transform='rotate(-105.699 543.979 1907.43)'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <rect
        x='524.729'
        y='1912.85'
        width='9'
        height='19'
        rx='1.5'
        transform='rotate(-105.699 524.729 1912.85)'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <rect
        x='549.393'
        y='1926.69'
        width='19'
        height='29'
        rx='1.5'
        transform='rotate(-105.699 549.393 1926.69)'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <rect
        x='510.885'
        y='1937.51'
        width='19'
        height='39'
        rx='1.5'
        transform='rotate(-105.699 510.885 1937.51)'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <rect
        x='531.707'
        y='1618.41'
        width='29'
        height='9'
        rx='1.5'
        transform='rotate(-98.0057 531.707 1618.41)'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <rect
        x='499.215'
        y='1602.78'
        width='9'
        height='29'
        rx='1.5'
        transform='rotate(-98.0057 499.215 1602.78)'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <rect
        x='502.004'
        y='1622.59'
        width='19'
        height='29'
        rx='1.5'
        transform='rotate(-98.0057 502.004 1622.59)'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <rect
        x='469.511'
        y='1606.96'
        width='9'
        height='29'
        rx='1.5'
        transform='rotate(-98.0057 469.511 1606.96)'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <rect
        x='449.699'
        y='1609.75'
        width='9'
        height='19'
        rx='1.5'
        transform='rotate(-98.0057 449.699 1609.75)'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <rect
        x='472.293'
        y='1626.77'
        width='19'
        height='29'
        rx='1.5'
        transform='rotate(-98.0057 472.293 1626.77)'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <rect
        x='432.683'
        y='1632.34'
        width='19'
        height='39'
        rx='1.5'
        transform='rotate(-98.0057 432.683 1632.34)'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <rect
        x='495.143'
        y='423.556'
        width='29'
        height='9'
        rx='1.5'
        transform='rotate(105.392 495.143 423.556)'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <rect
        x='518.76'
        y='450.802'
        width='9'
        height='29'
        rx='1.5'
        transform='rotate(105.392 518.76 450.802)'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <rect
        x='524.065'
        y='431.521'
        width='19'
        height='29'
        rx='1.5'
        transform='rotate(105.392 524.065 431.521)'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <rect
        x='547.682'
        y='458.763'
        width='9'
        height='29'
        rx='1.5'
        transform='rotate(105.392 547.682 458.763)'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <rect
        x='566.963'
        y='464.072'
        width='9'
        height='19'
        rx='1.5'
        transform='rotate(105.392 566.963 464.072)'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <rect
        x='552.995'
        y='439.482'
        width='19'
        height='29'
        rx='1.5'
        transform='rotate(105.392 552.995 439.482)'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <rect
        x='591.557'
        y='450.099'
        width='19'
        height='39'
        rx='1.5'
        transform='rotate(105.392 591.557 450.099)'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <rect
        x='1779.74'
        y='926.969'
        width='29'
        height='9'
        rx='1.5'
        transform='rotate(-105.699 1779.74 926.969)'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <rect
        x='1745.44'
        y='915.833'
        width='9'
        height='29'
        rx='1.5'
        transform='rotate(-105.699 1745.44 915.833)'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <rect
        x='1750.85'
        y='935.086'
        width='19'
        height='29'
        rx='1.5'
        transform='rotate(-105.699 1750.85 935.086)'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <rect
        x='1716.56'
        y='923.95'
        width='9'
        height='29'
        rx='1.5'
        transform='rotate(-105.699 1716.56 923.95)'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <rect
        x='1697.31'
        y='929.364'
        width='9'
        height='19'
        rx='1.5'
        transform='rotate(-105.699 1697.31 929.364)'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <rect
        x='1721.97'
        y='943.204'
        width='19'
        height='29'
        rx='1.5'
        transform='rotate(-105.699 1721.97 943.204)'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <rect
        x='1683.46'
        y='954.028'
        width='19'
        height='39'
        rx='1.5'
        transform='rotate(-105.699 1683.46 954.028)'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <rect
        x='1744.76'
        y='1042.69'
        width='29'
        height='9'
        rx='1.5'
        transform='rotate(51.6916 1744.76 1042.69)'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <rect
        x='1780.7'
        y='1039.79'
        width='9'
        height='29'
        rx='1.5'
        transform='rotate(51.6916 1780.7 1039.79)'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <rect
        x='1768.3'
        y='1024.09'
        width='19'
        height='29'
        rx='1.5'
        transform='rotate(51.6916 1768.3 1024.09)'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <rect
        x='1804.24'
        y='1021.19'
        width='9'
        height='29'
        rx='1.5'
        transform='rotate(51.6916 1804.24 1021.19)'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <rect
        x='1819.93'
        y='1008.79'
        width='9'
        height='19'
        rx='1.5'
        transform='rotate(51.6916 1819.93 1008.79)'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <rect
        x='1791.85'
        y='1005.5'
        width='19'
        height='29'
        rx='1.5'
        transform='rotate(51.6916 1791.85 1005.5)'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <rect
        x='1823.23'
        y='980.702'
        width='19'
        height='39'
        rx='1.5'
        transform='rotate(51.6916 1823.23 980.702)'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <rect
        x='1960'
        y='886.648'
        width='29'
        height='9'
        rx='1.5'
        transform='rotate(-46 1960 886.648)'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <rect
        x='1952.31'
        y='851.421'
        width='9'
        height='29'
        rx='1.5'
        transform='rotate(-46 1952.31 851.421)'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <rect
        x='1938.41'
        y='865.808'
        width='19'
        height='29'
        rx='1.5'
        transform='rotate(-46 1938.41 865.808)'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <rect
        x='1930.72'
        y='830.581'
        width='9'
        height='29'
        rx='1.5'
        transform='rotate(-46 1930.72 830.581)'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <rect
        x='1916.34'
        y='816.691'
        width='9'
        height='19'
        rx='1.5'
        transform='rotate(-46 1916.34 816.691)'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <rect
        x='1916.83'
        y='844.968'
        width='19'
        height='29'
        rx='1.5'
        transform='rotate(-46 1916.83 844.968)'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <rect
        x='1888.06'
        y='817.183'
        width='19'
        height='39'
        rx='1.5'
        transform='rotate(-46 1888.06 817.183)'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <path
        d='M1401.92 1386.37L1330.56 1301.35L1342.05 1291.71L1413.41 1376.73L1401.92 1386.37Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <g clip-path='url(#clip24_6651_98482)'>
        <path
          d='M1320.58 1322.12C1317.32 1318.23 1317.7 1312.49 1321.38 1309.07L1322.67 1310.61C1319.84 1313.32 1319.56 1317.79 1322.12 1320.84C1324.67 1323.88 1329.13 1324.39 1332.29 1322.07L1333.58 1323.61C1329.57 1326.63 1323.85 1326.01 1320.58 1322.12Z'
          fill='#CFD1D2'
          stroke='#9FA4AA'
        />
        <circle
          cx='1327.85'
          cy='1316.02'
          r='7.5'
          transform='rotate(139.992 1327.85 1316.02)'
          fill='#F3F3F4'
          stroke='#9FA4AA'
        />
      </g>
      <g clip-path='url(#clip25_6651_98482)'>
        <path
          d='M1335.36 1339.74C1332.1 1335.85 1332.49 1330.11 1336.16 1326.69L1337.45 1328.22C1334.62 1330.93 1334.34 1335.41 1336.9 1338.46C1339.45 1341.5 1343.91 1342 1347.07 1339.69L1348.36 1341.22C1344.35 1344.25 1338.63 1343.63 1335.36 1339.74Z'
          fill='#CFD1D2'
          stroke='#9FA4AA'
        />
        <circle
          cx='1342.64'
          cy='1333.63'
          r='7.5'
          transform='rotate(139.992 1342.64 1333.63)'
          fill='#F3F3F4'
          stroke='#9FA4AA'
        />
      </g>
      <g clip-path='url(#clip26_6651_98482)'>
        <path
          d='M1350.15 1357.36C1346.89 1353.47 1347.27 1347.73 1350.95 1344.31L1352.24 1345.84C1349.41 1348.55 1349.13 1353.03 1351.69 1356.07C1354.24 1359.12 1358.7 1359.62 1361.86 1357.31L1363.15 1358.84C1359.14 1361.86 1353.42 1361.25 1350.15 1357.36Z'
          fill='#CFD1D2'
          stroke='#9FA4AA'
        />
        <circle
          cx='1357.42'
          cy='1351.25'
          r='7.5'
          transform='rotate(139.992 1357.42 1351.25)'
          fill='#F3F3F4'
          stroke='#9FA4AA'
        />
      </g>
      <g clip-path='url(#clip27_6651_98482)'>
        <path
          d='M1364.94 1374.98C1361.68 1371.09 1362.06 1365.35 1365.74 1361.92L1367.03 1363.46C1364.2 1366.17 1363.92 1370.65 1366.47 1373.69C1369.03 1376.73 1373.49 1377.24 1376.65 1374.92L1377.94 1376.46C1373.93 1379.48 1368.21 1378.87 1364.94 1374.98Z'
          fill='#CFD1D2'
          stroke='#9FA4AA'
        />
        <circle
          cx='1372.21'
          cy='1368.87'
          r='7.5'
          transform='rotate(139.992 1372.21 1368.87)'
          fill='#F3F3F4'
          stroke='#9FA4AA'
        />
      </g>
      <g clip-path='url(#clip28_6651_98482)'>
        <path
          d='M1379.72 1392.59C1376.46 1388.7 1376.85 1382.96 1380.52 1379.54L1381.81 1381.08C1378.98 1383.79 1378.7 1388.26 1381.26 1391.31C1383.81 1394.35 1388.27 1394.86 1391.43 1392.54L1392.72 1394.07C1388.71 1397.1 1382.99 1396.48 1379.72 1392.59Z'
          fill='#CFD1D2'
          stroke='#9FA4AA'
        />
        <circle
          cx='1386.99'
          cy='1386.49'
          r='7.5'
          transform='rotate(139.992 1386.99 1386.49)'
          fill='#F3F3F4'
          stroke='#9FA4AA'
        />
      </g>
      <path
        d='M1123.69 571.699L1102.92 636.946L1088.63 632.396L1109.4 567.15L1123.69 571.699Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <g clip-path='url(#clip29_6651_98482)'>
        <path
          d='M1125.82 634.265C1124.28 639.105 1119.22 641.848 1114.36 640.575L1114.97 638.666C1118.77 639.607 1122.7 637.445 1123.91 633.658C1125.12 629.871 1123.16 625.835 1119.51 624.404L1120.12 622.495C1124.82 624.264 1127.36 629.425 1125.82 634.265Z'
          fill='#CFD1D2'
          stroke='#9FA4AA'
        />
        <circle
          cx='1116.76'
          cy='631.383'
          r='7.5'
          transform='rotate(17.657 1116.76 631.383)'
          fill='#F3F3F4'
          stroke='#9FA4AA'
        />
      </g>
      <g clip-path='url(#clip30_6651_98482)'>
        <path
          d='M1132.79 612.351C1131.25 617.191 1126.2 619.934 1121.34 618.661L1121.95 616.752C1125.75 617.693 1129.68 615.531 1130.89 611.744C1132.09 607.957 1130.13 603.921 1126.49 602.49L1127.09 600.581C1131.79 602.35 1134.33 607.511 1132.79 612.351Z'
          fill='#CFD1D2'
          stroke='#9FA4AA'
        />
        <circle
          cx='1123.74'
          cy='609.469'
          r='7.5'
          transform='rotate(17.657 1123.74 609.469)'
          fill='#F3F3F4'
          stroke='#9FA4AA'
        />
      </g>
      <g clip-path='url(#clip31_6651_98482)'>
        <path
          d='M1139.77 590.433C1138.23 595.273 1133.17 598.016 1128.32 596.743L1128.92 594.834C1132.73 595.775 1136.66 593.613 1137.86 589.826C1139.07 586.039 1137.11 582.003 1133.46 580.572L1134.07 578.663C1138.77 580.432 1141.31 585.593 1139.77 590.433Z'
          fill='#CFD1D2'
          stroke='#9FA4AA'
        />
        <circle
          cx='1130.71'
          cy='587.551'
          r='7.5'
          transform='rotate(17.657 1130.71 587.551)'
          fill='#F3F3F4'
          stroke='#9FA4AA'
        />
      </g>
      <rect
        x='470.25'
        y='1767.59'
        width='29'
        height='106.303'
        transform='rotate(-90 470.25 1767.59)'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <rect
        x='551.958'
        y='1780.76'
        width='13'
        height='11'
        rx='3.5'
        transform='rotate(154.49 551.958 1780.76)'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <path
        d='M553.898 1784.82C554.255 1785.57 553.938 1786.47 553.191 1786.82L544.165 1791.13C543.418 1791.49 542.522 1791.17 542.166 1790.42L541.735 1789.52C540.903 1787.78 541.642 1785.69 543.386 1784.85L548.802 1782.27C550.546 1781.44 552.635 1782.18 553.468 1783.92L553.898 1784.82Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M553.898 1784.82C554.255 1785.57 553.938 1786.47 553.191 1786.82L544.165 1791.13C543.418 1791.49 542.522 1791.17 542.166 1790.42C541.809 1789.68 542.126 1788.78 542.873 1788.42L551.899 1784.12C552.646 1783.76 553.542 1784.08 553.898 1784.82Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <rect
        x='553.763'
        y='1779.9'
        width='3'
        height='9'
        rx='1.5'
        transform='rotate(154.49 553.763 1779.9)'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <rect
        x='541.13'
        y='1785.93'
        width='3'
        height='9'
        rx='1.5'
        transform='rotate(154.49 541.13 1785.93)'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <rect
        x='511.055'
        y='1785.73'
        width='13'
        height='11'
        rx='3.5'
        transform='rotate(-151.694 511.055 1785.73)'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <path
        d='M508.922 1789.69C508.529 1790.42 507.62 1790.69 506.89 1790.3L498.086 1785.56C497.357 1785.16 497.084 1784.25 497.477 1783.53L497.951 1782.64C498.867 1780.94 500.99 1780.31 502.692 1781.22L507.975 1784.07C509.676 1784.98 510.313 1787.11 509.396 1788.81L508.922 1789.69Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M508.922 1789.69C508.529 1790.42 507.62 1790.69 506.89 1790.3L498.086 1785.56C497.357 1785.16 497.084 1784.25 497.477 1783.53C497.869 1782.8 498.779 1782.52 499.509 1782.92L508.313 1787.66C509.042 1788.05 509.315 1788.96 508.922 1789.69Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <rect
        x='512.812'
        y='1786.68'
        width='3'
        height='9'
        rx='1.5'
        transform='rotate(-151.694 512.812 1786.68)'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <rect
        x='500.484'
        y='1780.04'
        width='3'
        height='9'
        rx='1.5'
        transform='rotate(-151.694 500.484 1780.04)'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <g clip-path='url(#clip32_6651_98482)'>
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M560.481 1601.77C560.576 1600.14 561.144 1598.88 562.195 1598.32C564.626 1597.01 568.72 1599.89 571.34 1604.75C573.959 1609.61 574.112 1614.61 571.681 1615.92C570.63 1616.49 569.269 1616.27 567.851 1615.45C567.755 1618.38 566.148 1621.18 563.386 1622.66C559.253 1624.89 554.098 1623.35 551.871 1619.21L548.551 1613.05C546.324 1608.92 547.87 1603.76 552.002 1601.54C554.765 1600.05 557.985 1600.24 560.481 1601.77Z'
          fill='#D9D9D9'
        />
        <path
          d='M560.481 1601.77L560.22 1602.2L560.932 1602.64L560.981 1601.8L560.481 1601.77ZM567.851 1615.45L568.101 1615.02L567.379 1614.6L567.351 1615.44L567.851 1615.45ZM551.871 1619.21L551.431 1619.45L551.871 1619.21ZM548.551 1613.05L548.991 1612.81L548.551 1613.05ZM560.981 1601.8C561.071 1600.24 561.604 1599.2 562.432 1598.76L561.958 1597.88C560.685 1598.56 560.082 1600.03 559.982 1601.74L560.981 1601.8ZM562.432 1598.76C563.391 1598.24 564.803 1598.5 566.409 1599.63C567.988 1600.74 569.619 1602.61 570.9 1604.99L571.78 1604.51C570.441 1602.03 568.715 1600.03 566.984 1598.81C565.281 1597.61 563.43 1597.08 561.958 1597.88L562.432 1598.76ZM570.9 1604.99C572.18 1607.36 572.846 1609.75 572.904 1611.68C572.964 1613.65 572.403 1614.97 571.444 1615.48L571.918 1616.36C573.39 1615.57 573.968 1613.73 573.904 1611.65C573.839 1609.54 573.118 1607 571.78 1604.51L570.9 1604.99ZM571.444 1615.48C570.616 1615.93 569.454 1615.8 568.101 1615.02L567.601 1615.89C569.084 1616.74 570.645 1617.05 571.918 1616.36L571.444 1615.48ZM567.351 1615.44C567.261 1618.19 565.748 1620.82 563.148 1622.22L563.623 1623.1C566.549 1621.53 568.249 1618.57 568.351 1615.47L567.351 1615.44ZM563.148 1622.22C559.259 1624.32 554.407 1622.87 552.311 1618.98L551.431 1619.45C553.788 1623.83 559.247 1625.46 563.623 1623.1L563.148 1622.22ZM552.311 1618.98L548.991 1612.81L548.111 1613.29L551.431 1619.45L552.311 1618.98ZM548.991 1612.81C546.896 1608.92 548.35 1604.07 552.24 1601.98L551.765 1601.1C547.389 1603.45 545.753 1608.91 548.111 1613.29L548.991 1612.81ZM552.24 1601.98C554.839 1600.58 557.869 1600.76 560.22 1602.2L560.743 1601.35C558.101 1599.73 554.691 1599.52 551.765 1601.1L552.24 1601.98Z'
          fill='#9FA4AA'
        />
        <path
          d='M561.004 1605.77C560.008 1602.3 560.389 1599.29 562.194 1598.32M571.68 1615.92C569.875 1616.9 567.153 1615.56 564.798 1612.82'
          stroke='#9FA4AA'
        />
      </g>
      <g clip-path='url(#clip33_6651_98482)'>
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M504.69 396.836C504.236 398.41 503.405 399.509 502.256 399.829C499.595 400.57 496.238 396.857 494.757 391.537C493.276 386.216 494.232 381.302 496.892 380.562C498.042 380.242 499.321 380.753 500.523 381.867C501.263 379.035 503.448 376.661 506.471 375.819C510.994 374.56 515.681 377.206 516.939 381.729L518.817 388.472C520.076 392.995 517.43 397.681 512.907 398.94C509.884 399.782 506.787 398.879 504.69 396.836Z'
          fill='#D9D9D9'
        />
        <path
          d='M504.69 396.836L505.039 396.478L504.441 395.895L504.21 396.697L504.69 396.836ZM500.523 381.867L500.184 382.234L500.796 382.801L501.007 381.993L500.523 381.867ZM516.939 381.729L517.421 381.594L516.939 381.729ZM518.817 388.472L518.335 388.606L518.817 388.472ZM504.21 396.697C503.777 398.199 503.027 399.096 502.122 399.348L502.39 400.311C503.783 399.923 504.696 398.621 505.171 396.974L504.21 396.697ZM502.122 399.348C501.072 399.64 499.752 399.077 498.435 397.621C497.14 396.189 495.963 394.004 495.239 391.402L494.275 391.671C495.032 394.389 496.274 396.722 497.693 398.291C499.09 399.836 500.779 400.759 502.39 400.311L502.122 399.348ZM495.239 391.402C494.515 388.801 494.394 386.322 494.762 384.427C495.138 382.5 495.977 381.336 497.026 381.044L496.758 380.08C495.147 380.529 494.179 382.192 493.781 384.236C493.377 386.313 493.519 388.952 494.275 391.671L495.239 391.402ZM497.026 381.044C497.932 380.792 499.037 381.172 500.184 382.234L500.863 381.5C499.606 380.335 498.151 379.692 496.758 380.08L497.026 381.044ZM501.007 381.993C501.704 379.326 503.761 377.093 506.605 376.301L506.337 375.338C503.135 376.229 500.823 378.743 500.04 381.74L501.007 381.993ZM506.605 376.301C510.862 375.116 515.273 377.606 516.458 381.863L517.421 381.594C516.088 376.806 511.126 374.005 506.337 375.338L506.605 376.301ZM516.458 381.863L518.335 388.606L519.298 388.338L517.421 381.594L516.458 381.863ZM518.335 388.606C519.52 392.863 517.03 397.274 512.773 398.459L513.042 399.422C517.83 398.089 520.631 393.127 519.298 388.338L518.335 388.606ZM512.773 398.459C509.929 399.251 507.014 398.401 505.039 396.478L504.342 397.194C506.561 399.356 509.84 400.313 513.042 399.422L512.773 398.459Z'
          fill='#9FA4AA'
        />
        <path
          d='M505.061 392.82C505.265 396.429 504.229 399.28 502.254 399.829M496.891 380.562C498.866 380.012 501.225 381.917 502.916 385.113'
          stroke='#9FA4AA'
        />
      </g>
      <g clip-path='url(#clip34_6651_98482)'>
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M534.57 412.651C535.708 413.829 536.247 415.098 535.951 416.254C535.267 418.929 530.375 419.989 525.025 418.621C519.674 417.253 515.891 413.975 516.575 411.3C516.87 410.144 517.951 409.29 519.516 408.802C517.429 406.749 516.46 403.672 517.238 400.632C518.4 396.084 523.03 393.339 527.578 394.502L534.36 396.236C538.908 397.399 541.652 402.028 540.49 406.576C539.712 409.617 537.386 411.851 534.57 412.651Z'
          fill='#D9D9D9'
        />
        <path
          d='M534.57 412.651L534.433 412.17L533.63 412.398L534.21 412.999L534.57 412.651ZM519.516 408.802L519.664 409.28L520.461 409.032L519.866 408.446L519.516 408.802ZM527.578 394.502L527.454 394.986L527.578 394.502ZM534.36 396.236L534.484 395.751L534.36 396.236ZM534.21 412.999C535.296 414.122 535.7 415.219 535.467 416.13L536.436 416.378C536.794 414.977 536.12 413.536 534.929 412.304L534.21 412.999ZM535.467 416.13C535.197 417.185 534.051 418.049 532.132 418.465C530.246 418.874 527.765 418.806 525.148 418.137L524.901 419.106C527.635 419.805 530.276 419.89 532.344 419.442C534.379 419.001 536.022 417.998 536.436 416.378L535.467 416.13ZM525.148 418.137C522.532 417.468 520.323 416.337 518.864 415.073C517.38 413.787 516.789 412.479 517.059 411.424L516.09 411.176C515.676 412.796 516.635 414.465 518.209 415.829C519.808 417.214 522.166 418.407 524.901 419.106L525.148 418.137ZM517.059 411.424C517.292 410.513 518.172 409.745 519.664 409.28L519.367 408.325C517.73 408.835 516.448 409.775 516.09 411.176L517.059 411.424ZM519.866 408.446C517.901 406.513 516.991 403.617 517.722 400.756L516.753 400.508C515.93 403.728 516.957 406.986 519.165 409.159L519.866 408.446ZM517.722 400.756C518.816 396.475 523.174 393.892 527.454 394.986L527.702 394.018C522.886 392.787 517.984 395.692 516.753 400.508L517.722 400.756ZM527.454 394.986L534.236 396.72L534.484 395.751L527.702 394.018L527.454 394.986ZM534.236 396.72C538.517 397.815 541.1 402.172 540.005 406.452L540.974 406.7C542.205 401.885 539.299 396.983 534.484 395.751L534.236 396.72ZM540.005 406.452C539.274 409.314 537.085 411.417 534.433 412.17L534.706 413.132C537.687 412.286 540.151 409.92 540.974 406.7L540.005 406.452Z'
          fill='#9FA4AA'
        />
        <path
          d='M531.277 410.327C534.508 411.949 536.463 414.268 535.955 416.254M516.578 411.3C517.086 409.314 519.914 408.218 523.527 408.345'
          stroke='#9FA4AA'
        />
      </g>
      <g clip-path='url(#clip35_6651_98482)'>
        <path
          d='M535.033 371.721C535.251 370.922 536.076 370.451 536.875 370.67L612.114 391.242C612.913 391.461 613.383 392.286 613.165 393.085L611.055 400.802C610.399 403.199 607.925 404.611 605.527 403.955L536.077 384.965C533.679 384.31 532.267 381.835 532.923 379.438L535.033 371.721Z'
          fill='#F3F3F4'
          stroke='#9FA4AA'
        />
        <rect
          x='536.749'
          y='365.45'
          width='81'
          height='5'
          rx='1.5'
          transform='rotate(15.2926 536.749 365.45)'
          fill='#CFD1D2'
          stroke='#9FA4AA'
        />
      </g>
      <g clip-path='url(#clip36_6651_98482)'>
        <path
          d='M803.484 1581.92C803.758 1582.7 803.346 1583.56 802.564 1583.83L728.949 1609.62C728.167 1609.89 727.311 1609.48 727.037 1608.7L724.393 1601.15C723.571 1598.8 724.807 1596.23 727.152 1595.41L795.105 1571.61C797.451 1570.79 800.018 1572.03 800.84 1574.37L803.484 1581.92Z'
          fill='#F3F3F4'
          stroke='#9FA4AA'
        />
        <rect
          x='805.636'
          y='1588.05'
          width='81'
          height='5'
          rx='1.5'
          transform='rotate(160.698 805.636 1588.05)'
          fill='#CFD1D2'
          stroke='#9FA4AA'
        />
      </g>
      <g clip-path='url(#clip37_6651_98482)'>
        <path
          d='M931.415 1536.72C931.696 1537.5 931.291 1538.36 930.511 1538.64L857.111 1565.03C856.331 1565.31 855.472 1564.91 855.191 1564.13L852.485 1556.6C851.644 1554.26 852.859 1551.68 855.197 1550.84L922.952 1526.48C925.291 1525.64 927.868 1526.86 928.709 1529.2L931.415 1536.72Z'
          fill='#F3F3F4'
          stroke='#9FA4AA'
        />
        <rect
          x='933.61'
          y='1542.84'
          width='81'
          height='5'
          rx='1.5'
          transform='rotate(160.226 933.61 1542.84)'
          fill='#CFD1D2'
          stroke='#9FA4AA'
        />
      </g>
      <g clip-path='url(#clip38_6651_98482)'>
        <path
          d='M426.5 1155C426.5 1155.83 425.828 1156.5 425 1156.5H379C378.172 1156.5 377.5 1155.83 377.5 1155V1147C377.5 1144.51 379.515 1142.5 382 1142.5H422C424.485 1142.5 426.5 1144.51 426.5 1147V1155Z'
          fill='#F3F3F4'
          stroke='#9FA4AA'
        />
        <rect
          x='426.5'
          y='1161.5'
          width='49'
          height='5'
          rx='1.5'
          transform='rotate(-180 426.5 1161.5)'
          fill='#CFD1D2'
          stroke='#9FA4AA'
        />
      </g>
      <g clip-path='url(#clip39_6651_98482)'>
        <path
          d='M396.5 1199C396.5 1199.83 395.828 1200.5 395 1200.5H349C348.172 1200.5 347.5 1199.83 347.5 1199V1191C347.5 1188.51 349.515 1186.5 352 1186.5H392C394.485 1186.5 396.5 1188.51 396.5 1191V1199Z'
          fill='#F3F3F4'
          stroke='#9FA4AA'
        />
        <rect
          x='396.5'
          y='1205.5'
          width='49'
          height='5'
          rx='1.5'
          transform='rotate(-180 396.5 1205.5)'
          fill='#CFD1D2'
          stroke='#9FA4AA'
        />
      </g>
      <g clip-path='url(#clip40_6651_98482)'>
        <path
          d='M426.5 1243C426.5 1243.83 425.828 1244.5 425 1244.5H379C378.172 1244.5 377.5 1243.83 377.5 1243V1235C377.5 1232.51 379.515 1230.5 382 1230.5H422C424.485 1230.5 426.5 1232.51 426.5 1235V1243Z'
          fill='#F3F3F4'
          stroke='#9FA4AA'
        />
        <rect
          x='426.5'
          y='1249.5'
          width='49'
          height='5'
          rx='1.5'
          transform='rotate(-180 426.5 1249.5)'
          fill='#CFD1D2'
          stroke='#9FA4AA'
        />
      </g>
      <g clip-path='url(#clip41_6651_98482)'>
        <path
          d='M333.5 1221C333.5 1221.83 332.828 1222.5 332 1222.5H286C285.172 1222.5 284.5 1221.83 284.5 1221V1213C284.5 1210.51 286.515 1208.5 289 1208.5H329C331.485 1208.5 333.5 1210.51 333.5 1213V1221Z'
          fill='#F3F3F4'
          stroke='#9FA4AA'
        />
        <rect
          x='333.5'
          y='1227.5'
          width='49'
          height='5'
          rx='1.5'
          transform='rotate(-180 333.5 1227.5)'
          fill='#CFD1D2'
          stroke='#9FA4AA'
        />
      </g>
      <g clip-path='url(#clip42_6651_98482)'>
        <path
          d='M324.5 1177C324.5 1177.83 323.828 1178.5 323 1178.5H277C276.172 1178.5 275.5 1177.83 275.5 1177V1169C275.5 1166.51 277.515 1164.5 280 1164.5H320C322.485 1164.5 324.5 1166.51 324.5 1169V1177Z'
          fill='#F3F3F4'
          stroke='#9FA4AA'
        />
        <rect
          x='324.5'
          y='1183.5'
          width='49'
          height='5'
          rx='1.5'
          transform='rotate(-180 324.5 1183.5)'
          fill='#CFD1D2'
          stroke='#9FA4AA'
        />
      </g>
      <path d='M436 1163H264' stroke='#CFD1D2' />
      <path d='M436 1185H264' stroke='#CFD1D2' />
      <path d='M436 1207H264' stroke='#CFD1D2' />
      <path d='M436 1229H264' stroke='#CFD1D2' />
      <path d='M436 1251H264' stroke='#CFD1D2' />
      <rect
        x='859.207'
        y='501.201'
        width='12'
        height='53.2965'
        transform='rotate(19.0949 859.207 501.201)'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <rect
        x='1031.07'
        y='557.636'
        width='12'
        height='53.2965'
        transform='rotate(19.0949 1031.07 557.636)'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <rect
        x='1430.68'
        y='817.705'
        width='12'
        height='53.2965'
        transform='rotate(41.02 1430.68 817.705)'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <rect
        x='1473.49'
        y='1186.42'
        width='12'
        height='53.2965'
        transform='rotate(-130.46 1473.49 1186.42)'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <rect
        x='1390.49'
        y='1256.42'
        width='12'
        height='53.2965'
        transform='rotate(-130.46 1390.49 1256.42)'
        fill='#F3F3F4'
        stroke='#9FA4AA'
      />
      <rect
        x='349.5'
        y='1238.5'
        width='7'
        height='11'
        rx='1.5'
        transform='rotate(-90 349.5 1238.5)'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <rect
        x='277.5'
        y='1051.53'
        width='3.73584'
        height='155.223'
        rx='1.5'
        transform='rotate(-90 277.5 1051.53)'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M860.047 513.112C861.045 513.712 861.517 514.95 861.112 516.09L853.086 538.708C852.681 539.848 851.535 540.511 850.382 540.348L860.047 513.112Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M1031.91 570.828C1032.91 571.428 1033.38 572.666 1032.98 573.806L1024.95 596.424C1024.55 597.564 1023.4 598.228 1022.25 598.064L1031.91 570.828Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M1409.07 852.423C1408.35 851.504 1408.36 850.179 1409.14 849.262L1424.77 831.049C1425.56 830.131 1426.87 829.925 1427.89 830.492L1409.07 852.423Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M1479.55 1174.72C1478.98 1173.7 1479.19 1172.39 1480.11 1171.61L1498.34 1156C1499.26 1155.21 1500.58 1155.21 1501.5 1155.92L1479.55 1174.72Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path
        d='M1395.68 1244.72C1395.11 1243.7 1395.32 1242.39 1396.24 1241.61L1414.47 1226C1415.39 1225.21 1416.71 1225.21 1417.63 1225.92L1395.68 1244.72Z'
        fill='#CFD1D2'
        stroke='#9FA4AA'
      />
      <path d='M359.928 1230.81L433.359 1052.7' stroke='#CFD1D2' stroke-linecap='round' stroke-dasharray='7 7' />
      <path d='M350.002 1230.81L276.57 1052.7' stroke='#CFD1D2' stroke-linecap='round' stroke-dasharray='7 7' />
      <circle cx='486' cy='1754' r='8.5' fill='#F3F3F4' stroke='#9FA4AA' />
      <path
        d='M487.518 1754.48C487.772 1750.75 490.753 1747.77 494.482 1747.52C494.228 1751.25 491.247 1754.23 487.518 1754.48Z'
        fill='#93B975'
        stroke='#6E8E62'
      />
      <path
        d='M477.515 1745.52C481.799 1745.77 485.228 1749.2 485.485 1753.48C481.201 1753.23 477.772 1749.8 477.515 1745.52Z'
        fill='#93B975'
        stroke='#6E8E62'
      />
      <path
        d='M485.339 1754.46C487.329 1751.52 491.193 1750.48 494.387 1752.03C492.397 1754.97 488.533 1756.01 485.339 1754.46Z'
        fill='#93B975'
        stroke='#6E8E62'
      />
      <path
        d='M486.04 1752.96C484.241 1749.33 485.424 1744.92 488.796 1742.67C490.596 1746.3 489.412 1750.72 486.04 1752.96Z'
        fill='#93B975'
        stroke='#6E8E62'
      />
      <path
        d='M485.728 1755.44C482.532 1753.3 481.409 1749.11 483.107 1745.66C486.304 1747.8 487.427 1751.99 485.728 1755.44Z'
        fill='#93B975'
        stroke='#6E8E62'
      />
      <path
        d='M488.521 1756.52C491.695 1756.77 494.228 1759.3 494.479 1762.48C491.305 1762.23 488.772 1759.7 488.521 1756.52Z'
        fill='#93B975'
        stroke='#6E8E62'
      />
      <path
        d='M487.711 1755.19C490.487 1753.88 493.82 1754.77 495.568 1757.3C492.792 1758.61 489.458 1757.72 487.711 1755.19Z'
        fill='#93B975'
        stroke='#6E8E62'
      />
      <path
        d='M477.515 1762.48C477.772 1758.2 481.201 1754.77 485.485 1754.52C485.228 1758.8 481.799 1762.23 477.515 1762.48Z'
        fill='#93B975'
        stroke='#6E8E62'
      />
      <path
        d='M485.074 1764.2C482.723 1761.49 482.723 1757.44 485.074 1754.73C487.425 1757.44 487.425 1761.49 485.074 1764.2Z'
        fill='#93B975'
        stroke='#6E8E62'
      />
      <path
        d='M488.773 1761.35C486.342 1759.66 485.481 1756.44 486.74 1753.76C489.171 1755.45 490.032 1758.67 488.773 1761.35Z'
        fill='#93B975'
        stroke='#6E8E62'
      />
      <path
        d='M474.622 1750.02C476.979 1748.95 479.782 1749.7 481.286 1751.81C478.929 1752.88 476.126 1752.13 474.622 1750.02Z'
        fill='#93B975'
        stroke='#6E8E62'
      />
      <path
        d='M476.462 1753.8C479.212 1751.41 483.319 1751.41 486.069 1753.8C483.319 1756.19 479.212 1756.19 476.462 1753.8Z'
        fill='#93B975'
        stroke='#6E8E62'
      />
      <path
        d='M476.427 1757.31C478.191 1754.76 481.558 1753.85 484.361 1755.18C482.598 1757.73 479.23 1758.64 476.427 1757.31Z'
        fill='#93B975'
        stroke='#6E8E62'
      />
      <path d='M1539 685H1511' stroke='#9FA4AA' stroke-width='2' stroke-linecap='round' />
      <path d='M275 481V509' stroke='#8BB6DB' stroke-width='2' stroke-linecap='round' />
      <path d='M1390 1061V1089' stroke='#8BB6DB' stroke-width='2' stroke-linecap='round' />
      <path d='M655 261V289' stroke='#8BB6DB' stroke-width='2' stroke-linecap='round' />
      <path d='M445.207 149.654L451.453 122.359' stroke='#8BB6DB' stroke-width='2' stroke-linecap='round' />
      <path d='M424.09 291.337L430.336 264.043' stroke='#8BB6DB' stroke-width='2' stroke-linecap='round' />
      <path d='M177.021 356.333L150.062 348.766' stroke='#8BB6DB' stroke-width='2' stroke-linecap='round' />
      <path d='M278.646 387.388L251.688 379.82' stroke='#8BB6DB' stroke-width='2' stroke-linecap='round' />
      <path d='M266.203 560.698L238.523 556.473' stroke='#8BB6DB' stroke-width='2' stroke-linecap='round' />
      <path d='M245.76 796.138L247.938 768.223' stroke='#8BB6DB' stroke-width='2' stroke-linecap='round' />
      <path d='M238.057 894.064L240.234 866.148' stroke='#8BB6DB' stroke-width='2' stroke-linecap='round' />
      <path d='M104.03 931.183L76.0312 930.914' stroke='#8BB6DB' stroke-width='2' stroke-linecap='round' />
      <path d='M142.132 931.452L114.133 931.184' stroke='#8BB6DB' stroke-width='2' stroke-linecap='round' />
      <path d='M198.001 1226.83L200.539 1254.72' stroke='#8BB6DB' stroke-width='2' stroke-linecap='round' />
      <path d='M210.681 1365.1L213.219 1392.98' stroke='#8BB6DB' stroke-width='2' stroke-linecap='round' />
      <path d='M220.204 1470.65L222.742 1498.54' stroke='#8BB6DB' stroke-width='2' stroke-linecap='round' />
      <path d='M228.275 1561.49L230.812 1589.38' stroke='#8BB6DB' stroke-width='2' stroke-linecap='round' />
      <path d='M651.865 1775.05L660.531 1801.67' stroke='#8BB6DB' stroke-width='2' stroke-linecap='round' />
      <path d='M1146.59 1759.4L1159.7 1784.14' stroke='#8BB6DB' stroke-width='2' stroke-linecap='round' />
      <path d='M1150.85 1637.91L1173.94 1622.08' stroke='#8BB6DB' stroke-width='2' stroke-linecap='round' />
      <path d='M1235.5 1579.8L1258.59 1563.97' stroke='#8BB6DB' stroke-width='2' stroke-linecap='round' />
      <path d='M1312.25 1527.18L1335.34 1511.35' stroke='#8BB6DB' stroke-width='2' stroke-linecap='round' />
      <path d='M1401.33 1466.02L1424.42 1450.19' stroke='#8BB6DB' stroke-width='2' stroke-linecap='round' />
      <path d='M1539.9 1376.23L1557.59 1397.93' stroke='#8BB6DB' stroke-width='2' stroke-linecap='round' />
      <path d='M1581.39 1366.99L1600.01 1387.91' stroke='#8BB6DB' stroke-width='2' stroke-linecap='round' />
      <path d='M1615.93 1406.19L1634.55 1427.11' stroke='#8BB6DB' stroke-width='2' stroke-linecap='round' />
      <path d='M1842.03 1240.85L1859.99 1262.33' stroke='#8BB6DB' stroke-width='2' stroke-linecap='round' />
      <path d='M1894.07 1039.07L1915.47 1021.02' stroke='#8BB6DB' stroke-width='2' stroke-linecap='round' />
      <path d='M1973.27 971.617L1994.67 953.562' stroke='#8BB6DB' stroke-width='2' stroke-linecap='round' />
      <path d='M1451.46 1346.57L1472.86 1328.52' stroke='#8BB6DB' stroke-width='2' stroke-linecap='round' />
      <path d='M1568.48 1247.33L1589.88 1229.27' stroke='#8BB6DB' stroke-width='2' stroke-linecap='round' />
      <path d='M1563.47 921.853L1573.88 895.859' stroke='#8BB6DB' stroke-width='2' stroke-linecap='round' />
      <path d='M1367.49 580.25L1379.46 554.938' stroke='#8BB6DB' stroke-width='2' stroke-linecap='round' />
      <path d='M1382.49 548.765L1394.46 523.453' stroke='#8BB6DB' stroke-width='2' stroke-linecap='round' />
      <path d='M1361.51 469.258L1373.48 443.945' stroke='#8BB6DB' stroke-width='2' stroke-linecap='round' />
      <path d='M1114.42 382.019L1125.27 356.207' stroke='#8BB6DB' stroke-width='2' stroke-linecap='round' />
      <path d='M896.722 480.482L924.414 484.625' stroke='#8BB6DB' stroke-width='2' stroke-linecap='round' />
      <path d='M831.92 467.246L859.469 471.848' stroke='#8BB6DB' stroke-width='2' stroke-linecap='round' />
      <path d='M835 448L862 457' stroke='#8BB6DB' stroke-width='2' stroke-linecap='round' />
      <path d='M897 469L924 478' stroke='#8BB6DB' stroke-width='2' stroke-linecap='round' />
      <path d='M1258.56 553.882L1283.67 566.262' stroke='#8BB6DB' stroke-width='2' stroke-linecap='round' />
      <path d='M1149.85 499.433L1174.97 511.812' stroke='#8BB6DB' stroke-width='2' stroke-linecap='round' />
      <path d='M1833.17 813.727L1852.07 793.07' stroke='#8BB6DB' stroke-width='2' stroke-linecap='round' />
      <path d='M1815.68 1105.44L1837.09 1087.39' stroke='#8BB6DB' stroke-width='2' stroke-linecap='round' />
      <path d='M1881.64 1288.17L1899.6 1309.65' stroke='#8BB6DB' stroke-width='2' stroke-linecap='round' />
      <path d='M1639.68 1432.56L1658.3 1453.48' stroke='#8BB6DB' stroke-width='2' stroke-linecap='round' />
      <path d='M1605.12 1457.21L1622.81 1478.91' stroke='#8BB6DB' stroke-width='2' stroke-linecap='round' />
      <path d='M703.029 1932.02L711.695 1958.64' stroke='#8BB6DB' stroke-width='2' stroke-linecap='round' />
      <path d='M693.819 1903.21L700.484 1924.8' stroke='#8BB6DB' stroke-width='2' stroke-linecap='round' />
      <path d='M255.029 610.543L222.641 607.047' stroke='#8BB6DB' stroke-width='2' stroke-linecap='round' />
      <path d='M170.919 546.09L146.734 542.77' stroke='#8BB6DB' stroke-width='2' stroke-linecap='round' />
      <path d='M137.909 580.281L142.766 546.434' stroke='#8BB6DB' stroke-width='2' stroke-linecap='round' />
      <path d='M408.521 348.253L415.797 321.215' stroke='#8BB6DB' stroke-width='2' stroke-linecap='round' />
      <path d='M551.742 1224.99V1262.99' stroke='#9FA4AA' stroke-width='2' stroke-linecap='round' />
      <path d='M673.758 1181.99V1219.99' stroke='#9FA4AA' stroke-width='2' stroke-linecap='round' />
      <path d='M551.898 1313.5V1351.5' stroke='#9FA4AA' stroke-width='2' stroke-linecap='round' />
      <path d='M673.594 1270.08V1308.08' stroke='#9FA4AA' stroke-width='2' stroke-linecap='round' />
      <path d='M551.898 1136.53V1174.53' stroke='#9FA4AA' stroke-width='2' stroke-linecap='round' />
      <path d='M673.758 1096.46V1134.46' stroke='#9FA4AA' stroke-width='2' stroke-linecap='round' />
      <path d='M673.758 1008.74V1046.74' stroke='#9FA4AA' stroke-width='2' stroke-linecap='round' />
      <path d='M673.758 922.941V960.941' stroke='#9FA4AA' stroke-width='2' stroke-linecap='round' />
      <path d='M673.758 834.914V872.914' stroke='#9FA4AA' stroke-width='2' stroke-linecap='round' />
      <path d='M176.324 302.015L149.344 294.527' stroke='#9FA4AA' stroke-width='2' stroke-linecap='round' />
      <path d='M247.433 321.976L220.453 314.488' stroke='#9FA4AA' stroke-width='2' stroke-linecap='round' />
      <path d='M280.941 331.218L253.961 323.73' stroke='#9FA4AA' stroke-width='2' stroke-linecap='round' />
      <path d='M93.4531 975H65.4531' stroke='#9FA4AA' stroke-width='2' stroke-linecap='round' />
      <path d='M174.195 975H146.195' stroke='#9FA4AA' stroke-width='2' stroke-linecap='round' />
      <path d='M175.062 975H147.062' stroke='#9FA4AA' stroke-width='2' stroke-linecap='round' />
      <path d='M193.079 1121.53L194.281 1149.5' stroke='#9FA4AA' stroke-width='2' stroke-linecap='round' />
      <path d='M244.131 1680.72L245.641 1708.68' stroke='#9FA4AA' stroke-width='2' stroke-linecap='round' />
      <path d='M1206.76 1747.97L1192.7 1723.75' stroke='#9FA4AA' stroke-width='2' stroke-linecap='round' />
      <path d='M1684.73 1266.35L1707.8 1250.48' stroke='#9FA4AA' stroke-width='2' stroke-linecap='round' />
      <path d='M1657.72 1188.37L1678.97 1169.95' stroke='#9FA4AA' stroke-width='2' stroke-linecap='round' />
      <path d='M1932.82 1257.34L1914.95 1235.79' stroke='#9FA4AA' stroke-width='2' stroke-linecap='round' />
      <path d='M1910.33 1228.87L1894.39 1209.98' stroke='#9FA4AA' stroke-width='2' stroke-linecap='round' />
      <path d='M1620.15 835.121L1601.38 819.059' stroke='#9FA4AA' stroke-width='2' stroke-linecap='round' />
      <path d='M1537.36 763.676L1518.59 747.613' stroke='#9FA4AA' stroke-width='2' stroke-linecap='round' />
      <path d='M1461.59 774.449L1442.81 758.387' stroke='#9FA4AA' stroke-width='2' stroke-linecap='round' />
      <path d='M1028.33 648.023L1051.7 656.051' stroke='#9FA4AA' stroke-width='2' stroke-linecap='round' />
      <path d='M1449.28 688.699L1430.51 672.637' stroke='#9FA4AA' stroke-width='2' stroke-linecap='round' />
      <path d='M780.309 1721.45L806.672 1712.01' stroke='#9FA4AA' stroke-width='2' stroke-linecap='round' />
      <path d='M879.395 1686.38L905.758 1676.94' stroke='#9FA4AA' stroke-width='2' stroke-linecap='round' />
      <path d='M990.747 1645.71L1017.11 1636.27' stroke='#9FA4AA' stroke-width='2' stroke-linecap='round' />
      <path d='M1026.04 1493.83L1052.41 1484.4' stroke='#9FA4AA' stroke-width='2' stroke-linecap='round' />
      <path d='M960.582 1517.15L986.945 1507.71' stroke='#9FA4AA' stroke-width='2' stroke-linecap='round' />
      <path d='M296.225 1643.72L294.32 1615.79' stroke='#9FA4AA' stroke-width='2' stroke-linecap='round' />
      <path d='M291.483 1578.4L289.578 1550.46' stroke='#9FA4AA' stroke-width='2' stroke-linecap='round' />
      <path d='M284.178 1469.58L282.273 1441.64' stroke='#9FA4AA' stroke-width='2' stroke-linecap='round' />
      <path d='M279.568 1404.35L277.664 1376.41' stroke='#9FA4AA' stroke-width='2' stroke-linecap='round' />
      <path d='M275.225 1340.5L273.32 1311.43' stroke='#9FA4AA' stroke-width='2' stroke-linecap='round' />
      <path d='M577.988 1403.19L578.031 1432.7' stroke='#9FA4AA' stroke-width='2' stroke-linecap='round' />
      <path d='M648 1360L582 1371' stroke='#9FA4AA' stroke-width='2' stroke-linecap='round' />
      <path d='M652 782L586 773' stroke='#9FA4AA' stroke-width='2' stroke-linecap='round' />
      <path d='M579.726 687.637L579.914 718.484' stroke='#9FA4AA' stroke-width='2' stroke-linecap='round' />
      <path d='M660.078 641.336L660.078 670.633' stroke='#9FA4AA' stroke-width='2' stroke-linecap='round' />
      <path d='M1102.4 744.128L1074.59 733.684' stroke='#9FA4AA' stroke-width='2' stroke-linecap='round' />
      <path d='M959.696 693.287L931.891 682.843' stroke='#9FA4AA' stroke-width='2' stroke-linecap='round' />
      <path d='M1251.01 866.867L1231.33 850.891' stroke='#9FA4AA' stroke-width='2' stroke-linecap='round' />
      <path d='M1159.82 793.984L1139.38 775.977' stroke='#9FA4AA' stroke-width='2' stroke-linecap='round' />
      <path d='M1349.62 951.723L1329.24 933.559' stroke='#9FA4AA' stroke-width='2' stroke-linecap='round' />
      <path d='M1382.78 980.25L1364.11 963.891' stroke='#9FA4AA' stroke-width='2' stroke-linecap='round' />
      <path d='M1401.86 999.205V1024.03' stroke='#9FA4AA' stroke-width='2' stroke-linecap='round' />
      <path d='M1401.86 1120.44V1145.26' stroke='#9FA4AA' stroke-width='2' stroke-linecap='round' />
      <path d='M1251.54 1278.63L1231.32 1294.07' stroke='#9FA4AA' stroke-width='2' stroke-linecap='round' />
      <path d='M1154.82 1356.61L1132.19 1376.46' stroke='#9FA4AA' stroke-width='2' stroke-linecap='round' />
      <path d='M1327.55 1210.73L1305.08 1228.66' stroke='#9FA4AA' stroke-width='2' stroke-linecap='round' />
      <path d='M1382.78 1164.71L1364.11 1181.07' stroke='#9FA4AA' stroke-width='2' stroke-linecap='round' />
      <path d='M660.078 1471.17V1500.31' stroke='#9FA4AA' stroke-width='2' stroke-linecap='round' />
      <path d='M954.46 1450.07L927.225 1460.43' stroke='#9FA4AA' stroke-width='2' stroke-linecap='round' />
      <path d='M304.535 941.4L306.094 919.562' stroke='#9FA4AA' stroke-width='2' stroke-linecap='round' />
      <path d='M307.256 904.21L309.25 876.281' stroke='#9FA4AA' stroke-width='2' stroke-linecap='round' />
      <path d='M314.631 810.183L316.625 782.254' stroke='#9FA4AA' stroke-width='2' stroke-linecap='round' />
      <path d='M318.803 749.788L320.797 721.859' stroke='#9FA4AA' stroke-width='2' stroke-linecap='round' />
      <path d='M325.873 653.538L327.867 625.609' stroke='#9FA4AA' stroke-width='2' stroke-linecap='round' />
      <path d='M331.42 584.503L333.414 556.574' stroke='#9FA4AA' stroke-width='2' stroke-linecap='round' />
      <mask id='path-762-inside-65_6651_98482' fill='white'>
        <path d='M9.15625 859.969L13.0335 817.372L22.9924 818.279L19.1151 860.875L9.15625 859.969Z' />
      </mask>
      <path d='M9.15625 859.969L13.0335 817.372L22.9924 818.279L19.1151 860.875L9.15625 859.969Z' fill='#ECF5FC' />
      <path
        d='M10.1521 860.059L14.0294 817.463L12.0376 817.281L8.16037 859.878L10.1521 860.059ZM21.9965 818.188L18.1192 860.785L20.111 860.966L23.9882 818.369L21.9965 818.188Z'
        fill='#CFD1D2'
        mask='url(#path-762-inside-65_6651_98482)'
      />
      <mask id='path-764-inside-66_6651_98482' fill='white'>
        <path d='M12.1406 860.241L16.0385 817.418L20.022 817.78L16.1242 860.603L12.1406 860.241Z' />
      </mask>
      <path d='M12.1406 860.241L16.0385 817.418L20.022 817.78L16.1242 860.603L12.1406 860.241Z' fill='#AECCE7' />
      <path
        d='M12.1406 860.241L11.1447 860.15L11.0541 861.146L12.05 861.237L12.1406 860.241ZM16.0385 817.418L16.1291 816.422L15.1333 816.331L15.0426 817.327L16.0385 817.418ZM20.022 817.78L21.0179 817.871L21.1086 816.875L20.1127 816.784L20.022 817.78ZM16.1242 860.603L16.0335 861.599L17.0294 861.69L17.12 860.694L16.1242 860.603ZM13.1365 860.331L17.0344 817.508L15.0426 817.327L11.1447 860.15L13.1365 860.331ZM15.9479 818.414L19.9314 818.776L20.1127 816.784L16.1291 816.422L15.9479 818.414ZM19.0262 817.69L15.1283 860.513L17.12 860.694L21.0179 817.871L19.0262 817.69ZM16.2148 859.607L12.2313 859.245L12.05 861.237L16.0335 861.599L16.2148 859.607Z'
        fill='#8BB6DB'
        mask='url(#path-764-inside-66_6651_98482)'
      />
      <mask id='path-766-inside-67_6651_98482' fill='white'>
        <path d='M3.25781 1024.02L3.49214 980.083L13.492 980.136L13.2577 1024.08L3.25781 1024.02Z' />
      </mask>
      <path d='M3.25781 1024.02L3.49214 980.083L13.492 980.136L13.2577 1024.08L3.25781 1024.02Z' fill='#ECF5FC' />
      <path
        d='M4.2578 1024.03L4.49212 980.088L2.49215 980.078L2.25783 1024.02L4.2578 1024.03ZM12.492 980.131L12.2577 1024.07L14.2577 1024.08L14.492 980.142L12.492 980.131Z'
        fill='#CFD1D2'
        mask='url(#path-766-inside-67_6651_98482)'
      />
      <mask id='path-768-inside-68_6651_98482' fill='white'>
        <path d='M6.25781 1024.04L6.49245 980.04L10.4924 980.062L10.2578 1024.06L6.25781 1024.04Z' />
      </mask>
      <path d='M6.25781 1024.04L6.49245 980.04L10.4924 980.062L10.2578 1024.06L6.25781 1024.04Z' fill='#AECCE7' />
      <path
        d='M6.25781 1024.04L5.25783 1024.03L5.25249 1025.03L6.25248 1025.04L6.25781 1024.04ZM6.49245 980.04L6.49779 979.04L5.4978 979.035L5.49247 980.035L6.49245 980.04ZM10.4924 980.062L11.4924 980.067L11.4977 979.067L10.4977 979.062L10.4924 980.062ZM10.2578 1024.06L10.2524 1025.06L11.2524 1025.07L11.2577 1024.07L10.2578 1024.06ZM7.2578 1024.04L7.49244 980.046L5.49247 980.035L5.25783 1024.03L7.2578 1024.04ZM6.48712 981.04L10.4871 981.061L10.4977 979.062L6.49779 979.04L6.48712 981.04ZM9.49241 980.056L9.25777 1024.06L11.2577 1024.07L11.4924 980.067L9.49241 980.056ZM10.2631 1023.06L6.26315 1023.04L6.25248 1025.04L10.2524 1025.06L10.2631 1023.06Z'
        fill='#8BB6DB'
        mask='url(#path-768-inside-68_6651_98482)'
      />
      <mask id='path-770-inside-69_6651_98482' fill='white'>
        <path d='M3.15625 1077.06L3.15625 1034.06H13.1563L13.1562 1077.06H3.15625Z' />
      </mask>
      <path d='M3.15625 1077.06L3.15625 1034.06H13.1563L13.1562 1077.06H3.15625Z' fill='#ECF5FC' />
      <path
        d='M4.15625 1077.06L4.15625 1034.06H2.15625L2.15625 1077.06H4.15625ZM12.1563 1034.06L12.1562 1077.06H14.1562L14.1563 1034.06H12.1563Z'
        fill='#CFD1D2'
        mask='url(#path-770-inside-69_6651_98482)'
      />
      <mask id='path-772-inside-70_6651_98482' fill='white'>
        <path d='M6.15625 1077.06L6.15625 1034.06H10.1563L10.1562 1077.06H6.15625Z' />
      </mask>
      <path d='M6.15625 1077.06L6.15625 1034.06H10.1563L10.1562 1077.06H6.15625Z' fill='#AECCE7' />
      <path
        d='M6.15625 1077.06H5.15625V1078.06H6.15625V1077.06ZM6.15625 1034.06V1033.06H5.15625V1034.06H6.15625ZM10.1563 1034.06H11.1563V1033.06H10.1563V1034.06ZM10.1562 1077.06V1078.06H11.1562V1077.06H10.1562ZM7.15625 1077.06L7.15625 1034.06H5.15625L5.15625 1077.06H7.15625ZM6.15625 1035.06H10.1563V1033.06H6.15625V1035.06ZM9.15625 1034.06L9.15625 1077.06H11.1562L11.1563 1034.06H9.15625ZM10.1562 1076.06H6.15625V1078.06H10.1562V1076.06Z'
        fill='#8BB6DB'
        mask='url(#path-772-inside-70_6651_98482)'
      />
      <mask id='path-774-inside-71_6651_98482' fill='white'>
        <path d='M3.15625 1133.06L3.15625 1087.06H13.1563L13.1562 1133.06H3.15625Z' />
      </mask>
      <path d='M3.15625 1133.06L3.15625 1087.06H13.1563L13.1562 1133.06H3.15625Z' fill='#ECF5FC' />
      <path
        d='M4.15625 1133.06L4.15625 1087.06H2.15625L2.15625 1133.06H4.15625ZM12.1563 1087.06L12.1562 1133.06H14.1562L14.1563 1087.06H12.1563Z'
        fill='#CFD1D2'
        mask='url(#path-774-inside-71_6651_98482)'
      />
      <mask id='path-776-inside-72_6651_98482' fill='white'>
        <path d='M6.15625 1133.06L6.15625 1087.06H10.1563L10.1562 1133.06H6.15625Z' />
      </mask>
      <path d='M6.15625 1133.06L6.15625 1087.06H10.1563L10.1562 1133.06H6.15625Z' fill='#AECCE7' />
      <path
        d='M6.15625 1133.06H5.15625V1134.06H6.15625V1133.06ZM6.15625 1087.06V1086.06H5.15625V1087.06H6.15625ZM10.1563 1087.06H11.1563V1086.06H10.1563V1087.06ZM10.1562 1133.06V1134.06H11.1562V1133.06H10.1562ZM7.15625 1133.06L7.15625 1087.06H5.15625L5.15625 1133.06H7.15625ZM6.15625 1088.06H10.1563V1086.06H6.15625L6.15625 1088.06ZM9.15625 1087.06L9.15625 1133.06H11.1562L11.1563 1087.06H9.15625ZM10.1562 1132.06H6.15625V1134.06H10.1562V1132.06Z'
        fill='#8BB6DB'
        mask='url(#path-776-inside-72_6651_98482)'
      />
      <mask id='path-778-inside-73_6651_98482' fill='white'>
        <path d='M3.15625 1185.06L3.15625 1143.06H13.1563L13.1562 1185.06H3.15625Z' />
      </mask>
      <path d='M3.15625 1185.06L3.15625 1143.06H13.1563L13.1562 1185.06H3.15625Z' fill='#ECF5FC' />
      <path
        d='M4.15625 1185.06L4.15625 1143.06H2.15625L2.15625 1185.06H4.15625ZM12.1563 1143.06L12.1562 1185.06H14.1562L14.1563 1143.06H12.1563Z'
        fill='#CFD1D2'
        mask='url(#path-778-inside-73_6651_98482)'
      />
      <mask id='path-780-inside-74_6651_98482' fill='white'>
        <path d='M6.15625 1185.06L6.15625 1143.06H10.1563L10.1562 1185.06H6.15625Z' />
      </mask>
      <path d='M6.15625 1185.06L6.15625 1143.06H10.1563L10.1562 1185.06H6.15625Z' fill='#AECCE7' />
      <path
        d='M6.15625 1185.06H5.15625V1186.06H6.15625V1185.06ZM6.15625 1143.06V1142.06H5.15625V1143.06H6.15625ZM10.1563 1143.06H11.1563V1142.06H10.1563V1143.06ZM10.1562 1185.06V1186.06H11.1562V1185.06H10.1562ZM7.15625 1185.06L7.15625 1143.06H5.15625L5.15625 1185.06H7.15625ZM6.15625 1144.06H10.1563V1142.06H6.15625V1144.06ZM9.15625 1143.06L9.15625 1185.06H11.1562L11.1563 1143.06H9.15625ZM10.1562 1184.06H6.15625V1186.06H10.1562V1184.06Z'
        fill='#8BB6DB'
        mask='url(#path-780-inside-74_6651_98482)'
      />
      <mask id='path-782-inside-75_6651_98482' fill='white'>
        <path d='M4.92188 1239.82L3.09785 1195.15L13.0895 1194.74L14.9136 1239.42L4.92188 1239.82Z' />
      </mask>
      <path d='M4.92188 1239.82L3.09785 1195.15L13.0895 1194.74L14.9136 1239.42L4.92188 1239.82Z' fill='#ECF5FC' />
      <path
        d='M5.92104 1239.78L4.09702 1195.1L2.09868 1195.19L3.92271 1239.87L5.92104 1239.78ZM12.0904 1194.78L13.9144 1239.46L15.9127 1239.38L14.0887 1194.7L12.0904 1194.78Z'
        fill='#CFD1D2'
        mask='url(#path-782-inside-75_6651_98482)'
      />
      <mask id='path-784-inside-76_6651_98482' fill='white'>
        <path d='M7.92188 1239.7L6.08627 1194.74L10.0829 1194.58L11.9185 1239.54L7.92188 1239.7Z' />
      </mask>
      <path d='M7.92188 1239.7L6.08627 1194.74L10.0829 1194.58L11.9185 1239.54L7.92188 1239.7Z' fill='#AECCE7' />
      <path
        d='M7.92188 1239.7L6.92271 1239.74L6.9635 1240.74L7.96267 1240.7L7.92188 1239.7ZM6.08627 1194.74L6.04548 1193.74L5.04632 1193.78L5.08711 1194.78L6.08627 1194.74ZM10.0829 1194.58L11.0821 1194.54L11.0413 1193.54L10.0422 1193.58L10.0829 1194.58ZM11.9185 1239.54L11.9593 1240.54L12.9585 1240.5L12.9177 1239.5L11.9185 1239.54ZM8.92104 1239.66L7.08544 1194.7L5.08711 1194.78L6.92271 1239.74L8.92104 1239.66ZM6.12707 1195.74L10.1237 1195.58L10.0422 1193.58L6.04548 1193.74L6.12707 1195.74ZM9.08378 1194.62L10.9194 1239.58L12.9177 1239.5L11.0821 1194.54L9.08378 1194.62ZM11.8778 1238.54L7.88108 1238.7L7.96267 1240.7L11.9593 1240.54L11.8778 1238.54Z'
        fill='#8BB6DB'
        mask='url(#path-784-inside-76_6651_98482)'
      />
      <mask id='path-786-inside-77_6651_98482' fill='white'>
        <path d='M5.45312 916.207L8.12998 870.025L18.1132 870.603L15.4364 916.786L5.45312 916.207Z' />
      </mask>
      <path d='M5.45312 916.207L8.12998 870.025L18.1132 870.603L15.4364 916.786L5.45312 916.207Z' fill='#ECF5FC' />
      <path
        d='M6.45145 916.265L9.1283 870.082L7.13165 869.967L4.4548 916.149L6.45145 916.265ZM17.1149 870.545L14.438 916.728L16.4347 916.844L19.1115 870.661L17.1149 870.545Z'
        fill='#CFD1D2'
        mask='url(#path-786-inside-77_6651_98482)'
      />
      <mask id='path-788-inside-78_6651_98482' fill='white'>
        <path d='M8.44531 916.381L11.1071 870.458L15.1004 870.689L12.4386 916.612L8.44531 916.381Z' />
      </mask>
      <path d='M8.44531 916.381L11.1071 870.458L15.1004 870.689L12.4386 916.612L8.44531 916.381Z' fill='#AECCE7' />
      <path
        d='M8.44531 916.381L7.44699 916.323L7.38912 917.321L8.38745 917.379L8.44531 916.381ZM11.1071 870.458L11.165 869.46L10.1667 869.402L10.1088 870.4L11.1071 870.458ZM15.1004 870.689L16.0987 870.747L16.1566 869.749L15.1583 869.691L15.1004 870.689ZM12.4386 916.612L12.3807 917.611L13.3791 917.669L13.4369 916.67L12.4386 916.612ZM9.44364 916.439L12.1054 870.516L10.1088 870.4L7.44699 916.323L9.44364 916.439ZM11.0493 871.456L15.0426 871.688L15.1583 869.691L11.165 869.46L11.0493 871.456ZM14.1021 870.632L11.4403 916.554L13.4369 916.67L16.0987 870.747L14.1021 870.632ZM12.4965 915.614L8.50318 915.383L8.38745 917.379L12.3807 917.611L12.4965 915.614Z'
        fill='#8BB6DB'
        mask='url(#path-788-inside-78_6651_98482)'
      />
      <mask id='path-790-inside-79_6651_98482' fill='white'>
        <path d='M3.59375 969.996L5.5242 925.907L15.5146 926.345L13.5842 970.434L3.59375 969.996Z' />
      </mask>
      <path d='M3.59375 969.996L5.5242 925.907L15.5146 926.345L13.5842 970.434L3.59375 969.996Z' fill='#ECF5FC' />
      <path
        d='M4.59279 970.04L6.52324 925.951L4.52516 925.863L2.59471 969.952L4.59279 970.04ZM14.5156 926.301L12.5851 970.39L14.5832 970.477L16.5137 926.388L14.5156 926.301Z'
        fill='#CFD1D2'
        mask='url(#path-790-inside-79_6651_98482)'
      />
      <mask id='path-792-inside-80_6651_98482' fill='white'>
        <path d='M6.59375 970.127L8.51846 926.17L12.5146 926.345L10.5899 970.302L6.59375 970.127Z' />
      </mask>
      <path d='M6.59375 970.127L8.51846 926.17L12.5146 926.345L10.5899 970.302L6.59375 970.127Z' fill='#AECCE7' />
      <path
        d='M6.59375 970.127L5.59471 970.084L5.55096 971.083L6.55001 971.126L6.59375 970.127ZM8.51846 926.17L8.56221 925.171L7.56316 925.127L7.51942 926.126L8.51846 926.17ZM12.5146 926.345L13.5137 926.388L13.5574 925.389L12.5584 925.345L12.5146 926.345ZM10.5899 970.302L10.5462 971.301L11.5452 971.345L11.589 970.346L10.5899 970.302ZM7.59279 970.171L9.51751 926.213L7.51942 926.126L5.59471 970.084L7.59279 970.171ZM8.47472 927.169L12.4709 927.344L12.5584 925.345L8.56221 925.171L8.47472 927.169ZM11.5156 926.301L9.59088 970.259L11.589 970.346L13.5137 926.388L11.5156 926.301ZM10.6337 969.303L6.63749 969.128L6.55001 971.126L10.5462 971.301L10.6337 969.303Z'
        fill='#8BB6DB'
        mask='url(#path-792-inside-80_6651_98482)'
      />
      <mask id='path-794-inside-81_6651_98482' fill='white'>
        <path d='M119.07 1978.13L108.371 1936.09L118.062 1933.63L128.761 1975.67L119.07 1978.13Z' />
      </mask>
      <path d='M119.07 1978.13L108.371 1936.09L118.062 1933.63L128.761 1975.67L119.07 1978.13Z' fill='#ECF5FC' />
      <path
        d='M120.039 1977.89L109.34 1935.85L107.402 1936.34L118.101 1978.38L120.039 1977.89ZM117.093 1933.87L127.792 1975.91L129.731 1975.42L119.031 1933.38L117.093 1933.87Z'
        fill='#CFD1D2'
        mask='url(#path-794-inside-81_6651_98482)'
      />
      <mask id='path-796-inside-82_6651_98482' fill='white'>
        <path d='M121.977 1977.39L111.371 1935.72L115.248 1934.73L125.853 1976.41L121.977 1977.39Z' />
      </mask>
      <path d='M121.977 1977.39L111.371 1935.72L115.248 1934.73L125.853 1976.41L121.977 1977.39Z' fill='#AECCE7' />
      <path
        d='M121.977 1977.39L121.007 1977.64L121.254 1978.61L122.223 1978.36L121.977 1977.39ZM111.371 1935.72L111.125 1934.75L110.156 1935L110.402 1935.97L111.371 1935.72ZM115.248 1934.73L116.217 1934.49L115.97 1933.52L115.001 1933.77L115.248 1934.73ZM125.853 1976.41L126.1 1977.38L127.069 1977.13L126.822 1976.16L125.853 1976.41ZM122.946 1977.15L112.341 1935.47L110.402 1935.97L121.007 1977.64L122.946 1977.15ZM111.618 1936.69L115.495 1935.7L115.001 1933.77L111.125 1934.75L111.618 1936.69ZM114.279 1934.98L124.884 1976.65L126.822 1976.16L116.217 1934.49L114.279 1934.98ZM125.606 1975.44L121.73 1976.42L122.223 1978.36L126.1 1977.38L125.606 1975.44Z'
        fill='#8BB6DB'
        mask='url(#path-796-inside-82_6651_98482)'
      />
      <mask id='path-798-inside-83_6651_98482' fill='white'>
        <path d='M134.289 2030.95L121.278 1987.87L130.851 1984.98L143.862 2028.06L134.289 2030.95Z' />
      </mask>
      <path d='M134.289 2030.95L121.278 1987.87L130.851 1984.98L143.862 2028.06L134.289 2030.95Z' fill='#ECF5FC' />
      <path
        d='M135.246 2030.66L122.236 1987.58L120.321 1988.16L133.332 2031.24L135.246 2030.66ZM129.894 1985.27L142.905 2028.35L144.819 2027.77L131.808 1984.69L129.894 1985.27Z'
        fill='#CFD1D2'
        mask='url(#path-798-inside-83_6651_98482)'
      />
      <mask id='path-800-inside-84_6651_98482' fill='white'>
        <path d='M137.164 2030.08L124.153 1987L127.982 1985.85L140.993 2028.93L137.164 2030.08Z' />
      </mask>
      <path d='M137.164 2030.08L124.153 1987L127.982 1985.85L140.993 2028.93L137.164 2030.08Z' fill='#AECCE7' />
      <path
        d='M137.164 2030.08L136.207 2030.37L136.496 2031.33L137.453 2031.04L137.164 2030.08ZM124.153 1987L123.864 1986.05L122.907 1986.34L123.196 1987.29L124.153 1987ZM127.982 1985.85L128.94 1985.56L128.651 1984.6L127.693 1984.89L127.982 1985.85ZM140.993 2028.93L141.282 2029.88L142.24 2029.59L141.951 2028.64L140.993 2028.93ZM138.121 2029.79L125.111 1986.71L123.196 1987.29L136.207 2030.37L138.121 2029.79ZM124.442 1987.96L128.272 1986.8L127.693 1984.89L123.864 1986.05L124.442 1987.96ZM127.025 1986.14L140.036 2029.21L141.951 2028.64L128.94 1985.56L127.025 1986.14ZM140.704 2027.97L136.875 2029.12L137.453 2031.04L141.282 2029.88L140.704 2027.97Z'
        fill='#8BB6DB'
        mask='url(#path-800-inside-84_6651_98482)'
      />
      <mask id='path-802-inside-85_6651_98482' fill='white'>
        <path d='M149.617 2083.55L137.146 2040.16L146.757 2037.4L159.228 2080.79L149.617 2083.55Z' />
      </mask>
      <path d='M149.617 2083.55L137.146 2040.16L146.757 2037.4L159.228 2080.79L149.617 2083.55Z' fill='#ECF5FC' />
      <path
        d='M150.578 2083.27L138.107 2039.89L136.185 2040.44L148.656 2083.83L150.578 2083.27ZM145.796 2037.68L158.267 2081.06L160.189 2080.51L147.718 2037.13L145.796 2037.68Z'
        fill='#CFD1D2'
        mask='url(#path-802-inside-85_6651_98482)'
      />
      <mask id='path-804-inside-86_6651_98482' fill='white'>
        <path d='M152.5 2082.72L140.068 2039.47L143.913 2038.37L156.344 2081.62L152.5 2082.72Z' />
      </mask>
      <path d='M152.5 2082.72L140.068 2039.47L143.913 2038.37L156.344 2081.62L152.5 2082.72Z' fill='#AECCE7' />
      <path
        d='M152.5 2082.72L151.539 2083L151.815 2083.96L152.776 2083.68L152.5 2082.72ZM140.068 2039.47L139.792 2038.51L138.831 2038.79L139.107 2039.75L140.068 2039.47ZM143.913 2038.37L144.874 2038.09L144.598 2037.13L143.636 2037.41L143.913 2038.37ZM156.344 2081.62L156.621 2082.58L157.582 2082.3L157.305 2081.34L156.344 2081.62ZM153.461 2082.45L141.029 2039.2L139.107 2039.75L151.539 2083L153.461 2082.45ZM140.345 2040.43L144.189 2039.33L143.636 2037.41L139.792 2038.51L140.345 2040.43ZM142.952 2038.64L155.383 2081.89L157.305 2081.34L144.874 2038.09L142.952 2038.64ZM156.068 2080.66L152.224 2081.76L152.776 2083.68L156.621 2082.58L156.068 2080.66Z'
        fill='#8BB6DB'
        mask='url(#path-804-inside-86_6651_98482)'
      />
      <mask id='path-806-inside-87_6651_98482' fill='white'>
        <path d='M166.242 2135.88L152.317 2092.38L161.841 2089.33L175.766 2132.83L166.242 2135.88Z' />
      </mask>
      <path d='M166.242 2135.88L152.317 2092.38L161.841 2089.33L175.766 2132.83L166.242 2135.88Z' fill='#ECF5FC' />
      <path
        d='M167.195 2135.57L153.269 2092.07L151.364 2092.68L165.29 2136.18L167.195 2135.57ZM160.888 2089.63L174.814 2133.14L176.719 2132.53L162.793 2089.02L160.888 2089.63Z'
        fill='#CFD1D2'
        mask='url(#path-806-inside-87_6651_98482)'
      />
      <mask id='path-808-inside-88_6651_98482' fill='white'>
        <path d='M169.102 2134.96L155.078 2091.15L158.887 2089.93L172.911 2133.74L169.102 2134.96Z' />
      </mask>
      <path d='M169.102 2134.96L155.078 2091.15L158.887 2089.93L172.911 2133.74L169.102 2134.96Z' fill='#AECCE7' />
      <path
        d='M169.102 2134.96L168.149 2135.27L168.454 2136.22L169.406 2135.92L169.102 2134.96ZM155.078 2091.15L154.773 2090.2L153.821 2090.51L154.125 2091.46L155.078 2091.15ZM158.887 2089.93L159.84 2089.63L159.535 2088.68L158.583 2088.98L158.887 2089.93ZM172.911 2133.74L173.216 2134.7L174.168 2134.39L173.864 2133.44L172.911 2133.74ZM170.054 2134.66L156.03 2090.85L154.125 2091.46L168.149 2135.27L170.054 2134.66ZM155.383 2092.11L159.192 2090.89L158.583 2088.98L154.773 2090.2L155.383 2092.11ZM157.935 2090.24L171.959 2134.05L173.864 2133.44L159.84 2089.63L157.935 2090.24ZM172.606 2132.79L168.797 2134.01L169.406 2135.92L173.216 2134.7L172.606 2132.79Z'
        fill='#8BB6DB'
        mask='url(#path-808-inside-88_6651_98482)'
      />
      <mask id='path-810-inside-89_6651_98482' fill='white'>
        <path d='M105.875 1926.38L95.2199 1882.39L104.939 1880.04L115.594 1924.02L105.875 1926.38Z' />
      </mask>
    </>
  );
};
