import React, { useMemo } from 'react';

export const FloorWorkplaceRound = ({
  name,
  onClick,
  onTouchStart,
  workplaceStatus,
  serverData,
  tabIndex,
  textRotate,
  active = false,
  disabled = false,
  showName = false,
  position: { x, y, angle },
  size = { width: 40, height: 40 }
}) => {
  const isDisabled = useMemo(() => (disabled ? 'workplace-template-disabled' : ''), [disabled]);
  const isActive = useMemo(() => (active ? 'workplace-template-active' : ''), [active]);
  const isRotate = useMemo(() => (textRotate ? '' : 'workplace-text-rotate'), [textRotate]);
  return (
    <svg
      x={x}
      y={y}
      data-name={name}
      onClick={onClick}
      onTouchStart={onClick}
      width={size.width}
      height={size.height}
      viewBox='0 0 40 40'
      id={`workplace-${name}`}
      className='workplace-box'
    >
      <g
        tabIndex={tabIndex}
        className={`workplace-template-workplace ${isActive} ${isDisabled}`}
        style={{
          transform: `rotate(${angle}deg)`
        }}
      >
        <mask id='path-1-inside-1_6635_95571' fill='white'>
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M29.7458 26.6797C54.7928 26.6797 75.0974 46.9843 75.0974 72.0312C75.0974 82.6796 71.4276 92.4707 65.2837 100.209L50.6258 85.9554C53.2879 81.9713 54.8401 77.1825 54.8401 72.0312C54.8401 58.1721 43.605 46.937 29.7458 46.937C24.343 46.937 19.3389 48.6445 15.2438 51.5493L0.585938 37.2957C8.46987 30.6703 18.6418 26.6797 29.7458 26.6797Z'
          />
        </mask>
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M29.7458 26.6797C54.7928 26.6797 75.0974 46.9843 75.0974 72.0312C75.0974 82.6796 71.4276 92.4707 65.2837 100.209L50.6258 85.9554C53.2879 81.9713 54.8401 77.1825 54.8401 72.0312C54.8401 58.1721 43.605 46.937 29.7458 46.937C24.343 46.937 19.3389 48.6445 15.2438 51.5493L0.585938 37.2957C8.46987 30.6703 18.6418 26.6797 29.7458 26.6797Z'
          fill='#707479'
        />
        <path
          d='M65.2837 100.209L64.5866 100.926L65.3793 101.697L66.0669 100.831L65.2837 100.209ZM50.6258 85.9554L49.7943 85.3998L49.3318 86.092L49.9286 86.6723L50.6258 85.9554ZM15.2438 51.5493L14.5466 52.2662L15.1434 52.8465L15.8223 52.3649L15.2438 51.5493ZM0.585938 37.2957L-0.057417 36.5301L-0.904075 37.2416L-0.111213 38.0126L0.585938 37.2957ZM76.0974 72.0312C76.0974 46.432 55.3451 25.6797 29.7458 25.6797V27.6797C54.2405 27.6797 74.0974 47.5366 74.0974 72.0312H76.0974ZM66.0669 100.831C72.3461 92.9221 76.0974 82.9134 76.0974 72.0312H74.0974C74.0974 82.4458 70.5091 92.0194 64.5005 99.5872L66.0669 100.831ZM49.9286 86.6723L64.5866 100.926L65.9809 99.4921L51.3229 85.2385L49.9286 86.6723ZM53.8401 72.0312C53.8401 76.9787 52.35 81.5751 49.7943 85.3998L51.4573 86.511C54.2259 82.3676 55.8401 77.3864 55.8401 72.0312H53.8401ZM29.7458 47.937C43.0527 47.937 53.8401 58.7244 53.8401 72.0312H55.8401C55.8401 57.6198 44.1573 45.937 29.7458 45.937V47.937ZM15.8223 52.3649C19.7539 49.5761 24.557 47.937 29.7458 47.937V45.937C24.1289 45.937 18.9238 47.7129 14.6652 50.7336L15.8223 52.3649ZM-0.111213 38.0126L14.5466 52.2662L15.9409 50.8323L1.28309 36.5788L-0.111213 38.0126ZM29.7458 25.6797C18.3978 25.6797 7.99996 29.759 -0.057417 36.5301L1.22929 38.0613C8.93978 31.5817 18.8858 27.6797 29.7458 27.6797V25.6797Z'
          fill='#40464D'
          mask='url(#path-1-inside-1_6635_95571)'
        />
        <path
          d='M63.9467 25.129C64.5249 24.5357 65.4745 24.5234 66.0678 25.1015C66.6612 25.6797 66.6735 26.6294 66.0953 27.2227L61.908 31.5199C61.3298 32.1132 60.3802 32.1255 59.7868 31.5474C59.1935 30.9692 59.1812 30.0196 59.7594 29.4263L63.9467 25.129ZM73.9736 34.8995C74.5517 34.3061 75.5014 34.2938 76.0947 34.872C76.6881 35.4501 76.7004 36.3998 76.1222 36.9931L71.9349 41.2904C71.3567 41.8837 70.4071 41.896 69.8137 41.3178C69.2204 40.7397 69.2081 39.79 69.7863 39.1967L73.9736 34.8995Z'
          fill='#707479'
          stroke='#40464D'
        />
        <path
          d='M69.5717 22.2267C70.1499 21.6333 71.0995 21.621 71.6928 22.1992L78.8549 29.1781C79.4482 29.7562 79.4605 30.7059 78.8824 31.2992C78.3042 31.8925 77.3546 31.9048 76.7612 31.3267L69.5992 24.3478C69.0059 23.7697 68.9936 22.82 69.5717 22.2267Z'
          fill='#707479'
          stroke='#40464D'
        />
        <path
          d='M69.5717 22.2267C70.1499 21.6333 71.0995 21.621 71.6928 22.1992L78.8549 29.1781C79.4482 29.7562 79.4605 30.7059 78.8824 31.2992L78.1845 32.0154C76.8355 33.3999 74.6196 33.4286 73.2352 32.0795L68.9379 27.8922C67.5535 26.5432 67.5248 24.3273 68.8738 22.9429L69.5717 22.2267Z'
          fill='#707479'
          stroke='#40464D'
        />
        <rect
          x='66.431'
          y='25.4492'
          width='13'
          height='11'
          rx='3.5'
          transform='rotate(44.2578 66.431 25.4492)'
          fill='#707479'
          stroke='#40464D'
        />
        <path
          d='M87.8973 64.8452C88.7244 64.891 89.3578 65.5988 89.3119 66.4259C89.2661 67.2531 88.5583 67.8864 87.7312 67.8406L81.7404 67.5084C80.9132 67.4626 80.2799 66.7549 80.3257 65.9277C80.3716 65.1005 81.0793 64.4672 81.9065 64.513L87.8973 64.8452ZM87.1222 78.8237C87.9494 78.8696 88.5828 79.5773 88.5369 80.4045C88.4911 81.2316 87.7833 81.865 86.9562 81.8191L80.9654 81.487C80.1382 81.4411 79.5049 80.7334 79.5507 79.9062C79.5966 79.0791 80.3043 78.4457 81.1314 78.4916L87.1222 78.8237Z'
          fill='#707479'
          stroke='#40464D'
        />
        <path
          d='M93.7801 67.1736C94.6072 67.2194 95.2406 67.9271 95.1947 68.7543L94.6412 78.739C94.5953 79.5661 93.8876 80.1995 93.0604 80.1536C92.2333 80.1078 91.5999 79.4 91.6458 78.5729L92.1993 68.5882C92.2452 67.7611 92.9529 67.1277 93.7801 67.1736Z'
          fill='#707479'
          stroke='#40464D'
        />
        <path
          d='M93.7801 67.1736C94.6072 67.2194 95.2406 67.9271 95.1947 68.7543L94.6412 78.739C94.5953 79.5661 93.8876 80.1995 93.0604 80.1536L92.062 80.0983C90.1319 79.9913 88.6541 78.3399 88.7611 76.4099L89.0932 70.4191C89.2002 68.489 90.8516 67.0112 92.7816 67.1182L93.7801 67.1736Z'
          fill='#707479'
          stroke='#40464D'
        />
        <rect
          x='89.2856'
          y='66.9242'
          width='13'
          height='11'
          rx='3.5'
          transform='rotate(93.1734 89.2856 66.9242)'
          fill='#707479'
          stroke='#40464D'
        />
        <path
          d='M13.7644 16.1527C13.653 15.3318 14.2282 14.5761 15.0491 14.4647C15.87 14.3532 16.6258 14.9284 16.7372 15.7493L17.5441 21.6948C17.6555 22.5157 17.0803 23.2715 16.2594 23.3829C15.4385 23.4943 14.6827 22.9191 14.5713 22.0982L13.7644 16.1527ZM27.6372 14.27C27.5258 13.4491 28.101 12.6933 28.9219 12.5819C29.7428 12.4705 30.4986 13.0456 30.61 13.8665L31.4169 19.812C31.5283 20.6329 30.9531 21.3887 30.1322 21.5001C29.3113 21.6116 28.5556 21.0364 28.4441 20.2155L27.6372 14.27Z'
          fill='#707479'
          stroke='#40464D'
        />
        <path
          d='M14.9363 9.93791C14.8249 9.117 15.4 8.36122 16.2209 8.24981L26.1301 6.90498C26.951 6.79358 27.7068 7.36873 27.8182 8.18963C27.9296 9.01054 27.3545 9.76632 26.5336 9.87773L16.6244 11.2226C15.8035 11.334 15.0477 10.7588 14.9363 9.93791Z'
          fill='#707479'
          stroke='#40464D'
        />
        <path
          d='M14.9363 9.93791C14.8249 9.117 15.4 8.36122 16.2209 8.24981L26.1301 6.90498C26.951 6.79358 27.7068 7.36873 27.8182 8.18963L27.9527 9.18055C28.2126 11.096 26.8706 12.8595 24.9552 13.1194L19.0097 13.9263C17.0942 14.1863 15.3307 12.8443 15.0708 10.9288L14.9363 9.93791Z'
          fill='#707479'
          stroke='#40464D'
        />
        <rect
          x='15.5393'
          y='14.3968'
          width='13'
          height='11'
          rx='3.5'
          transform='rotate(-7.72869 15.5393 14.3968)'
          fill='#707479'
          stroke='#40464D'
        />
        {showName && (
          <g className={`workplace-text ${isRotate}`}>
            <text x='6' y='34'>
              {name}
            </text>
          </g>
        )}
      </g>
    </svg>
  );
};
