import React, { memo } from 'react';

const SVGComponents = () => {
  return (
    <svg width='2209' height='2138' viewBox='0 0 2209 2138' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M0.8125 1076.77C0.8125 494.265 157.57 0.795898 157.57 0.795898C157.57 0.795898 656.473 49.1774 1294.11 353.065C1777.85 583.612 2208.67 1059.14 2208.67 1059.14C2208.67 1059.14 1814.46 1510.21 1286.76 1783.32C737.96 2048.71 165.792 2137.01 165.792 2137.01C165.792 2137.01 10.9126 1685.3 0.8125 1076.77Z'
        fill='white'
      />
    </svg>
  );
};

export const CityFloor = memo(SVGComponents);
