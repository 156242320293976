import React from 'react';
import KomsSecondFloor from './floorPlans/komsomolskaya_floor_2_plan';
import KomsThirdFloor from './floorPlans/komsomolskaya_floor_3_plan';
import KomsFifthFloor from './floorPlans/komsomolskaya_floor_4_plan';
import FloorPlan from './FloorNewPlans/index';
import { Placeslist } from './FloorNewPlans/types';

const WorkplaceFloorPlan = props => {
  const { currentSubLocation, setSelectBookingPlace, rooms, zoom, minusZoom, plusZoom, filtersActive } = props;

  switch (currentSubLocation.id) {
    case 1: // 1 этаж блок 1 neo - geo
      return (
        <FloorPlan
          officeId={2}
          filtersActive={filtersActive}
          workplaces={rooms}
          key={currentSubLocation.id}
          openPriceCard={setSelectBookingPlace}
          selectedPlace={Placeslist.NEO_GEO_1_A}
        />
      );
    case 3: // 2 этаж блок А neo - geo
      return (
        <FloorPlan
          officeId={2}
          filtersActive={filtersActive}
          workplaces={rooms}
          key={currentSubLocation.id}
          openPriceCard={setSelectBookingPlace}
          selectedPlace={Placeslist.NEO_GEO_2_A}
        />
      );

    case 2: // блок С  neo - geo
      return (
        <FloorPlan
          officeId={2}
          filtersActive={filtersActive}
          workplaces={rooms}
          key={currentSubLocation.id}
          openPriceCard={setSelectBookingPlace}
          selectedPlace={Placeslist.NEO_GEO_1_C}
        />
      );
    case 4: // сити
      return (
        <FloorPlan
          officeId={1}
          filtersActive={filtersActive}
          workplaces={rooms}
          key={currentSubLocation.id}
          openPriceCard={setSelectBookingPlace}
          selectedPlace={Placeslist.CITY}
        />
      );
    case 5:
      return (
        <FloorPlan
          officeId={3}
          workplaces={rooms}
          key={currentSubLocation.id}
          openPriceCard={setSelectBookingPlace}
          selectedPlace={Placeslist.KOMSOMOLSKAYA}
        />
      );
    case 6: // koms 2 floor
      return (
        <KomsSecondFloor
          setSelectBookingPlace={setSelectBookingPlace}
          workplaces={rooms}
          zoom={zoom}
          plusZoom={plusZoom}
          minusZoom={minusZoom}
        />
      );
    case 7: // koms 3 floor
      return (
        <KomsThirdFloor
          setSelectBookingPlace={setSelectBookingPlace}
          workplaces={rooms}
          zoom={zoom}
          plusZoom={plusZoom}
          minusZoom={minusZoom}
        />
      );
    case 8: // koms 4 floor
      return (
        <KomsFifthFloor
          setSelectBookingPlace={setSelectBookingPlace}
          workplaces={rooms}
          zoom={zoom}
          plusZoom={plusZoom}
          minusZoom={minusZoom}
        />
      );
    case 9: // park 4 floor
      return (
        <FloorPlan
          officeId={4}
          filtersActive={filtersActive}
          workplaces={rooms}
          key={currentSubLocation.id}
          openPriceCard={setSelectBookingPlace}
          selectedPlace={Placeslist.PARK_KULTURY_4}
        />
      );
    case 10: // park 5 floor
      return (
        <FloorPlan
          officeId={4}
          filtersActive={filtersActive}
          workplaces={rooms}
          key={currentSubLocation.id}
          openPriceCard={setSelectBookingPlace}
          selectedPlace={Placeslist.PARK_KULTURY_5}
        />
      );
    case 11: // park 6 floor
      return (
        <FloorPlan
          officeId={4}
          filtersActive={filtersActive}
          workplaces={rooms}
          key={currentSubLocation.id}
          openPriceCard={setSelectBookingPlace}
          selectedPlace={Placeslist.PARK_KULTURY_6}
        />
      );
    case 13: // koms-fok -1 floor
      return (
        <FloorPlan
          officeId={5}
          workplaces={rooms}
          key={currentSubLocation.id}
          openPriceCard={setSelectBookingPlace}
          selectedPlace={Placeslist.KOMSOMOLSKAYA_FOK_0}
        />
      );
    case 14: // koms-fok 1 floor
      return (
        <FloorPlan
          officeId={5}
          workplaces={rooms}
          key={currentSubLocation.id}
          openPriceCard={setSelectBookingPlace}
          selectedPlace={Placeslist.KOMSOMOLSKAYA_FOK_1}
        />
      );
    case 15: // koms-fok 2 floor
      return (
        <FloorPlan
          officeId={5}
          workplaces={rooms}
          key={currentSubLocation.id}
          openPriceCard={setSelectBookingPlace}
          selectedPlace={Placeslist.KOMSOMOLSKAYA_FOK_2}
        />
      );
    case 21: //tulskaya 6
      return (
        <FloorPlan
          officeId={7}
          filtersActive={filtersActive}
          workplaces={rooms}
          key={currentSubLocation.id}
          openPriceCard={setSelectBookingPlace}
          selectedPlace={Placeslist.TULSKAYA_6}
        />
      );
    case 20: // tulskaya 5
      return (
        <FloorPlan
          officeId={7}
          filtersActive={filtersActive}
          workplaces={rooms}
          key={currentSubLocation.id}
          openPriceCard={setSelectBookingPlace}
          selectedPlace={Placeslist.TULSKAYA_5}
        />
      );
    default:
      return null;
  }
};

export default WorkplaceFloorPlan;
