import dayjs from 'dayjs';

export const stringAvatar = name => {
  // формирование аватара из инициалов (mui func)
  const initialsArr = name.split(' ');
  return {
    children: initialsArr.length === 1 ? `${initialsArr[0][0]}` : `${initialsArr[0][0]}${initialsArr[1][0]}`
  };
};

/** Проверяет является ли дата для рабочего места/кабинета забронированной
@param date - проверяемая дата, должна быть конвертируемой в dayjs
@param objects - массив броней (серверные данные)
@param id - айди рабочего места
@returns boolean - True - дата забронирована, False - дата свободна
 */
export function isDateBusy(date, objects, id) {
  // Проверяем каждый объект в массиве
  for (const obj of objects) {
    // Проверяем, что isFree равно false
    if (obj.service_id === id) {
      const targetDate = dayjs(date).startOf('day'); // Устанавливаем время на начало дня
      const startDate = dayjs(obj.start_at).startOf('day'); // Устанавливаем время на начало дня
      const expireDate = dayjs(obj.expire_at).startOf('day'); // Устанавливаем время на начало дня

      if (targetDate.isBetween(startDate, expireDate, null, '[]')) {
        return true; // Дата занята
      }
    }
  }
  return false; // Дата свободна
}
