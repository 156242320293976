import React from 'react';

export const CityPatch3 = () => {
  return (
    <>
      <path d='M105.875 1926.38L95.2199 1882.39L104.939 1880.04L115.594 1924.02L105.875 1926.38Z' fill='#ECF5FC' />
      <path
        d='M106.847 1926.14L96.1918 1882.16L94.248 1882.63L104.903 1926.61L106.847 1926.14ZM103.967 1880.28L114.622 1924.26L116.566 1923.78L105.911 1879.8L103.967 1880.28Z'
        fill='#CFD1D2'
        mask='url(#path-810-inside-89_6651_98482)'
      />
      <mask id='path-812-inside-90_6651_98482' fill='white'>
        <path d='M108.789 1925.67L98.1935 1881.93L102.081 1880.99L112.677 1924.73L108.789 1925.67Z' />
      </mask>
      <path d='M108.789 1925.67L98.1935 1881.93L102.081 1880.99L112.677 1924.73L108.789 1925.67Z' fill='#AECCE7' />
      <path
        d='M108.789 1925.67L107.817 1925.9L108.053 1926.88L109.025 1926.64L108.789 1925.67ZM98.1935 1881.93L97.9581 1880.96L96.9862 1881.2L97.2217 1882.17L98.1935 1881.93ZM102.081 1880.99L103.053 1880.76L102.818 1879.78L101.846 1880.02L102.081 1880.99ZM112.677 1924.73L112.912 1925.7L113.884 1925.46L113.648 1924.49L112.677 1924.73ZM109.761 1925.43L99.1654 1881.7L97.2217 1882.17L107.817 1925.9L109.761 1925.43ZM98.429 1882.91L102.317 1881.96L101.846 1880.02L97.9581 1880.96L98.429 1882.91ZM101.109 1881.23L111.705 1924.96L113.648 1924.49L103.053 1880.76L101.109 1881.23ZM112.441 1923.75L108.554 1924.7L109.025 1926.64L112.912 1925.7L112.441 1923.75Z'
        fill='#8BB6DB'
        mask='url(#path-812-inside-90_6651_98482)'
      />
      <mask id='path-814-inside-91_6651_98482' fill='white'>
        <path d='M92.9297 1872.59L82.5139 1828.16L92.2499 1825.88L102.666 1870.31L92.9297 1872.59Z' />
      </mask>
      <path d='M92.9297 1872.59L82.5139 1828.16L92.2499 1825.88L102.666 1870.31L92.9297 1872.59Z' fill='#ECF5FC' />
      <path
        d='M93.9033 1872.36L83.4875 1827.93L81.5403 1828.39L91.9561 1872.82L93.9033 1872.36ZM91.2763 1826.11L101.692 1870.54L103.639 1870.08L93.2235 1825.65L91.2763 1826.11Z'
        fill='#CFD1D2'
        mask='url(#path-814-inside-91_6651_98482)'
      />
      <mask id='path-816-inside-92_6651_98482' fill='white'>
        <path d='M95.8516 1871.91L85.3517 1827.12L89.2461 1826.21L99.746 1870.99L95.8516 1871.91Z' />
      </mask>
      <path d='M95.8516 1871.91L85.3517 1827.12L89.2461 1826.21L99.746 1870.99L95.8516 1871.91Z' fill='#AECCE7' />
      <path
        d='M95.8516 1871.91L94.878 1872.13L95.1062 1873.11L96.0798 1872.88L95.8516 1871.91ZM85.3517 1827.12L85.1234 1826.15L84.1498 1826.37L84.3781 1827.35L85.3517 1827.12ZM89.2461 1826.21L90.2197 1825.98L89.9914 1825L89.0178 1825.23L89.2461 1826.21ZM99.746 1870.99L99.9742 1871.97L100.948 1871.74L100.72 1870.76L99.746 1870.99ZM96.8252 1871.68L86.3253 1826.89L84.3781 1827.35L94.878 1872.13L96.8252 1871.68ZM85.5799 1828.09L89.4743 1827.18L89.0178 1825.23L85.1234 1826.15L85.5799 1828.09ZM88.2725 1826.43L98.7724 1871.22L100.72 1870.76L90.2197 1825.98L88.2725 1826.43ZM99.5177 1870.02L95.6233 1870.93L96.0798 1872.88L99.9742 1871.97L99.5177 1870.02Z'
        fill='#8BB6DB'
        mask='url(#path-816-inside-92_6651_98482)'
      />
      <mask id='path-818-inside-93_6651_98482' fill='white'>
        <path d='M80.5547 1818.17L70.8184 1771.02L80.6118 1769L90.3481 1816.15L80.5547 1818.17Z' />
      </mask>
      <path d='M80.5547 1818.17L70.8184 1771.02L80.6118 1769L90.3481 1816.15L80.5547 1818.17Z' fill='#ECF5FC' />
      <path
        d='M81.534 1817.97L71.7977 1770.82L69.839 1771.22L79.5753 1818.37L81.534 1817.97ZM79.6324 1769.2L89.3687 1816.35L91.3274 1815.95L81.5911 1768.8L79.6324 1769.2Z'
        fill='#CFD1D2'
        mask='url(#path-818-inside-93_6651_98482)'
      />
      <mask id='path-820-inside-94_6651_98482' fill='white'>
        <path d='M83.4922 1817.57L73.7855 1770.56L77.7029 1769.75L87.4095 1816.76L83.4922 1817.57Z' />
      </mask>
      <path d='M83.4922 1817.57L73.7855 1770.56L77.7029 1769.75L87.4095 1816.76L83.4922 1817.57Z' fill='#AECCE7' />
      <path
        d='M83.4922 1817.57L82.5128 1817.77L82.7151 1818.75L83.6944 1818.54L83.4922 1817.57ZM73.7855 1770.56L73.5833 1769.58L72.604 1769.78L72.8062 1770.76L73.7855 1770.56ZM77.7029 1769.75L78.6822 1769.55L78.48 1768.57L77.5007 1768.77L77.7029 1769.75ZM87.4095 1816.76L87.6118 1817.74L88.5911 1817.53L88.3889 1816.55L87.4095 1816.76ZM84.4715 1817.36L74.7649 1770.35L72.8062 1770.76L82.5128 1817.77L84.4715 1817.36ZM73.9878 1771.54L77.9051 1770.73L77.5007 1768.77L73.5833 1769.58L73.9878 1771.54ZM76.7236 1769.95L86.4302 1816.96L88.3889 1816.55L78.6822 1769.55L76.7236 1769.95ZM87.2073 1815.78L83.29 1816.59L83.6944 1818.54L87.6118 1817.74L87.2073 1815.78Z'
        fill='#8BB6DB'
        mask='url(#path-820-inside-94_6651_98482)'
      />
      <mask id='path-822-inside-95_6651_98482' fill='white'>
        <path d='M70.0859 1767.75L61.646 1724.82L71.4582 1722.9L79.8981 1765.82L70.0859 1767.75Z' />
      </mask>
      <path d='M70.0859 1767.75L61.646 1724.82L71.4582 1722.9L79.8981 1765.82L70.0859 1767.75Z' fill='#ECF5FC' />
      <path
        d='M71.0672 1767.56L62.6272 1724.63L60.6648 1725.02L69.1047 1767.95L71.0672 1767.56ZM70.477 1723.09L78.9169 1766.02L80.8793 1765.63L72.4394 1722.7L70.477 1723.09Z'
        fill='#CFD1D2'
        mask='url(#path-822-inside-95_6651_98482)'
      />
      <mask id='path-824-inside-96_6651_98482' fill='white'>
        <path d='M73.0312 1767.18L64.5433 1724L68.4682 1723.23L76.9561 1766.4L73.0312 1767.18Z' />
      </mask>
      <path d='M73.0312 1767.18L64.5433 1724L68.4682 1723.23L76.9561 1766.4L73.0312 1767.18Z' fill='#AECCE7' />
      <path
        d='M73.0312 1767.18L72.05 1767.37L72.2429 1768.35L73.2242 1768.16L73.0312 1767.18ZM64.5433 1724L64.3504 1723.02L63.3692 1723.21L63.5621 1724.19L64.5433 1724ZM68.4682 1723.23L69.4494 1723.04L69.2565 1722.06L68.2753 1722.25L68.4682 1723.23ZM76.9561 1766.4L77.149 1767.38L78.1302 1767.19L77.9373 1766.21L76.9561 1766.4ZM74.0125 1766.98L65.5245 1723.81L63.5621 1724.19L72.05 1767.37L74.0125 1766.98ZM64.7362 1724.98L68.6611 1724.21L68.2753 1722.25L64.3504 1723.02L64.7362 1724.98ZM67.487 1723.42L75.9749 1766.6L77.9373 1766.21L69.4494 1723.04L67.487 1723.42ZM76.7632 1765.42L72.8383 1766.19L73.2242 1768.16L77.149 1767.38L76.7632 1765.42Z'
        fill='#8BB6DB'
        mask='url(#path-824-inside-96_6651_98482)'
      />
      <mask id='path-826-inside-97_6651_98482' fill='white'>
        <path d='M59.9297 1715.29L53.3575 1680.33L63.1854 1678.48L69.7576 1713.45L59.9297 1715.29Z' />
      </mask>
      <path d='M59.9297 1715.29L53.3575 1680.33L63.1854 1678.48L69.7576 1713.45L59.9297 1715.29Z' fill='#ECF5FC' />
      <path
        d='M60.9125 1715.11L54.3403 1680.14L52.3747 1680.51L58.9469 1715.48L60.9125 1715.11ZM62.2026 1678.66L68.7748 1713.63L70.7404 1713.26L64.1682 1678.29L62.2026 1678.66Z'
        fill='#CFD1D2'
        mask='url(#path-826-inside-97_6651_98482)'
      />
      <mask id='path-828-inside-98_6651_98482' fill='white'>
        <path d='M62.875 1714.74L56.2251 1679.36L60.1563 1678.62L66.8062 1714L62.875 1714.74Z' />
      </mask>
      <path d='M62.875 1714.74L56.2251 1679.36L60.1563 1678.62L66.8062 1714L62.875 1714.74Z' fill='#AECCE7' />
      <path
        d='M62.875 1714.74L61.8922 1714.92L62.0769 1715.91L63.0597 1715.72L62.875 1714.74ZM56.2251 1679.36L56.0404 1678.38L55.0576 1678.56L55.2424 1679.54L56.2251 1679.36ZM60.1563 1678.62L61.1391 1678.43L60.9544 1677.45L59.9716 1677.64L60.1563 1678.62ZM66.8062 1714L66.9909 1714.98L67.9737 1714.8L67.789 1713.82L66.8062 1714ZM63.8578 1714.55L57.2079 1679.17L55.2424 1679.54L61.8922 1714.92L63.8578 1714.55ZM56.4099 1680.34L60.341 1679.6L59.9716 1677.64L56.0404 1678.38L56.4099 1680.34ZM59.1735 1678.8L65.8234 1714.18L67.789 1713.82L61.1391 1678.43L59.1735 1678.8ZM66.6214 1713.02L62.6903 1713.76L63.0597 1715.72L66.9909 1714.98L66.6214 1713.02Z'
        fill='#8BB6DB'
        mask='url(#path-828-inside-98_6651_98482)'
      />
      <mask id='path-830-inside-99_6651_98482' fill='white'>
        <path d='M51.6562 1670.85L44.1972 1627.15L54.0546 1625.47L61.5137 1669.16L51.6562 1670.85Z' />
      </mask>
      <path d='M51.6562 1670.85L44.1972 1627.15L54.0546 1625.47L61.5137 1669.16L51.6562 1670.85Z' fill='#ECF5FC' />
      <path
        d='M52.642 1670.68L45.183 1626.98L43.2115 1627.32L50.6705 1671.02L52.642 1670.68ZM53.0689 1625.64L60.5279 1669.33L62.4994 1669L55.0404 1625.3L53.0689 1625.64Z'
        fill='#CFD1D2'
        mask='url(#path-830-inside-99_6651_98482)'
      />
      <mask id='path-832-inside-100_6651_98482' fill='white'>
        <path d='M54.6172 1670.34L47.2131 1626.97L51.1561 1626.3L58.5601 1669.67L54.6172 1670.34Z' />
      </mask>
      <path d='M54.6172 1670.34L47.2131 1626.97L51.1561 1626.3L58.5601 1669.67L54.6172 1670.34Z' fill='#AECCE7' />
      <path
        d='M54.6172 1670.34L53.6314 1670.51L53.7997 1671.5L54.7855 1671.33L54.6172 1670.34ZM47.2131 1626.97L47.0448 1625.98L46.0591 1626.15L46.2274 1627.14L47.2131 1626.97ZM51.1561 1626.3L52.1418 1626.13L51.9735 1625.14L50.9878 1625.31L51.1561 1626.3ZM58.5601 1669.67L58.7284 1670.66L59.7142 1670.49L59.5459 1669.5L58.5601 1669.67ZM55.6029 1670.17L48.1989 1626.8L46.2274 1627.14L53.6314 1670.51L55.6029 1670.17ZM47.3814 1627.96L51.3244 1627.28L50.9878 1625.31L47.0448 1625.98L47.3814 1627.96ZM50.1703 1626.47L57.5744 1669.84L59.5459 1669.5L52.1418 1626.13L50.1703 1626.47ZM58.3919 1668.68L54.4489 1669.36L54.7855 1671.33L58.7284 1670.66L58.3919 1668.68Z'
        fill='#8BB6DB'
        mask='url(#path-832-inside-100_6651_98482)'
      />
      <mask id='path-834-inside-101_6651_98482' fill='white'>
        <path d='M42.875 1617.77L35.8 1573.09L45.6769 1571.53L52.7519 1616.21L42.875 1617.77Z' />
      </mask>
      <path d='M42.875 1617.77L35.8 1573.09L45.6769 1571.53L52.7519 1616.21L42.875 1617.77Z' fill='#ECF5FC' />
      <path
        d='M43.8627 1617.61L36.7877 1572.94L34.8123 1573.25L41.8873 1617.93L43.8627 1617.61ZM44.6892 1571.68L51.7642 1616.36L53.7396 1616.05L46.6646 1571.37L44.6892 1571.68Z'
        fill='#CFD1D2'
        mask='url(#path-834-inside-101_6651_98482)'
      />
      <mask id='path-836-inside-102_6651_98482' fill='white'>
        <path d='M45.8359 1617.3L38.7976 1572.85L42.7483 1572.23L49.7867 1616.67L45.8359 1617.3Z' />
      </mask>
      <path d='M45.8359 1617.3L38.7976 1572.85L42.7483 1572.23L49.7867 1616.67L45.8359 1617.3Z' fill='#AECCE7' />
      <path
        d='M45.8359 1617.3L44.8482 1617.46L45.0047 1618.44L45.9923 1618.29L45.8359 1617.3ZM38.7976 1572.85L38.6412 1571.87L37.6535 1572.02L37.8099 1573.01L38.7976 1572.85ZM42.7483 1572.23L43.736 1572.07L43.5796 1571.08L42.5919 1571.24L42.7483 1572.23ZM49.7867 1616.67L49.9431 1617.66L50.9308 1617.51L50.7744 1616.52L49.7867 1616.67ZM46.8236 1617.14L39.7853 1572.7L37.8099 1573.01L44.8482 1617.46L46.8236 1617.14ZM38.954 1573.84L42.9048 1573.22L42.5919 1571.24L38.6412 1571.87L38.954 1573.84ZM41.7606 1572.38L48.799 1616.83L50.7744 1616.52L43.736 1572.07L41.7606 1572.38ZM49.6303 1615.69L45.6795 1616.31L45.9923 1618.29L49.9431 1617.66L49.6303 1615.69Z'
        fill='#8BB6DB'
        mask='url(#path-836-inside-102_6651_98482)'
      />
      <mask id='path-838-inside-103_6651_98482' fill='white'>
        <path d='M34.6953 1563.19L29.8186 1520.01L39.7554 1518.89L44.6321 1562.07L34.6953 1563.19Z' />
      </mask>
      <path d='M34.6953 1563.19L29.8186 1520.01L39.7554 1518.89L44.6321 1562.07L34.6953 1563.19Z' fill='#ECF5FC' />
      <path
        d='M35.689 1563.08L30.8123 1519.9L28.8249 1520.12L33.7016 1563.3L35.689 1563.08ZM38.7617 1519L43.6384 1562.18L45.6258 1561.95L40.7491 1518.78L38.7617 1519Z'
        fill='#CFD1D2'
        mask='url(#path-838-inside-103_6651_98482)'
      />
      <mask id='path-840-inside-104_6651_98482' fill='white'>
        <path d='M37.6797 1562.85L32.8536 1520.12L36.8283 1519.67L41.6544 1562.4L37.6797 1562.85Z' />
      </mask>
      <path d='M37.6797 1562.85L32.8536 1520.12L36.8283 1519.67L41.6544 1562.4L37.6797 1562.85Z' fill='#AECCE7' />
      <path
        d='M37.6797 1562.85L36.686 1562.96L36.7982 1563.96L37.7919 1563.84L37.6797 1562.85ZM32.8536 1520.12L32.7414 1519.13L31.7477 1519.24L31.8599 1520.23L32.8536 1520.12ZM36.8283 1519.67L37.822 1519.56L37.7098 1518.57L36.7161 1518.68L36.8283 1519.67ZM41.6544 1562.4L41.7666 1563.4L42.7603 1563.28L42.6481 1562.29L41.6544 1562.4ZM38.6734 1562.74L33.8473 1520.01L31.8599 1520.23L36.686 1562.96L38.6734 1562.74ZM32.9658 1521.12L36.9406 1520.67L36.7161 1518.68L32.7414 1519.13L32.9658 1521.12ZM35.8346 1519.79L40.6607 1562.51L42.6481 1562.29L37.822 1519.56L35.8346 1519.79ZM41.5422 1561.41L37.5675 1561.86L37.7919 1563.84L41.7666 1563.4L41.5422 1561.41Z'
        fill='#8BB6DB'
        mask='url(#path-840-inside-104_6651_98482)'
      />
      <mask id='path-842-inside-105_6651_98482' fill='white'>
        <path d='M28.6562 1510.43L23.0139 1465.3L32.9367 1464.06L38.579 1509.19L28.6562 1510.43Z' />
      </mask>
      <path d='M28.6562 1510.43L23.0139 1465.3L32.9367 1464.06L38.579 1509.19L28.6562 1510.43Z' fill='#ECF5FC' />
      <path
        d='M29.6485 1510.31L24.0062 1465.17L22.0216 1465.42L27.664 1510.56L29.6485 1510.31ZM31.9444 1464.18L37.5867 1509.32L39.5713 1509.07L33.929 1463.93L31.9444 1464.18Z'
        fill='#CFD1D2'
        mask='url(#path-842-inside-105_6651_98482)'
      />
      <mask id='path-844-inside-106_6651_98482' fill='white'>
        <path d='M31.6328 1510.06L26.0509 1465.41L30.02 1464.91L35.6019 1509.57L31.6328 1510.06Z' />
      </mask>
      <path d='M31.6328 1510.06L26.0509 1465.41L30.02 1464.91L35.6019 1509.57L31.6328 1510.06Z' fill='#AECCE7' />
      <path
        d='M31.6328 1510.06L30.6405 1510.19L30.7646 1511.18L31.7569 1511.05L31.6328 1510.06ZM26.0509 1465.41L25.9268 1464.42L24.9346 1464.54L25.0586 1465.53L26.0509 1465.41ZM30.02 1464.91L31.0123 1464.79L30.8882 1463.8L29.8959 1463.92L30.02 1464.91ZM35.6019 1509.57L35.726 1510.56L36.7182 1510.43L36.5942 1509.44L35.6019 1509.57ZM32.6251 1509.94L27.0432 1465.29L25.0586 1465.53L30.6405 1510.19L32.6251 1509.94ZM26.1749 1466.4L30.144 1465.91L29.8959 1463.92L25.9268 1464.42L26.1749 1466.4ZM29.0277 1465.04L34.6096 1509.69L36.5942 1509.44L31.0123 1464.79L29.0277 1465.04ZM35.4779 1508.57L31.5088 1509.07L31.7569 1511.05L35.726 1510.56L35.4779 1508.57Z'
        fill='#8BB6DB'
        mask='url(#path-844-inside-106_6651_98482)'
      />
      <mask id='path-846-inside-107_6651_98482' fill='white'>
        <path d='M21.6094 1455.96L16.9711 1412.21L26.9154 1411.16L31.5537 1454.91L21.6094 1455.96Z' />
      </mask>
      <path d='M21.6094 1455.96L16.9711 1412.21L26.9154 1411.16L31.5537 1454.91L21.6094 1455.96Z' fill='#ECF5FC' />
      <path
        d='M22.6038 1455.86L17.9655 1412.1L15.9767 1412.32L20.6149 1456.07L22.6038 1455.86ZM25.9209 1411.26L30.5592 1455.02L32.5481 1454.81L27.9098 1411.05L25.9209 1411.26Z'
        fill='#CFD1D2'
        mask='url(#path-846-inside-107_6651_98482)'
      />
      <mask id='path-848-inside-108_6651_98482' fill='white'>
        <path d='M24.5938 1455.65L19.9555 1411.89L23.9332 1411.47L28.5715 1455.23L24.5938 1455.65Z' />
      </mask>
      <path d='M24.5938 1455.65L19.9555 1411.89L23.9332 1411.47L28.5715 1455.23L24.5938 1455.65Z' fill='#AECCE7' />
      <path
        d='M24.5938 1455.65L23.5993 1455.75L23.7047 1456.75L24.6992 1456.64L24.5938 1455.65ZM19.9555 1411.89L19.85 1410.9L18.8556 1411L18.961 1412L19.9555 1411.89ZM23.9332 1411.47L24.9276 1411.37L24.8222 1410.37L23.8278 1410.48L23.9332 1411.47ZM28.5715 1455.23L28.6769 1456.22L29.6713 1456.12L29.5659 1455.12L28.5715 1455.23ZM25.5882 1455.54L20.9499 1411.79L18.961 1412L23.5993 1455.75L25.5882 1455.54ZM20.0609 1412.89L24.0386 1412.47L23.8278 1410.48L19.85 1410.9L20.0609 1412.89ZM22.9387 1411.58L27.577 1455.33L29.5659 1455.12L24.9276 1411.37L22.9387 1411.58ZM28.466 1454.23L24.4883 1454.65L24.6992 1456.64L28.6769 1456.22L28.466 1454.23Z'
        fill='#8BB6DB'
        mask='url(#path-848-inside-108_6651_98482)'
      />
      <mask id='path-850-inside-109_6651_98482' fill='white'>
        <path d='M16.0859 1402.27L12.1118 1357.17L22.0732 1356.29L26.0473 1401.4L16.0859 1402.27Z' />
      </mask>
      <path d='M16.0859 1402.27L12.1118 1357.17L22.0732 1356.29L26.0473 1401.4L16.0859 1402.27Z' fill='#ECF5FC' />
      <path
        d='M17.0821 1402.19L13.1079 1357.08L11.1156 1357.26L15.0898 1402.36L17.0821 1402.19ZM21.077 1356.38L25.0512 1401.48L27.0435 1401.31L23.0693 1356.2L21.077 1356.38Z'
        fill='#CFD1D2'
        mask='url(#path-850-inside-109_6651_98482)'
      />
      <mask id='path-852-inside-110_6651_98482' fill='white'>
        <path d='M19.0781 1402.01L15.1285 1357.18L19.1131 1356.83L23.0627 1401.66L19.0781 1402.01Z' />
      </mask>
      <path d='M19.0781 1402.01L15.1285 1357.18L19.1131 1356.83L23.0627 1401.66L19.0781 1402.01Z' fill='#AECCE7' />
      <path
        d='M19.0781 1402.01L18.082 1402.1L18.1698 1403.09L19.1659 1403.01L19.0781 1402.01ZM15.1285 1357.18L15.0407 1356.19L14.0446 1356.28L14.1323 1357.27L15.1285 1357.18ZM19.1131 1356.83L20.1092 1356.75L20.0214 1355.75L19.0253 1355.84L19.1131 1356.83ZM23.0627 1401.66L23.1505 1402.66L24.1466 1402.57L24.0588 1401.57L23.0627 1401.66ZM20.0743 1401.92L16.1246 1357.1L14.1323 1357.27L18.082 1402.1L20.0743 1401.92ZM15.2163 1358.18L19.2008 1357.83L19.0253 1355.84L15.0407 1356.19L15.2163 1358.18ZM18.1169 1356.92L22.0665 1401.75L24.0588 1401.57L20.1092 1356.75L18.1169 1356.92ZM22.9749 1400.66L18.9904 1401.01L19.1659 1403.01L23.1505 1402.66L22.9749 1400.66Z'
        fill='#8BB6DB'
        mask='url(#path-852-inside-110_6651_98482)'
      />
      <mask id='path-854-inside-111_6651_98482' fill='white'>
        <path d='M11.4531 1347.38L8.55396 1303.55L18.5322 1302.89L21.4313 1346.71L11.4531 1347.38Z' />
      </mask>
      <path d='M11.4531 1347.38L8.55396 1303.55L18.5322 1302.89L21.4313 1346.71L11.4531 1347.38Z' fill='#ECF5FC' />
      <path
        d='M12.4509 1347.31L9.55178 1303.49L7.55614 1303.62L10.4553 1347.44L12.4509 1347.31ZM17.5343 1302.96L20.4335 1346.78L22.4291 1346.65L19.53 1302.83L17.5343 1302.96Z'
        fill='#CFD1D2'
        mask='url(#path-854-inside-111_6651_98482)'
      />
      <mask id='path-856-inside-112_6651_98482' fill='white'>
        <path d='M14.4453 1347.18L11.5407 1303.27L15.532 1303.01L18.4366 1346.91L14.4453 1347.18Z' />
      </mask>
      <path d='M14.4453 1347.18L11.5407 1303.27L15.532 1303.01L18.4366 1346.91L14.4453 1347.18Z' fill='#AECCE7' />
      <path
        d='M14.4453 1347.18L13.4475 1347.24L13.5135 1348.24L14.5113 1348.17L14.4453 1347.18ZM11.5407 1303.27L11.4747 1302.27L10.4769 1302.34L10.5429 1303.34L11.5407 1303.27ZM15.532 1303.01L16.5298 1302.94L16.4638 1301.94L15.466 1302.01L15.532 1303.01ZM18.4366 1346.91L18.5026 1347.91L19.5004 1347.84L19.4344 1346.85L18.4366 1346.91ZM15.4431 1347.11L12.5385 1303.21L10.5429 1303.34L13.4475 1347.24L15.4431 1347.11ZM11.6067 1304.27L15.598 1304.01L15.466 1302.01L11.4747 1302.27L11.6067 1304.27ZM14.5342 1303.07L17.4388 1346.98L19.4344 1346.85L16.5298 1302.94L14.5342 1303.07ZM18.3706 1345.91L14.3793 1346.18L14.5113 1348.17L18.5026 1347.91L18.3706 1345.91Z'
        fill='#8BB6DB'
        mask='url(#path-856-inside-112_6651_98482)'
      />
      <mask id='path-858-inside-113_6651_98482' fill='white'>
        <path d='M8.02344 1293.82L4.99587 1249.44L14.9727 1248.76L18.0002 1293.14L8.02344 1293.82Z' />
      </mask>
      <path d='M8.02344 1293.82L4.99587 1249.44L14.9727 1248.76L18.0002 1293.14L8.02344 1293.82Z' fill='#ECF5FC' />
      <path
        d='M9.02112 1293.75L5.99355 1249.37L3.99819 1249.51L7.02576 1293.88L9.02112 1293.75ZM13.975 1248.83L17.0026 1293.2L18.9979 1293.07L15.9704 1248.69L13.975 1248.83Z'
        fill='#CFD1D2'
        mask='url(#path-858-inside-113_6651_98482)'
      />
      <mask id='path-860-inside-114_6651_98482' fill='white'>
        <path d='M11.0156 1293.61L8.02086 1249.71L12.0116 1249.44L15.0063 1293.34L11.0156 1293.61Z' />
      </mask>
      <path d='M11.0156 1293.61L8.02086 1249.71L12.0116 1249.44L15.0063 1293.34L11.0156 1293.61Z' fill='#AECCE7' />
      <path
        d='M11.0156 1293.61L10.0179 1293.68L10.086 1294.68L11.0837 1294.61L11.0156 1293.61ZM8.02086 1249.71L7.9528 1248.72L6.95512 1248.78L7.02318 1249.78L8.02086 1249.71ZM12.0116 1249.44L13.0093 1249.37L12.9412 1248.38L11.9435 1248.44L12.0116 1249.44ZM15.0063 1293.34L15.0744 1294.34L16.0721 1294.27L16.004 1293.27L15.0063 1293.34ZM12.0133 1293.54L9.01854 1249.65L7.02318 1249.78L10.0179 1293.68L12.0133 1293.54ZM8.08892 1250.71L12.0796 1250.44L11.9435 1248.44L7.9528 1248.72L8.08892 1250.71ZM11.0139 1249.51L14.0087 1293.41L16.004 1293.27L13.0093 1249.37L11.0139 1249.51ZM14.9383 1292.34L10.9476 1292.61L11.0837 1294.61L15.0744 1294.34L14.9383 1292.34Z'
        fill='#8BB6DB'
        mask='url(#path-860-inside-114_6651_98482)'
      />
      <mask id='path-862-inside-115_6651_98482' fill='white'>
        <path d='M13.9531 807.957L17.6475 763.994L27.6124 764.832L23.918 808.794L13.9531 807.957Z' />
      </mask>
      <path d='M13.9531 807.957L17.6475 763.994L27.6124 764.832L23.918 808.794L13.9531 807.957Z' fill='#ECF5FC' />
      <path
        d='M14.9496 808.041L18.644 764.078L16.651 763.911L12.9566 807.873L14.9496 808.041ZM26.6159 764.748L22.9215 808.711L24.9145 808.878L28.6089 764.916L26.6159 764.748Z'
        fill='#CFD1D2'
        mask='url(#path-862-inside-115_6651_98482)'
      />
      <mask id='path-864-inside-116_6651_98482' fill='white'>
        <path d='M16.9453 808.208L20.6299 764.363L24.6158 764.698L20.9313 808.543L16.9453 808.208Z' />
      </mask>
      <path d='M16.9453 808.208L20.6299 764.363L24.6158 764.698L20.9313 808.543L16.9453 808.208Z' fill='#AECCE7' />
      <path
        d='M16.9453 808.208L15.9488 808.125L15.8651 809.121L16.8616 809.205L16.9453 808.208ZM20.6299 764.363L20.7136 763.367L19.7171 763.283L19.6334 764.279L20.6299 764.363ZM24.6158 764.698L25.6123 764.782L25.6961 763.785L24.6996 763.702L24.6158 764.698ZM20.9313 808.543L20.8475 809.54L21.844 809.624L21.9278 808.627L20.9313 808.543ZM17.9418 808.292L21.6264 764.447L19.6334 764.279L15.9488 808.125L17.9418 808.292ZM20.5461 765.36L24.5321 765.694L24.6996 763.702L20.7136 763.367L20.5461 765.36ZM23.6193 764.614L19.9348 808.46L21.9278 808.627L25.6123 764.782L23.6193 764.614ZM21.015 807.547L17.0291 807.212L16.8616 809.205L20.8475 809.54L21.015 807.547Z'
        fill='#8BB6DB'
        mask='url(#path-864-inside-116_6651_98482)'
      />
      <mask id='path-866-inside-117_6651_98482' fill='white'>
        <path d='M18.1484 754.16L23.1956 709.647L33.1319 710.774L28.0848 755.287L18.1484 754.16Z' />
      </mask>
      <path d='M18.1484 754.16L23.1956 709.647L33.1319 710.774L28.0848 755.287L18.1484 754.16Z' fill='#ECF5FC' />
      <path
        d='M19.1421 754.273L24.1892 709.76L22.2019 709.535L17.1548 754.047L19.1421 754.273ZM32.1383 710.661L27.0911 755.174L29.0784 755.399L34.1255 710.886L32.1383 710.661Z'
        fill='#CFD1D2'
        mask='url(#path-866-inside-117_6651_98482)'
      />
      <mask id='path-868-inside-118_6651_98482' fill='white'>
        <path d='M21.1328 754.498L26.2027 709.785L30.1772 710.235L25.1073 754.949L21.1328 754.498Z' />
      </mask>
      <path d='M21.1328 754.498L26.2027 709.785L30.1772 710.235L25.1073 754.949L21.1328 754.498Z' fill='#AECCE7' />
      <path
        d='M21.1328 754.498L20.1392 754.385L20.0265 755.379L21.0201 755.492L21.1328 754.498ZM26.2027 709.785L26.3153 708.791L25.3217 708.678L25.209 709.672L26.2027 709.785ZM30.1772 710.235L31.1708 710.348L31.2835 709.354L30.2899 709.242L30.1772 710.235ZM25.1073 754.949L24.9947 755.942L25.9883 756.055L26.101 755.061L25.1073 754.949ZM22.1264 754.611L27.1963 709.897L25.209 709.672L20.1392 754.385L22.1264 754.611ZM26.09 710.778L30.0645 711.229L30.2899 709.242L26.3153 708.791L26.09 710.778ZM29.1836 710.123L24.1137 754.836L26.101 755.061L31.1708 710.348L29.1836 710.123ZM25.22 753.955L21.2455 753.504L21.0201 755.492L24.9947 755.942L25.22 753.955Z'
        fill='#8BB6DB'
        mask='url(#path-868-inside-118_6651_98482)'
      />
      <mask id='path-870-inside-119_6651_98482' fill='white'>
        <path d='M24.3359 699.82L29.7399 655.162L39.6675 656.363L34.2635 701.022L24.3359 699.82Z' />
      </mask>
      <path d='M24.3359 699.82L29.7399 655.162L39.6675 656.363L34.2635 701.022L24.3359 699.82Z' fill='#ECF5FC' />
      <path
        d='M25.3287 699.94L30.7326 655.282L28.7471 655.042L23.3432 699.7L25.3287 699.94ZM38.6747 656.243L33.2708 700.901L35.2563 701.142L40.6602 656.483L38.6747 656.243Z'
        fill='#CFD1D2'
        mask='url(#path-870-inside-119_6651_98482)'
      />
      <mask id='path-872-inside-120_6651_98482' fill='white'>
        <path d='M27.3125 700.181L32.7183 655.507L36.6894 655.987L31.2835 700.661L27.3125 700.181Z' />
      </mask>
      <path d='M27.3125 700.181L32.7183 655.507L36.6894 655.987L31.2835 700.661L27.3125 700.181Z' fill='#AECCE7' />
      <path
        d='M27.3125 700.181L26.3197 700.061L26.1996 701.053L27.1924 701.173L27.3125 700.181ZM32.7183 655.507L32.8385 654.514L31.8457 654.394L31.7256 655.386L32.7183 655.507ZM36.6894 655.987L37.6821 656.107L37.8023 655.114L36.8095 654.994L36.6894 655.987ZM31.2835 700.661L31.1634 701.654L32.1562 701.774L32.2763 700.781L31.2835 700.661ZM28.3053 700.301L33.7111 655.627L31.7256 655.386L26.3197 700.061L28.3053 700.301ZM32.5982 656.499L36.5692 656.98L36.8095 654.994L32.8385 654.514L32.5982 656.499ZM35.6966 655.867L30.2908 700.541L32.2763 700.781L37.6821 656.107L35.6966 655.867ZM31.4037 699.668L27.4326 699.188L27.1924 701.173L31.1634 701.654L31.4037 699.668Z'
        fill='#8BB6DB'
        mask='url(#path-872-inside-120_6651_98482)'
      />
      <mask id='path-874-inside-121_6651_98482' fill='white'>
        <path d='M30.2266 645.676L36.8345 602.477L46.7195 603.989L40.1116 647.188L30.2266 645.676Z' />
      </mask>
      <path d='M30.2266 645.676L36.8345 602.477L46.7195 603.989L40.1116 647.188L30.2266 645.676Z' fill='#ECF5FC' />
      <path
        d='M31.2151 645.827L37.823 602.628L35.846 602.326L29.2381 645.525L31.2151 645.827ZM45.731 603.838L39.1231 647.037L41.1001 647.339L47.708 604.14L45.731 603.838Z'
        fill='#CFD1D2'
        mask='url(#path-874-inside-121_6651_98482)'
      />
      <mask id='path-876-inside-122_6651_98482' fill='white'>
        <path d='M33.1953 646.129L39.8485 602.635L43.8025 603.24L37.1493 646.734L33.1953 646.129Z' />
      </mask>
      <path d='M33.1953 646.129L39.8485 602.635L43.8025 603.24L37.1493 646.734L33.1953 646.129Z' fill='#AECCE7' />
      <path
        d='M33.1953 646.129L32.2068 645.978L32.0556 646.967L33.0441 647.118L33.1953 646.129ZM39.8485 602.635L39.9997 601.647L39.0112 601.496L38.86 602.484L39.8485 602.635ZM43.8025 603.24L44.791 603.391L44.9422 602.403L43.9537 602.252L43.8025 603.24ZM37.1493 646.734L36.9981 647.723L37.9866 647.874L38.1378 646.885L37.1493 646.734ZM34.1838 646.281L40.837 602.787L38.86 602.484L32.2068 645.978L34.1838 646.281ZM39.6973 603.624L43.6513 604.229L43.9537 602.252L39.9997 601.647L39.6973 603.624ZM42.814 603.089L36.1608 646.583L38.1378 646.885L44.791 603.391L42.814 603.089ZM37.3005 645.746L33.3465 645.141L33.0441 647.118L36.9981 647.723L37.3005 645.746Z'
        fill='#8BB6DB'
        mask='url(#path-876-inside-122_6651_98482)'
      />
      <mask id='path-878-inside-123_6651_98482' fill='white'>
        <path d='M37.7031 592.773L44.0456 549.036L53.9421 550.471L47.5996 594.209L37.7031 592.773Z' />
      </mask>
      <path d='M37.7031 592.773L44.0456 549.036L53.9421 550.471L47.5996 594.209L37.7031 592.773Z' fill='#ECF5FC' />
      <path
        d='M38.6928 592.917L45.0353 549.18L43.056 548.893L36.7135 592.63L38.6928 592.917ZM52.9525 550.328L46.61 594.065L48.5893 594.352L54.9318 550.615L52.9525 550.328Z'
        fill='#CFD1D2'
        mask='url(#path-878-inside-123_6651_98482)'
      />
      <mask id='path-880-inside-124_6651_98482' fill='white'>
        <path d='M40.6719 593.204L46.9864 549.66L50.945 550.234L44.6305 593.778L40.6719 593.204Z' />
      </mask>
      <path d='M40.6719 593.204L46.9864 549.66L50.945 550.234L44.6305 593.778L40.6719 593.204Z' fill='#AECCE7' />
      <path
        d='M40.6719 593.204L39.6822 593.061L39.5387 594.05L40.5284 594.194L40.6719 593.204ZM46.9864 549.66L47.13 548.67L46.1403 548.526L45.9968 549.516L46.9864 549.66ZM50.945 550.234L51.9347 550.377L52.0782 549.387L51.0885 549.244L50.945 550.234ZM44.6305 593.778L44.487 594.768L45.4766 594.911L45.6201 593.922L44.6305 593.778ZM41.6615 593.348L47.9761 549.803L45.9968 549.516L39.6822 593.061L41.6615 593.348ZM46.8429 550.649L50.8015 551.223L51.0885 549.244L47.13 548.67L46.8429 550.649ZM49.9554 550.09L43.6408 593.635L45.6201 593.922L51.9347 550.377L49.9554 550.09ZM44.774 592.789L40.8154 592.214L40.5284 594.194L44.487 594.768L44.774 592.789Z'
        fill='#8BB6DB'
        mask='url(#path-880-inside-124_6651_98482)'
      />
      <mask id='path-882-inside-125_6651_98482' fill='white'>
        <path d='M44.9453 539.168L51.272 494.321L61.1739 495.718L54.8473 540.565L44.9453 539.168Z' />
      </mask>
      <path d='M44.9453 539.168L51.272 494.321L61.1739 495.718L54.8473 540.565L44.9453 539.168Z' fill='#ECF5FC' />
      <path
        d='M45.9355 539.308L52.2622 494.461L50.2818 494.182L43.9551 539.028L45.9355 539.308ZM60.1837 495.579L53.8571 540.425L55.8375 540.705L62.1641 495.858L60.1837 495.579Z'
        fill='#CFD1D2'
        mask='url(#path-882-inside-125_6651_98482)'
      />
      <mask id='path-884-inside-126_6651_98482' fill='white'>
        <path d='M47.9141 539.587L54.2001 495.028L58.1609 495.587L51.8748 540.146L47.9141 539.587Z' />
      </mask>
      <path d='M47.9141 539.587L54.2001 495.028L58.1609 495.587L51.8748 540.146L47.9141 539.587Z' fill='#AECCE7' />
      <path
        d='M47.9141 539.587L46.9239 539.447L46.7842 540.437L47.7744 540.577L47.9141 539.587ZM54.2001 495.028L54.3398 494.038L53.3496 493.898L53.2099 494.888L54.2001 495.028ZM58.1609 495.587L59.1511 495.727L59.2908 494.736L58.3006 494.597L58.1609 495.587ZM51.8748 540.146L51.7352 541.136L52.7253 541.276L52.865 540.285L51.8748 540.146ZM48.9043 539.727L55.1903 495.168L53.2099 494.888L46.9239 539.447L48.9043 539.727ZM54.0604 496.018L58.0212 496.577L58.3006 494.597L54.3398 494.038L54.0604 496.018ZM57.1707 495.447L50.8846 540.006L52.865 540.285L59.1511 495.727L57.1707 495.447ZM52.0145 539.155L48.0538 538.597L47.7744 540.577L51.7352 541.136L52.0145 539.155Z'
        fill='#8BB6DB'
        mask='url(#path-884-inside-126_6651_98482)'
      />
      <mask id='path-886-inside-127_6651_98482' fill='white'>
        <path d='M52.6875 484.742L59.9672 440.619L69.8338 442.247L62.5541 486.37L52.6875 484.742Z' />
      </mask>
      <path d='M52.6875 484.742L59.9672 440.619L69.8338 442.247L62.5541 486.37L52.6875 484.742Z' fill='#ECF5FC' />
      <path
        d='M53.6742 484.905L60.9539 440.782L58.9805 440.456L51.7008 484.579L53.6742 484.905ZM68.8472 442.084L61.5675 486.207L63.5408 486.533L70.8205 442.409L68.8472 442.084Z'
        fill='#CFD1D2'
        mask='url(#path-886-inside-127_6651_98482)'
      />
      <mask id='path-888-inside-128_6651_98482' fill='white'>
        <path d='M55.6484 485.23L62.9737 440.831L66.9204 441.482L59.5951 485.882L55.6484 485.23Z' />
      </mask>
      <path d='M55.6484 485.23L62.9737 440.831L66.9204 441.482L59.5951 485.882L55.6484 485.23Z' fill='#AECCE7' />
      <path
        d='M55.6484 485.23L54.6618 485.068L54.499 486.054L55.4857 486.217L55.6484 485.23ZM62.9737 440.831L63.1365 439.844L62.1499 439.681L61.9871 440.668L62.9737 440.831ZM66.9204 441.482L67.907 441.645L68.0698 440.658L67.0832 440.495L66.9204 441.482ZM59.5951 485.882L59.4323 486.868L60.419 487.031L60.5817 486.044L59.5951 485.882ZM56.6351 485.393L63.9604 440.993L61.9871 440.668L54.6618 485.068L56.6351 485.393ZM62.811 441.817L66.7576 442.468L67.0832 440.495L63.1365 439.844L62.811 441.817ZM65.9337 441.319L58.6084 485.719L60.5817 486.044L67.907 441.645L65.9337 441.319ZM59.7579 484.895L55.8112 484.244L55.4857 486.217L59.4323 486.868L59.7579 484.895Z'
        fill='#8BB6DB'
        mask='url(#path-888-inside-128_6651_98482)'
      />
      <mask id='path-890-inside-129_6651_98482' fill='white'>
        <path d='M61.0781 431.492L69.0951 388.15L78.9283 389.969L70.9113 433.311L61.0781 431.492Z' />
      </mask>
      <path d='M61.0781 431.492L69.0951 388.15L78.9283 389.969L70.9113 433.311L61.0781 431.492Z' fill='#ECF5FC' />
      <path
        d='M62.0614 431.674L70.0784 388.332L68.1117 387.968L60.0948 431.31L62.0614 431.674ZM77.9449 389.787L69.928 433.129L71.8946 433.493L79.9116 390.151L77.9449 389.787Z'
        fill='#CFD1D2'
        mask='url(#path-890-inside-129_6651_98482)'
      />
      <mask id='path-892-inside-130_6651_98482' fill='white'>
        <path d='M64.0312 432.038L72.0341 388.772L75.9674 389.499L67.9645 432.765L64.0312 432.038Z' />
      </mask>
      <path d='M64.0312 432.038L72.0341 388.772L75.9674 389.499L67.9645 432.765L64.0312 432.038Z' fill='#AECCE7' />
      <path
        d='M64.0312 432.038L63.0479 431.856L62.866 432.839L63.8494 433.021L64.0312 432.038ZM72.0341 388.772L72.216 387.788L71.2327 387.606L71.0508 388.59L72.0341 388.772ZM75.9674 389.499L76.9507 389.681L77.1326 388.698L76.1493 388.516L75.9674 389.499ZM67.9645 432.765L67.7826 433.748L68.766 433.93L68.9479 432.947L67.9645 432.765ZM65.0146 432.219L73.0174 388.953L71.0508 388.59L63.0479 431.856L65.0146 432.219ZM71.8522 389.755L75.7855 390.482L76.1493 388.516L72.216 387.788L71.8522 389.755ZM74.9841 389.317L66.9812 432.583L68.9479 432.947L76.9507 389.681L74.9841 389.317ZM68.1464 431.782L64.2131 431.054L63.8494 433.021L67.7826 433.748L68.1464 431.782Z'
        fill='#8BB6DB'
        mask='url(#path-892-inside-130_6651_98482)'
      />
      <mask id='path-894-inside-131_6651_98482' fill='white'>
        <path d='M70.5234 378.664L79.2729 334.491L89.0823 336.434L80.3329 380.607L70.5234 378.664Z' />
      </mask>
      <path d='M70.5234 378.664L79.2729 334.491L89.0823 336.434L80.3329 380.607L70.5234 378.664Z' fill='#ECF5FC' />
      <path
        d='M71.5044 378.858L80.2538 334.685L78.2919 334.296L69.5425 378.47L71.5044 378.858ZM88.1014 336.239L79.3519 380.413L81.3138 380.801L90.0632 336.628L88.1014 336.239Z'
        fill='#CFD1D2'
        mask='url(#path-894-inside-131_6651_98482)'
      />
      <mask id='path-896-inside-132_6651_98482' fill='white'>
        <path d='M73.4688 379.247L82.2121 335.105L86.1358 335.882L77.3925 380.024L73.4688 379.247Z' />
      </mask>
      <path d='M73.4688 379.247L82.2121 335.105L86.1358 335.882L77.3925 380.024L73.4688 379.247Z' fill='#AECCE7' />
      <path
        d='M73.4688 379.247L72.4878 379.053L72.2935 380.034L73.2745 380.228L73.4688 379.247ZM82.2121 335.105L82.4064 334.124L81.4254 333.929L81.2311 334.91L82.2121 335.105ZM86.1358 335.882L87.1168 336.076L87.3111 335.095L86.3301 334.901L86.1358 335.882ZM77.3925 380.024L77.1982 381.005L78.1792 381.199L78.3735 380.219L77.3925 380.024ZM74.4497 379.441L83.193 335.299L81.2311 334.91L72.4878 379.053L74.4497 379.441ZM82.0178 336.086L85.9415 336.863L86.3301 334.901L82.4064 334.124L82.0178 336.086ZM85.1549 335.688L76.4116 379.83L78.3735 380.219L87.1168 336.076L85.1549 335.688ZM77.5868 379.043L73.663 378.266L73.2745 380.228L77.1982 381.005L77.5868 379.043Z'
        fill='#8BB6DB'
        mask='url(#path-896-inside-132_6651_98482)'
      />
      <mask id='path-898-inside-133_6651_98482' fill='white'>
        <path d='M81.1094 324.797L89.2232 283.914L99.0319 285.861L90.9181 326.744L81.1094 324.797Z' />
      </mask>
      <path d='M81.1094 324.797L89.2232 283.914L99.0319 285.861L90.9181 326.744L81.1094 324.797Z' fill='#ECF5FC' />
      <path
        d='M82.0902 324.992L90.2041 284.109L88.2423 283.719L80.1285 324.602L82.0902 324.992ZM98.051 285.666L89.9372 326.549L91.8989 326.938L100.013 286.055L98.051 285.666Z'
        fill='#CFD1D2'
        mask='url(#path-898-inside-133_6651_98482)'
      />
      <mask id='path-900-inside-134_6651_98482' fill='white'>
        <path d='M84.0547 325.381L92.2307 284.184L96.1542 284.963L87.9782 326.16L84.0547 325.381Z' />
      </mask>
      <path d='M84.0547 325.381L92.2307 284.184L96.1542 284.963L87.9782 326.16L84.0547 325.381Z' fill='#AECCE7' />
      <path
        d='M84.0547 325.381L83.0738 325.186L82.8792 326.167L83.86 326.362L84.0547 325.381ZM92.2307 284.184L92.4254 283.203L91.4445 283.009L91.2499 283.99L92.2307 284.184ZM96.1542 284.963L97.1351 285.158L97.3298 284.177L96.3489 283.982L96.1542 284.963ZM87.9782 326.16L87.7835 327.14L88.7644 327.335L88.959 326.354L87.9782 326.16ZM85.0356 325.576L93.2116 284.379L91.2499 283.99L83.0738 325.186L85.0356 325.576ZM92.0361 285.165L95.9595 285.944L96.3489 283.982L92.4254 283.203L92.0361 285.165ZM95.1733 284.768L86.9973 325.965L88.959 326.354L97.1351 285.158L95.1733 284.768ZM88.1728 325.179L84.2494 324.4L83.86 326.362L87.7835 327.14L88.1728 325.179Z'
        fill='#8BB6DB'
        mask='url(#path-900-inside-134_6651_98482)'
      />
      <mask id='path-902-inside-135_6651_98482' fill='white'>
        <path d='M91.6953 274.672L98.8416 229.236L108.72 230.79L101.574 276.226L91.6953 274.672Z' />
      </mask>
      <path d='M91.6953 274.672L98.8416 229.236L108.72 230.79L101.574 276.226L91.6953 274.672Z' fill='#ECF5FC' />
      <path
        d='M92.6832 274.828L99.8295 229.391L97.8537 229.081L90.7075 274.517L92.6832 274.828ZM107.732 230.634L100.586 276.071L102.562 276.381L109.708 230.945L107.732 230.634Z'
        fill='#CFD1D2'
        mask='url(#path-902-inside-135_6651_98482)'
      />
      <mask id='path-904-inside-136_6651_98482' fill='white'>
        <path d='M94.6562 275.139L101.803 229.697L105.755 230.319L98.6077 275.76L94.6562 275.139Z' />
      </mask>
      <path d='M94.6562 275.139L101.803 229.697L105.755 230.319L98.6077 275.76L94.6562 275.139Z' fill='#AECCE7' />
      <path
        d='M94.6562 275.139L93.6684 274.983L93.513 275.971L94.5009 276.127L94.6562 275.139ZM101.803 229.697L101.959 228.709L100.971 228.554L100.815 229.542L101.803 229.697ZM105.755 230.319L106.743 230.474L106.898 229.486L105.91 229.331L105.755 230.319ZM98.6077 275.76L98.4523 276.748L99.4402 276.903L99.5955 275.916L98.6077 275.76ZM95.6441 275.294L102.791 229.853L100.815 229.542L93.6684 274.983L95.6441 275.294ZM101.648 230.685L105.599 231.307L105.91 229.331L101.959 228.709L101.648 230.685ZM104.767 230.163L97.6198 275.605L99.5955 275.916L106.743 230.474L104.767 230.163ZM98.763 274.772L94.8116 274.151L94.5009 276.127L98.4523 276.748L98.763 274.772Z'
        fill='#8BB6DB'
        mask='url(#path-904-inside-136_6651_98482)'
      />
      <mask id='path-906-inside-137_6651_98482' fill='white'>
        <path d='M101.156 219.449L112.019 175.079L121.732 177.457L110.869 221.827L101.156 219.449Z' />
      </mask>
      <path d='M101.156 219.449L112.019 175.079L121.732 177.457L110.869 221.827L101.156 219.449Z' fill='#ECF5FC' />
      <path
        d='M102.128 219.687L112.991 175.317L111.048 174.842L100.185 219.211L102.128 219.687ZM120.761 177.22L109.898 221.589L111.841 222.065L122.704 177.695L120.761 177.22Z'
        fill='#CFD1D2'
        mask='url(#path-906-inside-137_6651_98482)'
      />
      <mask id='path-908-inside-138_6651_98482' fill='white'>
        <path d='M104.07 220.163L115.009 175.482L118.895 176.433L107.956 221.114L104.07 220.163Z' />
      </mask>
      <path d='M104.07 220.163L115.009 175.482L118.895 176.433L107.956 221.114L104.07 220.163Z' fill='#AECCE7' />
      <path
        d='M104.07 220.163L103.099 219.925L102.861 220.896L103.833 221.134L104.07 220.163ZM115.009 175.482L115.247 174.511L114.276 174.273L114.038 175.244L115.009 175.482ZM118.895 176.433L119.866 176.671L120.104 175.7L119.132 175.462L118.895 176.433ZM107.956 221.114L107.718 222.085L108.689 222.323L108.927 221.352L107.956 221.114ZM105.042 220.4L115.981 175.72L114.038 175.244L103.099 219.925L105.042 220.4ZM114.772 176.454L118.657 177.405L119.132 175.462L115.247 174.511L114.772 176.454ZM117.923 176.196L106.984 220.876L108.927 221.352L119.866 176.671L117.923 176.196ZM108.193 220.143L104.308 219.191L103.833 221.134L107.718 222.085L108.193 220.143Z'
        fill='#8BB6DB'
        mask='url(#path-908-inside-138_6651_98482)'
      />
      <mask id='path-910-inside-139_6651_98482' fill='white'>
        <path d='M114.305 165.613L124.928 121.375L134.651 123.71L124.028 167.948L114.305 165.613Z' />
      </mask>
      <path d='M114.305 165.613L124.928 121.375L134.651 123.71L124.028 167.948L114.305 165.613Z' fill='#ECF5FC' />
      <path
        d='M115.277 165.847L125.9 121.608L123.955 121.141L113.332 165.38L115.277 165.847ZM133.679 123.476L123.056 167.715L125.001 168.182L135.624 123.943L133.679 123.476Z'
        fill='#CFD1D2'
        mask='url(#path-910-inside-139_6651_98482)'
      />
      <mask id='path-912-inside-140_6651_98482' fill='white'>
        <path d='M117.219 166.314L127.726 122.558L131.615 123.492L121.108 167.248L117.219 166.314Z' />
      </mask>
      <path d='M117.219 166.314L127.726 122.558L131.615 123.492L121.108 167.248L117.219 166.314Z' fill='#AECCE7' />
      <path
        d='M117.219 166.314L116.246 166.08L116.013 167.053L116.985 167.286L117.219 166.314ZM127.726 122.558L127.959 121.585L126.987 121.352L126.754 122.324L127.726 122.558ZM131.615 123.492L132.588 123.725L132.821 122.753L131.849 122.519L131.615 123.492ZM121.108 167.248L120.875 168.22L121.847 168.454L122.081 167.481L121.108 167.248ZM118.191 166.547L128.698 122.791L126.754 122.324L116.246 166.08L118.191 166.547ZM127.492 123.53L131.382 124.464L131.849 122.519L127.959 121.585L127.492 123.53ZM130.643 123.258L120.136 167.014L122.081 167.481L132.588 123.725L130.643 123.258ZM121.342 166.276L117.452 165.342L116.985 167.286L120.875 168.22L121.342 166.276Z'
        fill='#8BB6DB'
        mask='url(#path-912-inside-140_6651_98482)'
      />
      <mask id='path-914-inside-141_6651_98482' fill='white'>
        <path d='M127.531 112.014L140.1 69.8205L149.684 72.6753L137.115 114.869L127.531 112.014Z' />
      </mask>
      <path d='M127.531 112.014L140.1 69.8205L149.684 72.6753L137.115 114.869L127.531 112.014Z' fill='#ECF5FC' />
      <path
        d='M128.49 112.3L141.058 70.106L139.141 69.535L126.573 111.729L128.49 112.3ZM148.725 72.3899L136.157 114.583L138.073 115.154L150.642 72.9608L148.725 72.3899Z'
        fill='#CFD1D2'
        mask='url(#path-914-inside-141_6651_98482)'
      />
      <mask id='path-916-inside-142_6651_98482' fill='white'>
        <path d='M130.406 112.871L142.967 70.7017L146.801 71.8436L134.24 114.013L130.406 112.871Z' />
      </mask>
      <path d='M130.406 112.871L142.967 70.7017L146.801 71.8436L134.24 114.013L130.406 112.871Z' fill='#AECCE7' />
      <path
        d='M130.406 112.871L129.448 112.585L129.162 113.544L130.121 113.829L130.406 112.871ZM142.967 70.7017L143.253 69.7433L142.295 69.4578L142.009 70.4162L142.967 70.7017ZM146.801 71.8436L147.759 72.1291L148.045 71.1707L147.086 70.8852L146.801 71.8436ZM134.24 114.013L133.954 114.971L134.913 115.256L135.198 114.298L134.24 114.013ZM131.365 113.156L143.926 70.9872L142.009 70.4162L129.448 112.585L131.365 113.156ZM142.682 71.6601L146.515 72.802L147.086 70.8852L143.253 69.7433L142.682 71.6601ZM145.843 71.5581L133.281 113.727L135.198 114.298L147.759 72.1291L145.843 71.5581ZM134.525 113.054L130.692 111.912L130.121 113.829L133.954 114.971L134.525 113.054Z'
        fill='#8BB6DB'
        mask='url(#path-916-inside-142_6651_98482)'
      />
      <mask id='path-918-inside-143_6651_98482' fill='white'>
        <path d='M143.031 60.0781L157.454 15.0007L166.978 18.048L152.556 63.1254L143.031 60.0781Z' />
      </mask>
      <path d='M143.031 60.0781L157.454 15.0007L166.978 18.048L152.556 63.1254L143.031 60.0781Z' fill='#ECF5FC' />
      <path
        d='M143.984 60.3829L158.406 15.3054L156.501 14.696L142.079 59.7734L143.984 60.3829ZM166.026 17.7433L151.603 62.8207L153.508 63.4301L167.93 18.3527L166.026 17.7433Z'
        fill='#CFD1D2'
        mask='url(#path-918-inside-143_6651_98482)'
      />
      <mask id='path-920-inside-144_6651_98482' fill='white'>
        <path d='M145.891 60.9922L160.213 16.2275L164.023 17.4465L149.7 62.2111L145.891 60.9922Z' />
      </mask>
      <path d='M145.891 60.9922L160.213 16.2275L164.023 17.4465L149.7 62.2111L145.891 60.9922Z' fill='#AECCE7' />
      <path
        d='M145.891 60.9922L144.938 60.6875L144.633 61.6399L145.586 61.9446L145.891 60.9922ZM160.213 16.2275L160.518 15.2751L159.565 14.9704L159.26 15.9228L160.213 16.2275ZM164.023 17.4465L164.975 17.7512L165.28 16.7988L164.327 16.494L164.023 17.4465ZM149.7 62.2111L149.396 63.1635L150.348 63.4683L150.653 62.5158L149.7 62.2111ZM146.843 61.2969L161.165 16.5323L159.26 15.9228L144.938 60.6875L146.843 61.2969ZM159.908 17.18L163.718 18.3989L164.327 16.494L160.518 15.2751L159.908 17.18ZM163.07 17.1417L148.748 61.9064L150.653 62.5158L164.975 17.7512L163.07 17.1417ZM150.005 61.2587L146.195 60.0397L145.586 61.9446L149.396 63.1635L150.005 61.2587Z'
        fill='#8BB6DB'
        mask='url(#path-920-inside-144_6651_98482)'
      />
      <mask id='path-922-inside-145_6651_98482' fill='white'>
        <path d='M471.258 63.9741L515.475 74.4466L513.171 84.1774L468.953 73.7049L471.258 63.9741Z' />
      </mask>
      <path d='M471.258 63.9741L515.475 74.4466L513.171 84.1774L468.953 73.7049L471.258 63.9741Z' fill='#ECF5FC' />
      <path
        d='M471.027 64.9472L515.245 75.4197L515.706 73.4735L471.488 63.001L471.027 64.9472ZM513.401 83.2043L469.184 72.7318L468.723 74.678L512.94 85.1505L513.401 83.2043Z'
        fill='#CFD1D2'
        mask='url(#path-922-inside-145_6651_98482)'
      />
      <mask id='path-924-inside-146_6651_98482' fill='white'>
        <path d='M470.57 66.8936L514.359 77.2645L513.437 81.1568L469.648 70.7859L470.57 66.8936Z' />
      </mask>
      <path d='M470.57 66.8936L514.359 77.2645L513.437 81.1568L469.648 70.7859L470.57 66.8936Z' fill='#AECCE7' />
      <path
        d='M470.57 66.8936L470.801 65.9205L469.828 65.69L469.597 66.6631L470.57 66.8936ZM514.359 77.2645L515.332 77.495L515.562 76.5219L514.589 76.2914L514.359 77.2645ZM513.437 81.1568L513.207 82.1299L514.18 82.3604L514.41 81.3873L513.437 81.1568ZM469.648 70.7859L468.675 70.5554L468.445 71.5285L469.418 71.759L469.648 70.7859ZM470.34 67.8666L514.128 78.2376L514.589 76.2914L470.801 65.9205L470.34 67.8666ZM513.386 77.034L512.464 80.9263L514.41 81.3873L515.332 77.495L513.386 77.034ZM513.668 80.1837L469.879 69.8128L469.418 71.759L513.207 82.1299L513.668 80.1837ZM470.622 71.0163L471.543 67.124L469.597 66.6631L468.675 70.5554L470.622 71.0163Z'
        fill='#8BB6DB'
        mask='url(#path-924-inside-146_6651_98482)'
      />
      <mask id='path-926-inside-147_6651_98482' fill='white'>
        <path d='M420.25 52.7773L461.639 61.7889L459.511 71.56L418.123 62.5484L420.25 52.7773Z' />
      </mask>
      <path d='M420.25 52.7773L461.639 61.7889L459.511 71.56L418.123 62.5484L420.25 52.7773Z' fill='#ECF5FC' />
      <path
        d='M420.037 53.7545L461.426 62.766L461.851 60.8118L420.463 51.8002L420.037 53.7545ZM459.724 70.5829L418.335 61.5713L417.91 63.5255L459.298 72.5371L459.724 70.5829Z'
        fill='#CFD1D2'
        mask='url(#path-926-inside-147_6651_98482)'
      />
      <mask id='path-928-inside-148_6651_98482' fill='white'>
        <path d='M419.609 55.7085L460.648 64.6439L459.797 68.5523L418.758 59.6169L419.609 55.7085Z' />
      </mask>
      <path d='M419.609 55.7085L460.648 64.6439L459.797 68.5523L418.758 59.6169L419.609 55.7085Z' fill='#AECCE7' />
      <path
        d='M419.609 55.7085L419.822 54.7314L418.845 54.5186L418.632 55.4957L419.609 55.7085ZM460.648 64.6439L461.625 64.8566L461.838 63.8795L460.861 63.6667L460.648 64.6439ZM459.797 68.5523L459.584 69.5294L460.561 69.7421L460.774 68.765L459.797 68.5523ZM418.758 59.6169L417.781 59.4042L417.569 60.3813L418.546 60.594L418.758 59.6169ZM419.397 56.6856L460.435 65.621L460.861 63.6667L419.822 54.7314L419.397 56.6856ZM459.671 64.4311L458.82 68.3395L460.774 68.765L461.625 64.8566L459.671 64.4311ZM460.01 67.5752L418.971 58.6398L418.546 60.594L459.584 69.5294L460.01 67.5752ZM419.735 59.8297L420.586 55.9212L418.632 55.4957L417.781 59.4042L419.735 59.8297Z'
        fill='#8BB6DB'
        mask='url(#path-928-inside-148_6651_98482)'
      />
      <mask id='path-930-inside-149_6651_98482' fill='white'>
        <path d='M168.492 8.17578L243.788 19.8523L242.256 29.7341L166.96 18.0577L168.492 8.17578Z' />
      </mask>
      <path d='M168.492 8.17578L243.788 19.8523L242.256 29.7341L166.96 18.0577L168.492 8.17578Z' fill='#ECF5FC' />
      <path
        d='M168.339 9.16397L243.635 20.8405L243.941 18.8641L168.645 7.18759L168.339 9.16397ZM242.409 28.746L167.113 17.0695L166.807 19.0459L242.102 30.7223L242.409 28.746Z'
        fill='#CFD1D2'
        mask='url(#path-930-inside-149_6651_98482)'
      />
      <mask id='path-932-inside-150_6651_98482' fill='white'>
        <path d='M168.031 11.1401L243.134 22.7866L242.521 26.7394L167.418 15.0929L168.031 11.1401Z' />
      </mask>
      <path d='M168.031 11.1401L243.134 22.7866L242.521 26.7394L167.418 15.0929L168.031 11.1401Z' fill='#AECCE7' />
      <path
        d='M168.031 11.1401L168.184 10.1519L167.196 9.99871L167.043 10.9869L168.031 11.1401ZM243.134 22.7866L244.122 22.9399L244.275 21.9517L243.287 21.7984L243.134 22.7866ZM242.521 26.7394L242.367 27.7276L243.356 27.8808L243.509 26.8926L242.521 26.7394ZM167.418 15.0929L166.43 14.9396L166.277 15.9278L167.265 16.0811L167.418 15.0929ZM167.878 12.1283L242.98 23.7748L243.287 21.7984L168.184 10.1519L167.878 12.1283ZM242.145 22.6334L241.532 26.5861L243.509 26.8926L244.122 22.9399L242.145 22.6334ZM242.674 25.7512L167.572 14.1047L167.265 16.0811L242.367 27.7276L242.674 25.7512ZM168.406 15.2461L169.019 11.2934L167.043 10.9869L166.43 14.9396L168.406 15.2461Z'
        fill='#8BB6DB'
        mask='url(#path-932-inside-150_6651_98482)'
      />
      <mask id='path-934-inside-151_6651_98482' fill='white'>
        <path d='M253.805 21.8633L301.211 30.2223L299.475 40.0704L252.068 31.7114L253.805 21.8633Z' />
      </mask>
      <path d='M253.805 21.8633L301.211 30.2223L299.475 40.0704L252.068 31.7114L253.805 21.8633Z' fill='#ECF5FC' />
      <path
        d='M253.631 22.8481L301.037 31.2071L301.385 29.2375L253.978 20.8785L253.631 22.8481ZM299.648 39.0856L252.242 30.7266L251.895 32.6962L299.301 41.0552L299.648 39.0856Z'
        fill='#CFD1D2'
        mask='url(#path-934-inside-151_6651_98482)'
      />
      <mask id='path-936-inside-152_6651_98482' fill='white'>
        <path d='M253.281 24.8179L300.552 33.153L299.857 37.0922L252.587 28.7571L253.281 24.8179Z' />
      </mask>
      <path d='M253.281 24.8179L300.552 33.153L299.857 37.0922L252.587 28.7571L253.281 24.8179Z' fill='#AECCE7' />
      <path
        d='M253.281 24.8179L253.455 23.8331L252.47 23.6594L252.296 24.6442L253.281 24.8179ZM300.552 33.153L301.537 33.3266L301.71 32.3418L300.726 32.1682L300.552 33.153ZM299.857 37.0922L299.684 38.077L300.669 38.2507L300.842 37.2659L299.857 37.0922ZM252.587 28.7571L251.602 28.5835L251.428 29.5683L252.413 29.7419L252.587 28.7571ZM253.108 25.8027L300.378 34.1378L300.726 32.1682L253.455 23.8331L253.108 25.8027ZM299.567 32.9793L298.873 36.9186L300.842 37.2659L301.537 33.3266L299.567 32.9793ZM300.031 36.1074L252.76 27.7723L252.413 29.7419L299.684 38.077L300.031 36.1074ZM253.571 28.9307L254.266 24.9915L252.296 24.6442L251.602 28.5835L253.571 28.9307Z'
        fill='#8BB6DB'
        mask='url(#path-936-inside-152_6651_98482)'
      />
      <mask id='path-938-inside-153_6651_98482' fill='white'>
        <path d='M310.945 32.1719L356.236 40.2169L354.487 50.0628L309.196 42.0177L310.945 32.1719Z' />
      </mask>
      <path d='M310.945 32.1719L356.236 40.2169L354.487 50.0628L309.196 42.0177L310.945 32.1719Z' fill='#ECF5FC' />
      <path
        d='M310.77 33.1565L356.061 41.2015L356.411 39.2323L311.12 31.1873L310.77 33.1565ZM354.662 49.0782L309.371 41.0332L309.021 43.0023L354.313 51.0474L354.662 49.0782Z'
        fill='#CFD1D2'
        mask='url(#path-938-inside-153_6651_98482)'
      />
      <mask id='path-940-inside-154_6651_98482' fill='white'>
        <path d='M310.422 35.1255L355.713 43.1705L355.013 47.1089L309.722 39.0638L310.422 35.1255Z' />
      </mask>
      <path d='M310.422 35.1255L355.713 43.1705L355.013 47.1089L309.722 39.0638L310.422 35.1255Z' fill='#AECCE7' />
      <path
        d='M310.422 35.1255L310.597 34.1409L309.612 33.966L309.437 34.9506L310.422 35.1255ZM355.713 43.1705L356.697 43.3454L356.872 42.3609L355.888 42.186L355.713 43.1705ZM355.013 47.1089L354.838 48.0935L355.823 48.2684L355.998 47.2838L355.013 47.1089ZM309.722 39.0638L308.738 38.8889L308.563 39.8735L309.547 40.0484L309.722 39.0638ZM310.247 36.1101L355.538 44.1551L355.888 42.186L310.597 34.1409L310.247 36.1101ZM354.728 42.9957L354.029 46.934L355.998 47.2838L356.697 43.3454L354.728 42.9957ZM355.188 46.1243L309.897 38.0793L309.547 40.0484L354.838 48.0935L355.188 46.1243ZM310.707 39.2387L311.406 35.3004L309.437 34.9506L308.738 38.8889L310.707 39.2387Z'
        fill='#8BB6DB'
        mask='url(#path-940-inside-154_6651_98482)'
      />
      <mask id='path-942-inside-155_6651_98482' fill='white'>
        <path d='M366.062 42.0547L410.722 50.7596L408.809 60.5748L364.149 51.87L366.062 42.0547Z' />
      </mask>
      <path d='M366.062 42.0547L410.722 50.7596L408.809 60.5748L364.149 51.87L366.062 42.0547Z' fill='#ECF5FC' />
      <path
        d='M365.871 43.0362L410.531 51.7411L410.913 49.778L366.254 41.0732L365.871 43.0362ZM409 59.5933L364.341 50.8884L363.958 52.8515L408.618 61.5564L409 59.5933Z'
        fill='#CFD1D2'
        mask='url(#path-942-inside-155_6651_98482)'
      />
      <mask id='path-944-inside-156_6651_98482' fill='white'>
        <path d='M365.492 44.9995L410.642 53.8L409.877 57.7262L364.727 48.9256L365.492 44.9995Z' />
      </mask>
      <path d='M365.492 44.9995L410.642 53.8L409.877 57.7262L364.727 48.9256L365.492 44.9995Z' fill='#AECCE7' />
      <path
        d='M365.492 44.9995L365.684 44.018L364.702 43.8267L364.511 44.8082L365.492 44.9995ZM410.642 53.8L411.624 53.9914L411.815 53.0098L410.834 52.8185L410.642 53.8ZM409.877 57.7262L409.686 58.7077L410.667 58.899L410.859 57.9175L409.877 57.7262ZM364.727 48.9256L363.745 48.7343L363.554 49.7158L364.536 49.9072L364.727 48.9256ZM365.301 45.981L410.451 54.7816L410.834 52.8185L365.684 44.018L365.301 45.981ZM409.661 53.6087L408.896 57.5348L410.859 57.9175L411.624 53.9914L409.661 53.6087ZM410.069 56.7446L364.918 47.9441L364.536 49.9072L409.686 58.7077L410.069 56.7446ZM365.708 49.1169L366.474 45.1908L364.511 44.8082L363.745 48.7343L365.708 49.1169Z'
        fill='#8BB6DB'
        mask='url(#path-944-inside-156_6651_98482)'
      />
      <mask id='path-946-inside-157_6651_98482' fill='white'>
        <path d='M525.156 76.9062L569.73 88.271L567.26 97.961L522.686 86.5963L525.156 76.9062Z' />
      </mask>
      <path d='M525.156 76.9062L569.73 88.271L567.26 97.961L522.686 86.5963L525.156 76.9062Z' fill='#ECF5FC' />
      <path
        d='M524.909 77.8753L569.483 89.24L569.977 87.302L525.403 75.9372L524.909 77.8753ZM567.507 96.992L522.933 85.6273L522.439 87.5653L567.013 98.93L567.507 96.992Z'
        fill='#CFD1D2'
        mask='url(#path-946-inside-157_6651_98482)'
      />
      <mask id='path-948-inside-158_6651_98482' fill='white'>
        <path d='M524.414 79.8135L568.988 91.1782L568 95.0542L523.426 83.6895L524.414 79.8135Z' />
      </mask>
      <path d='M524.414 79.8135L568.988 91.1782L568 95.0542L523.426 83.6895L524.414 79.8135Z' fill='#AECCE7' />
      <path
        d='M524.414 79.8135L524.661 78.8445L523.692 78.5974L523.445 79.5664L524.414 79.8135ZM568.988 91.1782L569.957 91.4253L570.204 90.4563L569.235 90.2092L568.988 91.1782ZM568 95.0542L567.753 96.0232L568.722 96.2703L568.969 95.3013L568 95.0542ZM523.426 83.6895L522.457 83.4424L522.21 84.4114L523.179 84.6585L523.426 83.6895ZM524.167 80.7825L568.741 92.1472L569.235 90.2092L524.661 78.8445L524.167 80.7825ZM568.019 90.9312L567.031 94.8072L568.969 95.3013L569.957 91.4253L568.019 90.9312ZM568.247 94.0852L523.673 82.7205L523.179 84.6585L567.753 96.0232L568.247 94.0852ZM524.395 83.9365L525.383 80.0605L523.445 79.5664L522.457 83.4424L524.395 83.9365Z'
        fill='#8BB6DB'
        mask='url(#path-948-inside-158_6651_98482)'
      />
      <mask id='path-950-inside-159_6651_98482' fill='white'>
        <path d='M579.289 90.8945L623.944 103.027L621.322 112.677L576.667 100.545L579.289 90.8945Z' />
      </mask>
      <path d='M579.289 90.8945L623.944 103.027L621.322 112.677L576.667 100.545L579.289 90.8945Z' fill='#ECF5FC' />
      <path
        d='M579.027 91.8595L623.682 103.992L624.206 102.062L579.551 89.9295L579.027 91.8595ZM621.584 111.712L576.929 99.5797L576.405 101.51L621.06 113.642L621.584 111.712Z'
        fill='#CFD1D2'
        mask='url(#path-950-inside-159_6651_98482)'
      />
      <mask id='path-952-inside-160_6651_98482' fill='white'>
        <path d='M578.5 93.7896L622.891 105.85L621.842 109.71L577.451 97.6496L578.5 93.7896Z' />
      </mask>
      <path d='M578.5 93.7896L622.891 105.85L621.842 109.71L577.451 97.6496L578.5 93.7896Z' fill='#AECCE7' />
      <path
        d='M578.5 93.7896L578.762 92.8245L577.797 92.5623L577.535 93.5274L578.5 93.7896ZM622.891 105.85L623.856 106.112L624.118 105.147L623.153 104.885L622.891 105.85ZM621.842 109.71L621.58 110.675L622.545 110.938L622.807 109.973L621.842 109.71ZM577.451 97.6496L576.486 97.3874L576.224 98.3524L577.189 98.6146L577.451 97.6496ZM578.238 94.7546L622.629 106.815L623.153 104.885L578.762 92.8245L578.238 94.7546ZM621.926 105.588L620.877 109.448L622.807 109.973L623.856 106.112L621.926 105.588ZM622.104 108.745L577.713 96.6846L577.189 98.6146L621.58 110.675L622.104 108.745ZM578.416 97.9118L579.465 94.0517L577.535 93.5274L576.486 97.3874L578.416 97.9118Z'
        fill='#8BB6DB'
        mask='url(#path-952-inside-160_6651_98482)'
      />
      <mask id='path-954-inside-161_6651_98482' fill='white'>
        <path d='M633.594 105.758L677.651 118.662L674.84 128.258L630.783 115.355L633.594 105.758Z' />
      </mask>
      <path d='M633.594 105.758L677.651 118.662L674.84 128.258L630.783 115.355L633.594 105.758Z' fill='#ECF5FC' />
      <path
        d='M633.313 106.717L677.37 119.621L677.932 117.702L633.875 104.798L633.313 106.717ZM675.121 127.299L631.064 114.395L630.502 116.314L674.559 129.218L675.121 127.299Z'
        fill='#CFD1D2'
        mask='url(#path-954-inside-161_6651_98482)'
      />
      <mask id='path-956-inside-162_6651_98482' fill='white'>
        <path d='M632.75 108.637L676.896 121.566L675.771 125.405L631.626 112.475L632.75 108.637Z' />
      </mask>
      <path d='M632.75 108.637L676.896 121.566L675.771 125.405L631.626 112.475L632.75 108.637Z' fill='#AECCE7' />
      <path
        d='M632.75 108.637L633.031 107.677L632.071 107.396L631.79 108.356L632.75 108.637ZM676.896 121.566L677.855 121.847L678.136 120.888L677.177 120.607L676.896 121.566ZM675.771 125.405L675.49 126.365L676.45 126.646L676.731 125.686L675.771 125.405ZM631.626 112.475L630.666 112.194L630.385 113.154L631.345 113.435L631.626 112.475ZM632.469 109.596L676.614 122.526L677.177 120.607L633.031 107.677L632.469 109.596ZM675.936 121.285L674.812 125.124L676.731 125.686L677.855 121.847L675.936 121.285ZM676.052 124.445L631.907 111.516L631.345 113.435L675.49 126.365L676.052 124.445ZM632.585 112.757L633.71 108.918L631.79 108.356L630.666 112.194L632.585 112.757Z'
        fill='#8BB6DB'
        mask='url(#path-956-inside-162_6651_98482)'
      />
      <mask id='path-958-inside-163_6651_98482' fill='white'>
        <path d='M687.219 121.453L731.202 134.926L728.273 144.487L684.29 131.015L687.219 121.453Z' />
      </mask>
      <path d='M687.219 121.453L731.202 134.926L728.273 144.487L684.29 131.015L687.219 121.453Z' fill='#ECF5FC' />
      <path
        d='M686.926 122.409L730.909 135.882L731.494 133.97L687.512 120.497L686.926 122.409ZM728.566 143.531L684.583 130.058L683.997 131.971L727.98 145.444L728.566 143.531Z'
        fill='#CFD1D2'
        mask='url(#path-958-inside-163_6651_98482)'
      />
      <mask id='path-960-inside-164_6651_98482' fill='white'>
        <path d='M686.344 124.322L730.327 137.795L729.155 141.619L685.172 128.146L686.344 124.322Z' />
      </mask>
      <path d='M686.344 124.322L730.327 137.795L729.155 141.619L685.172 128.146L686.344 124.322Z' fill='#AECCE7' />
      <path
        d='M686.344 124.322L686.637 123.366L685.68 123.073L685.388 124.029L686.344 124.322ZM730.327 137.795L731.283 138.087L731.576 137.131L730.619 136.838L730.327 137.795ZM729.155 141.619L728.862 142.575L729.818 142.868L730.111 141.912L729.155 141.619ZM685.172 128.146L684.216 127.853L683.923 128.81L684.879 129.103L685.172 128.146ZM686.051 125.278L730.034 138.751L730.619 136.838L686.637 123.366L686.051 125.278ZM729.37 137.502L728.199 141.326L730.111 141.912L731.283 138.087L729.37 137.502ZM729.448 140.663L685.465 127.19L684.879 129.103L728.862 142.575L729.448 140.663ZM686.128 128.439L687.3 124.615L685.388 124.029L684.216 127.853L686.128 128.439Z'
        fill='#8BB6DB'
        mask='url(#path-960-inside-164_6651_98482)'
      />
      <mask id='path-962-inside-165_6651_98482' fill='white'>
        <path d='M740.727 137.906L783.18 151.669L780.097 161.182L737.643 147.419L740.727 137.906Z' />
      </mask>
      <path d='M740.727 137.906L783.18 151.669L780.097 161.182L737.643 147.419L740.727 137.906Z' fill='#ECF5FC' />
      <path
        d='M740.418 138.858L782.872 152.621L783.489 150.718L741.035 136.955L740.418 138.858ZM780.405 160.231L737.951 146.468L737.334 148.37L779.788 162.133L780.405 160.231Z'
        fill='#CFD1D2'
        mask='url(#path-962-inside-165_6651_98482)'
      />
      <mask id='path-964-inside-166_6651_98482' fill='white'>
        <path d='M739.805 140.76L782.611 154.638L781.378 158.443L738.571 144.565L739.805 140.76Z' />
      </mask>
      <path d='M739.805 140.76L782.611 154.638L781.378 158.443L738.571 144.565L739.805 140.76Z' fill='#AECCE7' />
      <path
        d='M739.805 140.76L740.113 139.809L739.162 139.501L738.853 140.452L739.805 140.76ZM782.611 154.638L783.563 154.946L783.871 153.995L782.92 153.686L782.611 154.638ZM781.378 158.443L781.069 159.394L782.021 159.702L782.329 158.751L781.378 158.443ZM738.571 144.565L737.62 144.257L737.311 145.208L738.263 145.517L738.571 144.565ZM739.496 141.712L782.303 155.589L782.92 153.686L740.113 139.809L739.496 141.712ZM781.66 154.329L780.427 158.134L782.329 158.751L783.563 154.946L781.66 154.329ZM781.686 157.491L738.88 143.614L738.263 145.517L781.069 159.394L781.686 157.491ZM739.522 144.874L740.756 141.069L738.853 140.452L737.62 144.257L739.522 144.874Z'
        fill='#8BB6DB'
        mask='url(#path-964-inside-166_6651_98482)'
      />
      <mask id='path-966-inside-167_6651_98482' fill='white'>
        <path d='M792.633 154.801L835.352 169.002L832.197 178.491L789.478 164.29L792.633 154.801Z' />
      </mask>
      <path d='M792.633 154.801L835.352 169.002L832.197 178.491L789.478 164.29L792.633 154.801Z' fill='#ECF5FC' />
      <path
        d='M792.317 155.75L835.036 169.951L835.667 168.053L792.948 153.852L792.317 155.75ZM832.513 177.542L789.794 163.341L789.163 165.239L831.882 179.44L832.513 177.542Z'
        fill='#CFD1D2'
        mask='url(#path-966-inside-167_6651_98482)'
      />
      <mask id='path-968-inside-168_6651_98482' fill='white'>
        <path d='M791.688 157.647L834.39 171.843L833.128 175.639L790.426 161.443L791.688 157.647Z' />
      </mask>
      <path d='M791.688 157.647L834.39 171.843L833.128 175.639L790.426 161.443L791.688 157.647Z' fill='#AECCE7' />
      <path
        d='M791.688 157.647L792.003 156.699L791.054 156.383L790.739 157.332L791.688 157.647ZM834.39 171.843L835.339 172.159L835.654 171.21L834.705 170.894L834.39 171.843ZM833.128 175.639L832.812 176.588L833.761 176.903L834.077 175.954L833.128 175.639ZM790.426 161.443L789.477 161.128L789.161 162.077L790.11 162.392L790.426 161.443ZM791.372 158.596L834.074 172.792L834.705 170.894L792.003 156.699L791.372 158.596ZM833.441 171.528L832.179 175.323L834.077 175.954L835.339 172.159L833.441 171.528ZM833.443 174.69L790.741 160.494L790.11 162.392L832.812 176.588L833.443 174.69ZM791.375 161.759L792.636 157.963L790.739 157.332L789.477 161.128L791.375 161.759Z'
        fill='#8BB6DB'
        mask='url(#path-968-inside-168_6651_98482)'
      />
      <mask id='path-970-inside-169_6651_98482' fill='white'>
        <path d='M844.695 172.309L887.329 187.295L884.013 196.729L841.379 181.743L844.695 172.309Z' />
      </mask>
      <path d='M844.695 172.309L887.329 187.295L884.013 196.729L841.379 181.743L844.695 172.309Z' fill='#ECF5FC' />
      <path
        d='M844.364 173.252L886.998 188.238L887.661 186.352L845.027 171.365L844.364 173.252ZM884.345 195.786L841.711 180.799L841.047 182.686L883.681 197.672L884.345 195.786Z'
        fill='#CFD1D2'
        mask='url(#path-970-inside-169_6651_98482)'
      />
      <mask id='path-972-inside-170_6651_98482' fill='white'>
        <path d='M843.703 175.139L886.157 190.062L884.83 193.835L842.377 178.912L843.703 175.139Z' />
      </mask>
      <path d='M843.703 175.139L886.157 190.062L884.83 193.835L842.377 178.912L843.703 175.139Z' fill='#AECCE7' />
      <path
        d='M843.703 175.139L844.035 174.195L843.091 173.864L842.76 174.807L843.703 175.139ZM886.157 190.062L887.1 190.393L887.432 189.45L886.488 189.118L886.157 190.062ZM884.83 193.835L884.499 194.779L885.442 195.11L885.774 194.167L884.83 193.835ZM842.377 178.912L841.433 178.581L841.102 179.524L842.045 179.856L842.377 178.912ZM843.372 176.082L885.825 191.005L886.488 189.118L844.035 174.195L843.372 176.082ZM885.213 189.73L883.887 193.504L885.774 194.167L887.1 190.393L885.213 189.73ZM885.162 192.892L842.708 177.969L842.045 179.856L884.499 194.779L885.162 192.892ZM843.32 179.244L844.647 175.47L842.76 174.807L841.433 178.581L843.32 179.244Z'
        fill='#8BB6DB'
        mask='url(#path-972-inside-170_6651_98482)'
      />
      <mask id='path-974-inside-171_6651_98482' fill='white'>
        <path d='M896.594 190.676L938.735 206.17L935.284 215.556L893.143 200.061L896.594 190.676Z' />
      </mask>
      <path d='M896.594 190.676L938.735 206.17L935.284 215.556L893.143 200.061L896.594 190.676Z' fill='#ECF5FC' />
      <path
        d='M896.249 191.614L938.39 207.108L939.08 205.231L896.939 189.737L896.249 191.614ZM935.629 214.617L893.488 199.123L892.798 201L934.939 216.494L935.629 214.617Z'
        fill='#CFD1D2'
        mask='url(#path-974-inside-171_6651_98482)'
      />
      <mask id='path-976-inside-172_6651_98482' fill='white'>
        <path d='M895.555 193.492L937.79 209.021L936.41 212.775L894.174 197.246L895.555 193.492Z' />
      </mask>
      <path d='M895.555 193.492L937.79 209.021L936.41 212.775L894.174 197.246L895.555 193.492Z' fill='#AECCE7' />
      <path
        d='M895.555 193.492L895.9 192.553L894.961 192.208L894.616 193.147L895.555 193.492ZM937.79 209.021L938.729 209.366L939.074 208.427L938.135 208.082L937.79 209.021ZM936.41 212.775L936.065 213.713L937.004 214.058L937.349 213.12L936.41 212.775ZM894.174 197.246L893.236 196.901L892.891 197.839L893.829 198.185L894.174 197.246ZM895.21 194.43L937.445 209.959L938.135 208.082L895.9 192.553L895.21 194.43ZM936.852 208.675L935.471 212.43L937.349 213.12L938.729 209.366L936.852 208.675ZM936.755 211.836L894.519 196.307L893.829 198.185L936.065 213.713L936.755 211.836ZM895.113 197.591L896.493 193.837L894.616 193.147L893.236 196.901L895.113 197.591Z'
        fill='#8BB6DB'
        mask='url(#path-976-inside-172_6651_98482)'
      />
      <mask id='path-978-inside-173_6651_98482' fill='white'>
        <path d='M948.062 209.758L989.052 225.384L985.49 234.728L944.5 219.102L948.062 209.758Z' />
      </mask>
      <path d='M948.062 209.758L989.052 225.384L985.49 234.728L944.5 219.102L948.062 209.758Z' fill='#ECF5FC' />
      <path
        d='M947.706 210.692L988.696 226.319L989.408 224.45L948.419 208.823L947.706 210.692ZM985.846 233.794L944.856 218.167L944.144 220.036L985.134 235.663L985.846 233.794Z'
        fill='#CFD1D2'
        mask='url(#path-978-inside-173_6651_98482)'
      />
      <mask id='path-980-inside-174_6651_98482' fill='white'>
        <path d='M946.992 212.561L988.106 228.235L986.681 231.972L945.567 216.299L946.992 212.561Z' />
      </mask>
      <path d='M946.992 212.561L988.106 228.235L986.681 231.972L945.567 216.299L946.992 212.561Z' fill='#AECCE7' />
      <path
        d='M946.992 212.561L947.348 211.627L946.414 211.27L946.058 212.205L946.992 212.561ZM988.106 228.235L989.04 228.591L989.396 227.657L988.462 227.3L988.106 228.235ZM986.681 231.972L986.325 232.907L987.259 233.263L987.615 232.329L986.681 231.972ZM945.567 216.299L944.633 215.942L944.277 216.877L945.211 217.233L945.567 216.299ZM946.636 213.495L987.75 229.169L988.462 227.3L947.348 211.627L946.636 213.495ZM987.171 227.879L985.747 231.616L987.615 232.329L989.04 228.591L987.171 227.879ZM987.037 231.038L945.924 215.364L945.211 217.233L986.325 232.907L987.037 231.038ZM946.502 216.655L947.927 212.917L946.058 212.205L944.633 215.942L946.502 216.655Z'
        fill='#8BB6DB'
        mask='url(#path-980-inside-174_6651_98482)'
      />
      <mask id='path-982-inside-175_6651_98482' fill='white'>
        <path d='M998.359 229.07L1039.27 245.275L1035.58 254.572L994.676 238.367L998.359 229.07Z' />
      </mask>
      <path d='M998.359 229.07L1039.27 245.275L1035.58 254.572L994.676 238.367L998.359 229.07Z' fill='#ECF5FC' />
      <path
        d='M997.991 230L1038.9 246.205L1039.63 244.345L998.728 228.141L997.991 230ZM1035.95 253.642L995.045 237.438L994.308 239.297L1035.22 255.502L1035.95 253.642Z'
        fill='#CFD1D2'
        mask='url(#path-982-inside-175_6651_98482)'
      />
      <mask id='path-984-inside-176_6651_98482' fill='white'>
        <path d='M997.258 231.859L1038.17 248.064L1036.69 251.783L995.785 235.578L997.258 231.859Z' />
      </mask>
      <path d='M997.258 231.859L1038.17 248.064L1036.69 251.783L995.785 235.578L997.258 231.859Z' fill='#AECCE7' />
      <path
        d='M997.258 231.859L997.626 230.93L996.696 230.561L996.328 231.491L997.258 231.859ZM1038.17 248.064L1039.09 248.432L1039.46 247.503L1038.53 247.134L1038.17 248.064ZM1036.69 251.783L1036.32 252.713L1037.25 253.081L1037.62 252.151L1036.69 251.783ZM995.785 235.578L994.855 235.21L994.487 236.14L995.416 236.508L995.785 235.578ZM996.89 232.789L1037.8 248.994L1038.53 247.134L997.626 230.93L996.89 232.789ZM1037.24 247.696L1035.76 251.415L1037.62 252.151L1039.09 248.432L1037.24 247.696ZM1037.06 250.853L996.153 234.649L995.416 236.508L1036.32 252.713L1037.06 250.853ZM996.714 235.947L998.188 232.228L996.328 231.491L994.855 235.21L996.714 235.947Z'
        fill='#8BB6DB'
        mask='url(#path-984-inside-176_6651_98482)'
      />
      <mask id='path-986-inside-177_6651_98482' fill='white'>
        <path d='M1048.53 249.105L1089.3 265.904L1085.5 275.15L1044.72 258.352L1048.53 249.105Z' />
      </mask>
      <path d='M1048.53 249.105L1089.3 265.904L1085.5 275.15L1044.72 258.352L1048.53 249.105Z' fill='#ECF5FC' />
      <path
        d='M1048.15 250.03L1088.92 266.828L1089.69 264.979L1048.91 248.181L1048.15 250.03ZM1085.88 274.225L1045.1 257.427L1044.34 259.276L1085.11 276.075L1085.88 274.225Z'
        fill='#CFD1D2'
        mask='url(#path-986-inside-177_6651_98482)'
      />
      <mask id='path-988-inside-178_6651_98482' fill='white'>
        <path d='M1047.39 251.879L1088.07 268.64L1086.55 272.339L1045.87 255.578L1047.39 251.879Z' />
      </mask>
      <path d='M1047.39 251.879L1088.07 268.64L1086.55 272.339L1045.87 255.578L1047.39 251.879Z' fill='#AECCE7' />
      <path
        d='M1047.39 251.879L1047.77 250.955L1046.85 250.574L1046.47 251.498L1047.39 251.879ZM1088.07 268.64L1089 269.021L1089.38 268.097L1088.45 267.716L1088.07 268.64ZM1086.55 272.339L1086.17 273.263L1087.09 273.644L1087.47 272.72L1086.55 272.339ZM1045.87 255.578L1044.94 255.197L1044.56 256.121L1045.49 256.502L1045.87 255.578ZM1047.01 252.804L1087.69 269.565L1088.45 267.716L1047.77 250.955L1047.01 252.804ZM1087.15 268.259L1085.62 271.958L1087.47 272.72L1089 269.021L1087.15 268.259ZM1086.93 271.414L1046.25 254.653L1045.49 256.502L1086.17 273.263L1086.93 271.414ZM1046.79 255.959L1048.32 252.26L1046.47 251.498L1044.94 255.197L1046.79 255.959Z'
        fill='#8BB6DB'
        mask='url(#path-988-inside-178_6651_98482)'
      />
      <mask id='path-990-inside-179_6651_98482' fill='white'>
        <path d='M1098.45 269.707L1139.84 287.373L1135.91 296.571L1094.53 278.904L1098.45 269.707Z' />
      </mask>
      <path d='M1098.45 269.707L1139.84 287.373L1135.91 296.571L1094.53 278.904L1098.45 269.707Z' fill='#ECF5FC' />
      <path
        d='M1098.06 270.627L1139.45 288.293L1140.23 286.454L1098.85 268.787L1098.06 270.627ZM1136.31 295.651L1094.92 277.984L1094.13 279.824L1135.52 297.49L1136.31 295.651Z'
        fill='#CFD1D2'
        mask='url(#path-990-inside-179_6651_98482)'
      />
      <mask id='path-992-inside-180_6651_98482' fill='white'>
        <path d='M1097.27 272.466L1138.66 290.133L1137.09 293.812L1095.7 276.145L1097.27 272.466Z' />
      </mask>
      <path d='M1097.27 272.466L1138.66 290.133L1137.09 293.812L1095.7 276.145L1097.27 272.466Z' fill='#AECCE7' />
      <path
        d='M1097.27 272.466L1097.67 271.547L1096.75 271.154L1096.35 272.074L1097.27 272.466ZM1138.66 290.133L1139.58 290.525L1139.97 289.606L1139.05 289.213L1138.66 290.133ZM1137.09 293.812L1136.7 294.731L1137.62 295.124L1138.01 294.204L1137.09 293.812ZM1095.7 276.145L1094.78 275.753L1094.39 276.672L1095.31 277.065L1095.7 276.145ZM1096.88 273.386L1138.27 291.052L1139.05 289.213L1097.67 271.547L1096.88 273.386ZM1137.74 289.74L1136.17 293.419L1138.01 294.204L1139.58 290.525L1137.74 289.74ZM1137.48 292.892L1096.1 275.225L1095.31 277.065L1136.7 294.731L1137.48 292.892ZM1096.62 276.538L1098.19 272.859L1096.35 272.074L1094.78 275.753L1096.62 276.538Z'
        fill='#8BB6DB'
        mask='url(#path-992-inside-180_6651_98482)'
      />
      <mask id='path-994-inside-181_6651_98482' fill='white'>
        <path d='M1148.97 291.336L1191.97 310.313L1187.93 319.462L1144.93 300.485L1148.97 291.336Z' />
      </mask>
      <path d='M1148.97 291.336L1191.97 310.313L1187.93 319.462L1144.93 300.485L1148.97 291.336Z' fill='#ECF5FC' />
      <path
        d='M1148.56 292.251L1191.56 311.228L1192.37 309.398L1149.37 290.421L1148.56 292.251ZM1188.33 318.547L1145.33 299.57L1144.53 301.399L1187.53 320.377L1188.33 318.547Z'
        fill='#CFD1D2'
        mask='url(#path-994-inside-181_6651_98482)'
      />
      <mask id='path-996-inside-182_6651_98482' fill='white'>
        <path d='M1147.76 294.081L1190.76 313.058L1189.14 316.717L1146.14 297.74L1147.76 294.081Z' />
      </mask>
      <path d='M1147.76 294.081L1190.76 313.058L1189.14 316.717L1146.14 297.74L1147.76 294.081Z' fill='#AECCE7' />
      <path
        d='M1147.76 294.081L1148.16 293.166L1147.25 292.762L1146.84 293.677L1147.76 294.081ZM1190.76 313.058L1191.67 313.462L1192.07 312.547L1191.16 312.143L1190.76 313.058ZM1189.14 316.717L1188.74 317.632L1189.65 318.036L1190.06 317.121L1189.14 316.717ZM1146.14 297.74L1145.23 297.336L1144.82 298.251L1145.74 298.655L1146.14 297.74ZM1147.35 294.995L1190.35 313.973L1191.16 312.143L1148.16 293.166L1147.35 294.995ZM1189.84 312.654L1188.23 316.314L1190.06 317.121L1191.67 313.462L1189.84 312.654ZM1189.54 315.802L1146.55 296.825L1145.74 298.655L1188.74 317.632L1189.54 315.802ZM1147.06 298.144L1148.67 294.484L1146.84 293.677L1145.23 297.336L1147.06 298.144Z'
        fill='#8BB6DB'
        mask='url(#path-996-inside-182_6651_98482)'
      />
      <mask id='path-998-inside-183_6651_98482' fill='white'>
        <path d='M1201.07 314.422L1242.24 333.123L1238.11 342.228L1196.93 323.527L1201.07 314.422Z' />
      </mask>
      <path d='M1201.07 314.422L1242.24 333.123L1238.11 342.228L1196.93 323.527L1201.07 314.422Z' fill='#ECF5FC' />
      <path
        d='M1200.66 315.332L1241.83 334.034L1242.66 332.213L1201.48 313.511L1200.66 315.332ZM1238.52 341.318L1197.35 322.616L1196.52 324.437L1237.69 343.139L1238.52 341.318Z'
        fill='#CFD1D2'
        mask='url(#path-998-inside-183_6651_98482)'
      />
      <mask id='path-1000-inside-184_6651_98482' fill='white'>
        <path d='M1199.83 317.153L1240.8 335.763L1239.15 339.405L1198.17 320.795L1199.83 317.153Z' />
      </mask>
      <path d='M1199.83 317.153L1240.8 335.763L1239.15 339.405L1198.17 320.795L1199.83 317.153Z' fill='#AECCE7' />
      <path
        d='M1199.83 317.153L1200.24 316.243L1199.33 315.829L1198.92 316.74L1199.83 317.153ZM1240.8 335.763L1241.71 336.177L1242.12 335.266L1241.21 334.853L1240.8 335.763ZM1239.15 339.405L1238.73 340.316L1239.64 340.729L1240.06 339.819L1239.15 339.405ZM1198.17 320.795L1197.26 320.382L1196.85 321.292L1197.76 321.706L1198.17 320.795ZM1199.41 318.064L1240.39 336.674L1241.21 334.853L1200.24 316.243L1199.41 318.064ZM1239.89 335.35L1238.24 338.992L1240.06 339.819L1241.71 336.177L1239.89 335.35ZM1239.56 338.495L1198.59 319.885L1197.76 321.706L1238.73 340.316L1239.56 338.495ZM1199.08 321.209L1200.74 317.567L1198.92 316.74L1197.26 320.382L1199.08 321.209Z'
        fill='#8BB6DB'
        mask='url(#path-1000-inside-184_6651_98482)'
      />
      <mask id='path-1002-inside-185_6651_98482' fill='white'>
        <path d='M1251.3 337.277L1290.09 355.469L1285.84 364.523L1247.05 346.331L1251.3 337.277Z' />
      </mask>
      <path d='M1251.3 337.277L1290.09 355.469L1285.84 364.523L1247.05 346.331L1251.3 337.277Z' fill='#ECF5FC' />
      <path
        d='M1250.87 338.183L1289.66 356.375L1290.51 354.564L1251.72 336.372L1250.87 338.183ZM1286.27 363.618L1247.48 345.426L1246.63 347.237L1285.42 365.429L1286.27 363.618Z'
        fill='#CFD1D2'
        mask='url(#path-1002-inside-185_6651_98482)'
      />
      <mask id='path-1004-inside-186_6651_98482' fill='white'>
        <path d='M1250.02 339.994L1288.95 358.251L1287.26 361.873L1248.33 343.615L1250.02 339.994Z' />
      </mask>
      <path d='M1250.02 339.994L1288.95 358.251L1287.26 361.873L1248.33 343.615L1250.02 339.994Z' fill='#AECCE7' />
      <path
        d='M1250.02 339.994L1250.45 339.088L1249.54 338.664L1249.12 339.569L1250.02 339.994ZM1288.95 358.251L1289.86 358.676L1290.28 357.771L1289.38 357.346L1288.95 358.251ZM1287.26 361.873L1286.83 362.778L1287.74 363.203L1288.16 362.297L1287.26 361.873ZM1248.33 343.615L1247.42 343.191L1247 344.096L1247.9 344.521L1248.33 343.615ZM1249.6 340.899L1288.53 359.157L1289.38 357.346L1250.45 339.088L1249.6 340.899ZM1288.05 357.827L1286.35 361.448L1288.16 362.297L1289.86 358.676L1288.05 357.827ZM1287.68 360.967L1248.75 342.71L1247.9 344.521L1286.83 362.778L1287.68 360.967ZM1249.23 344.04L1250.93 340.418L1249.12 339.569L1247.42 343.191L1249.23 344.04Z'
        fill='#8BB6DB'
        mask='url(#path-1004-inside-186_6651_98482)'
      />
      <mask id='path-1006-inside-187_6651_98482' fill='white'>
        <path d='M1298.8 359.554L1341.33 380.474L1336.92 389.448L1294.38 368.527L1298.8 359.554Z' />
      </mask>
      <path d='M1298.8 359.554L1341.33 380.474L1336.92 389.448L1294.38 368.527L1298.8 359.554Z' fill='#ECF5FC' />
      <path
        d='M1298.36 360.451L1340.89 381.372L1341.77 379.577L1299.24 358.656L1298.36 360.451ZM1337.36 388.55L1294.82 367.63L1293.94 369.424L1336.48 390.345L1337.36 388.55Z'
        fill='#CFD1D2'
        mask='url(#path-1006-inside-187_6651_98482)'
      />
      <mask id='path-1008-inside-188_6651_98482' fill='white'>
        <path d='M1297.48 362.246L1339.65 382.989L1337.89 386.579L1295.71 365.835L1297.48 362.246Z' />
      </mask>
      <path d='M1297.48 362.246L1339.65 382.989L1337.89 386.579L1295.71 365.835L1297.48 362.246Z' fill='#AECCE7' />
      <path
        d='M1297.48 362.246L1297.92 361.348L1297.02 360.907L1296.58 361.804L1297.48 362.246ZM1339.65 382.989L1340.55 383.431L1340.99 382.533L1340.09 382.092L1339.65 382.989ZM1337.89 386.579L1337.44 387.476L1338.34 387.917L1338.78 387.02L1337.89 386.579ZM1295.71 365.835L1294.81 365.394L1294.37 366.291L1295.27 366.732L1295.71 365.835ZM1297.04 363.143L1339.21 383.887L1340.09 382.092L1297.92 361.348L1297.04 363.143ZM1338.75 382.548L1336.99 386.137L1338.78 387.02L1340.55 383.431L1338.75 382.548ZM1338.33 385.681L1296.15 364.938L1295.27 366.732L1337.44 387.476L1338.33 385.681ZM1296.61 366.276L1298.37 362.687L1296.58 361.804L1294.81 365.394L1296.61 366.276Z'
        fill='#8BB6DB'
        mask='url(#path-1008-inside-188_6651_98482)'
      />
      <mask id='path-1010-inside-189_6651_98482' fill='white'>
        <path d='M1350.17 385.074L1386.87 404.278L1382.23 413.138L1345.53 393.934L1350.17 385.074Z' />
      </mask>
      <path d='M1350.17 385.074L1386.87 404.278L1382.23 413.138L1345.53 393.934L1350.17 385.074Z' fill='#ECF5FC' />
      <path
        d='M1349.71 385.96L1386.4 405.164L1387.33 403.392L1350.64 384.188L1349.71 385.96ZM1382.69 412.252L1346 393.048L1345.07 394.82L1381.76 414.024L1382.69 412.252Z'
        fill='#CFD1D2'
        mask='url(#path-1010-inside-189_6651_98482)'
      />
      <mask id='path-1012-inside-190_6651_98482' fill='white'>
        <path d='M1348.78 387.732L1385.11 406.744L1383.25 410.288L1346.93 391.276L1348.78 387.732Z' />
      </mask>
      <path d='M1348.78 387.732L1385.11 406.744L1383.25 410.288L1346.93 391.276L1348.78 387.732Z' fill='#AECCE7' />
      <path
        d='M1348.78 387.732L1349.24 386.846L1348.36 386.383L1347.9 387.269L1348.78 387.732ZM1385.11 406.744L1385.99 407.208L1386.46 406.322L1385.57 405.858L1385.11 406.744ZM1383.25 410.288L1382.79 411.174L1383.67 411.638L1384.14 410.752L1383.25 410.288ZM1346.93 391.276L1346.04 390.813L1345.58 391.699L1346.46 392.162L1346.93 391.276ZM1348.32 388.618L1384.64 407.63L1385.57 405.858L1349.24 386.846L1348.32 388.618ZM1384.22 406.28L1382.37 409.824L1384.14 410.752L1385.99 407.208L1384.22 406.28ZM1383.72 409.402L1347.39 390.39L1346.46 392.162L1382.79 411.174L1383.72 409.402ZM1347.81 391.74L1349.67 388.196L1347.9 387.269L1346.04 390.813L1347.81 391.74Z'
        fill='#8BB6DB'
        mask='url(#path-1012-inside-190_6651_98482)'
      />
      <mask id='path-1014-inside-191_6651_98482' fill='white'>
        <path d='M1395.78 409.148L1434.91 431.19L1430 439.903L1390.87 417.861L1395.78 409.148Z' />
      </mask>
      <path d='M1395.78 409.148L1434.91 431.19L1430 439.903L1390.87 417.861L1395.78 409.148Z' fill='#ECF5FC' />
      <path
        d='M1395.29 410.02L1434.42 432.061L1435.4 430.319L1396.27 408.277L1395.29 410.02ZM1430.49 439.031L1391.36 416.99L1390.38 418.732L1429.51 440.774L1430.49 439.031Z'
        fill='#CFD1D2'
        mask='url(#path-1014-inside-191_6651_98482)'
      />
      <mask id='path-1016-inside-192_6651_98482' fill='white'>
        <path d='M1394.31 411.762L1433.52 433.849L1431.56 437.334L1392.35 415.247L1394.31 411.762Z' />
      </mask>
      <path d='M1394.31 411.762L1433.52 433.849L1431.56 437.334L1392.35 415.247L1394.31 411.762Z' fill='#AECCE7' />
      <path
        d='M1394.31 411.762L1394.8 410.891L1393.93 410.4L1393.44 411.271L1394.31 411.762ZM1433.52 433.849L1434.39 434.34L1434.88 433.469L1434.01 432.978L1433.52 433.849ZM1431.56 437.334L1431.07 438.206L1431.94 438.696L1432.43 437.825L1431.56 437.334ZM1392.35 415.247L1391.48 414.756L1390.99 415.628L1391.86 416.119L1392.35 415.247ZM1393.82 412.633L1433.03 434.721L1434.01 432.978L1394.8 410.891L1393.82 412.633ZM1432.65 433.358L1430.68 436.843L1432.43 437.825L1434.39 434.34L1432.65 433.358ZM1432.05 436.463L1392.84 414.376L1391.86 416.119L1431.07 438.206L1432.05 436.463ZM1393.22 415.738L1395.18 412.253L1393.44 411.271L1391.48 414.756L1393.22 415.738Z'
        fill='#8BB6DB'
        mask='url(#path-1016-inside-192_6651_98482)'
      />
      <mask id='path-1018-inside-193_6651_98482' fill='white'>
        <path d='M1443.46 436.055L1484.73 460.668L1479.61 469.257L1438.34 444.644L1443.46 436.055Z' />
      </mask>
      <path d='M1443.46 436.055L1484.73 460.668L1479.61 469.257L1438.34 444.644L1443.46 436.055Z' fill='#ECF5FC' />
      <path
        d='M1442.95 436.914L1484.22 461.527L1485.25 459.809L1443.97 435.196L1442.95 436.914ZM1480.12 468.398L1438.85 443.785L1437.83 445.503L1479.1 470.115L1480.12 468.398Z'
        fill='#CFD1D2'
        mask='url(#path-1018-inside-193_6651_98482)'
      />
      <mask id='path-1020-inside-194_6651_98482' fill='white'>
        <path d='M1441.92 438.632L1483.15 463.217L1481.1 466.652L1439.87 442.067L1441.92 438.632Z' />
      </mask>
      <path d='M1441.92 438.632L1483.15 463.217L1481.1 466.652L1439.87 442.067L1441.92 438.632Z' fill='#AECCE7' />
      <path
        d='M1441.92 438.632L1442.43 437.773L1441.58 437.261L1441.06 438.12L1441.92 438.632ZM1483.15 463.217L1484.01 463.729L1484.52 462.87L1483.66 462.358L1483.15 463.217ZM1481.1 466.652L1480.59 467.511L1481.45 468.023L1481.96 467.165L1481.1 466.652ZM1439.87 442.067L1439.01 441.555L1438.5 442.414L1439.36 442.926L1439.87 442.067ZM1441.41 439.491L1482.64 464.076L1483.66 462.358L1442.43 437.773L1441.41 439.491ZM1482.29 462.705L1480.24 466.14L1481.96 467.165L1484.01 463.729L1482.29 462.705ZM1481.61 465.793L1440.39 441.208L1439.36 442.926L1480.59 467.511L1481.61 465.793ZM1440.73 442.58L1442.78 439.144L1441.06 438.12L1439.01 441.555L1440.73 442.58Z'
        fill='#8BB6DB'
        mask='url(#path-1020-inside-194_6651_98482)'
      />
      <mask id='path-1022-inside-195_6651_98482' fill='white'>
        <path d='M1493.26 465.871L1528.88 488.041L1523.59 496.531L1487.97 474.361L1493.26 465.871Z' />
      </mask>
      <path d='M1493.26 465.871L1528.88 488.041L1523.59 496.531L1487.97 474.361L1493.26 465.871Z' fill='#ECF5FC' />
      <path
        d='M1492.73 466.72L1528.35 488.89L1529.41 487.193L1493.79 465.022L1492.73 466.72ZM1524.12 495.682L1488.5 473.512L1487.45 475.21L1523.06 497.38L1524.12 495.682Z'
        fill='#CFD1D2'
        mask='url(#path-1022-inside-195_6651_98482)'
      />
      <mask id='path-1024-inside-196_6651_98482' fill='white'>
        <path d='M1491.67 468.418L1527.33 490.612L1525.22 494.008L1489.56 471.814L1491.67 468.418Z' />
      </mask>
      <path d='M1491.67 468.418L1527.33 490.612L1525.22 494.008L1489.56 471.814L1491.67 468.418Z' fill='#AECCE7' />
      <path
        d='M1491.67 468.418L1492.2 467.569L1491.35 467.041L1490.82 467.89L1491.67 468.418ZM1527.33 490.612L1528.18 491.14L1528.71 490.291L1527.86 489.763L1527.33 490.612ZM1525.22 494.008L1524.69 494.857L1525.54 495.385L1526.06 494.536L1525.22 494.008ZM1489.56 471.814L1488.71 471.285L1488.18 472.134L1489.03 472.663L1489.56 471.814ZM1491.14 469.267L1526.8 491.461L1527.86 489.763L1492.2 467.569L1491.14 469.267ZM1526.48 490.083L1524.37 493.479L1526.06 494.536L1528.18 491.14L1526.48 490.083ZM1525.74 493.159L1490.09 470.965L1489.03 472.663L1524.69 494.857L1525.74 493.159ZM1490.41 472.342L1492.52 468.946L1490.82 467.89L1488.71 471.285L1490.41 472.342Z'
        fill='#8BB6DB'
        mask='url(#path-1024-inside-196_6651_98482)'
      />
      <mask id='path-1026-inside-197_6651_98482' fill='white'>
        <path d='M1537.38 493.609L1577.23 519.866L1571.73 528.216L1531.88 501.959L1537.38 493.609Z' />
      </mask>
      <path d='M1537.38 493.609L1577.23 519.866L1571.73 528.216L1531.88 501.959L1537.38 493.609Z' fill='#ECF5FC' />
      <path
        d='M1536.83 494.444L1576.68 520.701L1577.78 519.031L1537.93 492.774L1536.83 494.444ZM1572.28 527.381L1532.43 501.124L1531.33 502.794L1571.18 529.051L1572.28 527.381Z'
        fill='#CFD1D2'
        mask='url(#path-1026-inside-197_6651_98482)'
      />
      <mask id='path-1028-inside-198_6651_98482' fill='white'>
        <path d='M1535.73 496.114L1575.82 522.525L1573.61 525.865L1533.53 499.454L1535.73 496.114Z' />
      </mask>
      <path d='M1535.73 496.114L1575.82 522.525L1573.61 525.865L1533.53 499.454L1535.73 496.114Z' fill='#AECCE7' />
      <path
        d='M1535.73 496.114L1536.28 495.279L1535.45 494.729L1534.9 495.564L1535.73 496.114ZM1575.82 522.525L1576.65 523.075L1577.2 522.24L1576.37 521.69L1575.82 522.525ZM1573.61 525.865L1573.06 526.7L1573.9 527.25L1574.45 526.415L1573.61 525.865ZM1533.53 499.454L1532.7 498.904L1532.15 499.739L1532.98 500.289L1533.53 499.454ZM1535.18 496.949L1575.26 523.36L1576.37 521.69L1536.28 495.279L1535.18 496.949ZM1574.98 521.974L1572.78 525.314L1574.45 526.415L1576.65 523.075L1574.98 521.974ZM1574.16 525.03L1534.08 498.619L1532.98 500.289L1573.06 526.7L1574.16 525.03ZM1534.37 500.004L1536.57 496.664L1534.9 495.564L1532.7 498.904L1534.37 500.004Z'
        fill='#8BB6DB'
        mask='url(#path-1028-inside-198_6651_98482)'
      />
      <mask id='path-1030-inside-199_6651_98482' fill='white'>
        <path d='M1585.56 525.336L1619.9 548.837L1614.26 557.09L1579.91 533.588L1585.56 525.336Z' />
      </mask>
      <path d='M1585.56 525.336L1619.9 548.837L1614.26 557.09L1579.91 533.588L1585.56 525.336Z' fill='#ECF5FC' />
      <path
        d='M1585 526.161L1619.34 549.662L1620.47 548.012L1586.13 524.511L1585 526.161ZM1614.82 556.264L1580.48 532.763L1579.35 534.414L1613.69 557.915L1614.82 556.264Z'
        fill='#CFD1D2'
        mask='url(#path-1030-inside-199_6651_98482)'
      />
      <mask id='path-1032-inside-200_6651_98482' fill='white'>
        <path d='M1583.87 527.812L1618.53 551.532L1616.27 554.833L1581.61 531.113L1583.87 527.812Z' />
      </mask>
      <path d='M1583.87 527.812L1618.53 551.532L1616.27 554.833L1581.61 531.113L1583.87 527.812Z' fill='#AECCE7' />
      <path
        d='M1583.87 527.812L1584.43 526.986L1583.61 526.422L1583.04 527.247L1583.87 527.812ZM1618.53 551.532L1619.35 552.097L1619.92 551.271L1619.09 550.707L1618.53 551.532ZM1616.27 554.833L1615.7 555.658L1616.53 556.223L1617.09 555.398L1616.27 554.833ZM1581.61 531.113L1580.78 530.548L1580.22 531.373L1581.04 531.938L1581.61 531.113ZM1583.3 528.637L1617.96 552.357L1619.09 550.707L1584.43 526.986L1583.3 528.637ZM1617.7 550.967L1615.44 554.268L1617.09 555.398L1619.35 552.097L1617.7 550.967ZM1616.83 554.008L1582.17 530.287L1581.04 531.938L1615.7 555.658L1616.83 554.008ZM1582.43 531.677L1584.69 528.376L1583.04 527.247L1580.78 530.548L1582.43 531.677Z'
        fill='#8BB6DB'
        mask='url(#path-1032-inside-200_6651_98482)'
      />
      <mask id='path-1034-inside-201_6651_98482' fill='white'>
        <path d='M1627.91 554.356L1666.02 581.601L1660.2 589.736L1622.1 562.491L1627.91 554.356Z' />
      </mask>
      <path d='M1627.91 554.356L1666.02 581.601L1660.2 589.736L1622.1 562.491L1627.91 554.356Z' fill='#ECF5FC' />
      <path
        d='M1627.33 555.17L1665.44 582.415L1666.6 580.788L1628.5 553.543L1627.33 555.17ZM1660.78 588.923L1622.68 561.677L1621.52 563.304L1659.62 590.549L1660.78 588.923Z'
        fill='#CFD1D2'
        mask='url(#path-1034-inside-201_6651_98482)'
      />
      <mask id='path-1036-inside-202_6651_98482' fill='white'>
        <path d='M1626.17 556.797L1664.4 584.134L1662.08 587.388L1623.85 560.051L1626.17 556.797Z' />
      </mask>
      <path d='M1626.17 556.797L1664.4 584.134L1662.08 587.388L1623.85 560.051L1626.17 556.797Z' fill='#AECCE7' />
      <path
        d='M1626.17 556.797L1626.75 555.983L1625.94 555.402L1625.36 556.215L1626.17 556.797ZM1664.4 584.134L1665.22 584.715L1665.8 583.902L1664.99 583.32L1664.4 584.134ZM1662.08 587.388L1661.5 588.201L1662.31 588.783L1662.89 587.969L1662.08 587.388ZM1623.85 560.051L1623.03 559.469L1622.45 560.282L1623.26 560.864L1623.85 560.051ZM1625.59 557.61L1663.82 584.947L1664.99 583.32L1626.75 555.983L1625.59 557.61ZM1663.59 583.552L1661.26 586.806L1662.89 587.969L1665.22 584.715L1663.59 583.552ZM1662.66 586.574L1624.43 559.237L1623.26 560.864L1661.5 588.201L1662.66 586.574ZM1624.66 560.632L1626.99 557.379L1625.36 556.215L1623.03 559.469L1624.66 560.632Z'
        fill='#8BB6DB'
        mask='url(#path-1036-inside-202_6651_98482)'
      />
      <mask id='path-1038-inside-203_6651_98482' fill='white'>
        <path d='M1674.02 587.473L1707.76 612.271L1701.83 620.328L1668.1 595.53L1674.02 587.473Z' />
      </mask>
      <path d='M1674.02 587.473L1707.76 612.271L1701.83 620.328L1668.1 595.53L1674.02 587.473Z' fill='#ECF5FC' />
      <path
        d='M1673.43 588.278L1707.16 613.077L1708.35 611.465L1674.62 586.667L1673.43 588.278ZM1702.43 619.523L1668.69 594.724L1667.51 596.336L1701.24 621.134L1702.43 619.523Z'
        fill='#CFD1D2'
        mask='url(#path-1038-inside-203_6651_98482)'
      />
      <mask id='path-1040-inside-204_6651_98482' fill='white'>
        <path d='M1672.25 589.89L1706.09 614.766L1703.72 617.989L1669.88 593.113L1672.25 589.89Z' />
      </mask>
      <path d='M1672.25 589.89L1706.09 614.766L1703.72 617.989L1669.88 593.113L1672.25 589.89Z' fill='#AECCE7' />
      <path
        d='M1672.25 589.89L1672.84 589.084L1672.04 588.492L1671.44 589.297L1672.25 589.89ZM1706.09 614.766L1706.9 615.359L1707.49 614.553L1706.68 613.961L1706.09 614.766ZM1703.72 617.989L1703.13 618.795L1703.93 619.387L1704.53 618.582L1703.72 617.989ZM1669.88 593.113L1669.08 592.52L1668.48 593.326L1669.29 593.918L1669.88 593.113ZM1671.66 590.695L1705.5 615.572L1706.68 613.961L1672.84 589.084L1671.66 590.695ZM1705.28 614.174L1702.92 617.397L1704.53 618.582L1706.9 615.359L1705.28 614.174ZM1704.31 617.184L1670.47 592.307L1669.29 593.918L1703.13 618.795L1704.31 617.184ZM1670.69 593.705L1673.06 590.482L1671.44 589.297L1669.08 592.52L1670.69 593.705Z'
        fill='#8BB6DB'
        mask='url(#path-1040-inside-204_6651_98482)'
      />
      <mask id='path-1042-inside-205_6651_98482' fill='white'>
        <path d='M1715.88 618.398L1752.85 646.742L1746.77 654.678L1709.79 626.335L1715.88 618.398Z' />
      </mask>
      <path d='M1715.88 618.398L1752.85 646.742L1746.77 654.678L1709.79 626.335L1715.88 618.398Z' fill='#ECF5FC' />
      <path
        d='M1715.27 619.192L1752.24 647.536L1753.46 645.948L1716.48 617.605L1715.27 619.192ZM1747.37 653.885L1710.4 625.541L1709.18 627.128L1746.16 655.472L1747.37 653.885Z'
        fill='#CFD1D2'
        mask='url(#path-1042-inside-205_6651_98482)'
      />
      <mask id='path-1044-inside-206_6651_98482' fill='white'>
        <path d='M1714.05 620.779L1751.35 649.374L1748.91 652.548L1711.61 623.954L1714.05 620.779Z' />
      </mask>
      <path d='M1714.05 620.779L1751.35 649.374L1748.91 652.548L1711.61 623.954L1714.05 620.779Z' fill='#AECCE7' />
      <path
        d='M1714.05 620.779L1714.66 619.986L1713.86 619.377L1713.25 620.171L1714.05 620.779ZM1751.35 649.374L1752.14 649.982L1752.75 649.188L1751.96 648.58L1751.35 649.374ZM1748.91 652.548L1748.31 653.342L1749.1 653.95L1749.71 653.157L1748.91 652.548ZM1711.61 623.954L1710.82 623.345L1710.21 624.139L1711 624.747L1711.61 623.954ZM1713.44 621.573L1750.74 650.167L1751.96 648.58L1714.66 619.986L1713.44 621.573ZM1750.55 648.765L1748.12 651.94L1749.71 653.157L1752.14 649.982L1750.55 648.765ZM1749.52 651.755L1712.22 623.16L1711 624.747L1748.31 653.342L1749.52 651.755ZM1712.41 624.562L1714.84 621.388L1713.25 620.171L1710.82 623.345L1712.41 624.562Z'
        fill='#8BB6DB'
        mask='url(#path-1044-inside-206_6651_98482)'
      />
      <mask id='path-1046-inside-207_6651_98482' fill='white'>
        <path d='M1760.7 652.922L1794.75 679.727L1788.57 687.585L1754.51 660.78L1760.7 652.922Z' />
      </mask>
      <path d='M1760.7 652.922L1794.75 679.727L1788.57 687.585L1754.51 660.78L1760.7 652.922Z' fill='#ECF5FC' />
      <path
        d='M1760.08 653.708L1794.13 680.513L1795.37 678.942L1761.31 652.136L1760.08 653.708ZM1789.19 686.8L1755.13 659.994L1753.89 661.566L1787.95 688.371L1789.19 686.8Z'
        fill='#CFD1D2'
        mask='url(#path-1046-inside-207_6651_98482)'
      />
      <mask id='path-1048-inside-208_6651_98482' fill='white'>
        <path d='M1758.84 655.279L1792.63 681.874L1790.16 685.017L1756.37 658.423L1758.84 655.279Z' />
      </mask>
      <path d='M1758.84 655.279L1792.63 681.874L1790.16 685.017L1756.37 658.423L1758.84 655.279Z' fill='#AECCE7' />
      <path
        d='M1758.84 655.279L1759.46 654.493L1758.68 653.875L1758.06 654.661L1758.84 655.279ZM1792.63 681.874L1793.42 682.492L1794.04 681.707L1793.25 681.088L1792.63 681.874ZM1790.16 685.017L1789.54 685.803L1790.33 686.421L1790.95 685.636L1790.16 685.017ZM1756.37 658.423L1755.58 657.804L1754.97 658.59L1755.75 659.208L1756.37 658.423ZM1758.23 656.065L1792.01 682.66L1793.25 681.088L1759.46 654.493L1758.23 656.065ZM1791.85 681.255L1789.37 684.399L1790.95 685.636L1793.42 682.492L1791.85 681.255ZM1790.78 684.231L1756.99 657.637L1755.75 659.208L1789.54 685.803L1790.78 684.231ZM1757.16 659.041L1759.63 655.898L1758.06 654.661L1755.58 657.804L1757.16 659.041Z'
        fill='#8BB6DB'
        mask='url(#path-1048-inside-208_6651_98482)'
      />
      <mask id='path-1050-inside-209_6651_98482' fill='white'>
        <path d='M1802.72 686.062L1839.12 715.893L1832.78 723.627L1796.38 693.796L1802.72 686.062Z' />
      </mask>
      <path d='M1802.72 686.062L1839.12 715.893L1832.78 723.627L1796.38 693.796L1802.72 686.062Z' fill='#ECF5FC' />
      <path
        d='M1802.08 686.835L1838.48 716.666L1839.75 715.119L1803.35 685.289L1802.08 686.835ZM1833.41 722.853L1797.01 693.023L1795.75 694.57L1832.14 724.4L1833.41 722.853Z'
        fill='#CFD1D2'
        mask='url(#path-1050-inside-209_6651_98482)'
      />
      <mask id='path-1052-inside-210_6651_98482' fill='white'>
        <path d='M1800.82 688.382L1837.17 718.176L1834.64 721.269L1798.28 691.476L1800.82 688.382Z' />
      </mask>
      <path d='M1800.82 688.382L1837.17 718.176L1834.64 721.269L1798.28 691.476L1800.82 688.382Z' fill='#AECCE7' />
      <path
        d='M1800.82 688.382L1801.45 687.609L1800.68 686.975L1800.05 687.748L1800.82 688.382ZM1837.17 718.176L1837.94 718.809L1838.58 718.036L1837.8 717.402L1837.17 718.176ZM1834.64 721.269L1834 722.043L1834.77 722.677L1835.41 721.903L1834.64 721.269ZM1798.28 691.476L1797.51 690.842L1796.88 691.616L1797.65 692.249L1798.28 691.476ZM1800.19 689.156L1836.54 718.949L1837.8 717.402L1801.45 687.609L1800.19 689.156ZM1836.4 717.542L1833.86 720.635L1835.41 721.903L1837.94 718.809L1836.4 717.542ZM1835.27 720.496L1798.92 690.703L1797.65 692.249L1834 722.043L1835.27 720.496ZM1799.06 692.11L1801.59 689.016L1800.05 687.748L1797.51 690.842L1799.06 692.11Z'
        fill='#8BB6DB'
        mask='url(#path-1052-inside-210_6651_98482)'
      />
      <mask id='path-1054-inside-211_6651_98482' fill='white'>
        <path d='M1846.94 722.086L1882.39 751.693L1875.98 759.369L1840.53 729.761L1846.94 722.086Z' />
      </mask>
      <path d='M1846.94 722.086L1882.39 751.693L1875.98 759.369L1840.53 729.761L1846.94 722.086Z' fill='#ECF5FC' />
      <path
        d='M1846.3 722.853L1881.75 752.461L1883.03 750.925L1847.58 721.318L1846.3 722.853ZM1876.62 758.601L1841.17 728.994L1839.89 730.529L1875.34 760.136L1876.62 758.601Z'
        fill='#CFD1D2'
        mask='url(#path-1054-inside-211_6651_98482)'
      />
      <mask id='path-1056-inside-212_6651_98482' fill='white'>
        <path d='M1845.02 724.389L1880.32 753.874L1877.76 756.944L1842.45 727.459L1845.02 724.389Z' />
      </mask>
      <path d='M1845.02 724.389L1880.32 753.874L1877.76 756.944L1842.45 727.459L1845.02 724.389Z' fill='#AECCE7' />
      <path
        d='M1845.02 724.389L1845.66 723.621L1844.89 722.98L1844.25 723.748L1845.02 724.389ZM1880.32 753.874L1881.09 754.515L1881.73 753.747L1880.96 753.106L1880.32 753.874ZM1877.76 756.944L1877.12 757.712L1877.89 758.353L1878.53 757.585L1877.76 756.944ZM1842.45 727.459L1841.68 726.818L1841.04 727.585L1841.81 728.226L1842.45 727.459ZM1844.37 725.156L1879.68 754.642L1880.96 753.106L1845.66 723.621L1844.37 725.156ZM1879.56 753.233L1876.99 756.303L1878.53 757.585L1881.09 754.515L1879.56 753.233ZM1878.4 756.177L1843.09 726.691L1841.81 728.226L1877.12 757.712L1878.4 756.177ZM1843.22 728.1L1845.78 725.03L1844.25 723.748L1841.68 726.818L1843.22 728.1Z'
        fill='#8BB6DB'
        mask='url(#path-1056-inside-212_6651_98482)'
      />
      <mask id='path-1058-inside-213_6651_98482' fill='white'>
        <path d='M1889.71 758.188L1922.25 786.301L1915.71 793.867L1883.17 765.754L1889.71 758.188Z' />
      </mask>
      <path d='M1889.71 758.188L1922.25 786.301L1915.71 793.867L1883.17 765.754L1889.71 758.188Z' fill='#ECF5FC' />
      <path
        d='M1889.06 758.944L1921.59 787.057L1922.9 785.544L1890.36 757.431L1889.06 758.944ZM1916.36 793.111L1883.83 764.998L1882.52 766.511L1915.06 794.624L1916.36 793.111Z'
        fill='#CFD1D2'
        mask='url(#path-1058-inside-213_6651_98482)'
      />
      <mask id='path-1060-inside-214_6651_98482' fill='white'>
        <path d='M1887.75 760.458L1920.29 788.571L1917.67 791.597L1885.13 763.484L1887.75 760.458Z' />
      </mask>
      <path d='M1887.75 760.458L1920.29 788.571L1917.67 791.597L1885.13 763.484L1887.75 760.458Z' fill='#AECCE7' />
      <path
        d='M1887.75 760.458L1888.4 759.701L1887.65 759.047L1886.99 759.804L1887.75 760.458ZM1920.29 788.571L1921.04 789.224L1921.7 788.468L1920.94 787.814L1920.29 788.571ZM1917.67 791.597L1917.02 792.354L1917.77 793.008L1918.43 792.251L1917.67 791.597ZM1885.13 763.484L1884.38 762.83L1883.72 763.587L1884.48 764.241L1885.13 763.484ZM1887.1 761.214L1919.63 789.327L1920.94 787.814L1888.4 759.701L1887.1 761.214ZM1919.53 787.917L1916.92 790.944L1918.43 792.251L1921.04 789.224L1919.53 787.917ZM1918.33 790.841L1885.79 762.728L1884.48 764.241L1917.02 792.354L1918.33 790.841ZM1885.89 764.138L1888.51 761.111L1886.99 759.804L1884.38 762.83L1885.89 764.138Z'
        fill='#8BB6DB'
        mask='url(#path-1060-inside-214_6651_98482)'
      />
      <mask id='path-1062-inside-215_6651_98482' fill='white'>
        <path d='M1929.7 792.906L1962.84 822.223L1956.21 829.713L1923.07 800.396L1929.7 792.906Z' />
      </mask>
      <path d='M1929.7 792.906L1962.84 822.223L1956.21 829.713L1923.07 800.396L1929.7 792.906Z' fill='#ECF5FC' />
      <path
        d='M1929.03 793.655L1962.18 822.972L1963.5 821.474L1930.36 792.157L1929.03 793.655ZM1956.88 828.964L1923.73 799.647L1922.41 801.145L1955.55 830.462L1956.88 828.964Z'
        fill='#CFD1D2'
        mask='url(#path-1062-inside-215_6651_98482)'
      />
      <mask id='path-1064-inside-216_6651_98482' fill='white'>
        <path d='M1927.71 795.153L1960.67 824.305L1958.02 827.302L1925.06 798.149L1927.71 795.153Z' />
      </mask>
      <path d='M1927.71 795.153L1960.67 824.305L1958.02 827.302L1925.06 798.149L1927.71 795.153Z' fill='#AECCE7' />
      <path
        d='M1927.71 795.153L1928.37 794.404L1927.62 793.742L1926.96 794.491L1927.71 795.153ZM1960.67 824.305L1961.42 824.968L1962.08 824.219L1961.33 823.556L1960.67 824.305ZM1958.02 827.302L1957.36 828.051L1958.1 828.713L1958.77 827.964L1958.02 827.302ZM1925.06 798.149L1924.31 797.487L1923.65 798.236L1924.4 798.898L1925.06 798.149ZM1927.05 795.902L1960.01 825.054L1961.33 823.556L1928.37 794.404L1927.05 795.902ZM1959.92 823.643L1957.27 826.639L1958.77 827.964L1961.42 824.968L1959.92 823.643ZM1958.68 826.553L1925.72 797.4L1924.4 798.898L1957.36 828.051L1958.68 826.553ZM1925.81 798.812L1928.46 795.816L1926.96 794.491L1924.31 797.487L1925.81 798.812Z'
        fill='#8BB6DB'
        mask='url(#path-1064-inside-216_6651_98482)'
      />
      <mask id='path-1066-inside-217_6651_98482' fill='white'>
        <path d='M1969.98 828.707L2005.89 861.271L1999.17 868.68L1963.26 836.115L1969.98 828.707Z' />
      </mask>
      <path d='M1969.98 828.707L2005.89 861.271L1999.17 868.68L1963.26 836.115L1969.98 828.707Z' fill='#ECF5FC' />
      <path
        d='M1969.3 829.448L2005.22 862.012L2006.56 860.531L1970.65 827.966L1969.3 829.448ZM1999.85 867.939L1963.93 835.374L1962.59 836.856L1998.5 869.42L1999.85 867.939Z'
        fill='#CFD1D2'
        mask='url(#path-1066-inside-217_6651_98482)'
      />
      <mask id='path-1068-inside-218_6651_98482' fill='white'>
        <path d='M1967.96 830.93L2003.52 863.171L2000.83 866.135L1965.27 833.893L1967.96 830.93Z' />
      </mask>
      <path d='M1967.96 830.93L2003.52 863.171L2000.83 866.135L1965.27 833.893L1967.96 830.93Z' fill='#AECCE7' />
      <path
        d='M1967.96 830.93L1968.63 830.189L1967.89 829.517L1967.22 830.258L1967.96 830.93ZM2003.52 863.171L2004.26 863.843L2004.93 863.102L2004.19 862.431L2003.52 863.171ZM2000.83 866.135L2000.16 866.876L2000.9 867.547L2001.57 866.806L2000.83 866.135ZM1965.27 833.893L1964.53 833.221L1963.86 833.962L1964.6 834.634L1965.27 833.893ZM1967.29 831.671L2002.85 863.912L2004.19 862.431L1968.63 830.189L1967.29 831.671ZM2002.78 862.5L2000.09 865.463L2001.57 866.806L2004.26 863.843L2002.78 862.5ZM2001.51 865.394L1965.95 833.152L1964.6 834.634L2000.16 866.876L2001.51 865.394ZM1966.01 834.565L1968.7 831.601L1967.22 830.258L1964.53 833.221L1966.01 834.565Z'
        fill='#8BB6DB'
        mask='url(#path-1068-inside-218_6651_98482)'
      />
      <mask id='path-1070-inside-219_6651_98482' fill='white'>
        <path d='M2013.16 868.027L2048.45 901.086L2041.62 908.385L2006.32 875.326L2013.16 868.027Z' />
      </mask>
      <path d='M2013.16 868.027L2048.45 901.086L2041.62 908.385L2006.32 875.326L2013.16 868.027Z' fill='#ECF5FC' />
      <path
        d='M2012.47 868.757L2047.77 901.816L2049.14 900.356L2013.84 867.297L2012.47 868.757ZM2042.3 907.655L2007 874.596L2005.64 876.056L2040.94 909.115L2042.3 907.655Z'
        fill='#CFD1D2'
        mask='url(#path-1070-inside-219_6651_98482)'
      />
      <mask id='path-1072-inside-220_6651_98482' fill='white'>
        <path d='M2011.11 870.217L2046.14 903.028L2043.41 905.948L2008.38 873.136L2011.11 870.217Z' />
      </mask>
      <path d='M2011.11 870.217L2046.14 903.028L2043.41 905.948L2008.38 873.136L2011.11 870.217Z' fill='#AECCE7' />
      <path
        d='M2011.11 870.217L2011.79 869.487L2011.06 868.803L2010.38 869.533L2011.11 870.217ZM2046.14 903.028L2046.87 903.712L2047.56 902.982L2046.83 902.299L2046.14 903.028ZM2043.41 905.948L2042.73 906.678L2043.46 907.361L2044.14 906.631L2043.41 905.948ZM2008.38 873.136L2007.65 872.453L2006.96 873.183L2007.69 873.866L2008.38 873.136ZM2010.43 870.947L2045.46 903.758L2046.83 902.299L2011.79 869.487L2010.43 870.947ZM2045.41 902.345L2042.68 905.264L2044.14 906.631L2046.87 903.712L2045.41 902.345ZM2044.09 905.218L2009.06 872.406L2007.69 873.866L2042.73 906.678L2044.09 905.218ZM2009.1 873.82L2011.84 870.9L2010.38 869.533L2007.65 872.453L2009.1 873.82Z'
        fill='#8BB6DB'
        mask='url(#path-1072-inside-220_6651_98482)'
      />
      <mask id='path-1074-inside-221_6651_98482' fill='white'>
        <path d='M2055.8 908.123L2091.17 942.108L2084.25 949.319L2048.87 915.335L2055.8 908.123Z' />
      </mask>
      <path d='M2055.8 908.123L2091.17 942.108L2084.25 949.319L2048.87 915.335L2055.8 908.123Z' fill='#ECF5FC' />
      <path
        d='M2055.1 908.844L2090.48 942.829L2091.87 941.387L2056.49 907.402L2055.1 908.844ZM2084.94 948.598L2049.56 914.613L2048.18 916.056L2083.55 950.04L2084.94 948.598Z'
        fill='#CFD1D2'
        mask='url(#path-1074-inside-221_6651_98482)'
      />
      <mask id='path-1076-inside-222_6651_98482' fill='white'>
        <path d='M2053.72 910.287L2089.05 944.233L2086.28 947.118L2050.95 913.171L2053.72 910.287Z' />
      </mask>
      <path d='M2053.72 910.287L2089.05 944.233L2086.28 947.118L2050.95 913.171L2053.72 910.287Z' fill='#AECCE7' />
      <path
        d='M2053.72 910.287L2054.41 909.565L2053.69 908.873L2053 909.594L2053.72 910.287ZM2089.05 944.233L2089.78 944.926L2090.47 944.205L2089.75 943.512L2089.05 944.233ZM2086.28 947.118L2085.59 947.839L2086.31 948.531L2087 947.81L2086.28 947.118ZM2050.95 913.171L2050.23 912.478L2049.53 913.2L2050.25 913.892L2050.95 913.171ZM2053.03 911.008L2088.36 944.954L2089.75 943.512L2054.41 909.565L2053.03 911.008ZM2088.33 943.54L2085.56 946.425L2087 947.81L2089.78 944.926L2088.33 943.54ZM2086.98 946.396L2051.64 912.45L2050.25 913.892L2085.59 947.839L2086.98 946.396ZM2051.67 913.864L2054.44 910.979L2053 909.594L2050.23 912.478L2051.67 913.864Z'
        fill='#8BB6DB'
        mask='url(#path-1076-inside-222_6651_98482)'
      />
      <mask id='path-1078-inside-223_6651_98482' fill='white'>
        <path d='M2098.29 949.109L2130.88 981.616L2123.82 988.696L2091.23 956.189L2098.29 949.109Z' />
      </mask>
      <path d='M2098.29 949.109L2130.88 981.616L2123.82 988.696L2091.23 956.189L2098.29 949.109Z' fill='#ECF5FC' />
      <path
        d='M2097.58 949.817L2130.17 982.324L2131.59 980.908L2099 948.401L2097.58 949.817ZM2124.52 987.988L2091.93 955.481L2090.52 956.897L2123.11 989.404L2124.52 987.988Z'
        fill='#CFD1D2'
        mask='url(#path-1078-inside-223_6651_98482)'
      />
      <mask id='path-1080-inside-224_6651_98482' fill='white'>
        <path d='M2096.17 951.233L2128.74 983.719L2125.92 986.551L2093.35 954.065L2096.17 951.233Z' />
      </mask>
      <path d='M2096.17 951.233L2128.74 983.719L2125.92 986.551L2093.35 954.065L2096.17 951.233Z' fill='#AECCE7' />
      <path
        d='M2096.17 951.233L2096.88 950.525L2096.17 949.819L2095.46 950.527L2096.17 951.233ZM2128.74 983.719L2129.45 984.425L2130.15 983.717L2129.45 983.011L2128.74 983.719ZM2125.92 986.551L2125.21 987.259L2125.92 987.965L2126.62 987.257L2125.92 986.551ZM2093.35 954.065L2092.64 953.359L2091.93 954.067L2092.64 954.773L2093.35 954.065ZM2095.47 951.941L2128.03 984.427L2129.45 983.011L2096.88 950.525L2095.47 951.941ZM2128.03 983.013L2125.21 985.845L2126.62 987.257L2129.45 984.425L2128.03 983.013ZM2126.62 985.843L2094.05 953.357L2092.64 954.773L2125.21 987.259L2126.62 985.843ZM2094.06 954.772L2096.88 951.94L2095.46 950.527L2092.64 953.359L2094.06 954.772Z'
        fill='#8BB6DB'
        mask='url(#path-1080-inside-224_6651_98482)'
      />
      <mask id='path-1082-inside-225_6651_98482' fill='white'>
        <path d='M2137.95 988.527L2170.36 1021.71L2163.21 1028.7L2130.8 995.514L2137.95 988.527Z' />
      </mask>
      <path d='M2137.95 988.527L2170.36 1021.71L2163.21 1028.7L2130.8 995.514L2137.95 988.527Z' fill='#ECF5FC' />
      <path
        d='M2137.24 989.226L2169.65 1022.41L2171.08 1021.01L2138.67 987.829L2137.24 989.226ZM2163.93 1028L2131.51 994.816L2130.08 996.213L2162.49 1029.4L2163.93 1028Z'
        fill='#CFD1D2'
        mask='url(#path-1082-inside-225_6651_98482)'
      />
      <mask id='path-1084-inside-226_6651_98482' fill='white'>
        <path d='M2135.8 990.624L2167.95 1023.53L2165.08 1026.33L2132.94 993.418L2135.8 990.624Z' />
      </mask>
      <path d='M2135.8 990.624L2167.95 1023.53L2165.08 1026.33L2132.94 993.418L2135.8 990.624Z' fill='#AECCE7' />
      <path
        d='M2135.8 990.624L2136.52 989.925L2135.82 989.209L2135.11 989.908L2135.8 990.624ZM2167.95 1023.53L2168.64 1024.25L2169.36 1023.55L2168.66 1022.83L2167.95 1023.53ZM2165.08 1026.33L2164.37 1027.03L2165.07 1027.74L2165.78 1027.04L2165.08 1026.33ZM2132.94 993.418L2132.24 992.703L2131.53 993.402L2132.23 994.117L2132.94 993.418ZM2135.09 991.322L2167.23 1024.23L2168.66 1022.83L2136.52 989.925L2135.09 991.322ZM2167.25 1022.82L2164.38 1025.61L2165.78 1027.04L2168.64 1024.25L2167.25 1022.82ZM2165.8 1025.63L2133.66 992.72L2132.23 994.117L2164.37 1027.03L2165.8 1025.63ZM2133.64 994.134L2136.5 991.339L2135.11 989.908L2132.24 992.703L2133.64 994.134Z'
        fill='#8BB6DB'
        mask='url(#path-1084-inside-226_6651_98482)'
      />
      <mask id='path-1086-inside-227_6651_98482' fill='white'>
        <path d='M2177.27 1028.96L2206.1 1059.7L2198.81 1066.54L2169.98 1035.81L2177.27 1028.96Z' />
      </mask>
      <path d='M2177.27 1028.96L2206.1 1059.7L2198.81 1066.54L2169.98 1035.81L2177.27 1028.96Z' fill='#ECF5FC' />
      <path
        d='M2176.54 1029.65L2205.37 1060.38L2206.83 1059.01L2178 1028.28L2176.54 1029.65ZM2199.54 1065.85L2170.71 1035.12L2169.25 1036.49L2198.08 1067.22L2199.54 1065.85Z'
        fill='#CFD1D2'
        mask='url(#path-1086-inside-227_6651_98482)'
      />
      <mask id='path-1088-inside-228_6651_98482' fill='white'>
        <path d='M2175.09 1031.02L2203.82 1061.65L2200.9 1064.39L2172.17 1033.75L2175.09 1031.02Z' />
      </mask>
      <path d='M2175.09 1031.02L2203.82 1061.65L2200.9 1064.39L2172.17 1033.75L2175.09 1031.02Z' fill='#AECCE7' />
      <path
        d='M2175.09 1031.02L2175.82 1030.33L2175.13 1029.6L2174.4 1030.29L2175.09 1031.02ZM2203.82 1061.65L2204.5 1062.38L2205.23 1061.69L2204.55 1060.97L2203.82 1061.65ZM2200.9 1064.39L2200.17 1065.07L2200.86 1065.8L2201.59 1065.12L2200.9 1064.39ZM2172.17 1033.75L2171.48 1033.02L2170.76 1033.71L2171.44 1034.44L2172.17 1033.75ZM2174.36 1031.7L2203.09 1062.33L2204.55 1060.97L2175.82 1030.33L2174.36 1031.7ZM2203.14 1060.92L2200.22 1063.66L2201.59 1065.12L2204.5 1062.38L2203.14 1060.92ZM2201.63 1063.7L2172.9 1033.07L2171.44 1034.44L2200.17 1065.07L2201.63 1063.7ZM2172.85 1034.48L2175.77 1031.75L2174.4 1030.29L2171.48 1033.02L2172.85 1034.48Z'
        fill='#8BB6DB'
        mask='url(#path-1088-inside-228_6651_98482)'
      />
      <mask id='path-1090-inside-229_6651_98482' fill='white'>
        <path d='M464.117 2088.12L510.392 2077.18L508.089 2067.45L461.815 2078.39L464.117 2088.12Z' />
      </mask>
      <path d='M464.117 2088.12L510.392 2077.18L508.089 2067.45L461.815 2078.39L464.117 2088.12Z' fill='#ECF5FC' />
      <path
        d='M463.887 2087.15L510.161 2076.2L510.622 2078.15L464.347 2089.1L463.887 2087.15ZM508.32 2068.42L462.045 2079.37L461.585 2077.42L507.859 2066.47L508.32 2068.42Z'
        fill='#CFD1D2'
        mask='url(#path-1090-inside-229_6651_98482)'
      />
      <mask id='path-1092-inside-230_6651_98482' fill='white'>
        <path d='M463.43 2085.21L510.14 2074.15L509.219 2070.26L462.509 2081.31L463.43 2085.21Z' />
      </mask>
      <path d='M463.43 2085.21L510.14 2074.15L509.219 2070.26L462.509 2081.31L463.43 2085.21Z' fill='#AECCE7' />
      <path
        d='M463.43 2085.21L463.66 2086.18L462.687 2086.41L462.457 2085.44L463.43 2085.21ZM510.14 2074.15L511.113 2073.92L511.344 2074.9L510.37 2075.13L510.14 2074.15ZM509.219 2070.26L508.989 2069.29L509.962 2069.06L510.193 2070.03L509.219 2070.26ZM462.509 2081.31L461.536 2081.54L461.305 2080.57L462.279 2080.34L462.509 2081.31ZM463.199 2084.23L509.91 2073.18L510.37 2075.13L463.66 2086.18L463.199 2084.23ZM509.167 2074.38L508.246 2070.49L510.193 2070.03L511.113 2073.92L509.167 2074.38ZM509.45 2071.24L462.739 2082.29L462.279 2080.34L508.989 2069.29L509.45 2071.24ZM463.482 2081.08L464.403 2084.98L462.457 2085.44L461.536 2081.54L463.482 2081.08Z'
        fill='#8BB6DB'
        mask='url(#path-1092-inside-230_6651_98482)'
      />
      <mask id='path-1094-inside-231_6651_98482' fill='white'>
        <path d='M405.375 2100.68L454.22 2090.61L452.2 2080.81L403.355 2090.89L405.375 2100.68Z' />
      </mask>
      <path d='M405.375 2100.68L454.22 2090.61L452.2 2080.81L403.355 2090.89L405.375 2100.68Z' fill='#ECF5FC' />
      <path
        d='M405.173 2099.7L454.018 2089.63L454.422 2091.59L405.577 2101.66L405.173 2099.7ZM452.402 2081.79L403.557 2091.87L403.153 2089.91L451.998 2079.84L452.402 2081.79Z'
        fill='#CFD1D2'
        mask='url(#path-1094-inside-231_6651_98482)'
      />
      <mask id='path-1096-inside-232_6651_98482' fill='white'>
        <path d='M404.766 2097.75L453.735 2087.64L452.927 2083.73L403.958 2093.83L404.766 2097.75Z' />
      </mask>
      <path d='M404.766 2097.75L453.735 2087.64L452.927 2083.73L403.958 2093.83L404.766 2097.75Z' fill='#AECCE7' />
      <path
        d='M404.766 2097.75L404.968 2098.72L403.988 2098.93L403.786 2097.95L404.766 2097.75ZM453.735 2087.64L454.714 2087.44L454.916 2088.42L453.937 2088.62L453.735 2087.64ZM452.927 2083.73L452.725 2082.75L453.704 2082.55L453.906 2083.53L452.927 2083.73ZM403.958 2093.83L402.978 2094.03L402.776 2093.05L403.756 2092.85L403.958 2093.83ZM404.564 2096.77L453.533 2086.67L453.937 2088.62L404.968 2098.72L404.564 2096.77ZM452.755 2087.85L451.947 2083.93L453.906 2083.53L454.714 2087.44L452.755 2087.85ZM453.129 2084.71L404.16 2094.81L403.756 2092.85L452.725 2082.75L453.129 2084.71ZM404.937 2093.63L405.745 2097.54L403.786 2097.95L402.978 2094.03L404.937 2093.63Z'
        fill='#8BB6DB'
        mask='url(#path-1096-inside-232_6651_98482)'
      />
      <mask id='path-1098-inside-233_6651_98482' fill='white'>
        <path d='M177.398 2142.69L227.449 2134.48L225.829 2124.61L175.779 2132.82L177.398 2142.69Z' />
      </mask>
    </>
  );
};
