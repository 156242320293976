import React, { memo, useLayoutEffect, useRef } from 'react';
import { CityPatch1 } from './CityPatch1';
import { CityPatch2 } from './CityPatch2';
import { CityPatch3 } from './CityPatch3';
import { CityPatch4 } from './CityPatch4';
import { CityPatch5 } from './CityPatch5';

const SVGComponents = ({ className, setSize }) => {
  const svgRef = useRef(null);

  useLayoutEffect(() => {
    const target = svgRef.current;
    if (target) {
      const svgWidth = target.getAttribute('width'),
        svgHeight = target.getAttribute('height');
      if (svgWidth !== null && svgHeight !== null) {
        setSize({ width: +svgWidth, height: +svgHeight });
      }
    }
  }, []);
  return (
    // для оптимизации работы линтера и prettier cvg разбита на патчи по 1000 строк
    // в противном случае компилятор очень долго собирает бандл
    <svg
      ref={svgRef}
      className={className}
      width='2215'
      height='2150'
      viewBox='0 0 2215 2150'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      {CityPatch1()}
      {CityPatch2()}
      {CityPatch3()}
      {CityPatch4()}
      {CityPatch5()}
    </svg>
  );
};
export const CityPlan = memo(SVGComponents);
