import React from 'react';
import { Placeslist } from '../types';
import { Komsomolskaya_Plan as KomsomolskayaPlan } from './FloorPlanTemplates/Komsomolskaya_Plan';
import { Park_Kultury_4_Plan as ParkKultury4Plan } from './FloorPlanTemplates/Park_Kultury_4_Plan';
import { Park_Kultury_6_Plan as ParkKultury6Plan } from './FloorPlanTemplates/Park_Kultury_6_Plan';
import { Park_Kultury_5_Plan as ParkKultury5Plan } from './FloorPlanTemplates/Park_Kultury_5_Plan';
import { Komsomolskaya_FOK_1_Plan as KomsomolskayaFOK1Plan } from './FloorPlanTemplates/Komsomolskaya_FOK_1_Plan';
import { Komsomolskaya_FOK_2_Plan as KomsomolskayaFOK2Plan } from './FloorPlanTemplates/Komsomolskaya_FOK_2_Plan';
import { Komsomolskaya_FOK_0_Plan as KomsomolskayaFOK0Plan } from './FloorPlanTemplates/Komsomolskaya_FOK_0_Plan';
import { Neo_Geo_A_Plan as NeoGeo1APlan } from './FloorPlanTemplates/Neo_Geo_A_Plan';
import { Neo_Geo_2_A_Plan as NeoGeo2APlan } from './FloorPlanTemplates/Neo_Geo_2_A_Plan';
import { Neo_Geo_C_Plan as NeoGeo1CPlan } from './FloorPlanTemplates/Neo_Geo_C_Plan';
import { Tulskaya_6_Plan as Tulskaya6Plan } from './FloorPlanTemplates/Tulskaya_6_Plan';
import { Tulskaya_5_Plan as Tulskaya5Plan } from './FloorPlanTemplates/Tulskaya_5_Plan';
import { CityPlan } from './FloorPlanTemplates/CityPlan';
export const LazyFloorPlan = ({ place, className, setSize }) => {
  switch (place) {
    case Placeslist.KOMSOMOLSKAYA:
      return <KomsomolskayaPlan setSize={setSize} className={className} />;
    case Placeslist.PARK_KULTURY_5:
      return <ParkKultury5Plan setSize={setSize} className={className} />;
    case Placeslist.PARK_KULTURY_4:
      return <ParkKultury4Plan setSize={setSize} className={className} />;
    case Placeslist.PARK_KULTURY_6:
      return <ParkKultury6Plan setSize={setSize} className={className} />;
    case Placeslist.KOMSOMOLSKAYA_FOK_0:
      return <KomsomolskayaFOK0Plan setSize={setSize} className={className} />;
    case Placeslist.KOMSOMOLSKAYA_FOK_1:
      return <KomsomolskayaFOK1Plan setSize={setSize} className={className} />;
    case Placeslist.KOMSOMOLSKAYA_FOK_2:
      return <KomsomolskayaFOK2Plan setSize={setSize} className={className} />;
    case Placeslist.NEO_GEO_1_A:
      return <NeoGeo1APlan setSize={setSize} className={className} />;
    case Placeslist.NEO_GEO_2_A:
      return <NeoGeo2APlan setSize={setSize} className={className} />;
    case Placeslist.NEO_GEO_1_C:
      return <NeoGeo1CPlan setSize={setSize} className={className} />;
    case Placeslist.TULSKAYA_6:
      return <Tulskaya6Plan setSize={setSize} className={className} />;
    case Placeslist.TULSKAYA_5:
      return <Tulskaya5Plan setSize={setSize} className={className} />;
    case Placeslist.CITY:
      return <CityPlan setSize={setSize} className={className} />;
    default:
      return null;
  }
};
