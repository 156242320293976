import React from 'react';

export const CityPatch4 = () => {
  return (
    <>
      <path d='M177.398 2142.69L227.449 2134.48L225.829 2124.61L175.779 2132.82L177.398 2142.69Z' fill='#ECF5FC' />
      <path
        d='M177.237 2141.7L227.287 2133.49L227.611 2135.47L177.56 2143.68L177.237 2141.7ZM225.991 2125.6L175.941 2133.81L175.617 2131.84L225.668 2123.62L225.991 2125.6Z'
        fill='#CFD1D2'
        mask='url(#path-1098-inside-233_6651_98482)'
      />
      <mask id='path-1100-inside-234_6651_98482' fill='white'>
        <path d='M176.914 2139.73L227.241 2131.47L226.593 2127.53L176.266 2135.78L176.914 2139.73Z' />
      </mask>
      <path d='M176.914 2139.73L227.241 2131.47L226.593 2127.53L176.266 2135.78L176.914 2139.73Z' fill='#AECCE7' />
      <path
        d='M176.914 2139.73L177.076 2140.72L176.089 2140.88L175.927 2139.89L176.914 2139.73ZM227.241 2131.47L228.228 2131.31L228.39 2132.3L227.403 2132.46L227.241 2131.47ZM226.593 2127.53L226.431 2126.54L227.418 2126.38L227.58 2127.36L226.593 2127.53ZM176.266 2135.78L175.28 2135.95L175.118 2134.96L176.104 2134.8L176.266 2135.78ZM176.752 2138.74L227.079 2130.49L227.403 2132.46L177.076 2140.72L176.752 2138.74ZM226.254 2131.63L225.606 2127.69L227.58 2127.36L228.228 2131.31L226.254 2131.63ZM226.755 2128.51L176.428 2136.77L176.104 2134.8L226.431 2126.54L226.755 2128.51ZM177.253 2135.62L177.901 2139.57L175.927 2139.89L175.28 2135.95L177.253 2135.62Z'
        fill='#8BB6DB'
        mask='url(#path-1100-inside-234_6651_98482)'
      />
      <mask id='path-1102-inside-235_6651_98482' fill='white'>
        <path d='M236.82 2133.08L285.244 2124.53L283.505 2114.68L235.082 2123.23L236.82 2133.08Z' />
      </mask>
      <path d='M236.82 2133.08L285.244 2124.53L283.505 2114.68L235.082 2123.23L236.82 2133.08Z' fill='#ECF5FC' />
      <path
        d='M236.646 2132.09L285.07 2123.55L285.418 2125.52L236.994 2134.06L236.646 2132.09ZM283.679 2115.67L235.256 2124.22L234.908 2122.25L283.332 2113.7L283.679 2115.67Z'
        fill='#CFD1D2'
        mask='url(#path-1102-inside-235_6651_98482)'
      />
      <mask id='path-1104-inside-236_6651_98482' fill='white'>
        <path d='M236.297 2130.12L284.551 2121.61L283.856 2117.67L235.602 2126.18L236.297 2130.12Z' />
      </mask>
      <path d='M236.297 2130.12L284.551 2121.61L283.856 2117.67L235.602 2126.18L236.297 2130.12Z' fill='#AECCE7' />
      <path
        d='M236.297 2130.12L236.471 2131.11L235.486 2131.28L235.312 2130.3L236.297 2130.12ZM284.551 2121.61L285.536 2121.43L285.709 2122.42L284.725 2122.59L284.551 2121.61ZM283.856 2117.67L283.682 2116.68L284.666 2116.51L284.84 2117.49L283.856 2117.67ZM235.602 2126.18L234.617 2126.36L234.443 2125.37L235.428 2125.2L235.602 2126.18ZM236.123 2129.14L284.377 2120.62L284.725 2122.59L236.471 2131.11L236.123 2129.14ZM283.566 2121.78L282.871 2117.84L284.84 2117.49L285.536 2121.43L283.566 2121.78ZM284.029 2118.65L235.775 2127.17L235.428 2125.2L283.682 2116.68L284.029 2118.65ZM236.586 2126.01L237.282 2129.95L235.312 2130.3L234.617 2126.36L236.586 2126.01Z'
        fill='#8BB6DB'
        mask='url(#path-1104-inside-236_6651_98482)'
      />
      <mask id='path-1106-inside-237_6651_98482' fill='white'>
        <path d='M294.742 2122.36L340.395 2113.83L338.56 2104L292.907 2112.53L294.742 2122.36Z' />
      </mask>
      <path d='M294.742 2122.36L340.395 2113.83L338.56 2104L292.907 2112.53L294.742 2122.36Z' fill='#ECF5FC' />
      <path
        d='M294.559 2121.37L340.212 2112.85L340.579 2114.81L294.926 2123.34L294.559 2121.37ZM338.743 2104.98L293.09 2113.51L292.723 2111.54L338.376 2103.02L338.743 2104.98Z'
        fill='#CFD1D2'
        mask='url(#path-1106-inside-237_6651_98482)'
      />
      <mask id='path-1108-inside-238_6651_98482' fill='white'>
        <path d='M294.195 2119.41L339.414 2110.96L338.68 2107.03L293.461 2115.47L294.195 2119.41Z' />
      </mask>
      <path d='M294.195 2119.41L339.414 2110.96L338.68 2107.03L293.461 2115.47L294.195 2119.41Z' fill='#AECCE7' />
      <path
        d='M294.195 2119.41L294.379 2120.39L293.396 2120.57L293.212 2119.59L294.195 2119.41ZM339.414 2110.96L340.397 2110.78L340.58 2111.76L339.597 2111.95L339.414 2110.96ZM338.68 2107.03L338.496 2106.05L339.479 2105.86L339.663 2106.85L338.68 2107.03ZM293.461 2115.47L292.478 2115.66L292.295 2114.67L293.278 2114.49L293.461 2115.47ZM294.012 2118.42L339.23 2109.98L339.597 2111.95L294.379 2120.39L294.012 2118.42ZM338.431 2111.15L337.697 2107.21L339.663 2106.85L340.397 2110.78L338.431 2111.15ZM338.863 2108.01L293.645 2116.46L293.278 2114.49L338.496 2106.05L338.863 2108.01ZM294.444 2115.29L295.178 2119.22L293.212 2119.59L292.478 2115.66L294.444 2115.29Z'
        fill='#8BB6DB'
        mask='url(#path-1108-inside-238_6651_98482)'
      />
      <mask id='path-1110-inside-239_6651_98482' fill='white'>
        <path d='M350.359 2112.18L395.644 2102.94L393.645 2093.14L348.36 2102.39L350.359 2112.18Z' />
      </mask>
      <path d='M350.359 2112.18L395.644 2102.94L393.645 2093.14L348.36 2102.39L350.359 2112.18Z' fill='#ECF5FC' />
      <path
        d='M350.159 2111.2L395.444 2101.96L395.844 2103.92L350.559 2113.16L350.159 2111.2ZM393.845 2094.12L348.56 2103.37L348.16 2101.41L393.445 2092.16L393.845 2094.12Z'
        fill='#CFD1D2'
        mask='url(#path-1110-inside-239_6651_98482)'
      />
      <mask id='path-1112-inside-240_6651_98482' fill='white'>
        <path d='M349.758 2109.24L394.829 2100.05L394.029 2096.13L348.958 2105.32L349.758 2109.24Z' />
      </mask>
      <path d='M349.758 2109.24L394.829 2100.05L394.029 2096.13L348.958 2105.32L349.758 2109.24Z' fill='#AECCE7' />
      <path
        d='M349.758 2109.24L349.958 2110.22L348.978 2110.42L348.778 2109.44L349.758 2109.24ZM394.829 2100.05L395.809 2099.85L396.009 2100.83L395.029 2101.03L394.829 2100.05ZM394.029 2096.13L393.829 2095.15L394.809 2094.95L395.009 2095.93L394.029 2096.13ZM348.958 2105.32L347.978 2105.52L347.778 2104.55L348.758 2104.35L348.958 2105.32ZM349.558 2108.26L394.629 2099.07L395.029 2101.03L349.958 2110.22L349.558 2108.26ZM393.849 2100.25L393.049 2096.33L395.009 2095.93L395.809 2099.85L393.849 2100.25ZM394.229 2097.11L349.158 2106.3L348.758 2104.35L393.829 2095.15L394.229 2097.11ZM349.938 2105.12L350.738 2109.04L348.778 2109.44L347.978 2105.52L349.938 2105.12Z'
        fill='#8BB6DB'
        mask='url(#path-1112-inside-240_6651_98482)'
      />
      <mask id='path-1114-inside-241_6651_98482' fill='white'>
        <path d='M520.227 2074.62L564.24 2062.98L561.684 2053.31L517.671 2064.95L520.227 2074.62Z' />
      </mask>
      <path d='M520.227 2074.62L564.24 2062.98L561.684 2053.31L517.671 2064.95L520.227 2074.62Z' fill='#ECF5FC' />
      <path
        d='M519.971 2073.65L563.984 2062.02L564.495 2063.95L520.482 2075.58L519.971 2073.65ZM561.94 2054.28L517.926 2065.92L517.415 2063.98L561.429 2052.35L561.94 2054.28Z'
        fill='#CFD1D2'
        mask='url(#path-1114-inside-241_6651_98482)'
      />
      <mask id='path-1116-inside-242_6651_98482' fill='white'>
        <path d='M519.461 2071.72L563.933 2059.96L562.911 2056.09L518.439 2067.85L519.461 2071.72Z' />
      </mask>
      <path d='M519.461 2071.72L563.933 2059.96L562.911 2056.09L518.439 2067.85L519.461 2071.72Z' fill='#AECCE7' />
      <path
        d='M519.461 2071.72L519.717 2072.68L518.75 2072.94L518.494 2071.97L519.461 2071.72ZM563.933 2059.96L564.9 2059.7L565.156 2060.67L564.189 2060.93L563.933 2059.96ZM562.911 2056.09L562.655 2055.13L563.622 2054.87L563.878 2055.84L562.911 2056.09ZM518.439 2067.85L517.472 2068.11L517.216 2067.14L518.183 2066.88L518.439 2067.85ZM519.205 2070.75L563.678 2058.99L564.189 2060.93L519.717 2072.68L519.205 2070.75ZM562.966 2060.22L561.944 2056.35L563.878 2055.84L564.9 2059.7L562.966 2060.22ZM563.166 2057.06L518.694 2068.82L518.183 2066.88L562.655 2055.13L563.166 2057.06ZM519.405 2067.59L520.428 2071.46L518.494 2071.97L517.472 2068.11L519.405 2067.59Z'
        fill='#8BB6DB'
        mask='url(#path-1116-inside-242_6651_98482)'
      />
      <mask id='path-1118-inside-243_6651_98482' fill='white'>
        <path d='M573.883 2060.48L618.442 2047.95L615.735 2038.33L571.176 2050.85L573.883 2060.48Z' />
      </mask>
      <path d='M573.883 2060.48L618.442 2047.95L615.735 2038.33L571.176 2050.85L573.883 2060.48Z' fill='#ECF5FC' />
      <path
        d='M573.612 2059.52L618.171 2046.99L618.713 2048.91L574.153 2061.44L573.612 2059.52ZM616.006 2039.29L571.447 2051.82L570.905 2049.89L615.465 2037.36L616.006 2039.29Z'
        fill='#CFD1D2'
        mask='url(#path-1118-inside-243_6651_98482)'
      />
      <mask id='path-1120-inside-244_6651_98482' fill='white'>
        <path d='M573.07 2057.59L617.353 2045.14L616.271 2041.29L571.988 2053.74L573.07 2057.59Z' />
      </mask>
      <path d='M573.07 2057.59L617.353 2045.14L616.271 2041.29L571.988 2053.74L573.07 2057.59Z' fill='#AECCE7' />
      <path
        d='M573.07 2057.59L573.341 2058.55L572.378 2058.83L572.108 2057.86L573.07 2057.59ZM617.353 2045.14L618.316 2044.87L618.587 2045.83L617.624 2046.1L617.353 2045.14ZM616.271 2041.29L616 2040.33L616.963 2040.06L617.233 2041.02L616.271 2041.29ZM571.988 2053.74L571.025 2054.01L570.754 2053.05L571.717 2052.78L571.988 2053.74ZM572.8 2056.63L617.083 2044.18L617.624 2046.1L573.341 2058.55L572.8 2056.63ZM616.391 2045.41L615.308 2041.56L617.233 2041.02L618.316 2044.87L616.391 2045.41ZM616.541 2042.25L572.258 2054.7L571.717 2052.78L616 2040.33L616.541 2042.25ZM572.95 2053.47L574.033 2057.32L572.108 2057.86L571.025 2054.01L572.95 2053.47Z'
        fill='#8BB6DB'
        mask='url(#path-1120-inside-244_6651_98482)'
      />
      <mask id='path-1122-inside-245_6651_98482' fill='white'>
        <path d='M628.398 2045.34L672.051 2032.13L669.156 2022.56L625.503 2035.76L628.398 2045.34Z' />
      </mask>
      <path d='M628.398 2045.34L672.051 2032.13L669.156 2022.56L625.503 2035.76L628.398 2045.34Z' fill='#ECF5FC' />
      <path
        d='M628.109 2044.38L671.762 2031.18L672.341 2033.09L628.688 2046.29L628.109 2044.38ZM669.445 2023.52L625.793 2036.72L625.214 2034.81L668.866 2021.6L669.445 2023.52Z'
        fill='#CFD1D2'
        mask='url(#path-1122-inside-245_6651_98482)'
      />
      <mask id='path-1124-inside-246_6651_98482' fill='white'>
        <path d='M627.531 2042.46L671.561 2029.15L670.403 2025.32L626.373 2038.64L627.531 2042.46Z' />
      </mask>
      <path d='M627.531 2042.46L671.561 2029.15L670.403 2025.32L626.373 2038.64L627.531 2042.46Z' fill='#AECCE7' />
      <path
        d='M627.531 2042.46L627.821 2043.42L626.864 2043.71L626.574 2042.75L627.531 2042.46ZM671.561 2029.15L672.518 2028.86L672.808 2029.81L671.851 2030.1L671.561 2029.15ZM670.403 2025.32L670.114 2024.36L671.071 2024.07L671.36 2025.03L670.403 2025.32ZM626.373 2038.64L625.416 2038.93L625.127 2037.97L626.084 2037.68L626.373 2038.64ZM627.242 2041.51L671.272 2028.19L671.851 2030.1L627.821 2043.42L627.242 2041.51ZM670.604 2029.44L669.446 2025.61L671.36 2025.03L672.518 2028.86L670.604 2029.44ZM670.693 2026.28L626.663 2039.59L626.084 2037.68L670.114 2024.36L670.693 2026.28ZM627.33 2038.35L628.488 2042.17L626.574 2042.75L625.416 2038.93L627.33 2038.35Z'
        fill='#8BB6DB'
        mask='url(#path-1124-inside-246_6651_98482)'
      />
      <mask id='path-1126-inside-247_6651_98482' fill='white'>
        <path d='M681.695 2029.41L725.012 2015.73L721.999 2006.19L678.682 2019.88L681.695 2029.41Z' />
      </mask>
      <path d='M681.695 2029.41L725.012 2015.73L721.999 2006.19L678.682 2019.88L681.695 2029.41Z' fill='#ECF5FC' />
      <path
        d='M681.394 2028.46L724.711 2014.77L725.313 2016.68L681.997 2030.37L681.394 2028.46ZM722.3 2007.15L678.984 2020.83L678.381 2018.93L721.698 2005.24L722.3 2007.15Z'
        fill='#CFD1D2'
        mask='url(#path-1126-inside-247_6651_98482)'
      />
      <mask id='path-1128-inside-248_6651_98482' fill='white'>
        <path d='M680.789 2026.55L723.698 2013L722.493 2009.18L679.584 2022.74L680.789 2026.55Z' />
      </mask>
      <path d='M680.789 2026.55L723.698 2013L722.493 2009.18L679.584 2022.74L680.789 2026.55Z' fill='#AECCE7' />
      <path
        d='M680.789 2026.55L681.09 2027.51L680.137 2027.81L679.836 2026.85L680.789 2026.55ZM723.698 2013L724.652 2012.69L724.953 2013.65L723.999 2013.95L723.698 2013ZM722.493 2009.18L722.192 2008.23L723.145 2007.93L723.446 2008.88L722.493 2009.18ZM679.584 2022.74L678.63 2023.04L678.329 2022.09L679.283 2021.79L679.584 2022.74ZM680.488 2025.6L723.397 2012.04L723.999 2013.95L681.09 2027.51L680.488 2025.6ZM722.745 2013.3L721.539 2009.48L723.446 2008.88L724.652 2012.69L722.745 2013.3ZM722.794 2010.13L679.885 2023.69L679.283 2021.79L722.192 2008.23L722.794 2010.13ZM680.537 2022.44L681.743 2026.25L679.836 2026.85L678.63 2023.04L680.537 2022.44Z'
        fill='#8BB6DB'
        mask='url(#path-1128-inside-248_6651_98482)'
      />
      <mask id='path-1130-inside-249_6651_98482' fill='white'>
        <path d='M734.68 2012.86L777.512 1998.56L774.344 1989.08L731.512 2003.38L734.68 2012.86Z' />
      </mask>
      <path d='M734.68 2012.86L777.512 1998.56L774.344 1989.08L731.512 2003.38L734.68 2012.86Z' fill='#ECF5FC' />
      <path
        d='M734.363 2011.92L777.195 1997.61L777.828 1999.51L734.996 2013.81L734.363 2011.92ZM774.661 1990.02L731.829 2004.33L731.195 2002.43L774.028 1988.13L774.661 1990.02Z'
        fill='#CFD1D2'
        mask='url(#path-1130-inside-249_6651_98482)'
      />
      <mask id='path-1132-inside-250_6651_98482' fill='white'>
        <path d='M733.727 2010.02L776.41 1995.76L775.143 1991.97L732.46 2006.22L733.727 2010.02Z' />
      </mask>
      <path d='M733.727 2010.02L776.41 1995.76L775.143 1991.97L732.46 2006.22L733.727 2010.02Z' fill='#AECCE7' />
      <path
        d='M733.727 2010.02L734.043 2010.97L733.095 2011.28L732.778 2010.34L733.727 2010.02ZM776.41 1995.76L777.358 1995.45L777.675 1996.4L776.726 1996.71L776.41 1995.76ZM775.143 1991.97L774.826 1991.02L775.774 1990.71L776.091 1991.65L775.143 1991.97ZM732.46 2006.22L731.511 2006.54L731.194 2005.59L732.143 2005.28L732.46 2006.22ZM733.41 2009.07L776.093 1994.82L776.726 1996.71L734.043 2010.97L733.41 2009.07ZM775.461 1996.08L774.194 1992.29L776.091 1991.65L777.358 1995.45L775.461 1996.08ZM775.459 1992.92L732.776 2007.17L732.143 2005.28L774.826 1991.02L775.459 1992.92ZM733.408 2005.91L734.675 2009.7L732.778 2010.34L731.511 2006.54L733.408 2005.91Z'
        fill='#8BB6DB'
        mask='url(#path-1132-inside-250_6651_98482)'
      />
      <mask id='path-1134-inside-251_6651_98482' fill='white'>
        <path d='M786.938 1995.46L829.124 1981.02L825.886 1971.56L783.7 1986L786.938 1995.46Z' />
      </mask>
      <path d='M786.938 1995.46L829.124 1981.02L825.886 1971.56L783.7 1986L786.938 1995.46Z' fill='#ECF5FC' />
      <path
        d='M786.614 1994.51L828.8 1980.08L829.448 1981.97L787.261 1996.41L786.614 1994.51ZM826.21 1972.51L784.023 1986.95L783.376 1985.05L825.562 1970.62L826.21 1972.51Z'
        fill='#CFD1D2'
        mask='url(#path-1134-inside-251_6651_98482)'
      />
      <mask id='path-1136-inside-252_6651_98482' fill='white'>
        <path d='M785.969 1992.62L828.544 1978.05L827.249 1974.27L784.674 1988.84L785.969 1992.62Z' />
      </mask>
      <path d='M785.969 1992.62L828.544 1978.05L827.249 1974.27L784.674 1988.84L785.969 1992.62Z' fill='#AECCE7' />
      <path
        d='M785.969 1992.62L786.293 1993.57L785.346 1993.89L785.023 1992.95L785.969 1992.62ZM828.544 1978.05L829.491 1977.73L829.814 1978.67L828.868 1979L828.544 1978.05ZM827.249 1974.27L826.925 1973.32L827.872 1973L828.195 1973.94L827.249 1974.27ZM784.674 1988.84L783.727 1989.16L783.404 1988.22L784.35 1987.89L784.674 1988.84ZM785.645 1991.68L828.221 1977.11L828.868 1979L786.293 1993.57L785.645 1991.68ZM827.598 1978.38L826.303 1974.59L828.195 1973.94L829.491 1977.73L827.598 1978.38ZM827.573 1975.21L784.997 1989.78L784.35 1987.89L826.925 1973.32L827.573 1975.21ZM785.62 1988.51L786.915 1992.3L785.023 1992.95L783.727 1989.16L785.62 1988.51Z'
        fill='#8BB6DB'
        mask='url(#path-1136-inside-252_6651_98482)'
      />
      <mask id='path-1138-inside-253_6651_98482' fill='white'>
        <path d='M838.781 1977.78L884.569 1961.23L881.17 1951.82L835.382 1968.37L838.781 1977.78Z' />
      </mask>
      <path d='M838.781 1977.78L884.569 1961.23L881.17 1951.82L835.382 1968.37L838.781 1977.78Z' fill='#ECF5FC' />
      <path
        d='M838.441 1976.84L884.229 1960.29L884.909 1962.17L839.121 1978.72L838.441 1976.84ZM881.51 1952.77L835.722 1969.31L835.042 1967.43L880.83 1950.88L881.51 1952.77Z'
        fill='#CFD1D2'
        mask='url(#path-1138-inside-253_6651_98482)'
      />
      <mask id='path-1140-inside-254_6651_98482' fill='white'>
        <path d='M837.758 1974.96L883.84 1958.3L882.481 1954.54L836.398 1971.2L837.758 1974.96Z' />
      </mask>
      <path d='M837.758 1974.96L883.84 1958.3L882.481 1954.54L836.398 1971.2L837.758 1974.96Z' fill='#AECCE7' />
      <path
        d='M837.758 1974.96L838.098 1975.9L837.157 1976.24L836.817 1975.3L837.758 1974.96ZM883.84 1958.3L884.781 1957.96L885.121 1958.9L884.18 1959.24L883.84 1958.3ZM882.481 1954.54L882.141 1953.6L883.081 1953.26L883.421 1954.2L882.481 1954.54ZM836.398 1971.2L835.458 1971.54L835.118 1970.59L836.058 1970.25L836.398 1971.2ZM837.418 1974.02L883.5 1957.36L884.18 1959.24L838.098 1975.9L837.418 1974.02ZM882.9 1958.64L881.54 1954.88L883.421 1954.2L884.781 1957.96L882.9 1958.64ZM882.821 1955.48L836.738 1972.14L836.058 1970.25L882.141 1953.6L882.821 1955.48ZM837.339 1970.86L838.698 1974.62L836.817 1975.3L835.458 1971.54L837.339 1970.86Z'
        fill='#8BB6DB'
        mask='url(#path-1140-inside-254_6651_98482)'
      />
      <mask id='path-1142-inside-255_6651_98482' fill='white'>
        <path d='M893.977 1957.88L936.387 1941.86L932.853 1932.51L890.443 1948.52L893.977 1957.88Z' />
      </mask>
      <path d='M893.977 1957.88L936.387 1941.86L932.853 1932.51L890.443 1948.52L893.977 1957.88Z' fill='#ECF5FC' />
      <path
        d='M893.623 1956.94L936.033 1940.93L936.74 1942.8L894.33 1958.81L893.623 1956.94ZM933.207 1933.44L890.797 1949.46L890.09 1947.59L932.5 1931.57L933.207 1933.44Z'
        fill='#CFD1D2'
        mask='url(#path-1142-inside-255_6651_98482)'
      />
      <mask id='path-1144-inside-256_6651_98482' fill='white'>
        <path d='M892.914 1955.07L935.011 1939.17L933.598 1935.43L891.501 1951.33L892.914 1955.07Z' />
      </mask>
      <path d='M892.914 1955.07L935.011 1939.17L933.598 1935.43L891.501 1951.33L892.914 1955.07Z' fill='#AECCE7' />
      <path
        d='M892.914 1955.07L893.267 1956.01L892.332 1956.36L891.979 1955.43L892.914 1955.07ZM935.011 1939.17L935.947 1938.82L936.3 1939.75L935.365 1940.11L935.011 1939.17ZM933.598 1935.43L933.245 1934.49L934.18 1934.14L934.534 1935.08L933.598 1935.43ZM891.501 1951.33L890.565 1951.68L890.212 1950.75L891.147 1950.39L891.501 1951.33ZM892.561 1954.14L934.658 1938.24L935.365 1940.11L893.267 1956.01L892.561 1954.14ZM934.076 1939.53L932.663 1935.78L934.534 1935.08L935.947 1938.82L934.076 1939.53ZM933.952 1936.37L891.854 1952.27L891.147 1950.39L933.245 1934.49L933.952 1936.37ZM892.436 1950.98L893.85 1954.72L891.979 1955.43L890.565 1951.68L892.436 1950.98Z'
        fill='#8BB6DB'
        mask='url(#path-1144-inside-256_6651_98482)'
      />
      <mask id='path-1146-inside-257_6651_98482' fill='white'>
        <path d='M945.797 1938.28L985.793 1922.63L982.148 1913.31L942.153 1928.97L945.797 1938.28Z' />
      </mask>
      <path d='M945.797 1938.28L985.793 1922.63L982.148 1913.31L942.153 1928.97L945.797 1938.28Z' fill='#ECF5FC' />
      <path
        d='M945.432 1937.35L985.428 1921.69L986.157 1923.56L946.161 1939.21L945.432 1937.35ZM982.513 1914.24L942.517 1929.9L941.788 1928.03L981.784 1912.38L982.513 1914.24Z'
        fill='#CFD1D2'
        mask='url(#path-1146-inside-257_6651_98482)'
      />
      <mask id='path-1148-inside-258_6651_98482' fill='white'>
        <path d='M944.703 1935.48L984.746 1919.81L983.288 1916.09L943.245 1931.76L944.703 1935.48Z' />
      </mask>
      <path d='M944.703 1935.48L984.746 1919.81L983.288 1916.09L943.245 1931.76L944.703 1935.48Z' fill='#AECCE7' />
      <path
        d='M944.703 1935.48L945.068 1936.42L944.136 1936.78L943.772 1935.85L944.703 1935.48ZM984.746 1919.81L985.677 1919.45L986.042 1920.38L985.11 1920.75L984.746 1919.81ZM983.288 1916.09L982.924 1915.16L983.855 1914.79L984.22 1915.72L983.288 1916.09ZM943.245 1931.76L942.314 1932.12L941.95 1931.19L942.881 1930.83L943.245 1931.76ZM944.339 1934.55L984.382 1918.88L985.11 1920.75L945.068 1936.42L944.339 1934.55ZM983.815 1920.18L982.357 1916.45L984.22 1915.72L985.677 1919.45L983.815 1920.18ZM983.653 1917.02L943.61 1932.69L942.881 1930.83L982.924 1915.16L983.653 1917.02ZM944.177 1931.4L945.634 1935.12L943.772 1935.85L942.314 1932.12L944.177 1931.4Z'
        fill='#8BB6DB'
        mask='url(#path-1148-inside-258_6651_98482)'
      />
      <mask id='path-1150-inside-259_6651_98482' fill='white'>
        <path d='M995.25 1918.94L1032.66 1903.74L1028.9 1894.48L991.485 1909.68L995.25 1918.94Z' />
      </mask>
      <path d='M995.25 1918.94L1032.66 1903.74L1028.9 1894.48L991.485 1909.68L995.25 1918.94Z' fill='#ECF5FC' />
      <path
        d='M994.874 1918.02L1032.28 1902.82L1033.04 1904.67L995.626 1919.87L994.874 1918.02ZM1029.27 1895.4L991.862 1910.61L991.109 1908.75L1028.52 1893.55L1029.27 1895.4Z'
        fill='#CFD1D2'
        mask='url(#path-1150-inside-259_6651_98482)'
      />
      <mask id='path-1152-inside-260_6651_98482' fill='white'>
        <path d='M994.117 1916.16L1031.17 1901.11L1029.67 1897.4L992.611 1912.46L994.117 1916.16Z' />
      </mask>
      <path d='M994.117 1916.16L1031.17 1901.11L1029.67 1897.4L992.611 1912.46L994.117 1916.16Z' fill='#AECCE7' />
      <path
        d='M994.117 1916.16L994.494 1917.09L993.567 1917.47L993.191 1916.54L994.117 1916.16ZM1031.17 1901.11L1032.1 1900.73L1032.48 1901.66L1031.55 1902.03L1031.17 1901.11ZM1029.67 1897.4L1029.29 1896.47L1030.22 1896.1L1030.6 1897.02L1029.67 1897.4ZM992.611 1912.46L991.685 1912.83L991.308 1911.91L992.235 1911.53L992.611 1912.46ZM993.741 1915.24L1030.8 1900.18L1031.55 1902.03L994.494 1917.09L993.741 1915.24ZM1030.25 1901.48L1028.74 1897.78L1030.6 1897.02L1032.1 1900.73L1030.25 1901.48ZM1030.05 1898.33L992.988 1913.38L992.235 1911.53L1029.29 1896.47L1030.05 1898.33ZM993.538 1912.08L995.044 1915.79L993.191 1916.54L991.685 1912.83L993.538 1912.08Z'
        fill='#8BB6DB'
        mask='url(#path-1152-inside-260_6651_98482)'
      />
      <mask id='path-1154-inside-261_6651_98482' fill='white'>
        <path d='M1042.09 1899.97L1087.03 1880.99L1083.14 1871.78L1038.2 1890.75L1042.09 1899.97Z' />
      </mask>
      <path d='M1042.09 1899.97L1087.03 1880.99L1083.14 1871.78L1038.2 1890.75L1042.09 1899.97Z' fill='#ECF5FC' />
      <path
        d='M1041.7 1899.05L1086.64 1880.07L1087.42 1881.91L1042.47 1900.89L1041.7 1899.05ZM1083.53 1872.7L1038.58 1891.68L1037.81 1889.83L1082.75 1870.85L1083.53 1872.7Z'
        fill='#CFD1D2'
        mask='url(#path-1154-inside-261_6651_98482)'
      />
      <mask id='path-1156-inside-262_6651_98482' fill='white'>
        <path d='M1040.92 1897.2L1086.06 1878.14L1084.51 1874.45L1039.37 1893.52L1040.92 1897.2Z' />
      </mask>
      <path d='M1040.92 1897.2L1086.06 1878.14L1084.51 1874.45L1039.37 1893.52L1040.92 1897.2Z' fill='#AECCE7' />
      <path
        d='M1040.92 1897.2L1041.31 1898.12L1040.39 1898.51L1040 1897.59L1040.92 1897.2ZM1086.06 1878.14L1086.98 1877.75L1087.37 1878.67L1086.45 1879.06L1086.06 1878.14ZM1084.51 1874.45L1084.12 1873.53L1085.04 1873.14L1085.43 1874.07L1084.51 1874.45ZM1039.37 1893.52L1038.44 1893.91L1038.06 1892.99L1038.98 1892.6L1039.37 1893.52ZM1040.53 1896.28L1085.67 1877.22L1086.45 1879.06L1041.31 1898.12L1040.53 1896.28ZM1085.14 1878.53L1083.58 1874.84L1085.43 1874.07L1086.98 1877.75L1085.14 1878.53ZM1084.89 1875.38L1039.75 1894.44L1038.98 1892.6L1084.12 1873.53L1084.89 1875.38ZM1040.29 1893.13L1041.84 1896.81L1040 1897.59L1038.44 1893.91L1040.29 1893.13Z'
        fill='#8BB6DB'
        mask='url(#path-1156-inside-262_6651_98482)'
      />
      <mask id='path-1158-inside-263_6651_98482' fill='white'>
        <path d='M1096.45 1877.11L1136.76 1859.48L1132.76 1850.32L1092.44 1867.95L1096.45 1877.11Z' />
      </mask>
      <path d='M1096.45 1877.11L1136.76 1859.48L1132.76 1850.32L1092.44 1867.95L1096.45 1877.11Z' fill='#ECF5FC' />
      <path
        d='M1096.04 1876.19L1136.36 1858.56L1137.16 1860.39L1096.85 1878.03L1096.04 1876.19ZM1133.16 1851.23L1092.84 1868.86L1092.04 1867.03L1132.36 1849.4L1133.16 1851.23Z'
        fill='#CFD1D2'
        mask='url(#path-1158-inside-263_6651_98482)'
      />
      <mask id='path-1160-inside-264_6651_98482' fill='white'>
        <path d='M1095.24 1874.36L1135.56 1856.73L1133.95 1853.07L1093.64 1870.7L1095.24 1874.36Z' />
      </mask>
      <path d='M1095.24 1874.36L1135.56 1856.73L1133.95 1853.07L1093.64 1870.7L1095.24 1874.36Z' fill='#AECCE7' />
      <path
        d='M1095.24 1874.36L1095.64 1875.28L1094.73 1875.68L1094.33 1874.76L1095.24 1874.36ZM1135.56 1856.73L1136.47 1856.33L1136.87 1857.25L1135.96 1857.65L1135.56 1856.73ZM1133.95 1853.07L1133.55 1852.15L1134.47 1851.75L1134.87 1852.67L1133.95 1853.07ZM1093.64 1870.7L1092.72 1871.1L1092.32 1870.18L1093.24 1869.78L1093.64 1870.7ZM1094.84 1873.44L1135.16 1855.82L1135.96 1857.65L1095.64 1875.28L1094.84 1873.44ZM1134.64 1857.13L1133.04 1853.47L1134.87 1852.67L1136.47 1856.33L1134.64 1857.13ZM1134.35 1853.98L1094.04 1871.61L1093.24 1869.78L1133.55 1852.15L1134.35 1853.98ZM1094.56 1870.3L1096.16 1873.96L1094.33 1874.76L1092.72 1871.1L1094.56 1870.3Z'
        fill='#8BB6DB'
        mask='url(#path-1160-inside-264_6651_98482)'
      />
      <mask id='path-1162-inside-265_6651_98482' fill='white'>
        <path d='M1145.88 1855.57L1183.05 1838.77L1178.94 1829.66L1141.76 1846.46L1145.88 1855.57Z' />
      </mask>
      <path d='M1145.88 1855.57L1183.05 1838.77L1178.94 1829.66L1141.76 1846.46L1145.88 1855.57Z' fill='#ECF5FC' />
      <path
        d='M1145.46 1854.66L1182.64 1837.86L1183.47 1839.68L1146.29 1856.49L1145.46 1854.66ZM1179.35 1830.57L1142.17 1847.37L1141.35 1845.55L1178.52 1828.75L1179.35 1830.57Z'
        fill='#CFD1D2'
        mask='url(#path-1162-inside-265_6651_98482)'
      />
      <mask id='path-1164-inside-266_6651_98482' fill='white'>
        <path d='M1144.64 1852.84L1182 1835.96L1180.36 1832.31L1142.99 1849.2L1144.64 1852.84Z' />
      </mask>
      <path d='M1144.64 1852.84L1182 1835.96L1180.36 1832.31L1142.99 1849.2L1144.64 1852.84Z' fill='#AECCE7' />
      <path
        d='M1144.64 1852.84L1145.05 1853.75L1144.14 1854.16L1143.73 1853.25L1144.64 1852.84ZM1182 1835.96L1182.91 1835.54L1183.33 1836.46L1182.41 1836.87L1182 1835.96ZM1180.36 1832.31L1179.94 1831.4L1180.85 1830.99L1181.27 1831.9L1180.36 1832.31ZM1142.99 1849.2L1142.08 1849.61L1141.67 1848.7L1142.58 1848.28L1142.99 1849.2ZM1144.23 1851.93L1181.59 1835.04L1182.41 1836.87L1145.05 1853.75L1144.23 1851.93ZM1181.09 1836.37L1179.44 1832.72L1181.27 1831.9L1182.91 1835.54L1181.09 1836.37ZM1180.77 1833.22L1143.41 1850.11L1142.58 1848.28L1179.94 1831.4L1180.77 1833.22ZM1143.9 1848.78L1145.55 1852.43L1143.73 1853.25L1142.08 1849.61L1143.9 1848.78Z'
        fill='#8BB6DB'
        mask='url(#path-1164-inside-266_6651_98482)'
      />
      <mask id='path-1166-inside-267_6651_98482' fill='white'>
        <path d='M1192.34 1834.68L1238.2 1813.36L1233.98 1804.3L1188.12 1825.62L1192.34 1834.68Z' />
      </mask>
      <path d='M1192.34 1834.68L1238.2 1813.36L1233.98 1804.3L1188.12 1825.62L1192.34 1834.68Z' fill='#ECF5FC' />
      <path
        d='M1191.91 1833.78L1237.78 1812.46L1238.62 1814.27L1192.76 1835.59L1191.91 1833.78ZM1234.41 1805.2L1188.54 1826.52L1187.7 1824.71L1233.56 1803.39L1234.41 1805.2Z'
        fill='#CFD1D2'
        mask='url(#path-1166-inside-267_6651_98482)'
      />
      <mask id='path-1168-inside-268_6651_98482' fill='white'>
        <path d='M1191.07 1831.96L1237.32 1810.47L1235.63 1806.84L1189.38 1828.34L1191.07 1831.96Z' />
      </mask>
      <path d='M1191.07 1831.96L1237.32 1810.47L1235.63 1806.84L1189.38 1828.34L1191.07 1831.96Z' fill='#AECCE7' />
      <path
        d='M1191.07 1831.96L1191.49 1832.87L1190.59 1833.29L1190.16 1832.39L1191.07 1831.96ZM1237.32 1810.47L1238.22 1810.04L1238.65 1810.95L1237.74 1811.37L1237.32 1810.47ZM1235.63 1806.84L1235.21 1805.93L1236.12 1805.51L1236.54 1806.42L1235.63 1806.84ZM1189.38 1828.34L1188.48 1828.76L1188.06 1827.85L1188.96 1827.43L1189.38 1828.34ZM1190.65 1831.06L1236.9 1809.56L1237.74 1811.37L1191.49 1832.87L1190.65 1831.06ZM1236.41 1810.89L1234.72 1807.26L1236.54 1806.42L1238.22 1810.04L1236.41 1810.89ZM1236.05 1807.74L1189.81 1829.24L1188.96 1827.43L1235.21 1805.93L1236.05 1807.74ZM1190.29 1827.92L1191.98 1831.54L1190.16 1832.39L1188.48 1828.76L1190.29 1827.92Z'
        fill='#8BB6DB'
        mask='url(#path-1168-inside-268_6651_98482)'
      />
      <mask id='path-1170-inside-269_6651_98482' fill='white'>
        <path d='M1247.25 1809.27L1285.54 1790.9L1281.21 1781.88L1242.92 1800.25L1247.25 1809.27Z' />
      </mask>
      <path d='M1247.25 1809.27L1285.54 1790.9L1281.21 1781.88L1242.92 1800.25L1247.25 1809.27Z' fill='#ECF5FC' />
      <path
        d='M1246.82 1808.37L1285.11 1790L1285.97 1791.8L1247.68 1810.17L1246.82 1808.37ZM1281.64 1782.79L1243.36 1801.16L1242.49 1799.35L1280.78 1780.98L1281.64 1782.79Z'
        fill='#CFD1D2'
        mask='url(#path-1170-inside-269_6651_98482)'
      />
      <mask id='path-1172-inside-270_6651_98482' fill='white'>
        <path d='M1245.95 1806.56L1283.82 1788.4L1282.09 1784.79L1244.22 1802.96L1245.95 1806.56Z' />
      </mask>
      <path d='M1245.95 1806.56L1283.82 1788.4L1282.09 1784.79L1244.22 1802.96L1245.95 1806.56Z' fill='#AECCE7' />
      <path
        d='M1245.95 1806.56L1246.39 1807.47L1245.48 1807.9L1245.05 1807L1245.95 1806.56ZM1283.82 1788.4L1284.72 1787.96L1285.15 1788.87L1284.25 1789.3L1283.82 1788.4ZM1282.09 1784.79L1281.66 1783.89L1282.56 1783.46L1282.99 1784.36L1282.09 1784.79ZM1244.22 1802.96L1243.32 1803.39L1242.89 1802.49L1243.79 1802.06L1244.22 1802.96ZM1245.52 1805.66L1283.39 1787.5L1284.25 1789.3L1246.39 1807.47L1245.52 1805.66ZM1282.92 1788.83L1281.19 1785.22L1282.99 1784.36L1284.72 1787.96L1282.92 1788.83ZM1282.52 1785.69L1244.66 1803.86L1243.79 1802.06L1281.66 1783.89L1282.52 1785.69ZM1245.12 1802.53L1246.85 1806.13L1245.05 1807L1243.32 1803.39L1245.12 1802.53Z'
        fill='#8BB6DB'
        mask='url(#path-1172-inside-270_6651_98482)'
      />
      <mask id='path-1174-inside-271_6651_98482' fill='white'>
        <path d='M1294.57 1786.81L1334 1766.99L1329.51 1758.05L1290.08 1777.88L1294.57 1786.81Z' />
      </mask>
      <path d='M1294.57 1786.81L1334 1766.99L1329.51 1758.05L1290.08 1777.88L1294.57 1786.81Z' fill='#ECF5FC' />
      <path
        d='M1294.12 1785.92L1333.55 1766.09L1334.45 1767.88L1295.02 1787.71L1294.12 1785.92ZM1329.95 1758.95L1290.53 1778.77L1289.63 1776.98L1329.06 1757.16L1329.95 1758.95Z'
        fill='#CFD1D2'
        mask='url(#path-1174-inside-271_6651_98482)'
      />
      <mask id='path-1176-inside-272_6651_98482' fill='white'>
        <path d='M1293.22 1784.13L1332.53 1764.37L1330.73 1760.79L1291.42 1780.56L1293.22 1784.13Z' />
      </mask>
      <path d='M1293.22 1784.13L1332.53 1764.37L1330.73 1760.79L1291.42 1780.56L1293.22 1784.13Z' fill='#AECCE7' />
      <path
        d='M1293.22 1784.13L1293.67 1785.03L1292.77 1785.47L1292.33 1784.58L1293.22 1784.13ZM1332.53 1764.37L1333.42 1763.92L1333.87 1764.81L1332.98 1765.26L1332.53 1764.37ZM1330.73 1760.79L1330.28 1759.9L1331.18 1759.45L1331.63 1760.34L1330.73 1760.79ZM1291.42 1780.56L1290.53 1781.01L1290.08 1780.11L1290.97 1779.67L1291.42 1780.56ZM1292.77 1783.24L1332.08 1763.47L1332.98 1765.26L1293.67 1785.03L1292.77 1783.24ZM1331.64 1764.82L1329.84 1761.24L1331.63 1760.34L1333.42 1763.92L1331.64 1764.82ZM1331.18 1761.69L1291.87 1781.45L1290.97 1779.67L1330.28 1759.9L1331.18 1761.69ZM1292.32 1780.11L1294.11 1783.68L1292.33 1784.58L1290.53 1781.01L1292.32 1780.11Z'
        fill='#8BB6DB'
        mask='url(#path-1176-inside-272_6651_98482)'
      />
      <mask id='path-1178-inside-273_6651_98482' fill='white'>
        <path d='M1342.55 1762.86L1395.39 1734.61L1390.67 1725.8L1337.84 1754.04L1342.55 1762.86Z' />
      </mask>
      <path d='M1342.55 1762.86L1395.39 1734.61L1390.67 1725.8L1337.84 1754.04L1342.55 1762.86Z' fill='#ECF5FC' />
      <path
        d='M1342.08 1761.98L1394.91 1733.73L1395.86 1735.5L1343.03 1763.74L1342.08 1761.98ZM1391.14 1726.68L1338.31 1754.92L1337.37 1753.16L1390.2 1724.91L1391.14 1726.68Z'
        fill='#CFD1D2'
        mask='url(#path-1178-inside-273_6651_98482)'
      />
      <mask id='path-1180-inside-274_6651_98482' fill='white'>
        <path d='M1341.14 1760.21L1394.05 1731.93L1392.17 1728.4L1339.25 1756.69L1341.14 1760.21Z' />
      </mask>
      <path d='M1341.14 1760.21L1394.05 1731.93L1392.17 1728.4L1339.25 1756.69L1341.14 1760.21Z' fill='#AECCE7' />
      <path
        d='M1341.14 1760.21L1341.61 1761.1L1340.73 1761.57L1340.26 1760.69L1341.14 1760.21ZM1394.05 1731.93L1394.94 1731.45L1395.41 1732.34L1394.52 1732.81L1394.05 1731.93ZM1392.17 1728.4L1391.7 1727.52L1392.58 1727.04L1393.05 1727.93L1392.17 1728.4ZM1339.25 1756.69L1338.37 1757.16L1337.9 1756.28L1338.78 1755.8L1339.25 1756.69ZM1340.67 1759.33L1393.58 1731.04L1394.52 1732.81L1341.61 1761.1L1340.67 1759.33ZM1393.17 1732.4L1391.29 1728.87L1393.05 1727.93L1394.94 1731.45L1393.17 1732.4ZM1392.64 1729.28L1339.73 1757.57L1338.78 1755.8L1391.7 1727.52L1392.64 1729.28ZM1340.14 1756.21L1342.02 1759.74L1340.26 1760.69L1338.37 1757.16L1340.14 1756.21Z'
        fill='#8BB6DB'
        mask='url(#path-1180-inside-274_6651_98482)'
      />
      <mask id='path-1182-inside-275_6651_98482' fill='white'>
        <path d='M1404.31 1729.53L1446.25 1705.41L1441.27 1696.74L1399.33 1720.86L1404.31 1729.53Z' />
      </mask>
      <path d='M1404.31 1729.53L1446.25 1705.41L1441.27 1696.74L1399.33 1720.86L1404.31 1729.53Z' fill='#ECF5FC' />
      <path
        d='M1403.81 1728.66L1445.75 1704.54L1446.75 1706.28L1404.81 1730.39L1403.81 1728.66ZM1441.77 1697.61L1399.83 1721.72L1398.83 1719.99L1440.77 1695.88L1441.77 1697.61Z'
        fill='#CFD1D2'
        mask='url(#path-1182-inside-275_6651_98482)'
      />
      <mask id='path-1184-inside-276_6651_98482' fill='white'>
        <path d='M1402.82 1726.93L1444.43 1703L1442.44 1699.53L1400.83 1723.46L1402.82 1726.93Z' />
      </mask>
      <path d='M1402.82 1726.93L1444.43 1703L1442.44 1699.53L1400.83 1723.46L1402.82 1726.93Z' fill='#AECCE7' />
      <path
        d='M1402.82 1726.93L1403.32 1727.79L1402.45 1728.29L1401.95 1727.42L1402.82 1726.93ZM1444.43 1703L1445.3 1702.5L1445.8 1703.37L1444.93 1703.87L1444.43 1703ZM1442.44 1699.53L1441.94 1698.66L1442.81 1698.17L1443.3 1699.03L1442.44 1699.53ZM1400.83 1723.46L1399.96 1723.96L1399.46 1723.09L1400.33 1722.59L1400.83 1723.46ZM1402.32 1726.06L1443.93 1702.13L1444.93 1703.87L1403.32 1727.79L1402.32 1726.06ZM1443.56 1703.5L1441.57 1700.03L1443.3 1699.03L1445.3 1702.5L1443.56 1703.5ZM1442.94 1700.4L1401.32 1724.33L1400.33 1722.59L1441.94 1698.66L1442.94 1700.4ZM1401.69 1722.96L1403.69 1726.43L1401.95 1727.42L1399.96 1723.96L1401.69 1722.96Z'
        fill='#8BB6DB'
        mask='url(#path-1184-inside-276_6651_98482)'
      />
      <mask id='path-1186-inside-277_6651_98482' fill='white'>
        <path d='M1454.84 1700.23L1490.76 1678.38L1485.57 1669.84L1449.64 1691.69L1454.84 1700.23Z' />
      </mask>
      <path d='M1454.84 1700.23L1490.76 1678.38L1485.57 1669.84L1449.64 1691.69L1454.84 1700.23Z' fill='#ECF5FC' />
      <path
        d='M1454.32 1699.38L1490.24 1677.52L1491.28 1679.23L1455.36 1701.09L1454.32 1699.38ZM1486.09 1670.69L1450.16 1692.55L1449.12 1690.84L1485.05 1668.98L1486.09 1670.69Z'
        fill='#CFD1D2'
        mask='url(#path-1186-inside-277_6651_98482)'
      />
      <mask id='path-1188-inside-278_6651_98482' fill='white'>
        <path d='M1453.27 1697.67L1489.16 1675.84L1487.08 1672.43L1451.19 1694.25L1453.27 1697.67Z' />
      </mask>
      <path d='M1453.27 1697.67L1489.16 1675.84L1487.08 1672.43L1451.19 1694.25L1453.27 1697.67Z' fill='#AECCE7' />
      <path
        d='M1453.27 1697.67L1453.79 1698.53L1452.94 1699.05L1452.42 1698.19L1453.27 1697.67ZM1489.16 1675.84L1490.01 1675.32L1490.53 1676.18L1489.68 1676.7L1489.16 1675.84ZM1487.08 1672.43L1486.56 1671.57L1487.41 1671.05L1487.93 1671.91L1487.08 1672.43ZM1451.19 1694.25L1450.34 1694.77L1449.82 1693.92L1450.67 1693.4L1451.19 1694.25ZM1452.75 1696.82L1488.64 1674.99L1489.68 1676.7L1453.79 1698.53L1452.75 1696.82ZM1488.3 1676.36L1486.22 1672.95L1487.93 1671.91L1490.01 1675.32L1488.3 1676.36ZM1487.6 1673.28L1451.71 1695.11L1450.67 1693.4L1486.56 1671.57L1487.6 1673.28ZM1452.05 1693.73L1454.13 1697.15L1452.42 1698.19L1450.34 1694.77L1452.05 1693.73Z'
        fill='#8BB6DB'
        mask='url(#path-1188-inside-278_6651_98482)'
      />
      <mask id='path-1190-inside-279_6651_98482' fill='white'>
        <path d='M1499.27 1673.06L1538.63 1648.08L1533.27 1639.64L1493.91 1664.62L1499.27 1673.06Z' />
      </mask>
      <path d='M1499.27 1673.06L1538.63 1648.08L1533.27 1639.64L1493.91 1664.62L1499.27 1673.06Z' fill='#ECF5FC' />
      <path
        d='M1498.74 1672.22L1538.1 1647.24L1539.17 1648.93L1499.81 1673.91L1498.74 1672.22ZM1533.81 1640.48L1494.45 1665.46L1493.38 1663.77L1532.74 1638.79L1533.81 1640.48Z'
        fill='#CFD1D2'
        mask='url(#path-1190-inside-279_6651_98482)'
      />
      <mask id='path-1192-inside-280_6651_98482' fill='white'>
        <path d='M1497.66 1670.53L1537.35 1645.34L1535.2 1641.97L1495.52 1667.15L1497.66 1670.53Z' />
      </mask>
      <path d='M1497.66 1670.53L1537.35 1645.34L1535.2 1641.97L1495.52 1667.15L1497.66 1670.53Z' fill='#AECCE7' />
      <path
        d='M1497.66 1670.53L1498.2 1671.37L1497.36 1671.91L1496.82 1671.06L1497.66 1670.53ZM1537.35 1645.34L1538.19 1644.81L1538.73 1645.65L1537.88 1646.19L1537.35 1645.34ZM1535.2 1641.97L1534.67 1641.12L1535.51 1640.59L1536.05 1641.43L1535.2 1641.97ZM1495.52 1667.15L1494.68 1667.69L1494.14 1666.84L1494.98 1666.31L1495.52 1667.15ZM1497.13 1669.68L1536.81 1644.5L1537.88 1646.19L1498.2 1671.37L1497.13 1669.68ZM1536.5 1645.88L1534.36 1642.5L1536.05 1641.43L1538.19 1644.81L1536.5 1645.88ZM1535.74 1642.81L1496.06 1668L1494.98 1666.31L1534.67 1641.12L1535.74 1642.81ZM1496.36 1666.62L1498.51 1669.99L1496.82 1671.06L1494.68 1667.69L1496.36 1666.62Z'
        fill='#8BB6DB'
        mask='url(#path-1192-inside-280_6651_98482)'
      />
      <mask id='path-1194-inside-281_6651_98482' fill='white'>
        <path d='M1547.33 1642.11L1586.05 1616.1L1580.47 1607.8L1541.75 1633.8L1547.33 1642.11Z' />
      </mask>
      <path d='M1547.33 1642.11L1586.05 1616.1L1580.47 1607.8L1541.75 1633.8L1547.33 1642.11Z' fill='#ECF5FC' />
      <path
        d='M1546.77 1641.28L1585.49 1615.27L1586.6 1616.93L1547.89 1642.94L1546.77 1641.28ZM1581.03 1608.63L1542.31 1634.63L1541.2 1632.97L1579.91 1606.97L1581.03 1608.63Z'
        fill='#CFD1D2'
        mask='url(#path-1194-inside-281_6651_98482)'
      />
      <mask id='path-1196-inside-282_6651_98482' fill='white'>
        <path d='M1545.66 1639.62L1584.67 1613.41L1582.44 1610.09L1543.43 1636.29L1545.66 1639.62Z' />
      </mask>
      <path d='M1545.66 1639.62L1584.67 1613.41L1582.44 1610.09L1543.43 1636.29L1545.66 1639.62Z' fill='#AECCE7' />
      <path
        d='M1545.66 1639.62L1546.21 1640.45L1545.38 1641L1544.83 1640.17L1545.66 1639.62ZM1584.67 1613.41L1585.5 1612.85L1586.06 1613.68L1585.23 1614.24L1584.67 1613.41ZM1582.44 1610.09L1581.89 1609.26L1582.72 1608.7L1583.27 1609.53L1582.44 1610.09ZM1543.43 1636.29L1542.6 1636.85L1542.04 1636.02L1542.87 1635.46L1543.43 1636.29ZM1545.1 1638.79L1584.12 1612.58L1585.23 1614.24L1546.21 1640.45L1545.1 1638.79ZM1583.84 1613.97L1581.61 1610.65L1583.27 1609.53L1585.5 1612.85L1583.84 1613.97ZM1583 1610.92L1543.98 1637.12L1542.87 1635.46L1581.89 1609.26L1583 1610.92ZM1544.26 1635.74L1546.49 1639.06L1544.83 1640.17L1542.6 1636.85L1544.26 1635.74Z'
        fill='#8BB6DB'
        mask='url(#path-1196-inside-282_6651_98482)'
      />
      <mask id='path-1198-inside-283_6651_98482' fill='white'>
        <path d='M1593.94 1610.72L1630.81 1585.01L1625.09 1576.81L1588.22 1602.52L1593.94 1610.72Z' />
      </mask>
      <path d='M1593.94 1610.72L1630.81 1585.01L1625.09 1576.81L1588.22 1602.52L1593.94 1610.72Z' fill='#ECF5FC' />
      <path
        d='M1593.37 1609.9L1630.24 1584.19L1631.38 1585.83L1594.51 1611.54L1593.37 1609.9ZM1625.66 1577.63L1588.79 1603.34L1587.65 1601.7L1624.52 1575.99L1625.66 1577.63Z'
        fill='#CFD1D2'
        mask='url(#path-1198-inside-283_6651_98482)'
      />
      <mask id='path-1200-inside-284_6651_98482' fill='white'>
        <path d='M1592.22 1608.26L1629.13 1582.52L1626.84 1579.24L1589.93 1604.98L1592.22 1608.26Z' />
      </mask>
      <path d='M1592.22 1608.26L1629.13 1582.52L1626.84 1579.24L1589.93 1604.98L1592.22 1608.26Z' fill='#AECCE7' />
      <path
        d='M1592.22 1608.26L1592.79 1609.08L1591.97 1609.65L1591.4 1608.83L1592.22 1608.26ZM1629.13 1582.52L1629.95 1581.95L1630.52 1582.77L1629.7 1583.34L1629.13 1582.52ZM1626.84 1579.24L1626.27 1578.42L1627.09 1577.85L1627.66 1578.67L1626.84 1579.24ZM1589.93 1604.98L1589.11 1605.55L1588.54 1604.73L1589.36 1604.16L1589.93 1604.98ZM1591.65 1607.44L1628.56 1581.7L1629.7 1583.34L1592.79 1609.08L1591.65 1607.44ZM1628.31 1583.09L1626.02 1579.81L1627.66 1578.67L1629.95 1581.95L1628.31 1583.09ZM1627.41 1580.06L1590.5 1605.8L1589.36 1604.16L1626.27 1578.42L1627.41 1580.06ZM1590.75 1604.41L1593.04 1607.69L1591.4 1608.83L1589.11 1605.55L1590.75 1604.41Z'
        fill='#8BB6DB'
        mask='url(#path-1200-inside-284_6651_98482)'
      />
      <mask id='path-1202-inside-285_6651_98482' fill='white'>
        <path d='M1638.83 1579.18L1675.45 1552.51L1669.56 1544.43L1632.94 1571.1L1638.83 1579.18Z' />
      </mask>
      <path d='M1638.83 1579.18L1675.45 1552.51L1669.56 1544.43L1632.94 1571.1L1638.83 1579.18Z' fill='#ECF5FC' />
      <path
        d='M1638.24 1578.38L1674.86 1551.7L1676.04 1553.32L1639.42 1579.99L1638.24 1578.38ZM1670.15 1545.23L1633.53 1571.91L1632.35 1570.29L1668.97 1543.62L1670.15 1545.23Z'
        fill='#CFD1D2'
        mask='url(#path-1202-inside-285_6651_98482)'
      />
      <mask id='path-1204-inside-286_6651_98482' fill='white'>
        <path d='M1637.06 1576.76L1673.44 1550.26L1671.08 1547.03L1634.71 1573.53L1637.06 1576.76Z' />
      </mask>
      <path d='M1637.06 1576.76L1673.44 1550.26L1671.08 1547.03L1634.71 1573.53L1637.06 1576.76Z' fill='#AECCE7' />
      <path
        d='M1637.06 1576.76L1637.65 1577.57L1636.84 1578.16L1636.25 1577.35L1637.06 1576.76ZM1673.44 1550.26L1674.24 1549.68L1674.83 1550.48L1674.02 1551.07L1673.44 1550.26ZM1671.08 1547.03L1670.49 1546.22L1671.3 1545.63L1671.89 1546.44L1671.08 1547.03ZM1634.71 1573.53L1633.9 1574.11L1633.31 1573.31L1634.12 1572.72L1634.71 1573.53ZM1636.47 1575.95L1672.85 1549.46L1674.02 1551.07L1637.65 1577.57L1636.47 1575.95ZM1672.63 1550.85L1670.27 1547.62L1671.89 1546.44L1674.24 1549.68L1672.63 1550.85ZM1671.67 1547.84L1635.3 1574.33L1634.12 1572.72L1670.49 1546.22L1671.67 1547.84ZM1635.52 1572.94L1637.87 1576.17L1636.25 1577.35L1633.9 1574.11L1635.52 1572.94Z'
        fill='#8BB6DB'
        mask='url(#path-1204-inside-286_6651_98482)'
      />
      <mask id='path-1206-inside-287_6651_98482' fill='white'>
        <path d='M1683.81 1546.15L1714.03 1523.52L1708.04 1515.52L1677.82 1538.15L1683.81 1546.15Z' />
      </mask>
      <path d='M1683.81 1546.15L1714.03 1523.52L1708.04 1515.52L1677.82 1538.15L1683.81 1546.15Z' fill='#ECF5FC' />
      <path
        d='M1683.21 1545.35L1713.43 1522.72L1714.63 1524.32L1684.41 1546.95L1683.21 1545.35ZM1708.64 1516.32L1678.42 1538.95L1677.22 1537.35L1707.44 1514.72L1708.64 1516.32Z'
        fill='#CFD1D2'
        mask='url(#path-1206-inside-287_6651_98482)'
      />
      <mask id='path-1208-inside-288_6651_98482' fill='white'>
        <path d='M1682.02 1543.75L1712.43 1520.97L1710.04 1517.77L1679.62 1540.55L1682.02 1543.75Z' />
      </mask>
      <path d='M1682.02 1543.75L1712.43 1520.97L1710.04 1517.77L1679.62 1540.55L1682.02 1543.75Z' fill='#AECCE7' />
      <path
        d='M1682.02 1543.75L1682.61 1544.55L1681.81 1545.15L1681.22 1544.35L1682.02 1543.75ZM1712.43 1520.97L1713.23 1520.38L1713.83 1521.18L1713.03 1521.78L1712.43 1520.97ZM1710.04 1517.77L1709.44 1516.97L1710.24 1516.37L1710.84 1517.17L1710.04 1517.77ZM1679.62 1540.55L1678.82 1541.15L1678.22 1540.35L1679.02 1539.75L1679.62 1540.55ZM1681.42 1542.95L1711.83 1520.17L1713.03 1521.78L1682.61 1544.55L1681.42 1542.95ZM1711.63 1521.57L1709.24 1518.37L1710.84 1517.17L1713.23 1520.38L1711.63 1521.57ZM1710.64 1518.57L1680.22 1541.35L1679.02 1539.75L1709.44 1516.97L1710.64 1518.57ZM1680.42 1539.95L1682.82 1543.15L1681.22 1544.35L1678.82 1541.15L1680.42 1539.95Z'
        fill='#8BB6DB'
        mask='url(#path-1208-inside-288_6651_98482)'
      />
      <mask id='path-1210-inside-289_6651_98482' fill='white'>
        <path d='M1721.71 1517.55L1759.9 1487.73L1753.75 1479.85L1715.56 1509.67L1721.71 1517.55Z' />
      </mask>
      <path d='M1721.71 1517.55L1759.9 1487.73L1753.75 1479.85L1715.56 1509.67L1721.71 1517.55Z' fill='#ECF5FC' />
      <path
        d='M1721.1 1516.76L1759.29 1486.95L1760.52 1488.52L1722.33 1518.34L1721.1 1516.76ZM1754.36 1480.64L1716.17 1510.45L1714.94 1508.88L1753.13 1479.06L1754.36 1480.64Z'
        fill='#CFD1D2'
        mask='url(#path-1210-inside-289_6651_98482)'
      />
      <mask id='path-1212-inside-290_6651_98482' fill='white'>
        <path d='M1719.87 1515.18L1757.7 1485.65L1755.24 1482.49L1717.41 1512.03L1719.87 1515.18Z' />
      </mask>
      <path d='M1719.87 1515.18L1757.7 1485.65L1755.24 1482.49L1717.41 1512.03L1719.87 1515.18Z' fill='#AECCE7' />
      <path
        d='M1719.87 1515.18L1720.48 1515.97L1719.69 1516.59L1719.08 1515.8L1719.87 1515.18ZM1757.7 1485.65L1758.49 1485.03L1759.11 1485.82L1758.32 1486.44L1757.7 1485.65ZM1755.24 1482.49L1754.63 1481.71L1755.41 1481.09L1756.03 1481.88L1755.24 1482.49ZM1717.41 1512.03L1716.62 1512.65L1716 1511.86L1716.79 1511.24L1717.41 1512.03ZM1719.25 1514.4L1757.09 1484.86L1758.32 1486.44L1720.48 1515.97L1719.25 1514.4ZM1756.92 1486.26L1754.45 1483.11L1756.03 1481.88L1758.49 1485.03L1756.92 1486.26ZM1755.86 1483.28L1718.02 1512.82L1716.79 1511.24L1754.63 1481.71L1755.86 1483.28ZM1718.19 1511.42L1720.66 1514.57L1719.08 1515.8L1716.62 1512.65L1718.19 1511.42Z'
        fill='#8BB6DB'
        mask='url(#path-1212-inside-290_6651_98482)'
      />
      <mask id='path-1214-inside-291_6651_98482' fill='white'>
        <path d='M1767.67 1481.44L1801.14 1454.61L1794.89 1446.81L1761.42 1473.63L1767.67 1481.44Z' />
      </mask>
      <path d='M1767.67 1481.44L1801.14 1454.61L1794.89 1446.81L1761.42 1473.63L1767.67 1481.44Z' fill='#ECF5FC' />
      <path
        d='M1767.05 1480.66L1800.52 1453.83L1801.77 1455.39L1768.3 1482.22L1767.05 1480.66ZM1795.51 1447.59L1762.04 1474.41L1760.79 1472.85L1794.26 1446.03L1795.51 1447.59Z'
        fill='#CFD1D2'
        mask='url(#path-1214-inside-291_6651_98482)'
      />
      <mask id='path-1216-inside-292_6651_98482' fill='white'>
        <path d='M1765.8 1479.1L1799.35 1452.21L1796.85 1449.08L1763.3 1475.98L1765.8 1479.1Z' />
      </mask>
      <path d='M1765.8 1479.1L1799.35 1452.21L1796.85 1449.08L1763.3 1475.98L1765.8 1479.1Z' fill='#AECCE7' />
      <path
        d='M1765.8 1479.1L1766.42 1479.88L1765.64 1480.5L1765.02 1479.72L1765.8 1479.1ZM1799.35 1452.21L1800.13 1451.58L1800.76 1452.36L1799.98 1452.99L1799.35 1452.21ZM1796.85 1449.08L1796.22 1448.3L1797 1447.68L1797.63 1448.46L1796.85 1449.08ZM1763.3 1475.98L1762.52 1476.6L1761.89 1475.82L1762.67 1475.2L1763.3 1475.98ZM1765.17 1478.32L1798.73 1451.43L1799.98 1452.99L1766.42 1479.88L1765.17 1478.32ZM1798.57 1452.83L1796.07 1449.71L1797.63 1448.46L1800.13 1451.58L1798.57 1452.83ZM1797.47 1449.86L1763.92 1476.76L1762.67 1475.2L1796.22 1448.3L1797.47 1449.86ZM1764.08 1475.35L1766.58 1478.47L1765.02 1479.72L1762.52 1476.6L1764.08 1475.35Z'
        fill='#8BB6DB'
        mask='url(#path-1216-inside-292_6651_98482)'
      />
      <mask id='path-1218-inside-293_6651_98482' fill='white'>
        <path d='M1808.62 1448.46L1848.79 1414.95L1842.39 1407.27L1802.22 1440.78L1808.62 1448.46Z' />
      </mask>
      <path d='M1808.62 1448.46L1848.79 1414.95L1842.39 1407.27L1802.22 1440.78L1808.62 1448.46Z' fill='#ECF5FC' />
      <path
        d='M1807.98 1447.69L1848.15 1414.18L1849.43 1415.71L1809.27 1449.23L1807.98 1447.69ZM1843.03 1408.04L1802.86 1441.55L1801.58 1440.02L1841.74 1406.5L1843.03 1408.04Z'
        fill='#CFD1D2'
        mask='url(#path-1218-inside-293_6651_98482)'
      />
      <mask id='path-1220-inside-294_6651_98482' fill='white'>
        <path d='M1806.7 1446.16L1846.63 1412.84L1844.07 1409.77L1804.14 1443.09L1806.7 1446.16Z' />
      </mask>
      <path d='M1806.7 1446.16L1846.63 1412.84L1844.07 1409.77L1804.14 1443.09L1806.7 1446.16Z' fill='#AECCE7' />
      <path
        d='M1806.7 1446.16L1807.34 1446.93L1806.58 1447.57L1805.94 1446.8L1806.7 1446.16ZM1846.63 1412.84L1847.4 1412.2L1848.04 1412.97L1847.27 1413.61L1846.63 1412.84ZM1844.07 1409.77L1843.43 1409L1844.19 1408.36L1844.83 1409.13L1844.07 1409.77ZM1804.14 1443.09L1803.37 1443.73L1802.73 1442.96L1803.5 1442.32L1804.14 1443.09ZM1806.06 1445.39L1845.99 1412.08L1847.27 1413.61L1807.34 1446.93L1806.06 1445.39ZM1845.86 1413.48L1843.3 1410.41L1844.83 1409.13L1847.4 1412.2L1845.86 1413.48ZM1844.71 1410.54L1804.78 1443.86L1803.5 1442.32L1843.43 1409L1844.71 1410.54ZM1804.91 1442.45L1807.47 1445.52L1805.94 1446.8L1803.37 1443.73L1804.91 1442.45Z'
        fill='#8BB6DB'
        mask='url(#path-1220-inside-294_6651_98482)'
      />
      <mask id='path-1222-inside-295_6651_98482' fill='white'>
        <path d='M1856.31 1408.79L1887.78 1382.04L1881.3 1374.42L1849.84 1401.17L1856.31 1408.79Z' />
      </mask>
      <path d='M1856.31 1408.79L1887.78 1382.04L1881.3 1374.42L1849.84 1401.17L1856.31 1408.79Z' fill='#ECF5FC' />
      <path
        d='M1855.66 1408.03L1887.13 1381.28L1888.43 1382.8L1856.96 1409.55L1855.66 1408.03ZM1881.95 1375.18L1850.48 1401.93L1849.19 1400.41L1880.65 1373.66L1881.95 1375.18Z'
        fill='#CFD1D2'
        mask='url(#path-1222-inside-295_6651_98482)'
      />
      <mask id='path-1224-inside-296_6651_98482' fill='white'>
        <path d='M1854.37 1406.5L1885.6 1379.95L1883.01 1376.9L1851.78 1403.46L1854.37 1406.5Z' />
      </mask>
      <path d='M1854.37 1406.5L1885.6 1379.95L1883.01 1376.9L1851.78 1403.46L1854.37 1406.5Z' fill='#AECCE7' />
      <path
        d='M1854.37 1406.5L1855.01 1407.27L1854.25 1407.91L1853.61 1407.15L1854.37 1406.5ZM1885.6 1379.95L1886.37 1379.3L1887.01 1380.06L1886.25 1380.71L1885.6 1379.95ZM1883.01 1376.9L1882.37 1376.14L1883.13 1375.49L1883.78 1376.25L1883.01 1376.9ZM1851.78 1403.46L1851.01 1404.1L1850.37 1403.34L1851.13 1402.69L1851.78 1403.46ZM1853.72 1405.74L1884.96 1379.19L1886.25 1380.71L1855.01 1407.27L1853.72 1405.74ZM1884.84 1380.59L1882.25 1377.55L1883.78 1376.25L1886.37 1379.3L1884.84 1380.59ZM1883.66 1377.66L1852.42 1404.22L1851.13 1402.69L1882.37 1376.14L1883.66 1377.66ZM1852.54 1402.81L1855.13 1405.86L1853.61 1407.15L1851.01 1404.1L1852.54 1402.81Z'
        fill='#8BB6DB'
        mask='url(#path-1224-inside-296_6651_98482)'
      />
      <mask id='path-1226-inside-297_6651_98482' fill='white'>
        <path d='M1895.22 1375.25L1926.5 1347.74L1919.9 1340.23L1888.61 1367.74L1895.22 1375.25Z' />
      </mask>
      <path d='M1895.22 1375.25L1926.5 1347.74L1919.9 1340.23L1888.61 1367.74L1895.22 1375.25Z' fill='#ECF5FC' />
      <path
        d='M1894.56 1374.5L1925.84 1346.99L1927.17 1348.49L1895.88 1376L1894.56 1374.5ZM1920.56 1340.98L1889.27 1368.5L1887.95 1366.99L1919.24 1339.48L1920.56 1340.98Z'
        fill='#CFD1D2'
        mask='url(#path-1226-inside-297_6651_98482)'
      />
      <mask id='path-1228-inside-298_6651_98482' fill='white'>
        <path d='M1893.23 1373L1924.77 1345.26L1922.13 1342.26L1890.59 1370L1893.23 1373Z' />
      </mask>
      <path d='M1893.23 1373L1924.77 1345.26L1922.13 1342.26L1890.59 1370L1893.23 1373Z' fill='#AECCE7' />
      <path
        d='M1893.23 1373L1893.89 1373.75L1893.14 1374.41L1892.48 1373.66L1893.23 1373ZM1924.77 1345.26L1925.52 1344.6L1926.18 1345.35L1925.43 1346.01L1924.77 1345.26ZM1922.13 1342.26L1921.47 1341.51L1922.22 1340.85L1922.88 1341.6L1922.13 1342.26ZM1890.59 1370L1889.84 1370.66L1889.18 1369.91L1889.93 1369.25L1890.59 1370ZM1892.57 1372.25L1924.11 1344.51L1925.43 1346.01L1893.89 1373.75L1892.57 1372.25ZM1924.02 1345.92L1921.38 1342.92L1922.88 1341.6L1925.52 1344.6L1924.02 1345.92ZM1922.79 1343.01L1891.25 1370.75L1889.93 1369.25L1921.47 1341.51L1922.79 1343.01ZM1891.34 1369.34L1893.99 1372.34L1892.48 1373.66L1889.84 1370.66L1891.34 1369.34Z'
        fill='#8BB6DB'
        mask='url(#path-1228-inside-298_6651_98482)'
      />
      <mask id='path-1230-inside-299_6651_98482' fill='white'>
        <path d='M1934.05 1340.87L1969.69 1308.79L1963 1301.36L1927.36 1333.44L1934.05 1340.87Z' />
      </mask>
      <path d='M1934.05 1340.87L1969.69 1308.79L1963 1301.36L1927.36 1333.44L1934.05 1340.87Z' fill='#ECF5FC' />
      <path
        d='M1933.39 1340.13L1969.02 1308.05L1970.36 1309.53L1934.72 1341.62L1933.39 1340.13ZM1963.67 1302.1L1928.03 1334.19L1926.69 1332.7L1962.33 1300.61L1963.67 1302.1Z'
        fill='#CFD1D2'
        mask='url(#path-1230-inside-299_6651_98482)'
      />
      <mask id='path-1232-inside-300_6651_98482' fill='white'>
        <path d='M1932.05 1338.65L1967.72 1306.53L1965.04 1303.55L1929.37 1335.67L1932.05 1338.65Z' />
      </mask>
      <path d='M1932.05 1338.65L1967.72 1306.53L1965.04 1303.55L1929.37 1335.67L1932.05 1338.65Z' fill='#AECCE7' />
      <path
        d='M1932.05 1338.65L1932.72 1339.39L1931.97 1340.06L1931.3 1339.31L1932.05 1338.65ZM1967.72 1306.53L1968.46 1305.86L1969.13 1306.6L1968.39 1307.27L1967.72 1306.53ZM1965.04 1303.55L1964.37 1302.81L1965.12 1302.14L1965.79 1302.89L1965.04 1303.55ZM1929.37 1335.67L1928.63 1336.34L1927.96 1335.6L1928.7 1334.93L1929.37 1335.67ZM1931.38 1337.9L1967.05 1305.78L1968.39 1307.27L1932.72 1339.39L1931.38 1337.9ZM1966.98 1307.2L1964.3 1304.22L1965.79 1302.89L1968.46 1305.86L1966.98 1307.2ZM1965.71 1304.3L1930.04 1336.42L1928.7 1334.93L1964.37 1302.81L1965.71 1304.3ZM1930.11 1335L1932.79 1337.98L1931.3 1339.31L1928.63 1336.34L1930.11 1335Z'
        fill='#8BB6DB'
        mask='url(#path-1232-inside-300_6651_98482)'
      />
      <mask id='path-1234-inside-301_6651_98482' fill='white'>
        <path d='M1977 1301.93L2014.92 1266.94L2008.13 1259.59L1970.22 1294.58L1977 1301.93Z' />
      </mask>
      <path d='M1977 1301.93L2014.92 1266.94L2008.13 1259.59L1970.22 1294.58L1977 1301.93Z' fill='#ECF5FC' />
      <path
        d='M1976.32 1301.19L2014.24 1266.2L2015.6 1267.67L1977.68 1302.66L1976.32 1301.19ZM2008.81 1260.32L1970.9 1295.31L1969.54 1293.85L2007.46 1258.85L2008.81 1260.32Z'
        fill='#CFD1D2'
        mask='url(#path-1234-inside-301_6651_98482)'
      />
      <mask id='path-1236-inside-302_6651_98482' fill='white'>
        <path d='M1974.97 1299.72L2013.18 1264.46L2010.47 1261.52L1972.26 1296.78L1974.97 1299.72Z' />
      </mask>
      <path d='M1974.97 1299.72L2013.18 1264.46L2010.47 1261.52L1972.26 1296.78L1974.97 1299.72Z' fill='#AECCE7' />
      <path
        d='M1974.97 1299.72L1975.65 1300.46L1974.91 1301.14L1974.23 1300.4L1974.97 1299.72ZM2013.18 1264.46L2013.92 1263.78L2014.6 1264.51L2013.86 1265.19L2013.18 1264.46ZM2010.47 1261.52L2009.79 1260.78L2010.53 1260.11L2011.2 1260.84L2010.47 1261.52ZM1972.26 1296.78L1971.52 1297.46L1970.84 1296.73L1971.58 1296.05L1972.26 1296.78ZM1974.29 1298.99L2012.5 1263.72L2013.86 1265.19L1975.65 1300.46L1974.29 1298.99ZM2012.45 1265.14L2009.73 1262.2L2011.2 1260.84L2013.92 1263.78L2012.45 1265.14ZM2011.15 1262.25L1972.93 1297.52L1971.58 1296.05L2009.79 1260.78L2011.15 1262.25ZM1972.99 1296.11L1975.7 1299.05L1974.23 1300.4L1971.52 1297.46L1972.99 1296.11Z'
        fill='#8BB6DB'
        mask='url(#path-1236-inside-302_6651_98482)'
      />
      <mask id='path-1238-inside-303_6651_98482' fill='white'>
        <path d='M2022.03 1259.98L2053.91 1229.59L2047.01 1222.35L2015.13 1252.74L2022.03 1259.98Z' />
      </mask>
      <path d='M2022.03 1259.98L2053.91 1229.59L2047.01 1222.35L2015.13 1252.74L2022.03 1259.98Z' fill='#ECF5FC' />
      <path
        d='M2021.34 1259.25L2053.22 1228.86L2054.6 1230.31L2022.72 1260.7L2021.34 1259.25ZM2047.7 1223.07L2015.82 1253.46L2014.44 1252.01L2046.32 1221.62L2047.7 1223.07Z'
        fill='#CFD1D2'
        mask='url(#path-1238-inside-303_6651_98482)'
      />
      <mask id='path-1240-inside-304_6651_98482' fill='white'>
        <path d='M2019.96 1257.81L2051.81 1227.45L2049.05 1224.55L2017.2 1254.91L2019.96 1257.81Z' />
      </mask>
      <path d='M2019.96 1257.81L2051.81 1227.45L2049.05 1224.55L2017.2 1254.91L2019.96 1257.81Z' fill='#AECCE7' />
      <path
        d='M2019.96 1257.81L2020.65 1258.53L2019.93 1259.22L2019.24 1258.5L2019.96 1257.81ZM2051.81 1227.45L2052.53 1226.76L2053.22 1227.48L2052.5 1228.17L2051.81 1227.45ZM2049.05 1224.55L2048.36 1223.83L2049.08 1223.14L2049.77 1223.86L2049.05 1224.55ZM2017.2 1254.91L2016.48 1255.6L2015.79 1254.88L2016.51 1254.19L2017.2 1254.91ZM2019.27 1257.08L2051.12 1226.72L2052.5 1228.17L2020.65 1258.53L2019.27 1257.08ZM2051.09 1228.14L2048.33 1225.24L2049.77 1223.86L2052.53 1226.76L2051.09 1228.14ZM2049.74 1225.27L2017.89 1255.63L2016.51 1254.19L2048.36 1223.83L2049.74 1225.27ZM2017.92 1254.22L2020.68 1257.12L2019.24 1258.5L2016.48 1255.6L2017.92 1254.22Z'
        fill='#8BB6DB'
        mask='url(#path-1240-inside-304_6651_98482)'
      />
      <mask id='path-1242-inside-305_6651_98482' fill='white'>
        <path d='M2061.1 1222.48L2092.44 1191.84L2085.45 1184.69L2054.11 1215.33L2061.1 1222.48Z' />
      </mask>
      <path d='M2061.1 1222.48L2092.44 1191.84L2085.45 1184.69L2054.11 1215.33L2061.1 1222.48Z' fill='#ECF5FC' />
      <path
        d='M2060.4 1221.77L2091.74 1191.12L2093.14 1192.55L2061.8 1223.2L2060.4 1221.77ZM2086.15 1185.4L2054.81 1216.05L2053.41 1214.62L2084.75 1183.97L2086.15 1185.4Z'
        fill='#CFD1D2'
        mask='url(#path-1242-inside-305_6651_98482)'
      />
      <mask id='path-1244-inside-306_6651_98482' fill='white'>
        <path d='M2059.01 1220.34L2090.47 1189.57L2087.67 1186.71L2056.21 1217.48L2059.01 1220.34Z' />
      </mask>
      <path d='M2059.01 1220.34L2090.47 1189.57L2087.67 1186.71L2056.21 1217.48L2059.01 1220.34Z' fill='#AECCE7' />
      <path
        d='M2059.01 1220.34L2059.71 1221.05L2058.99 1221.75L2058.29 1221.03L2059.01 1220.34ZM2090.47 1189.57L2091.18 1188.88L2091.88 1189.59L2091.17 1190.29L2090.47 1189.57ZM2087.67 1186.71L2086.97 1186L2087.69 1185.3L2088.39 1186.02L2087.67 1186.71ZM2056.21 1217.48L2055.5 1218.17L2054.8 1217.46L2055.51 1216.76L2056.21 1217.48ZM2058.31 1219.62L2089.77 1188.86L2091.17 1190.29L2059.71 1221.05L2058.31 1219.62ZM2089.75 1190.27L2086.96 1187.41L2088.39 1186.02L2091.18 1188.88L2089.75 1190.27ZM2088.37 1187.43L2056.91 1218.19L2055.51 1216.76L2086.97 1186L2088.37 1187.43ZM2056.93 1216.78L2059.72 1219.64L2058.29 1221.03L2055.5 1218.17L2056.93 1216.78Z'
        fill='#8BB6DB'
        mask='url(#path-1244-inside-306_6651_98482)'
      />
      <mask id='path-1246-inside-307_6651_98482' fill='white'>
        <path d='M2099.71 1184.55L2131.57 1152.21L2124.45 1145.19L2092.59 1177.54L2099.71 1184.55Z' />
      </mask>
      <path d='M2099.71 1184.55L2131.57 1152.21L2124.45 1145.19L2092.59 1177.54L2099.71 1184.55Z' fill='#ECF5FC' />
      <path
        d='M2099 1183.85L2130.86 1151.51L2132.28 1152.91L2100.42 1185.26L2099 1183.85ZM2125.16 1145.89L2093.3 1178.24L2091.87 1176.83L2123.73 1144.49L2125.16 1145.89Z'
        fill='#CFD1D2'
        mask='url(#path-1246-inside-307_6651_98482)'
      />
      <mask id='path-1248-inside-308_6651_98482' fill='white'>
        <path d='M2097.57 1182.45L2129.15 1150.39L2126.3 1147.58L2094.72 1179.64L2097.57 1182.45Z' />
      </mask>
      <path d='M2097.57 1182.45L2129.15 1150.39L2126.3 1147.58L2094.72 1179.64L2097.57 1182.45Z' fill='#AECCE7' />
      <path
        d='M2097.57 1182.45L2098.28 1183.15L2097.58 1183.86L2096.87 1183.16L2097.57 1182.45ZM2129.15 1150.39L2129.85 1149.68L2130.56 1150.38L2129.86 1151.09L2129.15 1150.39ZM2126.3 1147.58L2125.59 1146.88L2126.29 1146.17L2127 1146.87L2126.3 1147.58ZM2094.72 1179.64L2094.02 1180.35L2093.31 1179.65L2094.01 1178.94L2094.72 1179.64ZM2096.86 1181.75L2128.44 1149.69L2129.86 1151.09L2098.28 1183.15L2096.86 1181.75ZM2128.45 1151.1L2125.6 1148.3L2127 1146.87L2129.85 1149.68L2128.45 1151.1ZM2127.01 1148.28L2095.43 1180.34L2094.01 1178.94L2125.59 1146.88L2127.01 1148.28ZM2095.42 1178.93L2098.27 1181.74L2096.87 1183.16L2094.02 1180.35L2095.42 1178.93Z'
        fill='#8BB6DB'
        mask='url(#path-1248-inside-308_6651_98482)'
      />
      <mask id='path-1250-inside-309_6651_98482' fill='white'>
        <path d='M2138.58 1145.23L2170.7 1111.76L2163.48 1104.84L2131.36 1138.31L2138.58 1145.23Z' />
      </mask>
      <path d='M2138.58 1145.23L2170.7 1111.76L2163.48 1104.84L2131.36 1138.31L2138.58 1145.23Z' fill='#ECF5FC' />
      <path
        d='M2137.86 1144.54L2169.97 1111.07L2171.42 1112.46L2139.3 1145.93L2137.86 1144.54ZM2164.2 1105.53L2132.08 1139L2130.64 1137.62L2162.76 1104.15L2164.2 1105.53Z'
        fill='#CFD1D2'
        mask='url(#path-1250-inside-309_6651_98482)'
      />
      <mask id='path-1252-inside-310_6651_98482' fill='white'>
        <path d='M2136.41 1143.16L2168.26 1109.97L2165.38 1107.2L2133.53 1140.39L2136.41 1143.16Z' />
      </mask>
      <path d='M2136.41 1143.16L2168.26 1109.97L2165.38 1107.2L2133.53 1140.39L2136.41 1143.16Z' fill='#AECCE7' />
      <path
        d='M2136.41 1143.16L2137.14 1143.85L2136.44 1144.57L2135.72 1143.88L2136.41 1143.16ZM2168.26 1109.97L2168.96 1109.25L2169.68 1109.94L2168.99 1110.66L2168.26 1109.97ZM2165.38 1107.2L2164.66 1106.5L2165.35 1105.78L2166.07 1106.48L2165.38 1107.2ZM2133.53 1140.39L2132.84 1141.11L2132.11 1140.42L2132.81 1139.7L2133.53 1140.39ZM2135.69 1142.46L2167.54 1109.27L2168.99 1110.66L2137.14 1143.85L2135.69 1142.46ZM2167.57 1110.69L2164.69 1107.92L2166.07 1106.48L2168.96 1109.25L2167.57 1110.69ZM2166.1 1107.89L2134.25 1141.08L2132.81 1139.7L2164.66 1106.5L2166.1 1107.89ZM2134.22 1139.67L2137.11 1142.44L2135.72 1143.88L2132.84 1141.11L2134.22 1139.67Z'
        fill='#8BB6DB'
        mask='url(#path-1252-inside-310_6651_98482)'
      />
      <mask id='path-1254-inside-311_6651_98482' fill='white'>
        <path d='M2177.55 1104.45L2206.02 1073.56L2198.67 1066.78L2170.19 1097.68L2177.55 1104.45Z' />
      </mask>
      <path d='M2177.55 1104.45L2206.02 1073.56L2198.67 1066.78L2170.19 1097.68L2177.55 1104.45Z' fill='#ECF5FC' />
      <path
        d='M2176.81 1103.78L2205.28 1072.88L2206.75 1074.24L2178.28 1105.13L2176.81 1103.78ZM2199.4 1067.46L2170.93 1098.35L2169.46 1097L2197.93 1066.1L2199.4 1067.46Z'
        fill='#CFD1D2'
        mask='url(#path-1254-inside-311_6651_98482)'
      />
      <mask id='path-1256-inside-312_6651_98482' fill='white'>
        <path d='M2175.34 1102.42L2203.81 1071.54L2200.87 1068.83L2172.4 1099.71L2175.34 1102.42Z' />
      </mask>
      <path d='M2175.34 1102.42L2203.81 1071.54L2200.87 1068.83L2172.4 1099.71L2175.34 1102.42Z' fill='#AECCE7' />
      <path
        d='M2175.34 1102.42L2176.08 1103.1L2175.4 1103.83L2174.67 1103.16L2175.34 1102.42ZM2203.81 1071.54L2204.49 1070.8L2205.22 1071.48L2204.54 1072.21L2203.81 1071.54ZM2200.87 1068.83L2200.13 1068.15L2200.81 1067.41L2201.54 1068.09L2200.87 1068.83ZM2172.4 1099.71L2171.72 1100.44L2170.99 1099.77L2171.67 1099.03L2172.4 1099.71ZM2174.61 1101.74L2203.07 1070.86L2204.54 1072.21L2176.08 1103.1L2174.61 1101.74ZM2203.13 1072.27L2200.19 1069.56L2201.54 1068.09L2204.49 1070.8L2203.13 1072.27ZM2201.6 1069.5L2173.14 1100.39L2171.67 1099.03L2200.13 1068.15L2201.6 1069.5ZM2173.08 1098.97L2176.02 1101.68L2174.67 1103.16L2171.72 1100.44L2173.08 1098.97Z'
        fill='#8BB6DB'
        mask='url(#path-1256-inside-312_6651_98482)'
      />
      <path
        d='M726.908 445.818C732.143 434.133 743.872 426 757.496 426C760.202 426 762.83 426.32 765.345 426.924L831.437 449L832.496 445.137L766.451 423.076L766.369 423.056C763.523 422.366 760.551 422 757.496 422C742.239 422 729.114 431.112 723.258 444.182L726.908 445.818Z'
        fill='#AECCE6'
      />
      <path d='M895.496 466.18L894.307 470L863.996 459.876L864.996 455.992L895.496 466.18Z' fill='#AECCE6' />
      <path d='M959.863 491.897L961.13 488.103L926.996 477L925.996 480.5L959.863 491.897Z' fill='#AECCE6' />
      <path
        d='M726.908 445.818C732.143 434.133 743.872 426 757.496 426C760.202 426 762.83 426.32 765.345 426.924L831.437 449L832.496 445.137L766.451 423.076L766.369 423.056C763.523 422.366 760.551 422 757.496 422C742.239 422 729.114 431.112 723.258 444.182L726.908 445.818Z'
        stroke='#8BB6DB'
      />
      <path d='M895.496 466.18L894.307 470L863.996 459.876L864.996 455.992L895.496 466.18Z' stroke='#8BB6DB' />
      <path d='M959.863 491.897L961.13 488.103L926.996 477L925.996 480.5L959.863 491.897Z' stroke='#8BB6DB' />
      <rect
        x='185.001'
        y='1321.78'
        width='18'
        height='5'
        transform='rotate(-1 185.001 1321.78)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='38.5399'
        y='1323.87'
        width='71'
        height='5'
        transform='rotate(-1 38.5399 1323.87)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='31.916'
        y='1456.59'
        width='183'
        height='5'
        transform='rotate(-6.92294 31.916 1456.59)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='62.0763'
        y='1671'
        width='175'
        height='5'
        transform='rotate(-9.58833 62.0763 1671)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='108.118'
        y='1805.6'
        width='72'
        height='5'
        transform='rotate(-12.2109 108.118 1805.6)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='215.254'
        y='1538.95'
        width='10'
        height='5'
        transform='rotate(-8.2563 215.254 1538.95)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='43.9963'
        y='1563.81'
        width='82'
        height='5'
        transform='rotate(-8.2563 43.9963 1563.81)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='225.781'
        y='1559.51'
        width='59'
        height='5'
        transform='rotate(-95.2024 225.781 1559.51)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='217.484'
        y='1468.34'
        width='73'
        height='5'
        transform='rotate(-95.2024 217.484 1468.34)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='207.882'
        y='1362.93'
        width='106'
        height='5'
        transform='rotate(-95.2024 207.882 1362.93)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='195.335'
        y='1225.1'
        width='31'
        height='5'
        transform='rotate(-95.2024 195.335 1225.1)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='230.24'
        y='1640.9'
        width='49'
        height='5'
        transform='rotate(-92.0124 230.24 1640.9)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='223.255'
        y='145.483'
        width='158'
        height='3'
        transform='rotate(105 223.255 145.483)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='283.474'
        y='206.656'
        width='125'
        height='3'
        transform='rotate(105 283.474 206.656)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='366.544'
        y='178.698'
        width='164'
        height='3'
        transform='rotate(105 366.544 178.698)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='424.825'
        y='293.468'
        width='27'
        height='3'
        transform='rotate(105 424.825 293.468)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='409.622'
        y='350.203'
        width='10'
        height='3'
        transform='rotate(105 409.622 350.203)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='464.315'
        y='73.1679'
        width='49'
        height='3'
        transform='rotate(102.887 464.315 73.1679)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='446.378'
        y='151.586'
        width='18'
        height='3'
        transform='rotate(102.887 446.378 151.586)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='1113.66'
        y='388.166'
        width='10'
        height='3'
        transform='rotate(112.803 1113.66 388.166)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='1143.81'
        y='316.464'
        width='42'
        height='3'
        transform='rotate(112.803 1143.81 316.464)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='1344.94'
        y='497.272'
        width='104'
        height='3'
        transform='rotate(-154.597 1344.94 497.272)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='1246.46'
        y='450.503'
        width='150'
        height='3'
        transform='rotate(-154.597 1246.46 450.503)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='1256.08'
        y='553.62'
        width='89'
        height='3'
        transform='rotate(-153.761 1256.08 553.62)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='1147.24'
        y='499.973'
        width='39'
        height='3'
        transform='rotate(-153.761 1147.24 499.973)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='1012.71'
        y='640.777'
        width='177'
        height='3'
        transform='rotate(-160.136 1012.71 640.777)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='829.576'
        y='468.078'
        width='109'
        height='3'
        transform='rotate(-169.544 829.576 468.078)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='962.798'
        y='491.863'
        width='37'
        height='3'
        transform='rotate(-171.489 962.798 491.863)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='893.955'
        y='481.56'
        width='12'
        height='3'
        transform='rotate(-171.489 893.955 481.56)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='1421.01'
        y='635.85'
        width='74'
        height='3'
        transform='rotate(-153.761 1421.01 635.85)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='1455.97'
        y='588.679'
        width='85'
        height='3'
        transform='rotate(-154.981 1455.97 588.679)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='1290.28'
        y='367.276'
        width='178'
        height='3'
        transform='rotate(115.306 1290.28 367.276)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='1361.58'
        y='472.245'
        width='36'
        height='3'
        transform='rotate(115.306 1361.58 472.245)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='1387.8'
        y='416.815'
        width='28'
        height='3'
        transform='rotate(115.306 1387.8 416.815)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='1433.98'
        y='443.23'
        width='87'
        height='3'
        transform='rotate(115.306 1433.98 443.23)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='1368.11'
        y='582.542'
        width='21'
        height='3'
        transform='rotate(115.306 1368.11 582.542)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='1617.92'
        y='560.407'
        width='107'
        height='3'
        transform='rotate(126.691 1617.92 560.407)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='1706.42'
        y='624.407'
        width='211'
        height='3'
        transform='rotate(128.146 1706.42 624.407)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='1792.69'
        y='691.536'
        width='104'
        height='3'
        transform='rotate(129.93 1792.69 691.536)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='1880.26'
        y='764.127'
        width='38'
        height='3'
        transform='rotate(132.46 1880.26 764.127)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='1832.66'
        y='816.148'
        width='18'
        height='3'
        transform='rotate(132.46 1832.66 816.148)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='1893.23'
        y='1041.54'
        width='70'
        height='3'
        transform='rotate(139.848 1893.23 1041.54)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='2045.27'
        y='913.272'
        width='62'
        height='3'
        transform='rotate(139.848 2045.27 913.272)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='1972.56'
        y='974.617'
        width='61'
        height='3'
        transform='rotate(139.848 1972.56 974.617)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='1505.13'
        y='711.771'
        width='13'
        height='3'
        transform='rotate(126.691 1505.13 711.771)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='336.455'
        y='294.87'
        width='81'
        height='3'
        transform='rotate(15 336.455 294.87)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='1966.59'
        y='1097.17'
        width='109'
        height='3'
        transform='rotate(141.112 1966.59 1097.17)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='1919.06'
        y='1130.14'
        width='83'
        height='3'
        transform='rotate(-130.392 1919.06 1130.14)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='1921.48'
        y='1338.2'
        width='34'
        height='3'
        transform='rotate(-129.906 1921.48 1338.2)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='1879.13'
        y='1287.57'
        width='26'
        height='3'
        transform='rotate(-129.906 1879.13 1287.57)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='1839.59'
        y='1240.28'
        width='61'
        height='3'
        transform='rotate(-129.906 1839.59 1240.28)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='1798.97'
        y='1306.2'
        width='94'
        height='3'
        transform='rotate(-129.061 1798.97 1306.2)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='1613.47'
        y='1405.66'
        width='16'
        height='3'
        transform='rotate(-131.673 1613.47 1405.66)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='1709.99'
        y='1514.08'
        width='77'
        height='3'
        transform='rotate(-131.673 1709.99 1514.08)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='1578.82'
        y='1366.74'
        width='16'
        height='3'
        transform='rotate(-131.673 1578.82 1366.74)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='1672.35'
        y='1541.56'
        width='78'
        height='3'
        transform='rotate(-129.179 1672.35 1541.56)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='1600.31'
        y='1453.16'
        width='67'
        height='3'
        transform='rotate(-129.179 1600.31 1453.16)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='1581.63'
        y='1606.78'
        width='104'
        height='3'
        transform='rotate(-125.993 1581.63 1606.78)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='1487.04'
        y='1667.88'
        width='213'
        height='3'
        transform='rotate(-123.55 1487.04 1667.88)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='1393.23'
        y='1724.19'
        width='107'
        height='3'
        transform='rotate(-123.55 1393.23 1724.19)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='1283.28'
        y='1558.38'
        width='7'
        height='3'
        transform='rotate(-123.55 1283.28 1558.38)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='1252.94'
        y='1674.49'
        width='86'
        height='3'
        transform='rotate(-124.332 1252.94 1674.49)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='1181.15'
        y='1829.01'
        width='47'
        height='3'
        transform='rotate(-117.922 1181.15 1829.01)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='1143.98'
        y='1758.13'
        width='23'
        height='3'
        transform='rotate(-117.922 1143.98 1758.13)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='990.993'
        y='1789.43'
        width='120'
        height='3'
        transform='rotate(-23.2316 990.993 1789.43)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='673.337'
        y='1831.96'
        width='92'
        height='3'
        transform='rotate(-18.6372 673.337 1831.96)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='699.751'
        y='1927.25'
        width='102'
        height='3'
        transform='rotate(-18.6372 699.751 1927.25)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='1030.84'
        y='1893.1'
        width='240'
        height='3'
        transform='rotate(-112.895 1030.84 1893.1)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='933.927'
        y='1931.39'
        width='99'
        height='3'
        transform='rotate(-111.077 933.927 1931.39)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='865.615'
        y='1754.15'
        width='54'
        height='3'
        transform='rotate(-111.077 865.615 1754.15)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='827.291'
        y='1970.37'
        width='244'
        height='3'
        transform='rotate(-111.354 827.291 1970.37)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='725.141'
        y='2004.65'
        width='46'
        height='3'
        transform='rotate(-108.034 725.141 2004.65)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='665.016'
        y='1819.98'
        width='16'
        height='3'
        transform='rotate(-108.034 665.016 1819.98)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='1194.65'
        y='1715.76'
        width='142'
        height='3'
        transform='rotate(-34.4339 1194.65 1715.76)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='1472.63'
        y='1456.71'
        width='31'
        height='3'
        transform='rotate(-125.993 1472.63 1456.71)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='1676.47'
        y='1342.68'
        width='90'
        height='3'
        transform='rotate(146.133 1676.47 1342.68)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='1567.77'
        y='1249.4'
        width='121'
        height='3'
        transform='rotate(139.415 1567.77 1249.4)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='1459.56'
        y='1184.26'
        width='41'
        height='3'
        transform='rotate(139.415 1459.56 1184.26)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='1563.92'
        y='924.19'
        width='65'
        height='3'
        transform='rotate(111.818 1563.92 924.19)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='1581.12'
        y='881.254'
        width='14'
        height='3'
        transform='rotate(111.818 1581.12 881.254)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='1421.6'
        y='930.969'
        width='66'
        height='3'
        transform='rotate(-139.808 1421.6 930.969)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='1440.02'
        y='758.41'
        width='42'
        height='3'
        transform='rotate(-138.051 1440.02 758.41)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='1538.28'
        y='1373.73'
        width='135'
        height='3'
        transform='rotate(145.563 1538.28 1373.73)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='1621.23'
        y='1316.86'
        width='65'
        height='3'
        transform='rotate(145.563 1621.23 1316.86)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='1400.12'
        y='1468.47'
        width='75'
        height='3'
        transform='rotate(145.563 1400.12 1468.47)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='1310.99'
        y='1529.57'
        width='61'
        height='3'
        transform='rotate(145.563 1310.99 1529.57)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='1234.3'
        y='1582.17'
        width='70'
        height='3'
        transform='rotate(145.563 1234.3 1582.17)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='1712.47'
        y='1381.16'
        width='91'
        height='3'
        transform='rotate(146.133 1712.47 1381.16)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='1605.18'
        y='1454.42'
        width='66'
        height='3'
        transform='rotate(143.428 1605.18 1454.42)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='1740.04'
        y='1349.17'
        width='150'
        height='3'
        transform='rotate(-35.1558 1740.04 1349.17)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='2034.37'
        y='1136.02'
        width='156'
        height='3'
        transform='rotate(141.112 2034.37 1136.02)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='71.0907'
        y='436.373'
        width='204'
        height='5'
        transform='rotate(8.67576 71.0907 436.373)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='90.9479'
        y='329.653'
        width='60'
        height='5'
        transform='rotate(15.6758 90.9479 329.653)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='200.157'
        y='397.396'
        width='57'
        height='5'
        transform='rotate(98.6758 200.157 397.396)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='190.501'
        y='460.665'
        width='84'
        height='5'
        transform='rotate(98.6758 190.501 460.665)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='172.7'
        y='543.933'
        width='65'
        height='5'
        transform='rotate(8.67576 172.7 543.933)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='64.1064'
        y='527.364'
        width='83'
        height='5'
        transform='rotate(8.67576 64.1064 527.364)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='131.267'
        y='626.164'
        width='90'
        height='5'
        transform='rotate(99.5851 131.267 626.164)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='240.369'
        y='896.59'
        width='39'
        height='5'
        transform='rotate(94.4569 240.369 896.59)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='248.017'
        y='798.406'
        width='66'
        height='5'
        transform='rotate(94.4569 248.017 798.406)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='262.697'
        y='610.086'
        width='157'
        height='5'
        transform='rotate(94.4569 262.697 610.086)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='179.296'
        y='861.864'
        width='67'
        height='5'
        transform='rotate(93.8214 179.296 861.864)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='116.53'
        y='858.063'
        width='75'
        height='5'
        transform='rotate(93.8214 116.53 858.063)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='95.5'
        y='1052.5'
        width='72'
        height='5'
        transform='rotate(-90 95.5 1052.5)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='51.824'
        y='709.564'
        width='197'
        height='5'
        transform='rotate(5.58515 51.824 709.564)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='144.558'
        y='928.909'
        width='87'
        height='5'
        transform='rotate(0.547415 144.558 928.909)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect
        x='32.8546'
        y='927.841'
        width='41'
        height='5'
        transform='rotate(0.547415 32.8546 927.841)'
        fill='#AECCE7'
        stroke='#8BB6DB'
      />
      <rect x='13.5' y='1079.5' width='78' height='5' fill='#AECCE7' stroke='#8BB6DB' />
      <rect x='13.5' y='1187.5' width='184' height='5' fill='#AECCE7' stroke='#8BB6DB' />
      <path d='M515.541 74.4817L525.24 76.9186L522.803 86.6172L513.104 84.1803L515.541 74.4817Z' fill='#9FA4AA' />
      <path d='M461.585 61.7343L471.329 63.9838L469.08 73.7275L459.336 71.478L461.585 61.7343Z' fill='#9FA4AA' />
      <path d='M410.651 50.7982L420.437 52.8563L418.378 62.6422L408.593 60.5841L410.651 50.7982Z' fill='#9FA4AA' />
      <path d='M244.072 19.9622L253.882 21.9054L251.938 31.7148L242.129 29.7716L244.072 19.9622Z' fill='#9FA4AA' />
      <path d='M301.264 30.2435L311.074 32.1866L309.131 41.996L299.321 40.0529L301.264 30.2435Z' fill='#9FA4AA' />
      <path d='M356.301 40.2435L366.132 42.0737L364.302 51.9048L354.471 50.0746L356.301 40.2435Z' fill='#9FA4AA' />
      <path d='M569.698 88.3099L579.374 90.8323L576.852 100.509L567.175 97.9865L569.698 88.3099Z' fill='#9FA4AA' />
      <path d='M624.013 103.029L633.631 105.768L630.891 115.386L621.273 112.646L624.013 103.029Z' fill='#9FA4AA' />
      <path d='M677.72 118.704L687.31 121.538L684.477 131.128L674.887 128.295L677.72 118.704Z' fill='#9FA4AA' />
      <path d='M731.296 134.962L740.849 137.918L737.893 147.471L728.34 144.515L731.296 134.962Z' fill='#9FA4AA' />
      <path d='M783.248 151.689L792.746 154.819L789.615 164.316L780.118 161.186L783.248 151.689Z' fill='#9FA4AA' />
      <path d='M835.436 169.044L844.87 172.362L841.552 181.796L832.118 178.478L835.436 169.044Z' fill='#9FA4AA' />
      <path d='M887.353 187.326L896.75 190.745L893.331 200.142L883.934 196.723L887.353 187.326Z' fill='#9FA4AA' />
      <path d='M938.802 206.224L948.143 209.794L944.573 219.135L935.232 215.565L938.802 206.224Z' fill='#9FA4AA' />
      <path d='M989.124 225.443L998.43 229.103L994.77 238.409L985.464 234.749L989.124 225.443Z' fill='#9FA4AA' />
      <path d='M1039.34 245.38L1048.62 249.101L1044.9 258.383L1035.62 254.662L1039.34 245.38Z' fill='#9FA4AA' />
      <path d='M1089.36 265.923L1098.59 269.773L1094.74 279.002L1085.51 275.152L1089.36 265.923Z' fill='#9FA4AA' />
      <path d='M1139.87 287.412L1149.06 291.363L1145.11 300.549L1135.92 296.598L1139.87 287.412Z' fill='#9FA4AA' />
      <path d='M1192.04 310.333L1201.14 314.468L1197.01 323.573L1187.9 319.439L1192.04 310.333Z' fill='#9FA4AA' />
      <path d='M1242.31 333.115L1251.39 337.312L1247.19 346.388L1238.12 342.191L1242.31 333.115Z' fill='#9FA4AA' />
      <path d='M1290 355.399L1299.03 359.7L1294.73 368.727L1285.7 364.426L1290 355.399Z' fill='#9FA4AA' />
      <path d='M1341.36 380.533L1350.26 385.083L1345.71 393.988L1336.81 389.438L1341.36 380.533Z' fill='#9FA4AA' />
      <path d='M1387.08 404.361L1395.82 409.213L1390.97 417.957L1382.22 413.105L1387.08 404.361Z' fill='#9FA4AA' />
      <path d='M1434.68 431.044L1443.34 436.044L1438.34 444.704L1429.68 439.704L1434.68 431.044Z' fill='#9FA4AA' />
      <path d='M1484.79 460.689L1493.32 465.908L1488.1 474.438L1479.57 469.219L1484.79 460.689Z' fill='#9FA4AA' />
      <path d='M1529.1 488.279L1537.43 493.82L1531.89 502.144L1523.56 496.604L1529.1 488.279Z' fill='#9FA4AA' />
      <path d='M1577.3 519.841L1585.63 525.382L1580.09 533.707L1571.76 528.166L1577.3 519.841Z' fill='#9FA4AA' />
      <path d='M1619.73 548.76L1627.9 554.524L1622.14 562.696L1613.97 556.932L1619.73 548.76Z' fill='#9FA4AA' />
      <path d='M1666.02 581.595L1674.09 587.491L1668.2 595.568L1660.12 589.672L1666.02 581.595Z' fill='#9FA4AA' />
      <path d='M1707.89 612.278L1715.84 618.347L1709.77 626.295L1701.82 620.226L1707.89 612.278Z' fill='#9FA4AA' />
      <path d='M1752.93 646.759L1760.83 652.892L1754.7 660.79L1746.8 654.657L1752.93 646.759Z' fill='#9FA4AA' />
      <path d='M1794.77 679.754L1802.56 686.024L1796.3 693.815L1788.5 687.545L1794.77 679.754Z' fill='#9FA4AA' />
      <path d='M1839.22 715.841L1846.98 722.152L1840.66 729.909L1832.91 723.598L1839.22 715.841Z' fill='#9FA4AA' />
      <path d='M1882.25 751.747L1889.84 758.255L1883.33 765.848L1875.74 759.34L1882.25 751.747Z' fill='#9FA4AA' />
      <path d='M1922.34 786.368L1929.86 792.962L1923.27 800.48L1915.75 793.887L1922.34 786.368Z' fill='#9FA4AA' />
      <path d='M1962.93 822.235L1970.34 828.946L1963.63 836.36L1956.22 829.649L1962.93 822.235Z' fill='#9FA4AA' />
      <path d='M2005.93 861.243L2013.35 867.954L2006.64 875.368L1999.22 868.657L2005.93 861.243Z' fill='#9FA4AA' />
      <path d='M2048.56 901.198L2055.85 908.038L2049.01 915.333L2041.72 908.493L2048.56 901.198Z' fill='#9FA4AA' />
      <path d='M2091.28 942.118L2098.39 949.146L2091.37 956.26L2084.25 949.232L2091.28 942.118Z' fill='#9FA4AA' />
      <path d='M2130.98 981.638L2138.09 988.666L2131.06 995.78L2123.95 988.752L2130.98 981.638Z' fill='#9FA4AA' />
      <path d='M2170.43 1021.75L2177.36 1028.96L2170.16 1035.89L2163.22 1028.69L2170.43 1021.75Z' fill='#9FA4AA' />
      <path d='M2206.11 1059.7L2212.22 1066.25L2205.94 1073.56L2198.73 1066.79L2206.11 1059.7Z' fill='#9FA4AA' />
      <path d='M510.586 2077.18L520.262 2074.66L517.74 2064.98L508.063 2067.5L510.586 2077.18Z' fill='#9FA4AA' />
      <path d='M454.4 2090.36L464.124 2088.03L461.789 2078.3L452.065 2080.64L454.4 2090.36Z' fill='#9FA4AA' />
      <path d='M395.745 2102.94L405.513 2100.8L403.369 2091.03L393.601 2093.17L395.745 2102.94Z' fill='#9FA4AA' />
      <path d='M227.215 2134.54L237.115 2133.13L235.703 2123.23L225.803 2124.64L227.215 2134.54Z' fill='#9FA4AA' />
      <path d='M285.183 2124.39L294.975 2122.36L292.946 2112.57L283.154 2114.59L285.183 2124.39Z' fill='#9FA4AA' />
      <path d='M340.512 2114.07L350.326 2112.16L348.41 2102.34L338.595 2104.26L340.512 2114.07Z' fill='#9FA4AA' />
      <path d='M564.222 2063.08L573.876 2060.48L571.268 2050.82L561.614 2053.43L564.222 2063.08Z' fill='#9FA4AA' />
      <path d='M618.804 2047.97L628.396 2045.14L625.572 2035.55L615.979 2038.37L618.804 2047.97Z' fill='#9FA4AA' />
      <path d='M672.104 2032.37L681.669 2029.45L678.752 2019.89L669.187 2022.8L672.104 2032.37Z' fill='#9FA4AA' />
      <path d='M725.139 2015.73L734.666 2012.69L731.626 2003.16L722.099 2006.2L725.139 2015.73Z' fill='#9FA4AA' />
      <path d='M777.722 1998.62L787.191 1995.41L783.978 1985.94L774.508 1989.15L777.722 1998.62Z' fill='#9FA4AA' />
      <path d='M829.209 1980.9L838.613 1977.5L835.213 1968.1L825.809 1971.5L829.209 1980.9Z' fill='#9FA4AA' />
      <path d='M884.56 1961.49L893.927 1957.99L890.425 1948.62L881.058 1952.12L884.56 1961.49Z' fill='#9FA4AA' />
      <path d='M936.291 1941.79L945.6 1938.14L941.948 1928.83L932.639 1932.48L936.291 1941.79Z' fill='#9FA4AA' />
      <path d='M985.857 1922.49L995.13 1918.75L991.388 1909.47L982.115 1913.22L985.857 1922.49Z' fill='#9FA4AA' />
      <path d='M1032.76 1903.78L1042.01 1899.98L1038.2 1890.73L1028.95 1894.53L1032.76 1903.78Z' fill='#9FA4AA' />
      <path d='M1087.21 1881.01L1096.4 1877.08L1092.47 1867.88L1083.28 1871.82L1087.21 1881.01Z' fill='#9FA4AA' />
      <path d='M1136.85 1859.49L1146 1855.46L1141.97 1846.31L1132.82 1850.34L1136.85 1859.49Z' fill='#9FA4AA' />
      <path d='M1183.32 1838.73L1192.39 1834.52L1188.18 1825.45L1179.11 1829.67L1183.32 1838.73Z' fill='#9FA4AA' />
      <path d='M1238.24 1813.34L1247.28 1809.06L1243 1800.02L1233.96 1804.3L1238.24 1813.34Z' fill='#9FA4AA' />
      <path d='M1285.67 1790.94L1294.66 1786.56L1290.28 1777.57L1281.29 1781.95L1285.67 1790.94Z' fill='#9FA4AA' />
      <path d='M1334.03 1767.55L1342.9 1762.93L1338.27 1754.06L1329.4 1758.69L1334.03 1767.55Z' fill='#9FA4AA' />
      <path d='M1395.73 1734.82L1404.43 1729.89L1399.5 1721.19L1390.8 1726.12L1395.73 1734.82Z' fill='#9FA4AA' />
      <path d='M1446.39 1705.55L1455.01 1700.48L1449.93 1691.86L1441.31 1696.94L1446.39 1705.55Z' fill='#9FA4AA' />
      <path d='M1490.53 1677.96L1499.01 1672.67L1493.72 1664.18L1485.24 1669.48L1490.53 1677.96Z' fill='#9FA4AA' />
      <path d='M1539.14 1647.93L1547.42 1642.32L1541.8 1634.05L1533.53 1639.66L1539.14 1647.93Z' fill='#9FA4AA' />
      <path d='M1586.02 1616.46L1594.29 1610.85L1588.68 1602.57L1580.4 1608.18L1586.02 1616.46Z' fill='#9FA4AA' />
      <path d='M1630.94 1585.09L1639.06 1579.25L1633.22 1571.13L1625.1 1576.96L1630.94 1585.09Z' fill='#9FA4AA' />
      <path d='M1675.61 1552.14L1683.63 1546.18L1677.66 1538.15L1669.64 1544.12L1675.61 1552.14Z' fill='#9FA4AA' />
      <path d='M1714.28 1523.33L1722.17 1517.19L1716.04 1509.29L1708.14 1515.43L1714.28 1523.33Z' fill='#9FA4AA' />
      <path d='M1759.99 1488.05L1767.83 1481.85L1761.63 1474L1753.78 1480.2L1759.99 1488.05Z' fill='#9FA4AA' />
      <path d='M1801.14 1454.69L1808.87 1448.35L1802.53 1440.61L1794.8 1446.95L1801.14 1454.69Z' fill='#9FA4AA' />
      <path d='M1848.6 1414.92L1856.3 1408.54L1849.92 1400.84L1842.22 1407.22L1848.6 1414.92Z' fill='#9FA4AA' />
      <path d='M1887.82 1382.11L1895.36 1375.53L1888.78 1368L1881.25 1374.57L1887.82 1382.11Z' fill='#9FA4AA' />
      <path d='M1926.61 1347.73L1934.07 1341.07L1927.41 1333.61L1919.95 1340.27L1926.61 1347.73Z' fill='#9FA4AA' />
      <path d='M1969.75 1308.77L1977.1 1301.99L1970.32 1294.64L1962.97 1301.41L1969.75 1308.77Z' fill='#9FA4AA' />
      <path d='M2014.79 1266.87L2022.15 1260.09L2015.37 1252.74L2008.02 1259.51L2014.79 1266.87Z' fill='#9FA4AA' />
      <path d='M2053.9 1229.53L2061.14 1222.63L2054.23 1215.39L2047 1222.29L2053.9 1229.53Z' fill='#9FA4AA' />
      <path d='M2092.62 1191.81L2099.67 1184.72L2092.58 1177.67L2085.53 1184.76L2092.62 1191.81Z' fill='#9FA4AA' />
      <path d='M2131.67 1152.26L2138.72 1145.17L2131.63 1138.12L2124.57 1145.21L2131.67 1152.26Z' fill='#9FA4AA' />
      <path d='M2170.76 1111.8L2177.64 1104.54L2170.37 1097.67L2163.5 1104.93L2170.76 1111.8Z' fill='#9FA4AA' />
      <path d='M108.456 1936.1L105.979 1926.41L115.667 1923.93L118.144 1933.62L108.456 1936.1Z' fill='#9FA4AA' />
      <path d='M121.372 1987.91L119.092 1978.17L128.828 1975.89L131.108 1985.63L121.372 1987.91Z' fill='#9FA4AA' />
      <path d='M137.128 2040.24L134.333 2030.64L143.934 2027.84L146.729 2037.44L137.128 2040.24Z' fill='#9FA4AA' />
      <path d='M152.253 2092.41L149.645 2082.76L159.299 2080.15L161.907 2089.8L152.253 2092.41Z' fill='#9FA4AA' />
      <path d='M168.657 2144.05L166.316 2135.92L175.842 2132.86L177.462 2142.7L168.657 2144.05Z' fill='#9FA4AA' />
      <path d='M95.241 1882.37L92.9946 1872.63L102.739 1870.38L104.985 1880.12L95.241 1882.37Z' fill='#9FA4AA' />
      <path d='M82.4022 1828.08L80.1735 1818.33L89.922 1816.1L92.1507 1825.85L82.4022 1828.08Z' fill='#9FA4AA' />
      <path d='M70.7655 1771.11L68.8036 1761.31L78.6093 1759.35L80.5712 1769.15L70.7655 1771.11Z' fill='#9FA4AA' />
      <path d='M61.6476 1724.87L59.9119 1715.02L69.7601 1713.29L71.4958 1723.14L61.6476 1724.87Z' fill='#9FA4AA' />
      <path d='M53.2354 1680.51L51.7531 1670.62L61.6427 1669.14L63.1249 1679.03L53.2354 1680.51Z' fill='#9FA4AA' />
      <path d='M44.2803 1627.24L42.9148 1617.34L52.8211 1615.97L54.1867 1625.88L44.2803 1627.24Z' fill='#9FA4AA' />
      <path d='M35.9051 1573.16L34.7283 1563.23L44.6588 1562.06L45.8356 1571.99L35.9051 1573.16Z' fill='#9FA4AA' />
      <path d='M29.7964 1520.09L28.6724 1510.16L38.6091 1509.03L39.733 1518.97L29.7964 1520.09Z' fill='#9FA4AA' />
      <path d='M23.0338 1465.6L21.7082 1455.68L31.62 1454.36L32.9456 1464.27L23.0338 1465.6Z' fill='#9FA4AA' />
      <path d='M17.0177 1412.25L16.1703 1402.28L26.1343 1401.44L26.9817 1411.4L17.0177 1412.25Z' fill='#9FA4AA' />
      <path d='M12.0805 1357.18L11.5611 1347.19L21.5477 1346.67L22.067 1356.66L12.0805 1357.18Z' fill='#9FA4AA' />
      <path d='M8.56787 1303.85L8.14094 1293.86L18.1318 1293.43L18.5587 1303.42L8.56787 1303.85Z' fill='#9FA4AA' />
      <path d='M17.6168 764.308L18.1704 754.323L28.1551 754.877L27.6014 764.862L17.6168 764.308Z' fill='#9FA4AA' />
      <path d='M23.2652 709.684L24.3861 699.747L34.3231 700.867L33.2022 710.804L23.2652 709.684Z' fill='#9FA4AA' />
      <path d='M29.3809 655.558L30.263 645.597L40.224 646.479L39.342 656.44L29.3809 655.558Z' fill='#9FA4AA' />
      <path d='M36.6593 602.824L37.8015 592.889L47.736 594.031L46.5939 603.966L36.6593 602.824Z' fill='#9FA4AA' />
      <path d='M43.9625 549.288L45.0134 539.343L54.958 540.394L53.9071 550.339L43.9625 549.288Z' fill='#9FA4AA' />
      <path d='M51.3925 494.35L52.864 484.459L62.7551 485.93L61.2836 495.821L51.3925 494.35Z' fill='#9FA4AA' />
      <path d='M59.8308 441.077L61.4503 431.209L71.3183 432.828L69.6988 442.696L59.8308 441.077Z' fill='#9FA4AA' />
      <path d='M69.1663 388.421L70.7522 378.547L80.6256 380.133L79.0398 390.007L69.1663 388.421Z' fill='#9FA4AA' />
      <path d='M79.2129 334.593L81.2335 324.799L91.0272 326.82L89.0066 336.614L79.2129 334.593Z' fill='#9FA4AA' />
      <path d='M89.3851 283.812L91.551 274.05L101.314 276.215L99.1478 285.978L89.3851 283.812Z' fill='#9FA4AA' />
      <path d='M98.9789 229.244L101.224 219.499L110.969 221.744L108.724 231.489L98.9789 229.244Z' fill='#9FA4AA' />
      <path d='M112.013 175.317L114.374 165.6L124.091 167.961L121.73 177.678L112.013 175.317Z' fill='#9FA4AA' />
      <path d='M125.004 121.374L127.687 111.741L137.32 114.424L134.637 124.057L125.004 121.374Z' fill='#9FA4AA' />
      <path d='M140.177 69.8021L143.101 60.239L152.664 63.1627L149.74 72.7257L140.177 69.8021Z' fill='#9FA4AA' />
      <path d='M157.501 15.091L160.194 6.14502L169.48 8.45404L167.017 18.0737L157.501 15.091Z' fill='#9FA4AA' />
      <path d='M5.42677 926.492V916.56H15.4268V926.492H5.42677Z' fill='#9FA4AA' />
      <path d='M13.0808 817.543L13.9644 807.65L23.9253 808.527L23.0417 818.421L13.0808 817.543Z' fill='#9FA4AA' />
      <path d='M8.27865 869.941L9.09064 860.042L19.0576 860.848L18.2456 870.748L8.27865 869.941Z' fill='#9FA4AA' />
      <path d='M3.51899 980.002V970.07H13.519V980.002H3.51899Z' fill='#9FA4AA' />
      <path d='M3.26252 1034.05L3.25104 1024.12L13.251 1024.11L13.2625 1034.04L3.26252 1034.05Z' fill='#9FA4AA' />
      <path d='M3.22401 1087.2V1077.27H13.224V1087.2H3.22401Z' fill='#9FA4AA' />
      <path d='M3.22401 1143.01V1133.07H13.224V1143.01H3.22401Z' fill='#9FA4AA' />
      <path d='M3.1875 1195.06V1185.13H13.1875V1195.06H3.1875Z' fill='#9FA4AA' />
      <path d='M5.01374 1249.46V1239.53H15.0137V1249.46H5.01374Z' fill='#9FA4AA' />
      <path
        d='M227.164 1973.96C227.164 1954.63 242.834 1938.96 262.164 1938.96C281.494 1938.96 297.164 1954.63 297.164 1973.96C297.164 1993.29 281.494 2008.96 262.164 2008.96C242.834 2008.96 227.164 1993.29 227.164 1973.96Z'
        fill='#9FA4AA'
      />
      <path
        d='M227.164 176C227.164 156.67 242.834 141 262.164 141C281.494 141 297.164 156.67 297.164 176C297.164 195.33 281.494 211 262.164 211C242.834 211 227.164 195.33 227.164 176Z'
        fill='#9FA4AA'
      />
      <path
        d='M1957.05 1076C1957.05 1062.19 1968.25 1051 1982.05 1051C1995.86 1051 2007.05 1062.19 2007.05 1076C2007.05 1089.81 1995.86 1101 1982.05 1101C1968.25 1101 1957.05 1089.81 1957.05 1076Z'
        fill='#9FA4AA'
      />
      <path d='M402 1895.32L450.063 1881.54L474.87 1968.05L426.807 1981.84L402 1895.32Z' fill='#9FA4AA' />
      <path d='M630.713 1831.62L668.759 1819.26L695.364 1901.14L657.318 1913.51L630.713 1831.62Z' fill='#9FA4AA' />
      <path d='M846.418 1762.93L892.443 1743.39L927.609 1826.24L881.583 1845.78L846.418 1762.93Z' fill='#9FA4AA' />
      <path d='M1269.44 1568.29L1302.98 1546.5L1352 1621.98L1318.45 1643.77L1269.44 1568.29Z' fill='#9FA4AA' />
      <path d='M1473.11 1457.28L1506.12 1433.04L1554.27 1496.93L1520.19 1522.34L1473.11 1457.28Z' fill='#9FA4AA' />
      <path d='M1653.87 1316.77L1683.12 1289.49L1744.5 1355.32L1715.25 1382.6L1653.87 1316.77Z' fill='#9FA4AA' />
      <path d='M1846 1170.28L1868.98 1151L1907.55 1196.96L1884.57 1216.25L1846 1170.28Z' fill='#9FA4AA' />
      <path d='M1878.52 993.461L1856.94 972.621L1898.62 929.46L1920.2 950.3L1878.52 993.461Z' fill='#9FA4AA' />
      <path d='M1692.41 860.262L1660.89 835.635L1716.3 764.714L1747.82 789.341L1692.41 860.262Z' fill='#9FA4AA' />
      <path d='M1510.01 714.4L1478.49 689.774L1527.75 626.733L1559.27 651.359L1510.01 714.4Z' fill='#9FA4AA' />
      <path d='M1318.22 591.972L1281.96 575.068L1320 493.5L1356.25 510.405L1318.22 591.972Z' fill='#9FA4AA' />
      <path d='M1108.77 488.945L1071.95 473.316L1104.77 396.003L1141.59 411.632L1108.77 488.945Z' fill='#9FA4AA' />
      <path d='M894.064 401.535L856.7 387.255L888.8 303.268L926.164 317.548L894.064 401.535Z' fill='#9FA4AA' />
      <path d='M678.013 324.551L639.713 313.015L663.7 233.373L702 244.909L678.013 324.551Z' fill='#9FA4AA' />
      <path d='M454.322 269.477L409.518 256.617L434.947 168.021L479.751 180.881L454.322 269.477Z' fill='#9FA4AA' />
      <path d='M1064.25 1671.53L1099.57 1652.76L1141.82 1732.22L1106.5 1751L1064.25 1671.53Z' fill='#9FA4AA' />
      <path d='M121.876 1525.03L211 1512.5L217.263 1557.06L128.139 1569.59L121.876 1525.03Z' fill='#9FA4AA' />
      <path d='M172 1762.14L245.024 1744.85L253.454 1780.46L180.43 1797.75L172 1762.14Z' fill='#9FA4AA' />
      <path d='M178.827 353.425L252 373.96L242.273 408.621L169.1 388.086L178.827 353.425Z' fill='#9FA4AA' />
      <path d='M92 1052.57H190V1096.57H92L92 1052.57Z' fill='#9FA4AA' />
      <path d='M109.49 1292.67L184.479 1291.36L185.09 1326.36L110.101 1327.67L109.49 1292.67Z' fill='#9FA4AA' />
      <path d='M135.809 581.534L222.58 596.187L215.087 640.559L128.316 625.906L135.809 581.534Z' fill='#9FA4AA' />
      <path d='M114.189 822.736L189.042 827.426L186.853 863.76L112 859.07L114.189 822.736Z' fill='#9FA4AA' />
      <rect x='176.781' y='970' width='79.22' height='10' fill='#9FA4AA' />
      <rect x='95.0156' y='970' width='49.2896' height='10' fill='#9FA4AA' />
      <rect x='12.9766' y='970' width='50.8066' height='10' fill='#9FA4AA' />
      <path d='M876 1065H973V1095H876V1065Z' fill='#9FA4AA' />
      <rect x='191.172' y='1187' width='35.5788' height='10' transform='rotate(-92.4599 191.172 1187)' fill='#9FA4AA' />
      <rect
        x='188.164'
        y='1120.03'
        width='140.971'
        height='10'
        transform='rotate(-92.4599 188.164 1120.03)'
        fill='#9FA4AA'
      />
      <path d='M300.105 1041L304.062 942.607L294.084 941.941L287.524 1040.22L300.105 1041Z' fill='#9FA4AA' />
      <rect
        x='265.031'
        y='1310.5'
        width='170.212'
        height='10'
        transform='rotate(-93.7148 265.031 1310.5)'
        fill='#9FA4AA'
      />
      <rect x='272' y='1264' width='165' height='10' fill='#9FA4AA' />
      <rect x='277' y='1342' width='160' height='4' fill='#9FA4AA' />
      <rect x='283.727' y='1436' width='143.276' height='4' fill='#9FA4AA' />
      <rect
        x='442.422'
        y='1482.03'
        width='122.896'
        height='4'
        transform='rotate(-1.5976 442.422 1482.03)'
        fill='#9FA4AA'
      />
      <rect x='329' y='620' width='114.844' height='4' fill='#9FA4AA' />
      <rect x='325' y='688' width='104.549' height='4' fill='#9FA4AA' />
      <rect x='318' y='777' width='119' height='4' fill='#9FA4AA' />
      <rect
        x='963.664'
        y='489.062'
        width='146.12'
        height='4'
        transform='rotate(19.3238 963.664 489.062)'
        fill='#9FA4AA'
      />
      <rect
        x='1458.16'
        y='1181.18'
        width='99.6027'
        height='4'
        transform='rotate(-40.4554 1458.16 1181.18)'
        fill='#9FA4AA'
      />
      <rect
        x='1537.73'
        y='1158.61'
        width='141.915'
        height='4'
        transform='rotate(-23.4801 1537.73 1158.61)'
        fill='#9FA4AA'
      />
      <rect
        x='1601.84'
        y='1233.26'
        width='70.1961'
        height='4'
        transform='rotate(-40.4554 1601.84 1233.26)'
        fill='#9FA4AA'
      />
      <rect
        x='1446.05'
        y='1196.92'
        width='116.104'
        height='4'
        transform='rotate(49.5616 1446.05 1196.92)'
        fill='#9FA4AA'
      />
      <rect
        x='1517.73'
        y='1134.53'
        width='133.688'
        height='4'
        transform='rotate(49.5616 1517.73 1134.53)'
        fill='#9FA4AA'
      />
      <rect
        x='1463.71'
        y='774.062'
        width='157.419'
        height='4'
        transform='rotate(40.9211 1463.71 774.062)'
        fill='#9FA4AA'
      />
    </>
  );
};
